import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import { axiosInstance } from "../../../network/client";
import { BEResponse } from "../../../types/BEResponse";
import {
  InviteNewUserBody,
  ResetPasswordTokenReqBody,
} from "../../../types/user";

export interface GeneralState {
  path: string;
  token?: any;
  expandSideBar: boolean;
  loading: boolean;
  user: string;
  roles: string[];
  rolesMetaData: BEResponse;
  decodedToken: any;
  resetPasswordEmailResp: any;
  resetPasswordUserResp: any;
  inviteUserResp: BEResponse;
  resetPasswordTokenResp: any;
  updateProrfile: any;
  createNewClusterResp: any;
  queryClusterResponse: BEResponse;
  queryLoad: boolean;
}

const initialState: GeneralState = {
  path: "",
  token: localStorage.getItem("token") || "",
  expandSideBar: true,
  loading: false,
  user: "",
  roles: [],
  rolesMetaData: {},
  decodedToken: {},
  resetPasswordEmailResp: null,
  resetPasswordUserResp: null,
  resetPasswordTokenResp: null,
  updateProrfile: null,
  inviteUserResp: {},
  createNewClusterResp: {},
  queryClusterResponse: {},
  queryLoad: false,
};

if (initialState.token) {
  axiosInstance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${initialState.token}`;
  const decodedToken: any = jwt_decode(initialState.token);
  initialState.decodedToken = decodedToken;
  initialState.user = decodedToken.sub;
  initialState.roles = decodedToken.roles;
} else {
  //TODO redirect to BE Login
}
export const API_URL = process.env.REACT_APP_API_ENDPOINT;

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setPath: (state, action) => {
      state.path = action.payload;
    },
    logout: (state) => { },
    logoutSuccess: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("pkce");
      axiosInstance.defaults.headers.common["Authorization"] = undefined;
      state.user = "";
      state.roles = [];
      window.location.href = `${API_URL}/mylogout`;
      //call logout api
    },
    toggleSideBar: (state) => {
      state.expandSideBar = !state.expandSideBar;
    },
    requestToken: (state, action) => {
      state.loading = true;
    },
    setToken: (state, action) => {
      state.token = action.payload;
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${state.token}`;
      localStorage.setItem("token", state.token);
      const decodedToken: any = jwt_decode(state.token);
      state.user = decodedToken.sub;
      state.decodedToken = decodedToken;
      state.roles = decodedToken.roles;
      state.loading = false;
    },
    authorizeRequest: (state, action) => {
      state.loading = true;
    },
    resetPasswordEmailRequest: (state, action) => {
      state.loading = true;
    },
    resetPasswordSentSuccessfully: (state, action) => {
      state.loading = false;
      state.resetPasswordEmailResp = action.payload;
    },
    resetPasswordUserRequest: (state, action) => {
      state.loading = true;
      state.resetPasswordUserResp = null;
    },
    resetPasswordSentUserDone: (state, action) => {
      state.loading = false;
      state.resetPasswordUserResp = action.payload;
    },
    resetPasswordTokenRequest: (
      state,
      action: PayloadAction<ResetPasswordTokenReqBody>
    ) => {
      state.loading = true;
      state.resetPasswordTokenResp = null;
    },
    resetPasswordSentTokenDone: (state, action) => {
      state.loading = false;
      state.resetPasswordTokenResp = action.payload;
    },
    updateProfile: (state, action) => {
      state.loading = true;
      state.updateProrfile = null;
    },
    updateProfileDone: (state, action) => {
      state.loading = false;
      state.updateProrfile = action.payload;
    },
    inviteNewUserRequest: (state, action: PayloadAction<InviteNewUserBody>) => {
      state.loading = true;
      state.inviteUserResp = {};
    },
    clearInviteNewUser: (state) => {
      state.loading = true;
      state.inviteUserResp = {};
    },
    inviteNewUserDone: (state, action) => {
      state.loading = false;
      state.inviteUserResp = action.payload;
    },
    getRolesRequest: (state, action) => { },
    setRolesData: (state, action) => {
      state.rolesMetaData = action.payload;
    },
    createNewClusterRequest: (state, actions) => {
      state.loading = true;
      state.createNewClusterResp = {};
    },
    createNewClusterDone: (state, action) => {
      state.loading = false;
      state.createNewClusterResp = action.payload;
    },
    createQueryRequest: (state, actions) => {
      state.queryLoad = true;
      state.queryClusterResponse = {};
    },

    createQueryDone: (state, action) => {
      state.queryLoad = false;
      state.queryClusterResponse = action.payload;
    },
  },
});

export const {
  setPath,
  logout,
  toggleSideBar,
  requestToken,
  setToken,
  authorizeRequest,
  resetPasswordEmailRequest,
  resetPasswordSentSuccessfully,
  resetPasswordUserRequest,
  resetPasswordSentUserDone,
  logoutSuccess,
  resetPasswordTokenRequest,
  resetPasswordSentTokenDone,
  updateProfile,
  updateProfileDone,
  inviteNewUserRequest,
  inviteNewUserDone,
  getRolesRequest,
  setRolesData,
  createNewClusterRequest,
  createNewClusterDone,
  clearInviteNewUser,
  createQueryRequest,
  createQueryDone,
} = generalSlice.actions;

export default generalSlice;

import { useSelector } from "react-redux";
import { RoleTypes } from "../routes/config";
import { selectRoles } from "../store/slices/general/selectors";

const usePermissions = (allowedRoles: RoleTypes[]) => {
    const userRoles = useSelector(selectRoles) || [];
    if (!allowedRoles || allowedRoles.length === 0){
        return true;
    }
    const intersection = allowedRoles.filter(item => userRoles.includes(item));
    return intersection.length > 0;
};

export default usePermissions;
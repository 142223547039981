import {PayloadAction} from "@reduxjs/toolkit";
import {call, put, takeLatest} from "redux-saga/effects";
import {Response} from "../../../types/Response";

import {
  deleteBackup,
  deleteBackupDone,
  getBackupPlansData,
  getBackupPlansDataDone,
  getBackupPlansDataError,
  getBackupsData,
  getBackupsDataDone,
  getBackupsDataError,
  restoreBackup,
  restoreBackupDone,
  saveNewBackup,
  saveNewBackupDone,
  saveNewBackupPlan,
  updateBackupPlan,
  updateBackupPlanDone,
} from "../../slices/backupRestore";
// import { toast } from "react-toastify";
import {
  BackupRestoreReqBody,
  BackupsFilterReqBody,
  SaveBackupPlanReqBody,
  SaveBackupReqBody,
  UpdateBackupPlanReqBody,
} from "../../../types/backupRestore";
import backupRestore from "../../../apis/backupRestore";
import clusterManagement from "../../../apis/clusterManagement";
import {toastify, toastTypes} from "../../../hooks/useToast";

export function* handleGetBackupsData(
  action: PayloadAction<{ clusterIds?: Array<string> }>
): any {
  try {
    const regionResponse: Response<any> = yield call(
      clusterManagement.getClusterRegions
    );
    let provider = null;
    if (regionResponse.success) {
      provider = regionResponse.data.data[0].cloud;
      const request = {
        cloudProvider: provider,
        ...(action.payload.clusterIds
          ? { clusterIds: action.payload.clusterIds }
          : {}),
      };
      const response: Response<any> = yield call(
        backupRestore.getBackups,
        request
      );
      if (response.success) {
        const { data } = response;
        yield put(getBackupsDataDone(data.data));
      } else {
        yield put(getBackupsDataError("error"));
      }
    } else {
      yield put(getBackupsDataError("error"));
    }
  } catch (error: any) {
    yield put(getBackupsDataError("error"));
    // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
  }
}

export function* handleGetBackupPlansData(
  action: PayloadAction<BackupsFilterReqBody>
): any {
  try {
    const response: Response<any> = yield call(
      backupRestore.getBackupPlans,
      action.payload
    );
    if (response.success) {
      const { data } = response;
      yield put(getBackupPlansDataDone(data.data));
    } else {
      yield put(getBackupPlansDataError("error"));
    }
  } catch (error: any) {
    yield put(getBackupPlansDataError("error"));
    // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
  }
}

export function* handleDeleteBackup(
  action: PayloadAction<{ clusterId: string; backupId: string }>
): any {
  try {
    const response: Response<any> = yield call(
      backupRestore.deleteBackup,
      action.payload.clusterId,
      action.payload.backupId
    );
    if (response.success) {
      const { data } = response;
      yield put(deleteBackupDone(data.data));
      toastify("Backup Deleted Successfully!", toastTypes.success);
    } else {
      //   toastify(
      //     "Error while deleting the backup, Please try again later.",
      //     toastTypes.error
      //   );
    }
  } catch (error: any) {
    // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    toastify(
      "Error while deleting the backup, Please try again later.",
      toastTypes.error
    );
  }
}

export function* handleUpdateBackupPlan(
  action: PayloadAction<{
    clusterId: string;
    body: UpdateBackupPlanReqBody;
    navigate?: any;
  }>
): any {
  try {
    const response: Response<any> = yield call(
      backupRestore.updateBackupPlan,
      action.payload.clusterId,
      action.payload.body
    );
    if (response.success) {
      const { data } = response;
      yield put(updateBackupPlanDone(data.data));
      toastify(`Backup Plan has been Updated!`, toastTypes.success);
      yield put(getBackupPlansData({}));
      if (action.payload.navigate) action.payload.navigate(-1);
    } else {
      //   toastify(
      //     "Error while performing action, Please try again later.",
      //     toastTypes.error
      //   );
    }
  } catch (error: any) {
    toastify(
      "Error while performing action, Please try again later.",
      toastTypes.error
    );
  }
}

export function* handleRestoreBackup(
  action: PayloadAction<{
    clusterId: string;
    body: BackupRestoreReqBody;
    navigate?: any;
  }>
): any {
  try {
    const response: Response<any> = yield call(
      backupRestore.restoreBackup,
      action.payload.clusterId,
      action.payload.body
    );
    if (response.success) {
      const { data } = response;
      yield put(restoreBackupDone(data.data));
      toastify("Backup Restored Successfully!", toastTypes.success);
      yield put(getBackupsData({}));
      if (action.payload.navigate) action.payload.navigate(-1);
    } else {
      //   toastify(
      //     "Error while restoring the backup, Please try again later.",
      //     toastTypes.error
      //   );
    }
  } catch (error: any) {
    // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    toastify(
      "Error while restoring the backup, Please try again later.",
      toastTypes.error
    );
  }
}

export function* handleSaveBackup(
  action: PayloadAction<{
    clusterId: string;
    body: SaveBackupReqBody;
    navigate?: any;
  }>
): any {
  try {
    const response: Response<any> = yield call(
      backupRestore.saveBackup,
      action.payload.clusterId,
      action.payload.body
    );
    if (response.success) {
      const { data } = response;
      yield put(saveNewBackupDone(data.data));
      toastify("Backup Saved Successfully!", toastTypes.success);
      yield put(getBackupsData({}));
      if (action.payload.navigate) action.payload.navigate(-1);
    } else {
      //   toastify(
      //     "Error while saving the backup, Please try again later.",
      //     toastTypes.error
      //   );
    }
  } catch (error: any) {
    // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    toastify(
      "Error while saving the backup, Please try again later.",
      toastTypes.error
    );
  }
}

export function* handleSaveBackupPlan(
  action: PayloadAction<{
    clusterId: string;
    body: SaveBackupPlanReqBody;
    navigate?: any;
  }>
): any {
  try {
    const response: Response<any> = yield call(
      backupRestore.saveBackupPlan,
      action.payload.clusterId,
      action.payload.body
    );
    if (response.success) {
      const { data } = response;
      yield put(saveNewBackupDone(data.data));
      toastify("Backup Plan Saved Successfully!", toastTypes.success);
      yield put(getBackupPlansData({}));
      if (action.payload.navigate) action.payload.navigate(-1);
    } else {
      //   toastify(
      //     "Error while saving the backup plan, Please try again later.",
      //     toastTypes.error
      //   );
    }
  } catch (error: any) {
    // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    toastify(
      "Error while saving the backup plan, Please try again later.",
      toastTypes.error
    );
  }
}

export function* backRestoreSaga() {
  // generator function
  yield takeLatest(getBackupsData, handleGetBackupsData);
  yield takeLatest(getBackupPlansData, handleGetBackupPlansData);
  yield takeLatest(deleteBackup, handleDeleteBackup);
  yield takeLatest(restoreBackup, handleRestoreBackup);
  yield takeLatest(updateBackupPlan, handleUpdateBackupPlan);
  yield takeLatest(saveNewBackup, handleSaveBackup);
  yield takeLatest(saveNewBackupPlan, handleSaveBackupPlan);
}

import styled from "styled-components";
export const Container = styled.div `
  width: 100%;
  height: 100%;
`;

export const RestoreBackupContainer = styled.div `
   display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const AddRestoreBackup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px 36px 16px 35px;
  gap: 10px;
  cursor: pointer;
  

  background: #5C2EDF;
  border-radius: 26.5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const BackupPlansButton = styled.div<{disabled: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px 36px 16px 35px;
  gap: 10px;
  cursor: ${p => p.disabled? "not-allowed" : "pointer"};
  

  background: ${p => p.disabled ? "#EEEEEE" : "#5C2EDF" };
  border-radius: 26.5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;



export const ButtonTitle = styled.div `
  
  /* Link & Button - Inconsolata Bold 17 - 10% Kerning */

  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 111%;
  /* identical to box height, or 19px */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #FBFBFB;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const FilterHeaderContainer = styled.div`
  width: 100%;
  padding: 0 22px ;
`;

export const PlusIcon = styled.img `

`;
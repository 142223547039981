import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import colorConfigs from "../../configs/colorConfigs";
import { RouteType } from "../../routes/config";
import SidebarItem from "./SidebarItem";
import { useSelector } from "react-redux";
import {
  selectExpandSidebar,
  selectPath,
} from "../../store/slices/general/selectors";
import usePermissions from "../../hooks/usePermissions";
import { useTranslation } from "react-i18next";

type Props = {
  item: RouteType;
};

const SidebarItemCollapse = ({ item }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const expand = useSelector(selectExpandSidebar);
  const path = useSelector(selectPath);
  const enabled = usePermissions(item.roles || []);
  console.log(enabled);

  useEffect(() => {
    if (path.includes(item.state)) {
      setOpen(true);
    }
  }, [path, item]);

  const getColor = () => {
    if (item.sidebarProps?.disabled) {
      return colorConfigs.sidebar.disabledAction;
    }
    return colorConfigs.sidebar.color;
  };

  const getFontSize = () => {
    if (!expand) {
      return "15px";
    }
    return path === item.state ? "20px" : "18px";
  };

  const getIcon = () => {
    if (open) {
      return item.sidebarProps?.selectedIcon;
    }
    if (item.sidebarProps?.disabled) {
      return item.sidebarProps?.unAvailableIcon;
    }
    return item.sidebarProps?.icon;
  };

  return item.sidebarProps && enabled ? (
    <>
      <ListItemButton
        disableRipple
        disabled={item.sidebarProps.disabled}
        onClick={() => setOpen(!open)}
        sx={{
          "&: hover": {
            backgroundColor: "unset",
          },
          color: getColor(),
          fontSize: getFontSize(),
          lineHeight: 0,
          fontWeight: path === item.state ? 700 : 500,
          fontStyle: "normal",
          marginBottom: "-15px",
          fontFamily: "Poppins",
          flexDirection: expand ? "row" : "column",
        }}
      >
        <ListItemIcon
          sx={{
            width: expand ? "36px" : "auto",
            minWidth: "auto",
            color: colorConfigs.sidebar.color,
          }}
        >
          {item.sidebarProps.icon && getIcon()}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography
              sx={{
                fontWeight: open && expand ? "600" : "500",
                fontSize: open && expand ? "18px" : "16px",
              }}
            >
              {t(item.sidebarProps.displayText)}
            </Typography>
          }
        />
        {/* {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />} */}
      </ListItemButton>
      <Collapse in={open && expand} timeout="auto">
        <List>
          {item.child?.map((route, index) =>
            route.sidebarProps ? <SidebarItem item={route} key={index} /> : null
          )}
        </List>
      </Collapse>
    </>
  ) : null;
};

export default SidebarItemCollapse;

import {POST} from '../network/client';
import {Response} from "../types/Response";

const paths = {
    GET_ROLES: `/v1/platform-roles/filter?size=1000`,
};

const getRoles = (): Promise<Response<any>> => POST<any>(paths.GET_ROLES, {});

const roles = { getRoles };

export default roles;

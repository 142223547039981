import { useMemo, useState } from "react";
import { ItemType } from "../../../../Components/FilterHeader/types";
import ActionCell from "../CustomCells/Action/index";
import IPCell from "../../CreateNewCluster/CustomCells/IPCell";
import {useTranslation} from "react-i18next";


interface IP {
    ip : string;
}

const useTable = (tableRef: any) => {
    const {t} = useTranslation();
    const [ipInput,setIpInput] = useState("");
    const [ipError, setIpError] = useState("");


    const providerData = useMemo(() => [{provider: "", region:"us-west-2", clusterType:"to be added", version:"1.0.0.0"}], []);


    const trustedIPSColumns = useMemo(
        () => [
            {
                Header: t("ui.cluster.columns.ip"),
                accessor: 'address',
                type: ItemType.text,
                allowFilter: false,
                disableSortBy: true,
                Cell: IPCell,
            },
            {
                Header: t("ui.user.management.columns.action"),
                accessor: 'Action',
                type: ItemType.text,
                disableSortBy: true,
                allowFilter: false,
                width: 25,
                Cell: ActionCell,
            },
        ],
        [t]
    );


    const handleIpChange = (text: string) => {
        setIpInput(text);
        setIpError("");
    }

    return {trustedIPSColumns, providerData, ipInput, handleIpChange, ipError, setIpError};
};

export default useTable;

import { useEffect, useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
  ClustersAdminListContainer,
  ClustersAdminListHeaderContainer,
  ClustersAdminListTableContainer,
  TableContainer,
} from "./styles";
import TableTest from "../../../../Components/Table2";
import ClusterAdminListHeader from "../../../../Components/ClusterAdminListHeader";
import useTable from "./hooks/useTable";
import useFilterSearch from "../../../../hooks/useFilterSearch";
import { useNavigate } from "react-router";
import { getClusterTypes } from "../../../../store/slices/clusterManagement";
interface RefType {
  getCheckboxRecords: Array<Record<string, any>>;
}

const Clusters = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [headerHeight, setHeaderHeight] = useState<any>(175);
  const ref = useRef<HTMLInputElement>(null);
  const tableRef = useRef<RefType>(null);
  const { initialState, columns, data, globalFilterFunction } = useTable();
  const {
    filterSearchCols,
    handleColumnCheckChange,
    handleResetAllFilters,
    handleResetFilter,
    handleColumnFilterValueChange,
    tableFilters,
    handleRemoveFilter,
    handleGlobalFilterChange,
    globalFilterValue,
  } = useFilterSearch(columns);
  const createNewCluster = () => {
    navigate(`create`, {
      relative: "route",
    });
  };

  useEffect(() => {
    dispatch(getClusterTypes());
  }, [dispatch]);

  const onSelectedRowsChange = useCallback(
    (ids: any) => {
      // console.log("selected ids", ids);
      // if(tableRef.current){
      //     const selectedRows = tableRef.current.getCheckboxRecords;
      //     console.log(selectedRows);
      //     if(selectedRows[0]){
      //         navigate(selectedRows[0].clusterName || 'Cluster',{
      //             state: selectedRows[0],
      //             relative: 'route',
      //         });
      //     }
      //         // navigate("/Clusters/ClustersList", {state: selectedRows[0]});
      // }
    },
    [navigate]
  );

  // const onDoubleClick = useCallback(
  //   (row: any) => {
  //     console.log("onDoubleClick", row);
  //     if (row) {
  //       navigate(row.clusterName || "Cluster", {
  //         state: row,
  //         relative: "route",
  //       });
  //     }
  //   },
  //   [navigate]
  // );

  return (
    <ClustersAdminListContainer>
      <ClustersAdminListTableContainer>
        <ClustersAdminListHeaderContainer ref={ref}>
          <ClusterAdminListHeader
            globalFilterValue={globalFilterValue}
            handleGlobalFilterChange={handleGlobalFilterChange}
            handleRemoveFilter={handleRemoveFilter}
            createNewCluster={createNewCluster}
            filterSearchCols={filterSearchCols}
            handleColumnCheckChange={handleColumnCheckChange}
            handleResetAllFilters={handleResetAllFilters}
            handleColumnFilterValueChange={handleColumnFilterValueChange}
            handleResetFilter={handleResetFilter}
          />
        </ClustersAdminListHeaderContainer>
        {/* <App /> */}
        {/* <div style={{ width: "100%" }}><RefAutoComplete
          className="inline-autocomplete-example"
          dataSource={dataSource}
          caseSensitive={false}
          onChange={onChange}
          onSelect={onSelect} /></div> */}
        <TableContainer height={headerHeight}>
          <TableTest
            // onDoubleClick={onDoubleClick}
            globalFilterValue={globalFilterValue}
            globalFilterFunction={globalFilterFunction}
            tableInitialState={initialState}
            ref={tableRef}
            data={data}
            columns={columns}
            size="small"
            rowHover={true}
            filters={tableFilters}
          />
        </TableContainer>
      </ClustersAdminListTableContainer>
    </ClustersAdminListContainer>
  );
};

export default Clusters;

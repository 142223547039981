import React, { useMemo } from "react";
import {
  AmazonStyled,
  GoogleCloudStyled,
  MicrosoftStyled,
  ProviderIconContainer,
  VerticalLine,
} from "./styles";
import amazonIcon from "../../../assets/svg/amazon_logo.svg";
import awsDisabled from "../../../assets/svg/aws_disabled.svg";
import azureEnabled from "../../../assets/svg/azure_enabled.svg";
import azureDisabled from "../../../assets/svg/microsoft_logo.svg";
import googleDisabled from "../../../assets/svg/google_cloud_logo.svg";
import googleEnabled from "../../../assets/svg/google_enabled.svg";

export default function CloudServices({
  cloud,
  handleFieldChange,
  register,
  cloudDisabled,
  t,
}: any) {
  const services = useMemo(
    () => [
      {
        key: "AWS",
        component: (
          <AmazonStyled
            onClick={() => !cloudDisabled && handleFieldChange("cloud", "AWS")}
            src={cloud === "AWS" ? amazonIcon : awsDisabled}
            alt={"amazonIcon"}
          />
        ),
      },
      {
        key: "AZURE",
        component: (
          <MicrosoftStyled
            onClick={() =>
              !cloudDisabled && handleFieldChange("cloud", "AZURE")
            }
            src={cloud === "AZURE" ? azureEnabled : azureDisabled}
            alt={"microsoftIcon"}
          />
        ),
      },
      {
        key: "GCP",
        component: (
          <GoogleCloudStyled
            onClick={() => !cloudDisabled && handleFieldChange("cloud", "GCP")}
            src={cloud === "GCP" ? googleEnabled : googleDisabled}
            alt={"googleCloudIcon"}
          />
        ),
      },
    ],
    [cloud, cloudDisabled, handleFieldChange]
  );

  return (
    <ProviderIconContainer>
      {services
        .sort((a, b) => {
          if (a.key === cloud) return -1;
          if (b.key === cloud) return 1;
          return 0;
        })
        .map((service, index) => (
          <React.Fragment key={service.key}>
            {service.component}
            {index < services.length - 1 && <VerticalLine />}
          </React.Fragment>
        ))}
    </ProviderIconContainer>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  align-items: flex-start;
`;

export const Title = styled.div<{signInScreen?: boolean}> `
  font-style: normal;
  font-family: ${({ theme: { language } }) =>
    language === "eng" ? "Poppins" : "unset"};
  font-weight: 500;
  font-size:18px;
  line-height: 100%;
  letter-spacing: -0.02em;
  /* or 18px */
  display: flex;
  color: #FFFFFF;
  padding-bottom: 10px;
`;

export const CloseIconStyled = styled.img`
  cursor: pointer;
  padding: 18px 12px 19px 0px;
`;


import { Autocomplete } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    position: relative;
`;

export const Title = styled.div<{titleEmpty?: boolean}>`
    font-style: normal;
    font-family: unset;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    display: flex;
    color: rgb(0, 0, 0);
    padding-bottom: ${(props) => (props.titleEmpty ? "" : "10")}px;
`;

export const AutoCompleteMUI = styled(Autocomplete) `

`;

export const OptionItem = styled.li `
  &.MuiAutocomplete-option[aria-selected="true"] {
    background-color:  #F6F5F5 !important;
  }
`;

export const ExpandIcon = styled.img `

`;


import {useEffect, useMemo, useRef, useState} from "react";
import { useDispatch } from "react-redux";
import {AccountsContainer, AccountsHeaderContainer, AccountsTableContainer, TableContainer} from "./styles";
import AccountsHeader from "../../Components/AccountsHeader/AccountsHeader";
import TableTest from "../../Components/Table2";
import CreateNewAccount from "../../Components/CreateNewAccount/CreateNewAccount";
import useTable from "./hooks/useTable";
import useFilterSearch from "../../hooks/useFilterSearch";
import { getAccountsDataRequest } from "../../store/slices/accounts";
import { useNavigate } from "react-router";


const Accounts = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [headerHeight, setHeaderHeight] = useState<any>(175);
    const ref = useRef<HTMLInputElement>(null);
    const {initialState, columns, data, globalFilterFunction} = useTable();
    const {filterSearchCols,
            handleColumnCheckChange,
            handleResetAllFilters,
            handleResetFilter,
            handleColumnFilterValueChange,
            tableFilters,
            handleRemoveFilter,
            globalFilterValue,
            handleGlobalFilterChange} = useFilterSearch(columns);

    const openCreateNewAccount = () => {
        navigate('/Admin/Accounts/new');
    };


    const refapi = useRef<any>(null)

    useEffect(() => {
        dispatch(getAccountsDataRequest({}));
        refapi.current = setInterval(() => {
            dispatch(getAccountsDataRequest({}));
        }, 5 * 1000);

    return () => {
        if(refapi.current){
        clearInterval(refapi.current)
        }
    }
    }, [dispatch])




    return (
        <AccountsContainer>
            <AccountsTableContainer>
                <AccountsHeaderContainer ref={ref}>
                    <AccountsHeader 
                    createNewAccount={openCreateNewAccount} 
                    handleRemoveFilter={handleRemoveFilter} 
                    filterSearchCols={filterSearchCols} 
                    handleColumnCheckChange={handleColumnCheckChange} 
                    handleResetAllFilters={handleResetAllFilters} 
                    handleColumnFilterValueChange={handleColumnFilterValueChange} 
                    globalFilterValue={globalFilterValue}
                    handleGlobalFilterChange={handleGlobalFilterChange}
                    handleResetFilter={handleResetFilter} />
                </AccountsHeaderContainer>
                <TableContainer height={headerHeight}>
                    <TableTest tableInitialState={initialState} data={data} columns={columns}  size="small" filters={tableFilters} globalFilterFunction={globalFilterFunction} globalFilterValue={globalFilterValue} />
                </TableContainer>
            </AccountsTableContainer>
        </AccountsContainer>
    );
};

export default Accounts;

import styled from "styled-components";

interface TableContainerType {
    height: number;
}

export const AccountsContainer = styled.div `
    height: 100%;
`;

export const AccountsTableContainer = styled.div `
    height: 100%;
`;

export const AccountsHeaderContainer = styled.div `
    margin-bottom: 27px;
`;
export const CellWrapper = styled.span `

`;

export const TableContainer = styled.div<TableContainerType> `
     height: calc(100% - ${props => props.height}px);
`;

import {ReactComponent as StartingStatus} from "../../../../../../assets/svg/StartingStatus.svg"
import {ReactComponent as FailedStatus} from "../../../../../../assets/svg/FailedStatus.svg"
import {ReactComponent as RunningStatus} from "../../../../../../assets/svg/RunningStatus.svg"
import {ReactComponent as StoppingStatus} from "../../../../../../assets/svg/StoppingStatus.svg"
import {ReactComponent as StoppedStatus} from "../../../../../../assets/svg/StoppedStatus.svg"
import styled from 'styled-components'

export const StartingStatusStyled = styled(StartingStatus)`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;
export const RunningStatusStyled = styled(RunningStatus)`
  width: 20px;
  height: 20px;
`;
export const FailedStatusStyled = styled(FailedStatus)`
  width: 20px;
  height: 20px;
`;

export const StoppingStatusStyled = styled(StoppingStatus)`
  width: 20px;
  height: 20px;
`;
export const StoppedStatusStyled = styled(StoppedStatus)`
  width: 20px;
  height: 20px;
`;

export const Container = styled.div`
    width:100%;
    height: 100%;
    display: flex ;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

export const Item = styled.div`
    width: 100px;
    display: flex;
    display: flex ;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

export const ItemText = styled.div<{ bold : boolean}>`
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: ${p => p.bold ? 500 : 400};
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */

  letter-spacing: -0.02em;

  color: #535151;
`;

export const ProgressBarContainer = styled.div`
    background: #EEEEEE;
    border-radius: 21px;
    width: 155px;
    height: 5px;
    position: relative;
`;

export const FilledProgress = styled.div<{percent: number}>`
    width: ${p => `${p.percent}%`};
    height: 5px;
    background: #FB5E03;
    border-radius: 21px;
    position: absolute;
    left: 0;
    top: 0;
`;

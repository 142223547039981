import React, { useMemo, useContext } from 'react';
import Context from '../context';
import styled from 'styled-components';
const CellText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  /* identical to box height, or 15px */
  letter-spacing: -0.02em;
  color: #535151;
`;

const Cell = ({ cell, style, checked, canSelect, canDoubleClick }) => {
  const { tableInstance } = useContext(Context);
  const { totalColumnsWidth } = tableInstance;
  const { column, render, value } = cell;
  const fixed = column.fixed ? `fixed-${column.fixed} `: '';
  const ellipsis = column.ellipsis ? 'ellipsis' : '';
  const isChecked = checked ? 'checked' : '';
  const canSelectSty = canSelect ? 'canSelect' : '';
  const doubleClickSty = canDoubleClick ? 'canDoubleClick' : '';

  const sty = useMemo(() => {
    const obj = { ...style };
    if (column.fixed === 'left') {
      obj.left = column.totalLeft;
    }
    if (column.fixed === 'right') {
      obj.right = totalColumnsWidth - column.totalLeft - column.totalWidth;
    }
    if (column.align) {
      obj.textAlign = column.align;
    }
    return obj
  }, [totalColumnsWidth, column, style]);

  return (
    <div className={`ck-td ${fixed} ${ellipsis} ${isChecked} ${canSelectSty} ${doubleClickSty}`} style={sty}>
      <div className="ck-cell" title={column.ellipsis ? value : null}>
       {render('Cell')}
      </div>
    </div>
  );
};

export default Cell;


import {ReactComponent as DialogInfo} from "../../../../assets/svg/dialog_info.svg"
import {ReactComponent as RunningStatus} from "../../../../assets/svg/RunningStatus.svg"
import {ReactComponent as StartingStatus} from "../../../../assets/svg/StartingStatus.svg"
import {ReactComponent as RevokedStatus} from "../../../../assets/svg/revoked.svg"
import {ReactComponent as RevokedExpiredStatus} from "../../../../assets/svg/revoked_expired.svg"
import styled from 'styled-components'

export const SentStatusStyled = styled(DialogInfo)`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;
export const RunningStatusStyled = styled(RunningStatus)`
  width: 20px;
  height: 20px;
`;

export const StartStatusStyled = styled(StartingStatus)`
  width: 20px;
  height: 20px;
`;

export const RevokedStatusStyled = styled(RevokedStatus)`
  width: 20px;
  height: 20px;
`;

export const RevokedExpiredStatusStyled = styled(RevokedExpiredStatus)`
  width: 20px;
  height: 20px;
`;


export const Container = styled.div`
    width:100%;
    height: 100%;
    display: flex ;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

export const Item = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

export const ItemText = styled.div<{ bold : boolean}>`
  
  font-family: 'Poppins';
  font-style: normal;
  font-weight: ${p => p.bold ? 500 : 400};
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 160% */

  letter-spacing: -0.02em;

  color: #535151;
`;

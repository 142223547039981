import { useMemo, useCallback } from "react";
import { ItemType } from "../../../Components/FilterHeader/types";
import ActionCell from "../CustomCells/Action/backup";
import ActionCell2 from "../CustomCells/Action/backupPlans";
import { buildTextSchedule } from "../CustomCells/Shedule";
import { Cell, IdType, Row } from "react-table";
import useDateFormat from "../../../hooks/useDateFormatHook";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import {
  selectBackupPlans,
  selectBackups,
} from "../../../store/slices/backupRestore/selectors";
import TagCell from "../CustomCells/tagCell";
import ScheduleCell from "../CustomCells/Shedule";

const useTable = () => {
  const formatDate = useDateFormat();
  const backups = useSelector(selectBackups);
  const backupPlans = useSelector(selectBackupPlans);
  const { t } = useTranslation();
  const backupData = useMemo(() => backups ?? [], [backups]);
  const backupPlanData = useMemo(() => backupPlans ?? [], [backupPlans]);

  // IN_PROGRESS, SUCCESS, FAIL
  const backupTypes: Array<{ label: string; value: string }> = useMemo(
    () => [
      { value: "IN_PROGRESS", label: "IN PROGRESS" },
      { value: "SUCCESS", label: "SUCCESS" },
      { value: "FAIL", label: "FAIL" },
    ],
    []
  );
  const backupTypesMap: Record<string, string> = useMemo(
    () => ({ IN_PROGRESS: "IN PROGRESS", SUCCESS: "SUCCESS", FAIL: "FAIL" }),
    []
  );

  const backupPlanTypes: Array<{ label: string; value: string }> = useMemo(
    () => [
      { value: "backup.plan.status.enabled", label: "Enabled" },
      { value: "backup.plan.status.disabled", label: "Disabled" },
    ],
    []
  );
  const backupPlanTypesMap: Record<string, string> = useMemo(
    () => ({
      "backup.plan.status.enabled": "Enabled",
      "backup.plan.status.disabled": "Disabled",
    }),
    []
  );

  const backupColumns = useMemo(
    () => [
      {
        Header: t("ui.backup.restore.columns.account"),
        accessor: "accountName",
        type: ItemType.text,
        ellipsis: true,
        allowFilter: true,
        // width: 180,
      },
      {
        Header: t("ui.backup.restore.columns.cluster"),
        accessor: "clusterName",
        type: ItemType.text,
        ellipsis: true,
        allowFilter: true,
        // width: 180,
      },
      {
        Header: t("ui.backup.restore.columns.backup.name"),
        accessor: "tag",
        type: ItemType.text,
        ellipsis: true,
        allowFilter: true,
        Cell: TagCell,
        width: 500,
      },
      {
        Header: t("ui.backup.restore.columns.status"),
        accessor: "status",
        types: backupTypes,
        type: ItemType.types,
        filter: "type",
        Cell: (cell: Cell) => backupTypesMap[cell.value],
        ellipsis: true,
        allowFilter: true,
        width: 120,
      },
      {
        Header: t("ui.backup.restore.columns.create.time"),
        accessor: "finishedAt",
        type: ItemType.date,
        ellipsis: true,
        Cell: (cell: Cell) => {
          return formatDate(cell.value);
        },
        filter: "date",
        allowFilter: true,
      },
      {
        Header: t("ui.backup.restore.columns.action"),
        accessor: "Action",
        type: ItemType.text,
        disableSortBy: true,
        allowFilter: false,
        width: 60,

        Cell: ActionCell,
      },
    ],
    [backupTypes, backupTypesMap, formatDate, t]
  );

  const backupPlansColumns = useMemo(
    () => [
      {
        Header: t("ui.backup.restore.columns.backupplan.name"),
        accessor: "tag",
        type: ItemType.text,
        ellipsis: true,
        allowFilter: true,
        Cell: TagCell,
        width: 180,
        // width: 150,
      },
      {
        Header: t("ui.backup.restore.columns.account"),
        accessor: "accountName",
        type: ItemType.text,
        ellipsis: true,
        allowFilter: true,
        width: 180,
        // width: 180,
      },
      {
        Header: t("ui.backup.restore.columns.cluster"),
        accessor: "clusterName",
        type: ItemType.text,
        ellipsis: true,
        allowFilter: true,
        width: 150,
        // width: 180,
      },
      {
        Header: t("ui.backup.restore.columns.schedule"),
        accessor: "schedule",
        type: ItemType.text,
        ellipsis: true,
        allowFilter: false,
        Cell: ScheduleCell,
        // width: 150,
      },
      {
        Header: t("ui.backup.restore.columns.status"),
        accessor: "status",
        types: backupPlanTypes,
        type: ItemType.types,
        filter: "type",
        Cell: (cell: Cell) => backupPlanTypesMap[cell.value],
        ellipsis: true,
        allowFilter: true,
        width: 100,
      },
      {
        Header: t("ui.backup.restore.columns.lastFailure"),
        accessor: "lastSuccess",
        type: ItemType.date,
        filter: "date",
        ellipsis: true,
        Cell: (cell: Cell) => {
          return formatDate(cell.value);
        },
        allowFilter: true,
        width: 190,

        // width: 150,
      },
      {
        Header: t("ui.backup.restore.columns.create.time"),
        accessor: "createdAt",
        ellipsis: true,
        Cell: (cell: Cell) => {
          return formatDate(cell.value);
        },
        type: ItemType.date,
        filter: "date",
        allowFilter: true,
        width: 140,
      },
      {
        Header: t("ui.backup.restore.columns.action"),
        accessor: "Action",
        type: ItemType.text,
        disableSortBy: true,
        allowFilter: false,
        width: 60,

        Cell: ActionCell2,
      },
    ],
    [backupPlanTypes, backupPlanTypesMap, formatDate, t]
  );

  const globalFilterFunctionBackups = useCallback(
    (rows: Row<any>[], ids: IdType<any>[], query: string, ...props: any) => {
      return rows.filter((row) => {
        const rowValueAccName = row.values["accountName"];
        const rowclusterName = row.values["clusterName"];
        const rowTag = row.values["tag"];
        const rowCreateDate = formatDate(row.values["finishedAt"]);

        const AccNameFilter =
          rowValueAccName !== undefined
            ? String(rowValueAccName)
                .toLowerCase()
                .includes(String(query).toLowerCase())
            : true;

        const clusterNameFilter =
          rowclusterName !== undefined
            ? String(rowclusterName)
                .toLowerCase()
                .includes(String(query).toLowerCase())
            : true;

        const tagFilter =
          rowTag !== undefined
            ? String(rowTag).toLowerCase().includes(String(query).toLowerCase())
            : true;

        const DateFilter1 =
          rowCreateDate !== undefined
            ? String(rowCreateDate)
                .toLowerCase()
                .includes(String(query).toLowerCase())
            : false;

        return AccNameFilter || clusterNameFilter || tagFilter || DateFilter1;
      });
    },
    [formatDate]
  );

  const globalFilterFunctionBackupPlans = useCallback(
    (rows: Row<any>[], ids: IdType<any>[], query: string, ...props: any) => {
      return rows.filter((row) => {
        const rowValueAccName = row.values["accountName"];
        const rowclusterName = row.values["clusterName"];
        const rowSchedule = buildTextSchedule(row);
        const rowCreateDate = formatDate(row.values["createdAt"]);
        const rowlastSuccess = formatDate(row.values["lastSuccess"]);

        const AccNameFilter =
          rowValueAccName !== undefined
            ? String(rowValueAccName)
                .toLowerCase()
                .includes(String(query).toLowerCase())
            : true;

        const clusterNameFilter =
          rowclusterName !== undefined
            ? String(rowclusterName)
                .toLowerCase()
                .includes(String(query).toLowerCase())
            : true;

        const scheduleFilter =
          rowSchedule !== undefined
            ? String(rowSchedule)
                .toLowerCase()
                .includes(String(query).toLowerCase())
            : true;

        const DateFilter1 =
          rowCreateDate !== undefined
            ? String(rowCreateDate)
                .toLowerCase()
                .includes(String(query).toLowerCase())
            : false;

        const DateFilter2 =
          rowlastSuccess !== undefined
            ? String(rowlastSuccess)
                .toLowerCase()
                .includes(String(query).toLowerCase())
            : false;

        return (
          AccNameFilter ||
          clusterNameFilter ||
          scheduleFilter ||
          DateFilter1 ||
          DateFilter2
        );
      });
    },
    [formatDate]
  );

  const backupPlanInitialState: any = useMemo(
    () => ({
      sortBy: [
        {
          id: "createdAt",
          desc: true,
        },
      ],
    }),
    []
  );

  return {
    backupColumns,
    backupData,
    backupPlansColumns,
    backupPlanData,
    globalFilterFunctionBackups,
    globalFilterFunctionBackupPlans,
    backupPlanInitialState,
  };
};

export default useTable;

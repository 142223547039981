import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  align-items: flex-start;
`;

export const Title = styled.div<{signInScreen?: boolean}> `
  font-style: normal;
  font-family: ${({ theme: { language } }) =>
    language === "eng" ? "Poppins" : "unset"};
  font-weight: 500;
  font-size:18px;
  line-height: 100%;
  letter-spacing: -0.02em;
  /* or 18px */
  display: flex;
  color: ${(props) => (props.signInScreen ? "#FFFFFF" : "#000000")};
  padding-bottom: 10px;
`;

export const ContainerInput = styled.div<{
    size: "lg" | "sm";
    message?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${p => p.size === "lg" ? 58 : 35}px ;
  border: ${props => props.message ? '3px solid #EA0000 !important' : '1px solid #EEEEEE'};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  gap: 12px;
  width: 100%;
  background: #ffffff;
  padding-left: 12px;
  &:hover,
  &:focus-within {
    border: 1px solid #EEEEEE;
  }
`;

export const IconStyled = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;


export const CloseIconStyled = styled.img`
  cursor: pointer;
  padding: 18px 22px 19px 0px;
`;

export const InputFieldStyled = styled.input `
  outline: none;
  border: none;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 100%;
  /* identical to box height, or 15px */

  letter-spacing: -0.02em;
  font-stretch: normal;
  font-family: ${({ theme: { language } }) =>
    language === "eng" ? "Poppins" : "unset"};
  background: transparent;
  color: #000000;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  }
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #888888;
  }
`;

import React, {useCallback, useEffect, useRef, useState} from "react";
import {
  AddButtonContainer,
  AddIconStyled,
  AddIpIconText,
  Body,
  BottomWrapperNew,
  ButtonsContainer,
  Container,
  InfoTitle,
  InputContainer,
  InputNumberNodeContainer,
  InputTitleContainer,
  IPInputContainer,
  ProviderContainer,
  ProviderNodeTypeSelectContainer,
  ProviderSelectContainer,
  ProviderTitle,
  ProviderTitleDropDown,
  ProviderTypeSelectContainer,
  RegionTitle,
  SaveUserButton,
  SelectClusterTypeContainer,
  SelectContainer,
  SelectionContainer,
  SwitchContainer,
  SwitchImg,
  SwitchTitle,
  TableContainer,
  TabsContentContainer,
  TitleInput,
  TrustedIPAddContainer,
  TrustIPSHeader,
  VPCCheckBoxContainer,
  VPCInputContainer,
} from "./styles";
import BreadCrumbs from "../../../Components/BreadCrubms";
import {useTranslation} from "react-i18next";
import InputField from "../../../Components/InputField/InputField";
import {useForm} from "react-hook-form";

import {CancelButton} from "../../../Components/CreateNewAccount/styles";
import {ButtonTitle} from "../../../Components/AccountsHeader/styles";
import AutoComplete from "../../../Components/AutoComplete";
import {useNavigate} from "react-router-dom";
import useToast from "../../../hooks/useToast";
import FormControlLabel from "@mui/material/FormControlLabel";
import {TabButton, TabsContainer} from "../../UsersManagement/styles";
import TableTest from "../../../Components/Table2";
import useTable from "./hooks/useTable";
import useData from "./hooks/useData";
import {useDispatch} from "react-redux";
import {createCluster} from "../../../store/slices/clusterManagement";
import AccountSelect from "../../../Components/AccountsSelect";
import ClusterTypeField from "./Components/clusterType";
import switchOn from "../../../assets/svg/triggle_switch_on.svg";
import switchOff from "../../../assets/svg/triggle_switch_off.svg";
import CheckBox from "../../../Components/CheckBox/CheckBox";
import CloudServices from "./CloudServices";

enum CreateNewClusterTabEnum {
  TRUSTEDIPS,
  VPC,
}

interface CreateNewClusterUserProps {}

const isValidIP = (ip: string) => {
  const octet = "(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|0)";
  const regex = new RegExp(`^${octet}\\.${octet}\\.${octet}\\.${octet}$`);
  return regex.test(ip);
};

const isValidVPCIPAddress = (ipAddress: string) => {
  const ipAddressPattern = /^(\d{1,3}\.){3}\d{1,3}\/\d{1,2}$/;
  return ipAddressPattern.test(ipAddress);
};

interface IP {
  ip: string;
}
interface RefType {
  tableInstance: { data: Array<IP> };
}

const CreateNewClusterUser: React.FC<CreateNewClusterUserProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  // const expand = useSelector(selectExpandSidebar);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    unregister,
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      region: "",
      type: "",
      account: "",
      nodeNumber: "",
      nodeType: "",
    },
  });
  const [cloudDisabled, setCloudDisabled] = useState(true);
  const { name, region, type, account, nodeNumber, nodeType } = getValues();
  const [tabSelected, setTabSelected] = useState<CreateNewClusterTabEnum>(
    CreateNewClusterTabEnum.TRUSTEDIPS
  );
  const [checked, setChecked] = React.useState(false);
  const [vpcChecked, setVpcChecked] = React.useState(false);
  const tableRef = useRef<RefType>(null);
  const {
    trustedIPSColumns,
    trustedIPSData,
    handleAddIP,
    handleVPCIP,
    handleGetIPS,
    handleGetIPVPC,
    ipInput,
    ipVPCInput,
    handleIpChange,
    handleIpVPCChange,
    ipError,
    setTrustedIPSData,
  } = useTable(tableRef);
  const { regionOptions, TypeOptions, isSystemAdmin, cloud } = useData();

  console.log("errors", errors);
  const handleFieldChange = useCallback(
    (field: any, value: string) => {
      console.log("value selected ", value);
      setValue(field, value);
      trigger(field);
    },
    [setValue, trigger]
  );

  const handleToggleSwitch = () => {
    setChecked(!checked);
  };
  const onSubmit = useCallback(() => {
    const ips = handleGetIPS();
    const ipVPC = handleGetIPVPC();
    console.log("values", ips, name, region, type, account, cloud, ipVPC);

    let ipValidation = true;
    if (checked) {
      ips.forEach((ip: string) => {
        if (!isValidIP(ip)) {
          ipValidation = false;
          toast.error(`${ip} is not an ip!`);
        }
      });
      // if(!isValidVPCIPAddress(ipVPC)){
      //     ipValidation = false;
      //     toast.error(`${ipVPC} VPC is not an ip!`);
      // }
    }

    if (ipValidation) {
      const req = {
        clusterName: name,
        region: region,
        cloud: cloud,
        clusterType: type,
        environment: "dev",
        ...(type === "Custom" && { nodeType: nodeType, nodeCount: nodeNumber }),
        ...(isSystemAdmin && { accountId: account }),
        ...(checked && { cidr: ipVPC, allowList: ips }),
      };
      console.log(req);
      dispatch(createCluster({ req, navigate }));
    }
  }, [
    handleGetIPS,
    handleGetIPVPC,
    name,
    region,
    type,
    account,
    cloud,
    checked,
    toast,
    nodeType,
    nodeNumber,
    isSystemAdmin,
    dispatch,
    navigate,
  ]);

  const handleTabChange = (tab: CreateNewClusterTabEnum) => {
    //save table data before changing tab
    if (tableRef.current) {
      const tableData = tableRef.current?.tableInstance.data;
      setTrustedIPSData(tableData);
    }

    //change tab
    setTabSelected(tab);
  };

  useEffect(() => {
    console.log("trustedIPSData", trustedIPSData);
  }, [trustedIPSData]);
  const validate = (value: string) => {
    const typeValue = getValues("type");
    if (typeValue === "Custom" && !value) {
      return "This field is required";
    }
    return true;
  };

  console.log("nodeType", nodeType);
  useEffect(() => {
    if (type !== "Custom") {
      unregister("nodeType");
      unregister("nodeNumber");
    }
  }, [unregister, type]);

  const handleVPCChecked = () => {
    setVpcChecked((prev) => !prev);
  };

  return (
    <Container>
      <BreadCrumbs />
      <Body>
        {isSystemAdmin ? (
          <InputTitleContainer>
            <TitleInput paddingRight={"26"}>
              {t("ui.account.select.title")}
            </TitleInput>
            <InputContainer>
              <AccountSelect
                name={"account"}
                register={register}
                value={account}
                handleChange={(name: string, id: string) =>
                  handleFieldChange("account", id)
                }
                errors={errors}
                title={""}
                placeholder={t("ui.account.select.title")}
                background={"#FFFFFF"}
              />
            </InputContainer>
          </InputTitleContainer>
        ) : (
          <></>
        )}
        <InputTitleContainer>
          <TitleInput paddingRight={"40"}>{t("cluster_name_title")}</TitleInput>
          <InputContainer>
            <InputField
                {...register("name", {
                  required: t("field.required") || "",
                  maxLength: {
                    value: 63,
                    message: t("pattern.cluster.name")
                  },
                  pattern: {
                    value: /^[a-z0-9_-]+$/,
                    message: t("pattern.cluster.name")
                  }
                })}
              placeholder={t("cluster_name_title")}
              value={name}
              onChange={(text: string) => handleFieldChange("name", text)}
              onClickCloseHandle={() => handleFieldChange("name", "")}
              required={true}
              error={(errors.name?.message as string) || ""}
              title={""}
            />
          </InputContainer>
        </InputTitleContainer>
        <SelectionContainer>
          <ProviderContainer>
            <ProviderTitle>
              <div style={{ color: "#FF6600" }}></div>
              {t("cluster_provider")}
            </ProviderTitle>
            <CloudServices
              handleFieldChange={handleFieldChange}
              cloud={cloud}
              register={register}
              cloudDisabled={cloudDisabled}
              t={t}
            />
            {/* <AmazonStyled {...register('cloud', {
                                required: t("field.required") || ""})}
                                onClick={() => false && handleFieldChange("cloud", "AWS")}
                                src={cloud === "AWS" ? amazonIcon : awsDisabled}
                                alt={"amazonIcon"}/>
                  <VerticalLine></VerticalLine>
                  <MicrosoftStyled {...register('cloud', {
                                    required: t("field.required") || ""})}
                                    onClick={() => handleFieldChange("cloud", "AZURE")}
                                    src={cloud === "AZURE" ? azureEnabled : azureDisabled}
                                    alt={"microsoftIcon"}/>
                  <VerticalLine></VerticalLine>
                  <GoogleCloudStyled {...register('cloud', {
                                    required: t("field.required") || ""})}
                                    onClick={() => false && handleFieldChange("cloud", "GOOGLE")}
                                    src={cloud === "GOOGLE" ? googleEnabled : googleDisabled}
                                    alt={"googleCloudIcon"}/> */}
          </ProviderContainer>
          <ProviderSelectContainer>
            <RegionTitle>
              <div style={{ color: "#FF6600" }}>{"*"}</div>
              {t("cluster_region")}
              {":"}
            </RegionTitle>
            <SelectContainer>
              <AutoComplete
                {...register("region", {
                  required: t("field.required") || "",
                })}
                placeholder={"ui.region.select.placeholder"}
                options={regionOptions}
                value={region}
                required={true}
                error={(errors.region?.message as string) || ""}
                updateValue={(value: string) =>
                  handleFieldChange("region", value)
                }
              />
            </SelectContainer>
          </ProviderSelectContainer>
          <React.Fragment>
            <ProviderTypeSelectContainer>
              <ProviderTitleDropDown>
                <div style={{ color: "#FF6600" }}>{"*"}</div>
                {t("cluster_type")}
                {":"}
              </ProviderTitleDropDown>
              <SelectClusterTypeContainer>
                <ClusterTypeField
                  {...register("type", {
                    required: t("field.required") || "",
                  })}
                  onChange={(value: any) => handleFieldChange("type", value)}
                  onClickCloseHandle={() => handleFieldChange("type", "")}
                  error={(errors.type?.message as string) || ""}
                  value={type}
                  data={TypeOptions}
                />
              </SelectClusterTypeContainer>
            </ProviderTypeSelectContainer>
          </React.Fragment>
          {type === "Custom" ? (
            <React.Fragment>
              <ProviderNodeTypeSelectContainer>
                <ProviderTitleDropDown>
                  <div style={{ color: "#FF6600" }}>{"*"}</div>
                  {t("cluster_node_type")}
                  {":"}
                </ProviderTitleDropDown>
                <InputNumberNodeContainer>
                  <InputField
                    {...register("nodeType", {
                      required: t("field.required") || "",
                    })}
                    placeholder={t("cluster_node_type")}
                    value={nodeType}
                    onChange={(text: string) =>
                      handleFieldChange("nodeType", text)
                    }
                    onClickCloseHandle={() => handleFieldChange("nodeType", "")}
                    required={true}
                    error={(errors.nodeType?.message as string) || ""}
                  />
                </InputNumberNodeContainer>
              </ProviderNodeTypeSelectContainer>
              <InputTitleContainer>
                <RegionTitle style={{ paddingRight: "23px" }}>
                  <div style={{ color: "#FF6600" }}>{"*"}</div>
                  {t("cluster_number_node")}
                  {":"}
                </RegionTitle>
                <InputNumberNodeContainer>
                  <InputField
                    {...register("nodeNumber", {
                      validate: validate,
                    })}
                    placeholder={t("cluster_number_node")}
                    value={nodeNumber}
                    onChange={(text: string) =>
                      handleFieldChange("nodeNumber", text)
                    }
                    onClickCloseHandle={() =>
                      handleFieldChange("nodeNumber", "")
                    }
                    required={true}
                    error={(errors.nodeNumber?.message as string) || ""}
                  />
                </InputNumberNodeContainer>
              </InputTitleContainer>
            </React.Fragment>
          ) : null}
        </SelectionContainer>
        <SwitchContainer>
          <SwitchTitle>{t("cluster_security")}</SwitchTitle>
          <FormControlLabel
            control={
              // <IOSSwitch sx={{ m: 1 }}
              //            defaultChecked
              //            checked={checked}
              //            onChange={handleChange}/>
              <SwitchImg
                src={checked ? switchOn : switchOff}
                alt={checked ? "switchOn" : "switchOff"}
                onClick={handleToggleSwitch}
              />
            }
            label=""
          />
        </SwitchContainer>

        <InfoTitle>
          {t("cluster_info", {
            clusterType:
              cloud === "AZURE" ? t("cluster_azure_tab") : t("cluster_vpc_tab"),
          })}
        </InfoTitle>

        {checked ? (
          <React.Fragment>
            <TabsContainer>
              <TabButton
                selected={tabSelected === CreateNewClusterTabEnum.TRUSTEDIPS}
                onClick={() =>
                  handleTabChange(CreateNewClusterTabEnum.TRUSTEDIPS)
                }
              >
                {t("cluster_trusted_tab")}
              </TabButton>
              <TabButton
                selected={tabSelected === CreateNewClusterTabEnum.VPC}
                onClick={() => handleTabChange(CreateNewClusterTabEnum.VPC)}
              >
                {cloud === "AZURE"
                  ? t("cluster_azure_tab")
                  : t("cluster_vpc_tab")}
              </TabButton>
            </TabsContainer>
            <TabsContentContainer>
              {tabSelected === CreateNewClusterTabEnum.TRUSTEDIPS && (
                <TableContainer>
                  <TrustIPSHeader>
                    <TrustedIPAddContainer>
                      <AddButtonContainer onClick={handleAddIP}>
                        <AddIconStyled />
                        <AddIpIconText>{t("cluster_add_ip")}</AddIpIconText>
                      </AddButtonContainer>
                      <IPInputContainer>
                        <InputField
                          placeholder={t(
                            "ui.create.new.cluster.ip.placeholder"
                          )}
                          value={ipInput}
                          onChange={(text: string) => handleIpChange(text)}
                          onClickCloseHandle={() => handleIpChange("")}
                          required={true}
                          onEnter={handleAddIP}
                          error={ipError}
                          title={""}
                        />
                      </IPInputContainer>
                    </TrustedIPAddContainer>
                  </TrustIPSHeader>
                  <TableTest
                    ref={tableRef}
                    filters={[]}
                    canSelect={false}
                    data={trustedIPSData}
                    columns={trustedIPSColumns}
                    size="small"
                  />
                </TableContainer>
              )}
              {tabSelected === CreateNewClusterTabEnum.VPC && (
                <TrustIPSHeader>
                  <TrustedIPAddContainer>
                    <VPCInputContainer>
                      <InputField
                        placeholder={t(
                          "ui.create.new.cluster.ip.vpc.placeholder"
                        )}
                        value={ipVPCInput}
                        onChange={(text: string) => handleIpVPCChange(text)}
                        onClickCloseHandle={() => handleIpVPCChange("")}
                        required={true}
                        onEnter={handleVPCIP}
                        error={ipError}
                        title={""}
                      />
                      <VPCCheckBoxContainer>
                        <div style={{ display: "none" }}>
                          <CheckBox
                            isChecked={vpcChecked}
                            checkHandler={() => handleVPCChecked()}
                            title={t("ui.cluster.create.vpc.enable") || ""}
                            varient="orange"
                          />
                        </div>
                      </VPCCheckBoxContainer>
                    </VPCInputContainer>
                  </TrustedIPAddContainer>
                </TrustIPSHeader>
              )}
            </TabsContentContainer>
          </React.Fragment>
        ) : null}
        <BottomWrapperNew>
          <ButtonsContainer>
            <CancelButton
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel.button")}{" "}
            </CancelButton>
            <SaveUserButton
              validation={isValid}
              onClick={handleSubmit(onSubmit)}
            >
              <ButtonTitle>
                {t("modal.confirm.button")} {">"}
              </ButtonTitle>
            </SaveUserButton>
          </ButtonsContainer>
        </BottomWrapperNew>
      </Body>
    </Container>
  );
};

export default CreateNewClusterUser;

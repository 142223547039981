import React, {useState} from 'react'
import styled from "styled-components";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const CustomSelect = styled(Select)((props) => ({
    width: 120,
    height: 30,
    
    "&.MuiOutlinedInput-root": {
        borderRadius: "10px",
        backgroundColor: "#F7F5F5",
        borderBottomLeftRadius: props.open ? "0px" : "10px",
        borderBottomRightRadius: props.open ? "0px" : "10px",

      "& .MuiOutlinedInput-input": {
        color: "#535151",
        fontFamily: 'Poppins',
      },
      " .MuiOutlinedInput-input[aria-expanded='true']": {
        // color: "red",
        fontFamily: 'Poppins',

      },
      
      "& fieldset": {
        borderColor: "#F7F5F5",
      },
      "&:hover fieldset": {
        // borderColor: "#000000",
        // borderColor: "yellow"
        borderColor: "#F7F5F5",
      },
      "&.Mui-focused fieldset": {
        // border: "1px solid black"
        // borderColor: "green"
        borderColor: "#F7F5F5",
      }
    }
  }));

interface DateFilterProps {
    items: Array<{label: string, value: string}>;
    selected: string;
    handleChange: (value : string) => void;
}

const SelectDropDown: React.FC<DateFilterProps> = (props) => {

    const {selected, items, handleChange} = props;
    const [open, setOpen] = useState<boolean>(false);
    const handleClose = () => {
        setOpen(false);
      };
    
    const handleOpen = () => {
        setOpen(true);
    };


    const handleSelectChange = (event: any) => {
        handleChange(event.target.value as string);
    };

  return (
    <CustomSelect
    open={open}
    onClose={handleClose}
    onOpen={handleOpen}
    value={selected}
    onChange={handleSelectChange}
    MenuProps={{
        PaperProps: {
        sx: {
            bgcolor: 'rgb(230, 230, 230);',
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            boxShadow: "none",
            '& .MuiMenuItem-root': {
                paddingTop: 0,
                alignItems: "center",
            },
        },
        },
    }}
    >
    {items.map(item => (
        <MenuItem value={item.value}>
            {item.label}
        </MenuItem>))}
    </CustomSelect>
  )
}

export default SelectDropDown
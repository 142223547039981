import React, {useMemo} from 'react'
import {
  Container,
  FailedStatusStyled,
  ItemText,
  RunningStatusStyled,
  StartingStatusStyled,
  ProgressBarContainer,
  Item,
  FilledProgress,
  StoppingStatusStyled, StoppedStatusStyled
} from "./styles"
import {StatusType} from "./types"
import { Cell } from 'react-table';

interface StatusAdminListHeaderProps {
  cell : Cell

}
export const statusMap =  {[StatusType.Running]: "Running", [StatusType.Failed]: "Failed", [StatusType.Starting]: "Starting", [StatusType.Deleting] : "Deleting", [StatusType.Stopping] : "Stopping", [StatusType.Stopped] : "Stopped" };


const Status: React.FC<StatusAdminListHeaderProps> = (props) => {

  const {value} =  props.cell;
  const isBold = useMemo(() => value === StatusType.Running ,[value]);

  
  //no idea what's progress for
  // const percent = useMemo(() =>  50, []);
  return (
    <React.Fragment>
      <Container>
        <Item>
          {value === StatusType.Running && <RunningStatusStyled />}
          {value === StatusType.Failed && <FailedStatusStyled />}
          {value === StatusType.Starting && <StartingStatusStyled />}
          {value === StatusType.Deleting && <FailedStatusStyled />}
          {value === StatusType.Stopping && <StoppingStatusStyled />}
          {value === StatusType.Stopped && <StoppedStatusStyled />}
          <ItemText bold={isBold}>{statusMap[value as keyof typeof statusMap]}</ItemText>
        </Item>
        {/* {value === StatusType.Starting && <ProgressBarContainer>
          <FilledProgress percent={percent} />
        </ProgressBarContainer>} */}
      </Container>
    </React.Fragment>
  )
}
export default Status;

import {POST} from '../network/client';
import { AccountReqBody, CreateAccountReqBody } from '../types/Accounts';
import {Response} from "../types/Response";

const paths = {
    GET_ACCOUNTS: '/v1/accounts/filter?size=1000',
    CREATE_ACCOUNT: '/v1/accounts',
};

const getAccounts = (body: AccountReqBody): Promise<Response<any>> => POST<any>(paths.GET_ACCOUNTS, body);

const createAccount = (body: CreateAccountReqBody): Promise<Response<any>> => POST<any>(paths.CREATE_ACCOUNT, body);

const accounts = { getAccounts, createAccount };

export default accounts;

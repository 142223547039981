import {PayloadAction} from '@reduxjs/toolkit';
import {call, put, takeLatest} from 'redux-saga/effects';
import auth from '../../../apis/auth';
import {Response} from '../../../types/Response';
import {
    createQueryDone,
    createQueryRequest,
    getRolesRequest,
    inviteNewUserDone,
    inviteNewUserRequest,
    logout,
    logoutSuccess,
    requestToken,
    resetPasswordEmailRequest,
    resetPasswordSentSuccessfully,
    resetPasswordSentTokenDone,
    resetPasswordSentUserDone,
    resetPasswordTokenRequest,
    resetPasswordUserRequest,
    setRolesData,
    setToken,
    updateProfile,
    updateProfileDone
} from '../../slices/general';
import {
    InviteNewUserBody,
    ResetPasswordTokenReqBody,
    ResetPasswordUserReqBody,
    UpdateProfileReqBody
} from "../../../types/user";
import roles from '../../../apis/roles';
import {CreateQueryRequest} from "../../../types/clusterManagement";
import clusterManagement from "../../../apis/clusterManagement";


interface TokenRequestI {
    code: string,
    verifier : string,
}
interface ResetPasswordEmail {
    email: string,
}

export function* handleGetToken (action: PayloadAction<TokenRequestI>): any {
    try {
        const response: Response<any> = yield call(auth.getToken, action.payload.code,action.payload.verifier);
        if (response.success){
            const { data } = response;
            yield put(setToken(data.access_token));
        }
        else {
            yield put(logout());
        }
    } catch(error: any){
        // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    }
}

export function* handleResetPasswordEmail (action: PayloadAction<any>): any {
    try {
        const response: Response<any> = yield call(auth.resetPasswordEmail, action.payload);
        if (response.success){
            const { data } = response;
            yield put(resetPasswordSentSuccessfully(data));
        }
        else {
            console.log('error');
            const { error } = response;
            yield put(resetPasswordSentSuccessfully(error));
        }
    } catch(error: any){
        // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    }
}

export function* handleLogout (action: PayloadAction<any>): any {
    try {
        // const response: Response<any> = yield call(auth.logout);
        // if (response.success){
            yield put(logoutSuccess());
        // }
        // else {
            // console.log('error');
        // }
    } catch(error: any){
        // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    }
}

export function* handleResetPasswordUser (action: PayloadAction<ResetPasswordUserReqBody>): any {
    try {
        const response: Response<any> = yield call(auth.resetPasswordUser, action.payload);
        if (response.success){
            const { data } = response;
            yield put(resetPasswordSentUserDone(data));
        }
        else {
            const { error } = response;
            yield put(resetPasswordSentUserDone(error));
        }
    } catch(error: any){
        // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    }
}

export function* handleResetPasswordToken (action: PayloadAction<ResetPasswordTokenReqBody>): any {
    try {
        const response: Response<any> = yield call(auth.resetPasswordToken, action.payload);
        if (response.success){
            const { data } = response;
            yield put(resetPasswordSentTokenDone(data));
        }
        else {
            const { error } = response;
            yield put(resetPasswordSentTokenDone(error));
        }
        // toastify("password reset by token", toastTypes.success);
    } catch(error: any){
        // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    }
}

export function* handleInviteNewUser (action: PayloadAction<InviteNewUserBody>): any {
    try {
        const response: Response<any> = yield call(auth.inviteNewUser, action.payload);
        if (response.success){
            const { data } = response;
            yield put(inviteNewUserDone(data));
        }
        else {
            const { error } = response;
            yield put(inviteNewUserDone(error));
        }
    } catch(error: any){
        // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    }
}

export function* handleUpdateProfile (action: PayloadAction<UpdateProfileReqBody>): any {
    try {
        const response: Response<any> = yield call(auth.updateProfile, action.payload);
        if (response.success){
            const { data } = response;
            yield put(updateProfileDone(data));
        }
        else {
            const { error } = response;
            yield put(updateProfileDone(error));
        }
        // toastify("password reset by token", toastTypes.success);
    } catch(error: any){
        // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    }
}

export function* handleGetRoles (action: PayloadAction<UpdateProfileReqBody>): any {
    try {
        const response: Response<any> = yield call(roles.getRoles);
        if (response.success){
            const { data } = response;
            yield put(setRolesData(data));
        }
        else {
            const { error } = response;
            yield put(updateProfileDone(error));
        }
        // toastify("password reset by token", toastTypes.success);
    } catch(error: any){
        // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    }
}

export function* handlePostQueryCluster(action: PayloadAction<CreateQueryRequest>): any {
    try {
        const response: Response<any> = yield call(clusterManagement.queryCluster, action.payload);
        if (response.success) {
            const {data} = response;
            yield put(createQueryDone(data))
        }
    } catch (error: any) {
    }
}

export function* generalSaga() { // generator function
    yield takeLatest(requestToken, handleGetToken);
    yield takeLatest(resetPasswordEmailRequest, handleResetPasswordEmail);
    yield takeLatest(resetPasswordUserRequest, handleResetPasswordUser);
    yield takeLatest(resetPasswordTokenRequest, handleResetPasswordToken);
    yield takeLatest(updateProfile, handleUpdateProfile);
    yield takeLatest(logout, handleLogout);
    yield takeLatest(inviteNewUserRequest, handleInviteNewUser);
    yield takeLatest(getRolesRequest, handleGetRoles);
    yield takeLatest(createQueryRequest, handlePostQueryCluster);
}


import React, { useState } from "react";
import {
    ContainerInput,
    InputFieldStyled,
    Container,
    IconStyled, LineIconStyled, FilterIconStyled,
    menuPopperProps,
    SearchFilterContainer,
    TitleFilter, ClearFiltersContainer
} from "./styles";
import SearchIcon from "../../assets/svg/search_icon.svg";
import lineIcon from "../../assets/svg/line_search.svg";
import filterIcon from "../../assets/svg/filter_search.svg";
import Popover from '@mui/material/Popover';
import CheckBox from "../CheckBox/CheckBox";
import {useTranslation} from "react-i18next";
import {ClearAllContainer, ClearAllText, RemoveButtonStyled} from "../FilterHeader/styles";

interface SearchInterface {
    placeholder: string;
    value: string;
    onChange: (text: string) => void;
    onEnter?: () => void;
    size?: "lg" | "sm";
    onClickHandle?: () => void;
    filterSearchCols: Record<string, any>;
    handleColumnCheckChange: (id: string) => void;
    handleResetAllFilters: () => void;
}

const Search: React.FC<SearchInterface> = (props) => {
    const {
        placeholder,
        onChange,
        value,
        onEnter,
        size = "lg",
        filterSearchCols,
        handleColumnCheckChange,
        handleResetAllFilters
    } = props;

    const {t} = useTranslation();
    const [focused, setFocused] = useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <Container>
            <ContainerInput size={size}>
                {!value && !focused && <IconStyled title={t("Search_title") || ""} src={SearchIcon}/>}
                <InputFieldStyled
                    value={value}
                    placeholder={focused ? "" : placeholder}
                    type={"text"}
                    onChange={(e) => onChange(e.target.value)}
                    onKeyPress={(event) => {
                        if (event.key === "Enter") {
                            event.preventDefault();
                            if (onEnter) {
                                onEnter();
                            }
                        }
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                <LineIconStyled src={lineIcon} alt={"lineIcon"}/>
                <FilterIconStyled src={filterIcon} alt={"filterIcon"} onClick={handleClick}/>
                <Popover
                    id={"filter-search-panel"}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={menuPopperProps}
                >
                <SearchFilterContainer>
                    <TitleFilter>{t("search_filters")}</TitleFilter>
                    {Object.entries(filterSearchCols).map(([id,values], i) => <CheckBox key={i} isChecked={values.enabled} checkHandler={() => handleColumnCheckChange(id)} title={values.Header} />)  }
                    <ClearFiltersContainer>
                      <ClearAllContainer onClick={handleResetAllFilters}>
                        <ClearAllText>{t("clear all")}</ClearAllText>
                        <RemoveButtonStyled />
                     </ClearAllContainer>
                    </ClearFiltersContainer>
                </SearchFilterContainer>
            </Popover>
            </ContainerInput>
        </Container>
    );
};

export default Search;

import {PATCH, POST, POST_TOKEN} from '../network/client';
import {Response} from "../types/Response";
import {
    InviteNewUserBody,
    ResetPasswordTokenReqBody,
    ResetPasswordUserReqBody,
    UpdateProfileReqBody
} from "../types/user";

const paths = {
    GET_TOKEN: `/oauth2/token`,
    RESET_PASSWORD_EMAIL: `/v1/users/reset-password`,
    RESET_PASSWORD_USER: `/v1/users/{userId}/update-password`,
    RESET_PASSWORD_TOKEN: `/v1/users/reset-password/{resetPasswordToken}`,
    UPDATE_PROFILE: `/v1/accounts/{id}`,
    LOGOUT: `/logout`,
    INVITE_NEW_USER: `/v1/users/invitations`,
};
const getToken = (
    code: string,
    verifier: string
): Promise<Response<any>> => {
    const details = {
        client_id: 'Regatta-client',
        redirect_uri: `${window.location.origin}/login`,
        grant_type: 'authorization_code',
        code: code,
        code_verifier: verifier,
    };

    const formBody = Object.entries(details)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');

    return POST_TOKEN<any>(
        paths.GET_TOKEN,
        formBody,
        { 'Content-Type': 'application/x-www-form-urlencoded' }
    );
};

const resetPasswordEmail = (email: string): Promise<Response<any>> => POST<any>(paths.RESET_PASSWORD_EMAIL, {email});
const resetPasswordUser = (body: ResetPasswordUserReqBody): Promise<Response<any>> => PATCH<any>(paths.RESET_PASSWORD_USER.replace("{userId}", body.id), {newPassword: body.newPassword, oldPassword: body.oldPassword});
const resetPasswordToken = (body: ResetPasswordTokenReqBody): Promise<Response<any>> => PATCH<any>(paths.RESET_PASSWORD_TOKEN.replace("{resetPasswordToken}", body.resetPasswordToken), {password: body.password});
const updateProfile = (body: UpdateProfileReqBody): Promise<Response<any>> => PATCH<any>(paths.UPDATE_PROFILE.replace("{id}", body.id), {name: body.name});
const logout = (): Promise<Response<any>> => POST<any>(paths.LOGOUT, {});
const inviteNewUser = (body: InviteNewUserBody): Promise<Response<any>> => POST<any>(paths.INVITE_NEW_USER,{accountId: body.accountId, email: body.email, name: body.name, roleId: body.roleId});
const auth = { getToken, resetPasswordEmail, logout, resetPasswordUser, resetPasswordToken, updateProfile, inviteNewUser };
export default auth;
import styled from "styled-components";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
export const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiPaper-root {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    min-width: 775px;
  }
`;
export const StyledDialogContent = styled(DialogContent)`
  margin-top:54px;
  display: flex ;
  flex-direction: column;
  justify-content: center ;
  gap: 16px;
`;
export const IconContainer = styled.div`
    display: flex ;
    justify-content: center ;
    align-items: center ;
`;
export const CloseIconStyled = styled.img `
    position: absolute;
    top: 18px;
    right: 12px;
    cursor: pointer;
    width: 19px;
    height: 19px;
`;
export const DialogIconStyled = styled.img `
`;

export const DialogInfoStyled = styled.img `
    padding-right: 8px;
`;

export const DividerStyled = styled.div`
    width: 100%;
    height: 1px;
    top: 52px;
    position: absolute;
    padding: 0 16px;
    &::before {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        background-color:rgba(183, 185, 187, 0.3);
    }
`;

export const TitleDialog = styled.div `

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 111%;
  /* or 28px */

  text-align: center;
  text-transform: capitalize;
  padding-top: 11px;
  color: #000000;
`;

export const DialogContentTextStyled = styled.div`

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  padding: 0 60px;
`;
export const InputContainer = styled.div`
  display: flex;
  padding: 0 60px;
  align-items: center;
  gap: 10px;
`;

export const DividerStyledFooter = styled.div`
    width: 100%;
    height: 1px;
    top: 0px;
    position: absolute;
    padding: 0 16px;
    &::before {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        background-color:rgba(183, 185, 187, 0.3);
    }
`;
export const DialogActionsStyled = styled(DialogActions)`
    position: relative ;
    display: flex;
    gap: 18px;
`;
 export const ButtonsContainer = styled.div `
   display: flex;
   align-items: center;
   gap: 18px;
   margin: 17px 0 13px 0;
 `;

export const CancelButton = styled.button`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 111%;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #BABABA;
`;

export const ConfirmButton = styled.button<{disabled: boolean}>`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center ;
    width: 155px;
    height: 53px;
    background: ${p => p.disabled? "#E0E0E0" : "#FB5E03"};
    border-radius: 26.5px;
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 111%;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F5F5F5;
    margin-right: 7px;
`;

export const ClusterNameText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 42px;
  white-space: nowrap;
  /* or 210% */

  letter-spacing: -0.02em;

  color: #000000;
`;

import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import './App.css';
import MainLayout from "./Components/layout/MainLayout";
import { routes } from "./routes";
import Login from "./containers/Login";
import ResetPasswordSignIn from "./Components/ResetPasswordSignIn/ResetPasswordSignIn";
import SetNewPasswordSignIn from "./Components/SetNewPasswordSignIn/SetNewPasswordSignIn";
import SignUp from "./Components/SignUp/SignUp";
import useAuth from "./hooks/useAuth";

function App() {
  const loggedIn = useAuth();
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<SetNewPasswordSignIn />} />
          <Route path="/reset-password-email" element={<ResetPasswordSignIn />} />
          <Route path="/invite-user" element={<SignUp />} />
          {
            loggedIn
              ?
                (<Route path="/" element={<MainLayout />}>
                      {routes}
                </Route>)
              :
                <></>

          }
        </Routes>
    </BrowserRouter>
  );
}

export default App;

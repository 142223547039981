import { useMemo, useTransition } from 'react';
import { toast } from 'react-toastify';
import toastCheckmark from "../assets/svg/checkmark-toast.svg";
import toastError from "../assets/svg/danger.svg";
import toastWarning from "../assets/svg/toast-warning.svg";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const useToast = () => {
    const { t } = useTranslation();
    return useMemo(() => ({
        success: (message: string) => {
            toast.success(t(message),{
                icon: ({theme, type}) =>  (<img src={toastCheckmark}/>)
                });
        },
        warning: (message: string) => {
            toast.warning(t(message),{
                icon: ({theme, type}) =>  (<img src={toastWarning}/>)
                });
        },
        error: (message: string) => {
            toast.error(t(message),{
                icon: ({theme, type}) =>  (<img src={toastError}/>)
                });
        },
    }),[t]);

};

export default useToast;

//toasts to use inside sagas:
export enum toastTypes {
    success = "success",
    warning = "warning",
    error = "error"
}
export const toastify = (message: string, type: toastTypes) => {
    switch(type){

        case toastTypes.success: 
            toast.success(i18next.t(message),{
                icon: ({theme, type}) =>  (<img src={toastCheckmark}/>)
            });
        break;
        case toastTypes.warning: 
            toast.warning(i18next.t(message),{
                icon: ({theme, type}) =>  (<img src={toastWarning}/>)
            });
        break;
        case toastTypes.error:
            toast.error(i18next.t(message),{
                icon: ({theme, type}) =>  (<img src={toastError}/>)
            });
            break;
    }
}

import React, {useCallback, useEffect, useState} from "react";
import {
  AccountTitle,
  Container,
  EmailContainer,
  FullNameContainer,
  GoogleButtonContainer,
  GoogleButtonContainerA,
  GoogleImg,
  GoogleTitle,
  Gradient,
  LeftSide,
  Line,
  LineContainer,
  Or,
  PasswordContainer,
  RightSide,
  SubmitBtn,
  SubTitleSignIn,
  TitleSignIn,
  WelcomeInfo,
  WelcomeSubTitle,
  WelcomeTitle,
} from "./styles";
import googleLogo from "../../assets/svg/googlelogo.svg";
import EmailInput from "../EmailInput/EmailInput";
import PasswordInput from "../PasswordInput/PasswordInput";
import Footer from "../Footer/Footer";
import InputField from "../InputField/InputField";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {selectInvitationData, selectSignUpData,} from "../../store/slices/users/selectors";
import {invitationDataReq, userSignUpReq} from "../../store/slices/users";
import useToast from "../../hooks/useToast";
import {BEErrorResponse} from "../../types/BEResponse";

export const API_URL = process.env.REACT_APP_API_ENDPOINT;

const SignUp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm();
  const { email, name, password } = getValues();
  const invitationData = useSelector(selectInvitationData);
  const signUpData = useSelector(selectSignUpData);
  const [account, setAccount] = useState<string>("");

  const handleFieldChange = useCallback(
    (field: string, value: string) => {
      setValue(field, value);
      clearErrors(field);
    },
    [clearErrors, setValue]
  );

  const onSubmit = useCallback(() => {
    console.log("handleSubmit");
    const queryParams = new URLSearchParams(
      window.location.search.replace("?", "")
    );
    if (queryParams.has("token")) {
      const token = queryParams.get("token");
      dispatch(
        userSignUpReq({
          token,
          password,
          name: name,
        })
      );
    }
  }, [dispatch, password, name]);

  useEffect(() => {
    console.log(invitationData);
    if (
      invitationData &&
      invitationData.httpStatusCode === 200 &&
      invitationData.data &&
      invitationData.data[0]
    ) {
      handleFieldChange("email", invitationData.data[0].email);
      handleFieldChange("name", invitationData.data[0].name);
      setAccount(invitationData.data[0].accountName);
    }
  }, [invitationData, handleFieldChange, setAccount]);

  useEffect(() => {
    const queryParams = new URLSearchParams(
      window.location.search.replace("?", "")
    );
    if (queryParams.has("token")) {
      const token = queryParams.get("token");
      dispatch(invitationDataReq(token));
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (signUpData && signUpData.httpStatusCode === 200) {
      toast.success(signUpData.reason || signUpData.message);
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else if (signUpData && signUpData.httpStatusCode !== 200) {
      const errors = signUpData.errors || [];
      errors.forEach((err: BEErrorResponse) => {
        if (err.field) {
          setError(err.field, {
            type: "server",
            message: t(err.reason) || err.message,
          });
        }
      });
    }
  }, [navigate, setError, signUpData, t, toast]);

  console.log("errors", errors);
  return (
    <Gradient>
      <Container>
        <LeftSide>
          <AccountTitle>
            {t("sign_up_your")} {account}
          </AccountTitle>
          <WelcomeTitle>{t("login.title")} </WelcomeTitle>
          <WelcomeSubTitle>{t("login.subtitle")} </WelcomeSubTitle>
          <WelcomeInfo>{t("login.info")}</WelcomeInfo>
        </LeftSide>
        <RightSide>
          <TitleSignIn>{t("sign_up_title")}</TitleSignIn>
          <SubTitleSignIn>{t("sign_up_subtitle")}</SubTitleSignIn>
          <GoogleButtonContainer
            href={`${API_URL}/oauth2/authorization/google`}
          >
            <GoogleButtonContainerA>
              <GoogleImg src={googleLogo} alt={"googleLogo"} />
              <GoogleTitle>{t("sign_up_google_button")}</GoogleTitle>
            </GoogleButtonContainerA>
          </GoogleButtonContainer>
          <LineContainer>
            <Line></Line>
            <Or>{t("sign_up_or")}</Or>
            <Line></Line>
          </LineContainer>
          <EmailContainer>
            <EmailInput
              {...register("email", {
                required: t("field.required") || "",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: t("email.format") || "",
                },
              })}
              value={email}
              placeholder={t("user.profile.email.placeholder")}
              status={"editable"}
              onChange={(text: string) => handleFieldChange("email", text)}
              isRequired={true}
              readonly={true}
              title={t("user.profile.user.email") || ""}
              signInScreen={true}
              error={(errors.email?.message as string) || ""}
              errorType={"error"}
            />
          </EmailContainer>
          <FullNameContainer>
            <InputField
              {...register("name", {
                required: t("field.required") || "",
              })}
              placeholder={t("create_user_full_name_placeholder")}
              value={name}
              onChange={(text: string) => handleFieldChange("name", text)}
              // onEnter={onEnterAccount}
              // onClickHandle={onInputClickHandle}
              onClickCloseHandle={() => handleFieldChange("name", "")}
              title={t("create_user_full_name_title") || ""}
              showAsterisk={false}
              signInScreen={true}
              error={(errors.name?.message as string) || ""}
              errorType={"warning"}
            />
          </FullNameContainer>
          <PasswordContainer>
            <PasswordInput
              {...register("password", {
                required: t("field.required") || "",
                minLength: {
                  value: 10,
                  message: t("password.length") || "",
                },
                pattern: {
                  value: /^(?=.*[a-zA-Z])(?=.*\d).{10,}$/,
                  message: t("password.must.contain") || "",
                },
              })}
              value={password}
              placeholder={""}
              status={"editable"}
              onChange={(text: string) => handleFieldChange("password", text)}
              isRequired={true}
              title={t("new_password_input.title") || ""}
              signInScreen={true}
              error={(errors.password?.message as string) || ""}
              errorType={"warning"}
            />
          </PasswordContainer>
          <SubmitBtn type="submit" onClick={handleSubmit(onSubmit)}>
            {" "}
            {t("sign_up_button")} {">"}
          </SubmitBtn>
        </RightSide>
      </Container>
      <Footer />
    </Gradient>
  );
};

export default SignUp;

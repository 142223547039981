import React, {useState} from "react";
import {
  ArrowDown,
  ArrowUp,
  CloseIconStyled,
  Container,
  ContainerInput,
  EndContainer,
  TableContainer,
  Title,
  ValueContainer,
} from "./styles";
import closeIcon from "../../../../../assets/svg/close.svg";
import SimpleTable from "../SimpleTable";
import InputError from "../../../../../Components/InputError";
import {useOutsideClick} from "../../../../../Components/ClickOutSide/ClickOutSide";

interface InputInterface {
  value: string;
  onChange: (text: string) => void;
  onClickCloseHandle?: () => void;
  title?: string;
  errorType?: "warning" | "error";
  error: string;
  data: any[];
}

const ClusterTypeField: React.FC<InputInterface> = (props) => {
  const {
    onChange,
    value,
    onClickCloseHandle,
    title,
    error,
    errorType = "error",
    data,
  } = props;
  const [open, setOpen] = useState(false);
  const onClickClose = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation();
    if (onClickCloseHandle) {
      onClickCloseHandle();
    }
    setOpen(false);
  };

  const ref = useOutsideClick(() => {
    setOpen(false);
  });

  const columns = [
    { id: "ClusterType", label: "Cluster Type" },
  ];
  console.log("data cluster ", data);

  const onRowSelect = (row: Record<string, string>) => {
    onChange(row.ClusterType);
    setOpen(false);
  };

  return (
    <Container ref={ref}>
      {title && <Title>{title}</Title>}
      <ContainerInput error={error} onClick={() => setOpen((prev) => !prev)}>
        <ValueContainer>{value}</ValueContainer>
        {/* <InputFieldStyled
                    onClick={() => setOpen(prev => !prev)}
                    value={value}
                    // placeholder={focused ? "" : placeholder}
                    disabled={true}
                    type={"text"}
                    onChange={(e) => onChange(e.target.value)}
                /> */}

        <EndContainer>
          <CloseIconStyled
            className="closeicon"
            src={closeIcon}
            alt={"closeIcon"}
            onClick={(e) => onClickClose(e)}
          />
          {open ? <ArrowUp /> : <ArrowDown />}
        </EndContainer>
      </ContainerInput>
      {!open && <InputError message={error} errorType={errorType} />}
      {open && (
        <TableContainer>
          <SimpleTable
            columns={columns}
            data={data}
            onRowSelect={onRowSelect}
          />
        </TableContainer>
      )}
    </Container>
  );
};

export default ClusterTypeField;

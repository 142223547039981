import styled from "styled-components";

export const CheckBoxFilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px 10px 10px;
`;

export const Title = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    /* or 20px */

    letter-spacing: -0.02em;

    color: #000000;
`;
import React, { useMemo } from "react";
import { Container, ButtonContainer, Title } from "./styles";
import { Cell } from "react-table";
import { StatusType } from "../Status/types";
import { useDispatch } from "react-redux";
import { connectCluster } from "../../../../../../store/slices/clusterManagement";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
interface ConnectAdminListHeaderProps {
  cell: Cell<Record<string, any>>;
}

const Connect: React.FC<ConnectAdminListHeaderProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isEnabled = useMemo(
    () => props.cell.row.original.status === StatusType.Running,
    [props.cell.row.original.status]
  );
  const navigate = useNavigate();
  const handleConnect = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (isEnabled) {
      console.log("test");
      // dispatch(connectCluster(props.cell.row.original.clusterId));
      // /CLI/:id
      navigate(`/CLI/${props.cell.row.original.clusterName}`, {
        state: props.cell.row.original,
      });
    }
  };
  return (
    <React.Fragment>
      <Container>
        <ButtonContainer onClick={handleConnect} isEnabled={isEnabled}>
          <Title isEnabled={isEnabled}>{t("clusters_list_connect")}</Title>
        </ButtonContainer>
      </Container>
    </React.Fragment>
  );
};
export default Connect;

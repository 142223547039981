import React, { useMemo } from "react";
import {
  Container,
  SentStatusStyled,
  ItemText,
  RunningStatusStyled,
  StartStatusStyled,
  Item,
  RevokedStatusStyled,
  RevokedExpiredStatusStyled,
} from "./styles";
import { Cell, Column } from "react-table";
import { StatusType } from "./types";
import moment from "moment";
interface StatusAdminListHeaderProps {
  cell: Cell<Record<string, any>>;
  column: any;
}

// const getStatusText= ( status: string) => {

//     if(status === StatusType.Completed)
//         return "Completed";
//     return "Waiting for reviewer"
// }
const Status: React.FC<StatusAdminListHeaderProps> = (props) => {
  const { cell, column } = props;
  const { value } = cell;
  const text = useMemo(
    () =>
      column
        ? column?.types.find(
            (elem: { value: string; label: string }) => elem.value === value
          )?.label
        : "",
    [column, value]
  );
  // const title = useMemo(() => getStatusText(value), [value]);
  const isBold = useMemo(() => value === StatusType.Completed, [value]);

  const checkExpired = useMemo(() => {
    if (
      moment(new Date(new Date().getTime())).unix() <
      props.cell.row.original.expiryDate
    ) {
      return false;
    } else {
      return true;
    }
  }, [props.cell.row.original.expiryDate]);

  console.log("value", value);
  return (
    <React.Fragment>
      <Container>
        <Item>
          {value === StatusType.Completed && <RunningStatusStyled />}
          {value === StatusType.Sent && <SentStatusStyled />}
          {value === StatusType.Revoked && checkExpired && (
            <RevokedExpiredStatusStyled />
          )}
          {value === StatusType.Revoked && !checkExpired && (
            <RevokedStatusStyled />
          )}
          {value === StatusType.Canceled && <RevokedStatusStyled />}
          <ItemText bold={isBold}>{text}</ItemText>
        </Item>
      </Container>
    </React.Fragment>
  );
};
export default Status;

import { Outlet, useNavigate } from "react-router-dom";
import {Box, Toolbar} from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../Common/Sidebar";
import Topbar from "../Common/Topbar";
import { useSelector } from "react-redux";
import { selectExpandSidebar, selectPath, selectToken } from "../../store/slices/general/selectors";

import Footer from "../Footer/Footer";
import { OutletContainer } from "./styles";
import { useEffect } from "react";

const MainLayout = () => {
  const expand = useSelector(selectExpandSidebar);
  const path = useSelector(selectPath);
  const navigate = useNavigate();
  const token = useSelector(selectToken);

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  },[token]);
  
  return (
      <Box sx={{ display: "flex" }}>
        <Topbar />
        <Box
          component="nav"
          sx={{
            width: expand ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.collapsedWidth,
            flexShrink: 0
          }}
        >
          <Sidebar />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            padding: 0,
            width: `calc(100% - ${expand ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.collapsedWidth})`,
            minHeight: "100vh",
            backgroundColor: colorConfigs.mainBg
          }}
        >
          <Toolbar sx={{maxHeight: sizeConfigs.header.height, minHeight: `${sizeConfigs.header.height} !important`}} />
          <OutletContainer path={path} >
            <Outlet  />
          </OutletContainer>
        </Box>
        <Footer />
      </Box>
  );
};

export default MainLayout;

import { createSlice } from "@reduxjs/toolkit";
import { BEResponse } from "../../../types/BEResponse";

export interface AccountsState {
    data: BEResponse;
    createData: BEResponse;
    isLoading: boolean,
};

export interface AccountType {

}

const initialState: AccountsState = {
    data: {
        data: [],
    },
    createData: {},
    isLoading: false,
};



const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    getAccountsDataRequest: (state, action) => {
        state.isLoading = true;
    },
    updateAccountsData: (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
    },
    getAccountsDataRequestError: (state,action) => {
        state.isLoading = false;
        state.createData = action.payload;
    },
    createNewAccountReq: (state, action) => {
        state.isLoading = true;
    },
    createAccountSuccess: (state, action) => {
        state.isLoading = false;
        state.createData = action.payload;
    },
    clearAccountNewData:(state) => {
        state.createData = {};
    },
  }
});

export const {
    getAccountsDataRequest,
    updateAccountsData,
    getAccountsDataRequestError,
    createNewAccountReq,
    createAccountSuccess,
    clearAccountNewData,
} = accountsSlice.actions;

export default accountsSlice;
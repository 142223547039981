import React, {useState, useEffect} from 'react';

import{ TextFilterContainer, Title, InputContainer } from "./styles"
import InputField from '../../InputField/InputField';
interface TextFilterProps {
    Header: string;
    accessor: string;
    handleColumnFilterValueChange: (id: string, value:any) => void;
    value :any;
}
const TextFilter: React.FC<TextFilterProps> = (props) => {

    const { accessor, Header, handleColumnFilterValueChange, value } = props;
    const [text, setText] = useState<string>(value as string);

    useEffect(() => {
        //debounce value
        handleColumnFilterValueChange(accessor, text)
    },[accessor, handleColumnFilterValueChange, text])

    return (
        <TextFilterContainer>
            <Title>{Header}</Title>
            <InputContainer>
                <InputField placeholder={Header} onChange={setText} value={text} size='sm' onClickCloseHandle={() => setText("")} />
            </InputContainer>
        </TextFilterContainer>
    );
};

export default TextFilter;

import styled from "styled-components";

interface TableContainerType {
    height: number;
}

export const ClustersAdminListContainer = styled.div `
    height: 100%;
`;

export const ClustersAdminListTableContainer = styled.div `
    height: 100%;
`;

export const ClustersAdminListHeaderContainer = styled.div `
    margin-bottom: 27px;
`;

export const TableContainer = styled.div<TableContainerType> `
     height: calc(100% - ${props => props.height}px);
`;

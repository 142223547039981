import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    BottomWrapperNew,
    CloseUserProfileContainer,
    Container,
    EmailContainer, InputContainer, SaveUserButton,
    SelectContainer, UserButtonsWrapper,
    UserContainer
} from "./styles";
import BreadCrumbs from "../../Components/BreadCrubms";
import {CloseIconResetPassword} from "../ResetPassword/styles";
import close from "../../assets/svg/close.svg";
import Select from "../../Components/Select/Select";
import {DropDownMode} from "../../Components/Select/types";
import EmailInput from "../../Components/EmailInput/EmailInput";
import InputField from "../../Components/InputField/InputField";
import {ButtonsContainer, CancelButton, LineBottom} from "../../Components/CreateNewAccount/styles";
import {ButtonTitle} from "../../Components/AccountsHeader/styles";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {clearInviteNewUser, getRolesRequest, inviteNewUserRequest} from "../../store/slices/general";
import {useForm} from "react-hook-form";
import { getAccountsDataRequest } from '../../store/slices/accounts';
import { selectAccountsData } from '../../store/slices/accounts/selectors';
import { BEErrorResponse, BEResponse } from '../../types/BEResponse';
import AutoComplete from '../../Components/AutoComplete';
import { selectInviteUserResponse, selectRolesMetaData } from '../../store/slices/general/selectors';
import useToast from '../../hooks/useToast';
import AccountSelect from '../../Components/AccountsSelect';
// import useFieldsErrorHook from '../../hooks/useFieldsErrorHook';

const CreateNewUser = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    // const {fieldsErrors, updateErrorField} = useFieldsErrorHook();
    const { register, handleSubmit, formState : {errors} , setValue,  getValues, clearErrors, setError } = useForm();
    const {name, email, account} = getValues();
    const rolesMetaData: BEResponse = useSelector(selectRolesMetaData);
    const inviteResponse = useSelector(selectInviteUserResponse);
    const [accountManagerRoleId, setAccountManagerRoleId] = useState<string>('');

    useEffect(() => {
        if (inviteResponse &&
            inviteResponse.httpStatusCode === 200) {
            toast.success(inviteResponse.reason || inviteResponse.message || '');
            dispatch(clearInviteNewUser());
            navigate(-1);
        }
        else if (inviteResponse &&
            inviteResponse.httpStatusCode !== 200){
                const errors = inviteResponse.errors || [];
                 errors.forEach((err: BEErrorResponse) => {
                    if(err.field){
                        setError(err.field, {
                            type: "server",
                            message: err.reason || err.message,
                          });
                    }
                 });
        }
    },[dispatch, inviteResponse, navigate, setError, toast]);

    useEffect(() => {
        if (rolesMetaData && rolesMetaData.data && rolesMetaData.data.length > 0){
            const accountManagerRole = rolesMetaData.data.find((it: any) => it.name === 'ACCOUNT_ADMIN');
            if (accountManagerRole) {
                setAccountManagerRoleId(accountManagerRole.id);
            }
        }
    }, [rolesMetaData]);

    const handleFieldChange = useCallback((field: string,  value: string) => {
        setValue(field,value);
        clearErrors(field)
    },[clearErrors, setValue]);


    const onSubmit = () => {
        if (accountManagerRoleId) {
            dispatch(inviteNewUserRequest({accountId: account, email: email,name: name, roleId: accountManagerRoleId }));
        }
    };


    useEffect(() => {
        dispatch(getRolesRequest({}));
        // setHeaderHeight((ref?.current?.clientHeight || 0) + 41);
    }, [dispatch]);

    return (
        <Container>
            <BreadCrumbs />
            <UserContainer>
                <CloseUserProfileContainer>
                    <CloseIconResetPassword src={close} alt={"close"} onClick={() => {navigate(-1);}}/>
                </CloseUserProfileContainer>
                <SelectContainer>
                    <AccountSelect 
                        name={"account"}
                        register={register}
                        value={account}
                        handleChange={handleFieldChange}
                        errors={errors}
                        title={t('ui.account.select.title') || ""}
                        placeholder={'ui.account.select.placeholder'}
                    />
                </SelectContainer>
                <EmailContainer>
                    <EmailInput
                        {...register('email', {

                            required: t("field.required") || "",
                            pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: t("email.format") || ""
                        }})}
                        value={email}
                        placeholder={t("user.profile.email.placeholder")}
                        status={"editable"}
                        onChange={(text: string) => handleFieldChange("email", text)}
                        error={errors.email?.message as string || ""}
                        isRequired={true} title={t("user.profile.user.email") || ""}/>
                </EmailContainer>
                <InputContainer>
                    <InputField
                        {...register('name', {
                            required: t("field.required") || "",
                            })}
                        placeholder={t("create_user_full_name_placeholder")}
                        value={name}
                        onChange={(text: string) => handleFieldChange("name", text)}
                        onClickCloseHandle={() => handleFieldChange("name", "")}
                        error={errors.name?.message as string || ""}
                        title={t("create_user_full_name_title") || ""}
                        showAsterisk={false}
                    />
                </InputContainer>
                <BottomWrapperNew>
                    <LineBottom/>
                    <UserButtonsWrapper>
                        <ButtonsContainer>
                            <CancelButton onClick={() => {navigate(-1);}}>{t("cancel.button")} </CancelButton>
                            <SaveUserButton onClick={handleSubmit(onSubmit)}>
                                <ButtonTitle>{t("create_user_full_name_send_button")} {'>'}</ButtonTitle>
                            </SaveUserButton>
                        </ButtonsContainer>
                    </UserButtonsWrapper>
                </BottomWrapperNew>
            </UserContainer>
        </Container>

    );
};

export default CreateNewUser;

const sizeConfigs = {
    sidebar: {
      width: "250px",
      collapsedWidth: '161px'
    },
    header: {
      height: "59px",
    },
    footer: {
      height: '51px',
    }
  };
  
export default sizeConfigs;
import * as React from 'react';
import {
    StyledDialog, CloseIconStyled, ConfirmButton, DividerStyled, StyledDialogContent,
    DialogIconStyled, IconContainer, DialogContentTextStyled, DialogActionsStyled,
    DividerStyledFooter, CancelButton, TitleDialog, DialogContentTextInfo, DialogInfoStyled
} from "./styles";
import closeIcon from "../../assets/svg/close.svg";
import dialogIcon from "../../assets/svg/dialog_icon.svg";
import dialogInfo from "../../assets/svg/dialog_info.svg";
import {useTranslation} from "react-i18next";

type AlertDialogProps = {
    title: string,
    subTitle: string,
    paragraph: string
    closeDialog: () => void;
    handleConfirm: () => void;
}
const AlertDialog  = ({ title, subTitle, paragraph, closeDialog, handleConfirm }: AlertDialogProps) =>  {
    const [open, setOpen] = React.useState(true);
    const {t} = useTranslation();
    const handleClose = () => {
        setOpen(false);
        closeDialog();
    };
    return (
        <div>
            {/*<Button variant="outlined" onClick={handleClickOpen}>*/}
            {/*    Open alert dialog*/}
            {/*</Button>*/}
            <StyledDialog
                open={open}
                onClose={handleClose}
            >
                <CloseIconStyled src={closeIcon} alt={"closeIcon"} onClick={handleClose}/>
                <DividerStyled />
                <StyledDialogContent>
                    <IconContainer><DialogIconStyled src={dialogIcon} alt={"dialogIcon"}/></IconContainer>
                    <TitleDialog>{title}</TitleDialog>
                    <DialogContentTextStyled>
                        {subTitle}
                    </DialogContentTextStyled>
                    <DialogContentTextInfo>
                        <DialogInfoStyled src={dialogInfo} alt={"dialogInfo"}/>{paragraph}
                    </DialogContentTextInfo>
                </StyledDialogContent>
                <DialogActionsStyled>
                    <DividerStyledFooter />
                    <CancelButton onClick={handleClose}>{t("cancel.button")}</CancelButton>
                    <ConfirmButton onClick={handleConfirm} autoFocus>
                        {t("modal.confirm.button")}
                    </ConfirmButton>
                </DialogActionsStyled>
            </StyledDialog>
        </div>
    );
}
export default AlertDialog;

import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put } from 'redux-saga/effects';
import accounts from '../../../apis/accounts';
import { AccountReqBody, CreateAccountReqBody } from '../../../types/Accounts';
import { Response } from '../../../types/Response';
import { createAccountSuccess, createNewAccountReq, getAccountsDataRequest, getAccountsDataRequestError, updateAccountsData } from '../../slices/accounts';

export function* handleGetAccountsData (action: PayloadAction<AccountReqBody>): any {
    try {
        const response: Response<any> = yield call(accounts.getAccounts, action.payload);
        if (response.success){
            const { data } = response;
            yield put(updateAccountsData(data));
        }
        else {
            yield put(getAccountsDataRequestError('error'));
        }
    } catch(error: any){
        // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    }
}

export function* handleCreateNewAccount (action: PayloadAction<CreateAccountReqBody>): any {
    try {
        const response: Response<any> = yield call(accounts.createAccount, action.payload);
        if (response.success){
            const { data } = response;
        yield put(createAccountSuccess(data));
        }
        else {
            const { error } = response;
            yield put(getAccountsDataRequestError(error));
        }
    } catch(error: any){
        // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    }
}


export function* accountsSaga() { // generator function
    yield takeLatest(getAccountsDataRequest, handleGetAccountsData);
    yield takeLatest(createNewAccountReq, handleCreateNewAccount);
}


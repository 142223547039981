import styled from "styled-components";

import {ReactComponent as TraingleIcon} from "../../../../../assets/svg/arrowdown2.svg"

export const ArrowUp = styled(TraingleIcon)`
  transform: rotate(180deg);
  transition: all 0.5s linear;
  margin-left: 10px ;
`;
export const ArrowDown = styled(TraingleIcon)`
  transition: all 0.5s linear ;
  margin-left: 10px ;
`;

export const EndContainer = styled.div`
  display: flex;
  width: 60px;
  justify-content: end ;
  align-items: center ;
  margin-right: 14px;
  height: 100% ;

`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  align-items: flex-start;
  cursor: pointer;
`;

export const TitleContainer = styled.div `
  height: 58px;
`
export const Title = styled.div `
  font-style: normal;
  font-family: ${({ theme: { language } }) =>
    language === "eng" ? "Poppins" : "unset"};
  font-weight: 500;
  font-size:18px;
  line-height: 100%;
  letter-spacing: -0.02em;
  /* or 18px */
  display: flex;
  color: #000000;
  padding-bottom: 10px;
`;

export const ContainerInput = styled.div<{error: string | boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05); */
  border-radius: 30px;
  gap: 12px;
  width: 100%;
  //background: linear-gradient(174.06deg, #EEEEEE 20.86%, #FFFFFF 87.44%, #FFFFFF 131.09%);
  background: #F7F5F5;
  padding-left: 19px;
  border: ${p => p.error ? "3px solid rgb(234, 0, 0)": "1px solid #EEEEEE"};
  cursor: pointer ;
  z-index: 999;

  &:hover .closeicon {
    display: block;
  }

`;

export const ValueContainer = styled.div`
  min-width: 20px;
`;

export const IconStyled = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;


export const CloseIconStyled = styled.img`
  cursor: pointer;
  display: none ;

`;

export const InputFieldStyled = styled.input `
  outline: none;
  border: none;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 100%;
  /* identical to box height, or 15px */

  letter-spacing: -0.02em;
  font-stretch: normal;
  font-family: ${({ theme: { language } }) =>
    language === "eng" ? "Poppins" : "unset"};
  background: transparent;
  color: #2C2C2C;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  }
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #888888;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
`;

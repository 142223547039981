import React, {useEffect, useRef, useState, useCallback, useMemo } from 'react';
import {useNavigate} from "react-router-dom";
import {
    Container, UsersManagementHeaderContainer,
    UsersManagementTableContainer,
    TableContainer, TabsContainer, TabButton
} from "./styles";
import UsersManagementHeader from "../../Components/UsersManagementHeader/UsersManagementHeader";
import useFilterSearch from "../../hooks/useFilterSearch";
import TableTest from "../../Components/Table2";
import useTable from "./hooks/useTable";
import {useTranslation} from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { SubmitRevoke, inviteUser, getUsersData, getInvitationsData } from '../../store/slices/users';
import { Row } from 'react-table';
import { selectResetPasswordEmailResp } from '../../store/slices/general/selectors';
import useToast from '../../hooks/useToast';
import { resetPasswordSentSuccessfully } from '../../store/slices/general';

enum UsersManagementTabEnum {
    USER,
    INVITATION,
}

interface RefType {
    getCheckboxRecords: any;
}

const UsersManagement = () => {

    const navigate = useNavigate();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const toast = useToast();
    const ref = useRef<HTMLInputElement>(null);
    const tableRef = useRef<RefType>(null);
    const resetPasswordEmailResp = useSelector(selectResetPasswordEmailResp);
    const [tabSelected, setTabSelected] = useState<UsersManagementTabEnum>(UsersManagementTabEnum.USER);
    const [headerHeight, setHeaderHeight] = useState<any>(250);
    const {invitationsInitialState, userColumns, userData, invitationsColumns, invitationData, globalFilterFunctionUsers, globalFilterFunctionInvitations} = useTable();
    const {filterSearchCols, handleColumnCheckChange,
        handleResetAllFilters, handleResetFilter,
        handleColumnFilterValueChange, tableFilters, handleRemoveFilter, globalFilterValue, handleGlobalFilterChange} =
        useFilterSearch(tabSelected === UsersManagementTabEnum.USER ? userColumns : invitationsColumns);

    const  [selectedRows, setSelectedRows] = useState<Array<Record<string,any>>>([]);

    const refapi = useRef<any>(null)

    useEffect(() => {
        dispatch(getUsersData({}));
        dispatch(getInvitationsData({}));
        refapi.current = setInterval(() => {
            if (tabSelected === UsersManagementTabEnum.USER) {
                dispatch(getUsersData({}));
            }
            else if (tabSelected === UsersManagementTabEnum.INVITATION) {
                dispatch(getInvitationsData({}));
            }
        }, 5 * 1000);

    return () => {
        if(refapi.current){
        clearInterval(refapi.current)
        }
    }
    }, [dispatch, tabSelected])

    const buttonDisabled = useMemo(() => Array.isArray(selectedRows) && selectedRows.length ? 
                                     ({revoke: selectedRows[0].status === "invitation.status.revoked", invite: false}) : 
                                     ({revoke: true, invite: true}), [selectedRows])
    
    const resendInvitations = useCallback(() => {
        if(Array.isArray(selectedRows) && selectedRows.length){
            const accountId  = selectedRows[0].accountId;
            const email = selectedRows[0].email;
            const name = selectedRows[0].name;
            const roleId = selectedRows[0].roleId;

            dispatch(inviteUser({accountId, email, name, roleId}));
        }   
    },[dispatch, selectedRows]);

    const openCreateNewUser = useCallback(() => {
        navigate('/Admin/UsersManagement/new');
    },[navigate]);

    const handleRevoke = useCallback(() => {
        if(Array.isArray(selectedRows) && selectedRows.length){
            const invitationToken = selectedRows[0].token;
            dispatch(SubmitRevoke(invitationToken));
        }        
    },[dispatch, selectedRows]);

    useEffect(() => {
        if (resetPasswordEmailResp && resetPasswordEmailResp &&
            resetPasswordEmailResp.httpStatusCode === 200) {
            toast.success(resetPasswordEmailResp.reason || resetPasswordEmailResp.message || '');
            dispatch(resetPasswordSentSuccessfully({}));
        }
    },[dispatch, resetPasswordEmailResp, toast]);


    const onSelectedRowsChange = useCallback((ids : any) => {
        console.log("selected ids", ids);
        if(tableRef.current){
            const selectedRows = tableRef.current.getCheckboxRecords;
            console.log(selectedRows);
            setSelectedRows(selectedRows);
        }
    },[]);

    return (
        <Container>
           <UsersManagementTableContainer>
              <UsersManagementHeaderContainer ref={ref}>
                   <UsersManagementHeader createNewUser={openCreateNewUser}
                                          reSendInvitations={resendInvitations}
                                          handleRemoveFilter={handleRemoveFilter}
                                          filterSearchCols={filterSearchCols}
                                          handleColumnCheckChange={handleColumnCheckChange}
                                          handleResetAllFilters={handleResetAllFilters}
                                          handleColumnFilterValueChange={handleColumnFilterValueChange}
                                          handleResetFilter={handleResetFilter}
                                          userTab={tabSelected === UsersManagementTabEnum.USER}
                                          globalFilterValue={globalFilterValue}
                                          handleGlobalFilterChange={handleGlobalFilterChange}
                                          buttonDisabled={buttonDisabled}
                                          handleRevoke={handleRevoke}/>
                  <TabsContainer>
                      <TabButton selected={tabSelected === UsersManagementTabEnum.USER} onClick={() => {setTabSelected(UsersManagementTabEnum.USER)}} >{t("users_management_tab_user")}</TabButton>
                      <TabButton selected={tabSelected === UsersManagementTabEnum.INVITATION} onClick={() => {setTabSelected(UsersManagementTabEnum.INVITATION)}}>{t("users_management_tab_invitation")}</TabButton>
                  </TabsContainer>
              </UsersManagementHeaderContainer>
               <TableContainer height={headerHeight}>
                   {tabSelected === UsersManagementTabEnum.USER &&  <TableTest tabContainer={true} globalFilterValue={globalFilterValue} globalFilterFunction={globalFilterFunctionUsers} canSelect={false} data={userData} columns={userColumns}  size="small" filters={tableFilters} />}
                   {tabSelected === UsersManagementTabEnum.INVITATION && <TableTest tabContainer={true} tableInitialState={invitationsInitialState} ref={tableRef} globalFilterValue={globalFilterValue} globalFilterFunction={globalFilterFunctionInvitations} canSelect={true} data={invitationData} columns={invitationsColumns}  size="small" filters={tableFilters} onSelectedRowsChange={onSelectedRowsChange} singleSelect={true}/>}
               </TableContainer> 
           </UsersManagementTableContainer>
        </Container>

    );
};

export default UsersManagement;

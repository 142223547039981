import {
    configureStore,
    combineReducers,
  } from "@reduxjs/toolkit";
  import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./sagas";
import accountsSlice from "./slices/accounts";
import generalSlice from "./slices/general";
import usersSlice from "./slices/users";
import backupRestoreSlice from "./slices/backupRestore";
import clusterManagementSlice from "./slices/clusterManagement"
  

const reducer = combineReducers({
    general: generalSlice.reducer,
    accounts: accountsSlice.reducer,
    users: usersSlice.reducer,
    clustermanagement: clusterManagementSlice.reducer,
    backupRestore:backupRestoreSlice.reducer,
});

let sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = configureStore({
    reducer,
    middleware
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;

export default store;

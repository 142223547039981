import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from "react-router";
import BreadCrumbs from "../../Components/BreadCrubms";
import {ButtonsWrapper, ClusterQueryContainer, Container, ResponseQueryContainer, SubmitBtn,} from "./styles";
import {BottomWrapperNew} from "../UserProfile/styles";
import {ButtonsContainer, LineBottom,} from "../../Components/CreateNewAccount/styles";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {ContainerLoading} from "./loading";
import {selectIsQueryLoading, selectQueryClusterResponse,} from "../../store/slices/general/selectors";
import {BEErrorResponse} from "../../types/BEResponse";
import useToast from "../../hooks/useToast";
import ClusterQueryOutput from "../../Components/ClusterQueryOutput/ClusterQueryOutput";
import useInputHistory from "./hooks/useInputHistory";
import RefAutoComplete from "../../Components/cli-input/component";
import {CircularProgress} from "@mui/material";
import Basic from "./basicTable/basic"

interface outputInterface {
    "columns": Array<any>,
    "rows": Array<any>,
    "success": boolean,
    "errorMessage": string;
    "errorCode": string;
    "duration": number;
    query: string
}


const RenderOutput = ({ outputRes }: { outputRes: outputInterface[] }) => {

    return <React.Fragment>
        {outputRes.map(elem => {
            if (elem.success === false) {
                return elem.errorMessage + " (Error code: " + elem.errorCode + ")"
            } else {
                if (elem.columns) {
                    const table = {
                        data: {
                            columns: elem.columns,
                            rows: elem.rows || []
                        }
                    }
                    return <Basic table={table} />
                }

                if (elem.query) {
                    return "Query completed with no result."
                }
                return ""
            }
        })}
    </React.Fragment>
}


const ClusterCli = () => {
    const params = useParams();
    const location = useLocation();
    const {
        setError,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        clearErrors,
    } = useForm();
    const { t } = useTranslation();
    const toast = useToast();
    const { query } = getValues();
    const dispatch = useDispatch();
    let queryResponse = useSelector(selectQueryClusterResponse) || "";
    const isLoading = useSelector(selectIsQueryLoading);

    const handleFieldChange = useCallback(
        (field: string, value: string) => {
            setValue(field, value);
            clearErrors(field);
        },
        [clearErrors, setValue]
    );

    const { handleChange, handleKeyDown, onSubmit } = useInputHistory(
        query,
        handleFieldChange,
        isLoading
    );

    useEffect(() => {
        // Reset the state whenever the dynamic part of the path changes
        setResponses([]);
        queryResponse = {};
    }, [location, params]);

    const [responses, setResponses] = useState<
        { input: string; output: outputInterface[] }[]
    >([]);

    useEffect(() => {
        if (queryResponse && queryResponse.httpStatusCode === 200) {
            const response: outputInterface[] = queryResponse.data;
            // console.log("response list!", queryResponse)
            const prevQuery = query;
            setResponses((prevResponses) => [
                ...prevResponses,
                { input: prevQuery, output: response },
            ]);
            setValue("query", "");
        } else if (queryResponse && queryResponse.httpStatusCode !== 200) {
            const errors = queryResponse.errors || [];
            errors.forEach((err: BEErrorResponse) => {
                if (err.field) {
                    setError(err.field, {
                        type: "server",
                        message: err.reason || err.message,
                    });
                }
            });
        }
    }, [queryResponse, dispatch, setError, toast]);

    const responseEndRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        responseEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [responses]);

    const dataSource: any[] = [
        {
            text: 'WITH (devices = (FILL HERE DEVICE NAME LIST))',
            value: 'WITH',
        },
        {
            text: 'WITH (devices = (DEVICE NAME))',
            value: 'WITH',
        },
        {
            text: 'WITH (devices = ([DEVICE NAME1, DEVICE NAME2]))',
            value: 'WITH',
        },
        {
            text: 'create',
            value: 'create',
        },
        {
            text: 'create table',
            value: 'createTable',
        },
        {
            text: 'create table (table name)',
            value: 'createTableName',
        },
    ];





    return (
        <ClusterQueryContainer>

            <BreadCrumbs darkMode={true} title={params.title} />
            <Container>
                <ResponseQueryContainer>
                    <ClusterQueryOutput
                        value={responses.map((response, index) => (
                            <div key={index}>
                                <p style={{fontSize: "18px", fontWeight: "bold"}}>Input:</p>
                                <div style={{fontSize: "16px", lineHeight: 1.5}}>{response.input}</div>
                                <p style={{fontSize: "18px", fontWeight: "bold"}}>Output:</p>
                                <div style={{fontSize: "16px", lineHeight: 1.5}}>
                                    {/* {response.output} */}
                                    <RenderOutput outputRes={response.output}/>

                                    {/* <Basic table={table} /> */}
                                </div>
                                <p style={{fontSize: "18px", fontWeight: "bold"}}>Execution Time: {response.output[0].duration} ms</p>
                            </div>
                        ))}
                    />

                    <div ref={responseEndRef} />
                </ResponseQueryContainer>
                <BottomWrapperNew>

                    <LineBottom />
                    <ButtonsWrapper>
                        {/* <CliAutoCompleteInput
                            {...register("query", {
                                required: t("field.required") || "",
                                validate: (value) =>
                                    value.trim() !== "" || "The field cannot be empty",
                            })}
                            placeholder="Query me"
                            value={query}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            onClickCloseHandle={() => handleFieldChange("query", "")}
                            required={true}
                            // onEnter={handleSubmit(onSubmit)}
                            error={(errors.name?.message as string) || ""}
                        /> */}
                        <RefAutoComplete
                            {...register("query", {
                                required: t("field.required") || "",
                                validate: (value) =>
                                    value.trim() !== "" || "The field cannot be empty",
                            })}
                            className="inline-autocomplete-example"
                            dataSource={dataSource}
                            placeholder="Query me"
                            value={query}
                            onChange={handleChange}
                            disabled={isLoading}
                            onPressEnter={handleSubmit(onSubmit)}
                            onKeyDown={handleKeyDown}
                            onClickCloseHandle={() => handleFieldChange("query", "")}
                            caseSensitive={false}
                        />
                        <ButtonsContainer>
                            <SubmitBtn
                                isLoading={isLoading}
                                type="submit"
                                onClick={handleSubmit(onSubmit)}
                            >
                                {isLoading ? <ContainerLoading><CircularProgress disableShrink /></ContainerLoading> : "Send"}
                            </SubmitBtn>
                        </ButtonsContainer>
                    </ButtonsWrapper>
                </BottomWrapperNew>
            </Container>
        </ClusterQueryContainer>
    );
};

export default ClusterCli;

import { PropaneRounded } from "@mui/icons-material";
import styled from "styled-components";

export const BreadcrumbsContainer = styled.div `
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 4px;
`;
export const HeaderContainer = styled.div `
    position: relative;
    margin-bottom: 28px;
`;


export const BreadCrumbItem = styled.div<{enabled:boolean}> `
    height: 24px;
    margin-bottom: 10px;
    font-family: ${({ theme: { language } }) =>
            language === "eng" ? "Rubik" : "unset"};
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 133% */
    color: ${props => props.enabled ? '#707070' : '#C2C2C2'};
    cursor: ${props => props.enabled ? 'pointer' : 'unset'};
`;

export const TitleContainer = styled.div`
    width: fit-content;
`;

export const LineTitle = styled.div `
  /* Main Orange */
  height: 0;
  border: 3px solid #FF6600;
  border-radius: 5px;
  z-index: 10;
  position: relative; 
`;

export const Title = styled.div<{darkMode? : boolean}> `
    font-family: ${({ theme: { language } }) =>
    language === "eng" ? "Poppins" : "unset"};
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    /* or 117% */
    letter-spacing: -0.02em;
    color: ${props => props.darkMode ? '#FFFFFF' : '#000000'};
    text-shadow: 0px 4px 20px rgba(45, 44, 44, 0.15);
    margin-bottom: 8px;
`;


export const Line = styled.div `
  width: 100%;
  border: 1px solid rgba(183, 185, 187, 0.3);
  transform: matrix(1, 0, 0, -1, 0, 0);
  position: absolute;
  bottom: 1.5px;
`;
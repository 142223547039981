import {  POST, DELETE, GET, PUT } from '../network/client';
import {Response} from "../types/Response";
import {ClustersReportReqBody, CreateClusterReqBody, CreateQueryRequest, GetConfigInterface} from '../types/clusterManagement';
import {UserSingUpReqInterface} from "../store/sagas/users";

const paths = {
    QUERY_CLUSTER: '/v1/clusters/{clusterId}/run-query',
    GET_CLUSTER_REPORT: '/v1/clusters/report?size=1000&full=false&sort=id%2Casc',
    DESTROY_CLUSTER : '/v1/clusters/{clusterId}',
    CONNECT_CLUSTER: '/cli/{clusterId}/connect',
    START_CLUSTER: '/v2/skynet/cluster-ops/{clusterId}/start',
    STOP_CLUSTER: '/v2/skynet/cluster-ops/{clusterId}/stop',
    CREATE_CLUSTER: '/v1/clusters',
    GET_CLUSTER_REGIONS: '/v1/clusters/regions',
    GET_CLUSTER_TYPES: '/v1/clusters/types',
    DELETE_TRUSTED_IPS: '/v1/clusters/{clusterId}/trusted-ips',
    GET_TRUSTED_IPS: '/v1/clusters/{clusterId}/trusted-ips?size=1000',
    ADD_TRUSTED_IPS: '/v1/clusters/{clusterId}/trusted-ips',
    GET_CONFIGURATION: '/v1/clusters/{clusterId}/configuration'
};
const queryCluster = (data: CreateQueryRequest): Promise<Response<any>> => POST<any>(paths.QUERY_CLUSTER.replace('{clusterId}', data.clusterId),{query : data.query});
const getClusters = (body: ClustersReportReqBody): Promise<Response<any>> => POST<any>(paths.GET_CLUSTER_REPORT, body);
const destroyCluster = (clusterId: string): Promise<Response<any>> => DELETE<any>(paths.DESTROY_CLUSTER.replace('{clusterId}', clusterId));
const connectClusters = (clusterId: string): Promise<Response<any>> => POST<any>(paths.CONNECT_CLUSTER.replace('{clusterId}', clusterId), {});
const createClusters = (body: CreateClusterReqBody): Promise<Response<any>> => POST<any>(paths.CREATE_CLUSTER, body);
const getClusterRegions = (): Promise<Response<any>> => POST<any>(paths.GET_CLUSTER_REGIONS, {});
const getClusterTypes = (): Promise<Response<any>> => POST<any>(paths.GET_CLUSTER_TYPES, {});
const deleteTrustedIPs = (clusterId: string, ids: Array<string>): Promise<Response<any>> => DELETE<any>(paths.DELETE_TRUSTED_IPS.replace('{clusterId}', clusterId), ids);
const getTrustedIPs = (clusterId: string): Promise<Response<any>> => GET<any>(paths.GET_TRUSTED_IPS.replace('{clusterId}', clusterId));
const addTrustedIPs = (clusterId: string, ips: Array<string>): Promise<Response<any>> => POST<any>(paths.DELETE_TRUSTED_IPS.replace('{clusterId}', clusterId), ips);
const getconfig = (data: GetConfigInterface): Promise<Response<any>> => GET<any>(paths.GET_CONFIGURATION.replace('{clusterId}', data.clusterId),{}, undefined, data.suppressErrorToast);
const stopCluster = (clusterId: string): Promise<Response<any>> => PUT<any>(paths.STOP_CLUSTER.replace('{clusterId}', clusterId), {});
const startCluster = (clusterId: string): Promise<Response<any>> => PUT<any>(paths.START_CLUSTER.replace('{clusterId}', clusterId), {});

const clusterManagement = { getClusters, destroyCluster, connectClusters, createClusters,
    getClusterRegions, getClusterTypes, deleteTrustedIPs, getTrustedIPs, addTrustedIPs, queryCluster, getconfig, stopCluster , startCluster };

export default clusterManagement;

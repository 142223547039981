import React, { useMemo } from "react";
import {
  CloseIconStyled,
  IconContainer,
  Item,
  ItemContainer,
  ItemText,
  RestoreIconStyled,
  DeleteIconStyled,
  menuPopperProps,
  EditIconStyled,
  EnableIconStyled,
  DisableIconStyled,
} from "./styles";
import Popover from "@mui/material/Popover";
import { resetPasswordEmailRequest } from "../../../../store/slices/general";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateBackupPlan } from "../../../../store/slices/backupRestore";
import { useNavigate } from "react-router";

export default function ActionCell(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const isEnabled = useMemo(
    () => props.cell.row.original.status === "backup.plan.status.enabled",
    [props.cell.row.original.status]
  );
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleEnableBackup = () => {
    if (!isEnabled) {
      dispatch(
        updateBackupPlan({
          clusterId: props.cell.row.original.clusterId,
          body: {
            ...props.cell.row.original,
            status: "backup.plan.status.enabled",
          },
        })
      );
      handleClose();
    }
  };

  const handleDisableBackup = () => {
    if (isEnabled) {
      dispatch(
        updateBackupPlan({
          clusterId: props.cell.row.original.clusterId,
          body: {
            ...props.cell.row.original,
            status: "backup.plan.status.disabled",
          },
        })
      );
      handleClose();
    }
  };

  const handleEdit = () => {
    navigate("/update-backup-plan", {
      state: props.cell.row.original,
    });
  };

  return (
    <React.Fragment>
      <IconContainer onClick={handleClick}>
        <CloseIconStyled />
      </IconContainer>
      <Popover
        id={"filter-panel"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        PaperProps={menuPopperProps}
      >
        <ItemContainer>
          <Item disabled={isEnabled} onClick={handleEnableBackup}>
            {/* <RestoreIconStyled /> */}
            <EnableIconStyled disabled={isEnabled} />
            <ItemText disabled={isEnabled}>
              {t("ui.backup.restore.action.enable")}
            </ItemText>
          </Item>
          <Item disabled={!isEnabled} onClick={handleDisableBackup}>
            {/* <DeleteIconStyled /> */}
            <DisableIconStyled disabled={!isEnabled} />
            <ItemText disabled={!isEnabled}>
              {t("ui.backup.restore.action.disable")}
            </ItemText>
          </Item>
          <Item onClick={handleEdit}>
            <EditIconStyled />
            <ItemText>{t("ui.backup.restore.action.edit")}</ItemText>
          </Item>
          {/*<Item>*/}
          {/*    <DeleteIconStyled />*/}
          {/*    <ItemText>Delete</ItemText>*/}
          {/*</Item>*/}
        </ItemContainer>
      </Popover>
    </React.Fragment>
  );
}


export interface AccountReqBody {
    description?: string;
    accountName?: string;
    types?: AccountsTypes[];
}

export interface CreateAccountReqBody {
    name: string;
    type: AccountsTypes;
}

export enum AccountsTypes {
    premium = "account.type.premium",
    freemium = "account.type.freemium",
}
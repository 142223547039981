import { Schedule } from "@mui/icons-material";
import styled from "styled-components";
export const Container = styled.div `
 //padding: 29px 27px 0 37px;
 height: calc(100vh - 180px);
  position: relative ;
  
  padding-bottom: 188px;
`;


export const LineTitle = styled.div `
  /* Main Orange */
  width: 70px;
  height: 0;
  border: 3px solid #FF6600;
  border-radius: 5px;
`;

export const AccountLinesContainer = styled.div `
   display: flex;
  width: 100%;
  height: 0;
  align-items: center;
  //margin-bottom: 107px;
  margin-bottom: 28px;
  padding-top: 2px;
`;

export const UserContainer = styled.div `

  background: #FFFFFF;
  border-radius: 10px;
  padding: 18px 16px 0 58px;
  overflow: auto ;
  margin-bottom: 100px ;
  height: 100% ;
  width: 100% ;

`;

export const InputContainer = styled.div `
  display: flex;
  justify-content: left;
  padding-top: 28px;
  width: 468px;
`;

export const EmailContainer = styled.div `
  display: flex;
  justify-content: left;
  padding: 39px 0 33px 0;
  width: 468px;
`;

export const UserButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  padding: 34px 0 0 0;
`;

export const SaveUserButton = styled.div<{disabled?: boolean}> `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 17px 26px;
  gap: 10px;
  cursor: pointer;
  height: 53px;

  background: ${props => props.disabled ? '#E0E0E0' : '#FB5E03'};
  border-radius: 26.5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const CloseUserProfileContainer = styled.div `
  justify-content: flex-end;
  display: flex;
  top: 13px;
  right: 13px;
`;

export const ResetPasswordContainer = styled.div `
  justify-content: flex-end;
  display: flex;
  padding-bottom: 27px;
`;

export const ResetPasswordButton = styled.div `

  /* Link & Button - Inconsolata Bold 17 - 10% Kerning */

  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 111%;
  /* identical to box height, or 19px */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #FBFBFB;

  background: #5C2EDF;
  border-radius: 26.5px;
  cursor: pointer;
  padding: 17px 26px;
  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const BottomWrapperNew = styled.div`
      width: 100%;
      padding: 10px;
      left: 0;
      bottom: 0px;
      position: absolute;
      background: #ffffff ;
     
`;

export const ScheduleContainer = styled.div`
  display: flex ;
  gap: 15px;
  align-items: center ;
  padding-top: 28px;
  
`;
export const TimeWrapper = styled.div`
  display: flex ;
  width: 250px;
  gap: 10px;
  justify-content: space-between ;
  align-items: center ;
`;

export const RadioGroup = styled.div`
  display: flex ;
  flex-direction: column ;
  align-items: start ;
  justify-content: center ;
`;

export const RadioRow = styled.div`
  display: flex ;
  align-items: center ;
  /* gap: 8px; */
`;

export const RadioText = styled.div`
  font-family: Poppins;
font-size: 18px;
font-weight: 400;
line-height: 18px;
text-align: left;
color: #000000;

`;

export const Divider = styled.div`

height: 150px;
width: 1px;
background-color: #EFEFF0;

`;

export const WeeklyRowDay = styled.div`
  display: flex;
  align-items: center ;
  gap: 10px;
  width: 250px;
`;

export const MonthlyContainer = styled.div`
  display: flex ;
  flex-direction: column ;
  align-items: start;
  justify-content: center ;
  gap: 10px;
`;
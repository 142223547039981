
import styled from "styled-components";
import {ReactComponent as CloseButton}  from "../../../assets/svg/close.svg"
import {ReactComponent as ArrowDown}  from "../../../assets/svg/ArrowFilter.svg"
export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 26px;
  height: 34px;
  background: #EEEEEE;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
`;

export const RemoveButtonStyled = styled(CloseButton)`
    width: 8px;
    height:8px;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &:hover {
        & path {
            fill: black;
        }
    }
`;

export const ArrowDownStyled = styled(ArrowDown)`
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
`;


export const Text = styled.div`
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */

    letter-spacing: -0.02em;

    color: #2C2C2C;
`;

export const ButtonHidden = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  z-index: -1;
`;
export const menuPopperProps = ({
    elevation: 0,
    sx: {
      borderRadius: '20px',
      minWidth: '200px',
      background: '##FFFFFF',
      overflow: 'visible',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
      mt: 1.5,  
      '& .MuiList-root': {
        padding: 0,
      },
    },
  });
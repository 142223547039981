
export interface BackupsFilterReqBody {

}

export interface BackupRestoreReqBody {
    sourceCluster: string;
    targetCluster: string;
    backupId: string;
    restart: true;
}

export interface SaveBackupReqBody {
 
    tag: string;
    restart: boolean;

}


export enum recurrenceBackupPlan {
    DAILY="backup.recurrence.pattern.daily",
    WEEKLY="backup.recurrence.pattern.weekly",
    MONTHLY="backup.recurrence.pattern.monthly",
    ENDOFMONTH="backup.recurrence.pattern.every.end.of.month",
}
export interface SaveBackupPlanReqBody {

    dayOfMonth: number,
    dayOfWeek: number,
    enabled: boolean,
    recurrencePattern: recurrenceBackupPlan,
    retentionInDays: number,
    startTime: string;
    tag: string;
    timeZone: string;

}

export interface UpdateBackupPlanReqBody {
    [key: string]: any;
}


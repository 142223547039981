import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import auth from "../../../apis/auth";
import { axiosInstance } from "../../../network/client";
import { ResetPasswordTokenReqBody } from "../../../types/user";

export interface UsersState {
  loading: boolean;
  invitationData: any;
  signUpData: any;
  users: any;
  invitations: any;
  submitRevoke: any;
  inviteUser: any;
}

const initialState: UsersState = {
  loading: false,
  invitationData: {},
  signUpData: {},
  users: { data: [], isLoading: false, isError: false },
  invitations: { data: [], isLoading: false, isError: false },
  submitRevoke: null,
  inviteUser: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    invitationDataReq: (state, action) => {
      state.loading = true;
    },
    invitationData: (state, action) => {
      state.loading = true;
      state.invitationData = action.payload;
    },
    userSignUpReq: (state, action) => {
      state.loading = true;
    },
    userSignUpData: (state, action) => {
      state.loading = false;
      state.signUpData = action.payload;
    },
    getUsersData: (state, action) => {
      state.users.isLoading = true;
      state.users.isError = false;
      // state.users.data = null;
    },
    getUsersDataDone: (state, action) => {
      state.users.isLoading = false;
      state.users.isError = false;
      state.users.data = action.payload;
    },
    getUsersDataError: (state, action) => {
      state.users.isLoading = false;
      state.users.isError = true;
      state.users.data = null;
    },
    getInvitationsData: (state, action) => {
      state.invitations.isLoading = false;
      state.invitations.isError = true;
      // state.invitations.data = null;
    },
    getInvitationsDataDone: (state, action) => {
      state.invitations.isLoading = false;
      state.invitations.isError = false;
      state.invitations.data = (action.payload || []).filter(
        (it: any) => it.status !== "invitation.status.completed"
      );
    },
    getInvitationsDataError: (state, action) => {
      state.invitations.isLoading = false;
      state.invitations.isError = true;
      state.invitations.data = null;
    },
    SubmitRevoke(state, action) {
      state.submitRevoke = null;
    },
    SubmitRevokeDone(state, action) {
      state.submitRevoke = action.payload;
    },
    inviteUser(state, action) {
      state.inviteUser = null;
    },
    inviteUserDone(state, action) {
      state.inviteUser = action.payload;
    },
  },
});

export const {
  invitationDataReq,
  invitationData,
  userSignUpReq,
  userSignUpData,
  getUsersData,
  getUsersDataDone,
  getUsersDataError,
  getInvitationsData,
  getInvitationsDataDone,
  getInvitationsDataError,
  SubmitRevoke,
  SubmitRevokeDone,
  inviteUser,
  inviteUserDone,
} = usersSlice.actions;

export default usersSlice;

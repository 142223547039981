import React, { useContext, useRef, useEffect, useCallback } from 'react';
import { useVirtualizer, elementScroll } from "@tanstack/react-virtual";
import Context from '../context';
import CkRow from './ckRow';

function easeInOutQuint(t) {
  return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
}


const Body = () => {
  const bodyRef = useRef(null);
  const { dispatch, state, tableInstance, size = 'small' } = useContext(Context);
  const { prepareRow, rows, getTableBodyProps } = tableInstance;

  const scrollingRef = useRef();
  const scrollToFn = useCallback((offset, canSmooth, instance) => {
    const duration = 1000;
    if (bodyRef.current) {
      const start = bodyRef.current.scrollTop;
      const startTime = (scrollingRef.current = Date.now());

      const run = () => {
        if (scrollingRef.current !== startTime) return;
        const now = Date.now();
        const elapsed = now - startTime;
        const progress = easeInOutQuint(Math.min(elapsed / duration, 1));
        const interpolated = start + (offset - start) * progress;

        if (elapsed < duration) {
          elementScroll(interpolated, canSmooth, instance);
          requestAnimationFrame(run);
        } else {
          elementScroll(interpolated, canSmooth, instance);
        }
      };

      requestAnimationFrame(run);
    }
  }, []);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => bodyRef.current,
    enableSmoothScroll: true,
    estimateSize: React.useCallback(() => {
      switch (size) {
        case 'large':
          return 64
        case 'small':
          return 48
        case 'mini':
          return 36
        default:
          return 48
      }
    }, [size]),
    overscan: 1,
    scrollToFn: scrollToFn,
  });

  const onScroll = (e) => {
    const { scrollLeft, scrollWidth, clientWidth, scrollTop } = e.target;
    if (scrollLeft !== state.curScroll.scrollLeft) {
      if (state.headerElem) { state.headerElem.current.scrollLeft = scrollLeft };
      if (state.footerElem) { state.footerElem.current.scrollLeft = scrollLeft };
    }

    dispatch({
      type: 'changeScroll',
      curScroll: { scrollLeft, scrollWidth, clientWidth, scrollTop: scrollTop },
    });
  };

  useEffect(() => {
    if (bodyRef) {
      dispatch({
        type: 'bodyElem',
        bodyElem: bodyRef
      });
    };
  }, [dispatch]);

  return (
    <>
      <div className="ck-body" {...getTableBodyProps()}>
        <div ref={bodyRef} className='ck-tbody' onScroll={onScroll}     style={{
                overflowY: "auto",
                boxSizing: "border-box",
                position: "relative",
              }}>
          <div
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: "100%",
              position: "relative"
            }}
          >
            {rowVirtualizer.getVirtualItems().map(virtualRow => {
              prepareRow(rows[virtualRow.index]);
              const style = {
                position: "absolute",
                top: 0,
                left: 0,
                minWidth: '100%',
                transform: `translateY(${virtualRow.start}px)`,
              }
              return <CkRow row={rows[virtualRow.index]} {...rows[virtualRow.index].getRowProps({ style })}  />
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;

import { MenuItem, Popover } from "@mui/material";
import styled from "styled-components";

export const HeaderBackground = styled.div `
  background: #FF6600;
  height: 59px;
  padding-bottom: 8px;
`;

export const HeaderContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  height: 56px;
  margin: 0 27px 19px 29px;
  color: #FFFFFF;
  background: #FF6600;
  height: 59px;
`;

export const Header_left = styled.div `
  flex: 0.3;
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

export const Header_logo_img = styled.img `

`;

export const Header_right = styled.div `
  display: flex;
  align-items: center;
  margin-right: 21px;
`;

export const Header_info_img = styled.img<{active: boolean}> `
  margin-right: 15px;
  width: 31px;
  opacity: ${(props) => (props.active ? "1.0" : "0.25")};
  cursor: ${(p) => (p.active ? "pointer" : "default")};
  display: none;
`;

export const Header_help_img = styled.img<{active: boolean}> `
  margin-right: 17px;
  width: 31px;
  cursor: ${(p) => (p.active ? "pointer" : "default")};
  display: none;
`;

export const Header_notification_img = styled.img<{active: boolean}> `
  margin-right: 35px;
  width: 31px;
  cursor: ${(p) => (p.active ? "pointer" : "default")};
`;

export const Vertical_i = styled.div `
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Poppins" : "unset"};
  font-style: normal;
  font-weight: 500;
  height: 19px;
  font-size: 15px;
  line-height: 130%;
  /* or 19px */
  padding-right: 27px;
  text-align: center;
  color: #FFFFFF;

`;

export const Account = styled.div `
  font-family: ${({ theme: { language } }) =>
    language === "eng" ? "Poppins" : "unset"};
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #FFFFFF;
  padding-right: 11px;
`;

export const ProfileContainer = styled.div `
  cursor: pointer;
`;

export const ProfileListContainer = styled.div `
  position: relative;
  height:231px;
  width: 297px;
`;

export const menuPopperProps = ({
  elevation: 0,
  sx: {
    borderRadius: '20px',
    transform: 'matrix(0, 1, 1, 0, 0, 0)',
    padding: 0,
    width: '297px',
    background: '#F5F5F7',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiList-root': {
      padding: 0,
    },
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      left: undefined,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
});

export const AvatarTitle = styled.div `
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Poppins" : "unset"};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */
  width: 171px;
  text-align: center;

  color: #000000;

`;

export const MenuItemContainer = styled(MenuItem) `

  &:hover {
    background-color: transparent !important;
  }
`;

export const ListTitle = styled.a `
  font-family: ${({ theme: { language } }) =>
    language === "eng" ? "Poppins" : "unset"};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  /* or 21px */

  text-align: center;

  color: #000000;
`;

export const ListIcon = styled.img `
  margin-right: 15px;
  margin-left: 19px;
`;

export const ListIconReset = styled.img `
  margin-right: 9px;
  margin-left: 16px;
`;

export const ListIconSignOut = styled.img `
  margin-right: 10px;
  margin-left: 17px;
`;

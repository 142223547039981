import styled from "styled-components";
export const Container = styled.div `
 //padding: 29px 27px 0 37px;
`;

export const NavTitleContainer = styled.div `
display: flex;
`;

export const NavTitleActive = styled.div `
  height: 24px;
  margin-bottom: 10px;
  font-family: ${({ theme: { language } }) =>
    language === "eng" ? "Rubik" : "unset"};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 133% */
  color: #707070;
`;

export const LineTitle = styled.div `
  /* Main Orange */
  width: 246px;
  height: 0;
  border: 3px solid #FF6600;
  border-radius: 5px;
`;

export const AccountLinesContainer = styled.div `
   display: flex;
  width: 100%;
  height: 0;
  align-items: center;
  //margin-bottom: 107px;
  margin-bottom: 28px;
  padding-top: 2px;
`;

export const UserContainer = styled.div `
      height: calc(-290px + 100vh);
  background: #FFFFFF;
  border-radius: 10px;
  padding: 13px 16px 0 50px;
  margin-right: 6px;
`;

export const InputContainer = styled.div `
  display: flex;
  justify-content: left;
  padding-top: 30px;
  width: 600px;
`;

export const EmailContainer = styled.div `
  display: flex;
  justify-content: left;
  padding: 40px 0 0 0;
  width: 600px;
`;

export const UserButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  padding: 34px 0 0 0;
`;

export const SaveUserButton = styled.div `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 17px 26px;
  gap: 10px;
  cursor: pointer;
  height: 53px;

  background: #FB5E03;
  border-radius: 26.5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const CloseUserProfileContainer = styled.div `
  justify-content: flex-end;
  display: flex;
  top: 13px;
  right: 13px;
`;

export const BottomWrapperNew = styled.div`
     width: calc(100% - 125px);
      bottom: 50px;
      left: 60px;
      position: absolute;
`;

export const SelectContainer = styled.div `
   padding-top: 37px;
   width: 600px;
`;


import React, { useMemo, useState } from "react";
import {
  CloseIconStyled,
  DeleteIconStyled,
  IconContainer,
  Item,
  ItemContainer,
  ItemText,
  menuPopperProps,
  RestoreIconStyled,
} from "./styles";
import deleteIcon from "../../../../assets/svg/info_new.svg";

import Popover from "@mui/material/Popover";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { deleteBackup } from "../../../../store/slices/backupRestore";
import AlertDialog from "./Modal";
import { useNavigate } from "react-router";

export default function ActionCell(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const { status } = props.cell.row.original;
  const { disabled, disabledRestore } = useMemo(
    () => ({
      disabled: status !== "SUCCESS" && status !== "FAIL",
      disabledRestore: status === "FAIL",
    }),
    [status]
  );
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleDeleteBackup = () => {
    setModal(true);
    handleClose();
  };

  const handleRestoreBackup = () => {
    if (disabled || disabledRestore) return;
    navigate("/backup/restore", {
      state: props.cell.row.original,
    });

    // setModals({ delete: false, restore: true });
  };

  const handleConfirmDelete = () => {
    if (disabled) return;
    dispatch(
      deleteBackup({
        clusterName: props.cell.row.original.clusterName,
        clusterId: props.cell.row.original.clusterId,
        backupId: props.cell.row.original.id,
      })
    );
    setModal(false);
  };

  // const handleConfirmRestore = () => {
  //   dispatch(
  //     restoreBackup({
  //       clusterId: props.cell.row.original.clusterId,
  //       body: {
  //         backupId: props.cell.row.original.id,
  //         restart: true,
  //         sourceCluster: props.cell.row.original.clusterId,
  //         targetCluster: props.cell.row.original.clusterId,
  //       },
  //     })
  //   );
  //   setModals({ delete: false, restore: false });
  // };

  return (
    <React.Fragment>
      <IconContainer onClick={handleClick}>
        <CloseIconStyled />
      </IconContainer>
      <Popover
        id={"filter-panel"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        PaperProps={menuPopperProps}
      >
        <ItemContainer>
          <Item
            onClick={handleRestoreBackup}
            disabled={disabled || disabledRestore}
          >
            <RestoreIconStyled disabled={disabled || disabledRestore} />
            <ItemText disabled={disabled || disabledRestore}>
              {t("ui.backup.restore.action.restore")}
            </ItemText>
          </Item>
          <Item onClick={handleDeleteBackup} disabled={disabled}>
            <DeleteIconStyled disabled={disabled} />
            <ItemText disabled={disabled}>
              {t("ui.backup.restore.action.delete")}
            </ItemText>
          </Item>
        </ItemContainer>
      </Popover>
      {modal && (
        <AlertDialog
          title={"Are you sure?"}
          subTitle={`You are about to delete backup "${props.cell.row.original.tag}" for cluster`}
          src={deleteIcon}
          paragraph={props.cell.row.original.clusterName}
          subTitle2={""}
          closeDialog={() => {
            setModal(false);
            handleClose();
          }}
          handleConfirm={handleConfirmDelete}
        />
      )}
    </React.Fragment>
  );
}

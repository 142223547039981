import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import {
  getClusterRegions,
  getClusterTypes,
} from "../../../../store/slices/clusterManagement";
import {
  selectClusterRegions,
  selectClusterTypes,
} from "../../../../store/slices/clusterManagement/selectors";
import { selectRoles } from "../../../../store/slices/general/selectors";
import { useTranslation } from "react-i18next";

interface Region {
  id: string;
  regionName: string;
  cloud: string;
  providerRegionName: string;
  displayName: string;
}

interface Type {
  id: string;
  type: string;
  cloud: string;
  nodeCount: number;
  instanceType: string;
  description: string;
}
const useData = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const regions = useSelector(selectClusterRegions);
  const types = useSelector(selectClusterTypes);
  const roles = useSelector(selectRoles);
  const [cloud, setCloud] = useState("");
  const isSystemAdmin = useMemo(
    () => (roles ? roles.indexOf("SYSTEM_ADMIN") >= 0 : false),
    [roles]
  );

  useEffect(() => {
    dispatch(getClusterRegions());
    dispatch(getClusterTypes());
  }, [dispatch]);

  useEffect(() => {
    //get the cloud from region, all regions have the same cloud so we can get the first item only and set the cloud to it
    // I think this will be temporary untill regions have different clouds
    const cloud = regions.length > 0 ? regions[0].cloud : "";
    setCloud(cloud);
  }, [regions]);

  const regionOptions = useMemo(
    () =>
      regions
        .filter((region: Region) => region.cloud === cloud)
        .map((region: Region) => region.regionName),
    [cloud, regions]
  );

  //const filterdTypes = useMemo(() => types.filter((region : Type) => region.cloud === cloud)
  // .map((type : Type) =>  type.type), [cloud, types]);

  const filterdTypes = useMemo(
    () =>
      types
        .filter((region: Type) => region.cloud === cloud)
        .map((type: Type) => ({
          ClusterType: type.type,
          description: t(type.description),
        })),
    [cloud, t, types]
  );

  const TypeOptions = useMemo(
    () =>
      isSystemAdmin
        ? [...filterdTypes, { ClusterType: "Custom", description: "" }]
        : filterdTypes,
    [filterdTypes, isSystemAdmin]
  );

  //     console.log("types", TypeOptions);

  //    useEffect(() => {
  //     console.log("regions TypeOptions", regionOptions, TypeOptions);
  //    },[TypeOptions, regionOptions])

  return { regionOptions, TypeOptions, isSystemAdmin, cloud };
};

export default useData;

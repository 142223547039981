import React from 'react';
import {CopyTermsContainer, Footer_logo_img, FooterContainer, Terms} from "./styles";
import logo from "../../assets/svg/logoregatta.svg";
import footerBg from "../../assets/svg/footer_bg.svg";
import colorConfigs from '../../configs/colorConfigs';
import sizeConfigs from '../../configs/sizeConfigs';
import {useTranslation} from "react-i18next";
const termsUrl = "https://regatta.dev/privacy-policy/";

const Footer = () => {
    const {t} = useTranslation();
    const openTerms = () => {
        window.open(termsUrl,'_blank');
    };

    return (
        <FooterContainer height={sizeConfigs.footer.height} bg={colorConfigs.footer.bg}>
            <Footer_logo_img src={logo} alt={"logo"} />
            <CopyTermsContainer>
                <span>{t("footer_all_rights")}{" "}</span>
                <Terms onClick={() => {openTerms();}}>{t("footer_privacy_policy")}</Terms>
            </CopyTermsContainer>
        </FooterContainer>
    );
};

export default Footer;

import styled from "styled-components";

export const Container = styled.div `
  width: 100%;
  padding-right: 16px;
`;

export const Body = styled.div `
  width: calc(100% + 48px);
  padding-right: 48px;
  height: calc(100vh - 265px);
  overflow: auto;
`;

export const OverviewContainer = styled.div `
  width: 100%;
  justify-content: space-between;
  display: flex;
`;

export const Title = styled.div `
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 0px;
 /* identical to box height, or 0% */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #000000;

`;

export const ConnectButton = styled.div<{statusRunning: boolean}> `
  padding: 17px 43px;
  gap: 10px;
  cursor: pointer;
  height: 53px;
  
  background: ${(props) => (props.statusRunning ? "#5C2EDF": "#E0E0E0")};
  border-radius: 26.5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 111%;
 /* identical to box height, or 19px */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Light Grey */

   color: #EEEEEE;
`;

export const SecurityTitle = styled.div `
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 0px;
 /* identical to box height, or 0% */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #000000;
  padding-top: 63px;
`;

export const TableProviderContainer = styled.div`
  width: 100%;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 5px 5px 0 5px;
  margin-top: 21px;
`;

export const TableTitleProviderContainer = styled.div `
  display: flex;
  justify-content: space-between;
  background: #F6F6F6;
  border-radius: 5px;
  padding: 0 16px 0 24px;
`;

export const TableTitle = styled.div `

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  /* or 18px */

  letter-spacing: -0.02em;
  color: #000000;
  padding: 14px 0 16px 0;
`;

export const TableDetailsContainer = styled.div `
  display: flex;
  padding: 10px 23px 19px 20px;
  justify-content: space-between;
`;

export const TableDetails = styled.div<{paddingRight: string}> `
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #535151;
  padding-right: ${(props) => (props.paddingRight)}px;
`;

export const TableTrustIPContainer = styled.div`
  height: 400px;
  position: relative;
`;

export const AzureIcon = styled.img `
  height: 23px;
`;

export const GoogleIcon = styled.img `
  height: 23px;
`;

export const AmazonIcon = styled.img `
  width: 37px;
  height: 23px;
  margin: 5px 220px 0 0;
`;

export const OverviewDataContainer = styled.section `
  width: 100%;
  height: 105px;
  background: #FFFFFF;
  padding: 5px;
  border-radius: 10px;
  margin-top: 20px;
`;


export const OverviewTitleContainer = styled.div `
  width: 100%;
  background: #F6F6F6;
  border-radius: 5px;
  display: flex;
  padding: 0px 24px;
`;

export const OverviewBodyContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 24px;
`;


export const OverviewTitle = styled.div<{action?: boolean}> `
  flex: 1;        
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  /* or 18px */
  max-width: ${props => props.action ? '80px' : ''};
  letter-spacing: -0.02em;
  color: #000000;
  padding: 14px 0 16px 0;
`;

export const OverviewBodyItem = styled.div<{action?: boolean}>`
  flex: 1;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  max-width: ${props => props.action ? '80px' : ''};
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  justify-content: ${props => props.action ? 'center' : ''};
  color: #535151;
`;

export const VPCInputContainer = styled.div`
  width: 100%;
  display: flex ;
  flex-direction: column ;
  gap: 15px;
 padding: 50px 0;
`;

export const TitleInput = styled.div`
padding-left: 12px;
 font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 0px;
 /* identical to box height, or 0% */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #000000;
`


import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, call, put } from 'redux-saga/effects';
import auth from '../../../apis/auth';
import { Response } from '../../../types/Response';
import {
    logout,
    logoutSuccess,
    requestToken,
    resetPasswordEmailRequest,
    resetPasswordSentSuccessfully,
    setToken,
    resetPasswordUserRequest,
    resetPasswordTokenRequest,
} from '../../slices/general';
import {InvitationsFilterReqBody, InviteUserReqBody, ResetPasswordTokenReqBody, ResetPasswordUserReqBody, UsersFilterReqBody} from "../../../types/user";
import { toastify, toastTypes } from '../../../hooks/useToast';
import { SubmitRevoke, SubmitRevokeDone, getInvitationsData, getInvitationsDataDone, getInvitationsDataError, getUsersData, getUsersDataDone, getUsersDataError, invitationData, invitationDataReq, inviteUser, inviteUserDone, userSignUpData, userSignUpReq } from '../../slices/users';
import users from '../../../apis/users';
import { toast } from 'react-toastify';
export function* handleInvitationReq (action: PayloadAction<string>): any {
    try {
        const response: Response<any> = yield call(users.getInvitationData, action.payload);
        if (response.success){
            const { data } = response;
            yield put(invitationData(data));
        }
    } catch(error: any){
        // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    }
}

export interface UserSingUpReqInterface {
    token: string;
    password: string;
    name: string;
}

export function* handleUserSignUpReq (action: PayloadAction<UserSingUpReqInterface>): any {
    try {
        const response: Response<any> = yield call(users.signUpUser, action.payload);
        if (response.success){
            const { data } = response;
            yield put(userSignUpData(data));
        }else{
            const { error } = response;
            yield put(userSignUpData(error));
        }
    } catch(error: any){
        // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    }
}

export function* handleGetUsersData (action: PayloadAction<UsersFilterReqBody>): any {
    try {
        const response: Response<any> = yield call(users.getUsers, action.payload);
        if (response.success){
            const { data } = response;
            yield put(getUsersDataDone(data.data));
        }
        else {
            yield put(getUsersDataError('error'));
        }
    } catch(error: any){
        // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    }
}

export function* handleGetInvitationsData (action: PayloadAction<InvitationsFilterReqBody>): any {
    try {
        const response: Response<any> = yield call(users.getInvitations, action.payload);
        if (response.success){
            const { data } = response;
            yield put(getInvitationsDataDone(data.data));
        }
        else {
            yield put(getInvitationsDataError('error'));
        }
    } catch(error: any){
        // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    }
}

export function* handleSubmitRevoke (action: PayloadAction<string>): any {
    try {

        const response: Response<any> = yield call(users.revokeInvitation, action.payload);
        if (response.success){
            const { data } = response;
            yield put(SubmitRevokeDone(data.data));
            toastify(data.reason || "Invitations was successfully revoked!", toastTypes.success);
            yield put(getInvitationsData({}))
        }
    } catch(error: any){
        // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    }
}

export function* handleInviteUser (action: PayloadAction<InviteUserReqBody>): any {
    try {

        const response: Response<any> = yield call(users.inviteUser, action.payload);
        if (response.success){
            const { data } = response;
            yield put(inviteUserDone(data.data));
            toastify(data.reason || 'Invitation was sent successfully', toastTypes.success);
            yield put(getInvitationsData({}))
    
        }
    } catch(error: any){
        // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
    }
}



export function* usersSaga() { // generator function
    yield takeLatest(invitationDataReq, handleInvitationReq);
    yield takeLatest(userSignUpReq, handleUserSignUpReq);
    yield takeLatest(getUsersData, handleGetUsersData);
    yield takeLatest(getInvitationsData, handleGetInvitationsData);
    yield takeLatest(SubmitRevoke, handleSubmitRevoke);
    yield takeLatest(inviteUser, handleInviteUser);

    
}


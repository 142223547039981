import { Autocomplete, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExpandIcon, Container, Title, OptionItem } from "./styles";
import expand from "../../assets/svg/expand.svg";
import InputError from "../InputError";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

interface AutoCompleteInterface {
  placeholder: string;
  options: string[];
  updateValue: any;
  value: string | null;
  required?: boolean;
  error?: string;
  errorType?: "error" | "warning";
  title?: string;
  background?: string;
}

const AutoComplete: React.FC<AutoCompleteInterface> = (props) => {
  const {
    updateValue,
    value,
    options,
    placeholder,
    required = false,
    errorType = "error",
    error,
    title = "",
    background = "#F7F5F5",
  } = props;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    if (options && options.length === 1 && required) {
      updateValue(options[0]);
    }
  }, [options, required]);

  const [open, setOpen] = useState(false);
  return (
    <Container>
      <Title titleEmpty={title === ""}>{title}</Title>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        PaperComponent={(props) => (
          <Paper
            sx={{
              background: "#FFFFFF",
              border: "1px solid #EEEEEE",
              borderRadius: "10px",
              paddingTop: "30px",
            }}
            {...props}
          />
        )}
        sx={{
          width: "100%",
          "& .MuiInputBase-root": {
            background,
            fontSize: "15px",
            borderRadius: "30px",
            fontStyle: "normal",
            fontWeight: "300",
            lineHeight: "100%",
            letterSpacing: "-0.02em",
            fontStretch: "normal",
            fontFamily: "unset",
            border: error
              ? "3px solid rgb(234, 0, 0)"
              : "1px solid rgb(238, 238, 238)",
            color: background !== "#F7F5F5" ? "#535151" : "#000000",
          },
          ".MuiAutocomplete-popper": {
            top: "-30px",
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "rgba(0, 0, 0, 0.23)",
            },
          "& .MuiAutocomplete-endAdornment": {
            right: "20px !important",
          },
          "& fieldset": {
            border: "transparent",
            boxShadow: "rgba(0, 0, 0, 0.05) 0px 4px 20px",
          },
        }}
        popupIcon={<ExpandIcon src={expand} />}
        value={value}
        onChange={(event: any, newValue: string | null) => {
          updateValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event: any, newInputValue: string) => {
          setInputValue(newInputValue);
        }}
        id={`auto-complete-${placeholder}`}
        options={options || []}
        renderInput={(params: any) => (
          <TextField
            placeholder={t(placeholder)}
            sx={{
              zIndex: open ? 10000 : 1000,

              "& .MuiInputBase-input::placeholder": {
                fontStyle: "normal",
                fontWeight: "300",
                fontSize: "15px",
                lineHeight: "100%",
                letterSpacing: "-0.02em",
                fontStretch: "normal",
                fontFamily: "unset",
                color: "black",
                opacity: 0.7,
              },
            }}
            hiddenLabel
            variant="outlined"
            {...params}
          />
        )}
        renderOption={(props, option: string, { inputValue }) => {
          const matches = match(option || "", inputValue, {
            insideWords: true,
          });
          const parts = parse(option || "", matches);
          return (
            <OptionItem {...props}>
              <div
                style={{
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      fontSize: "15px",
                      letterSpacing: "-0.02em",
                      fontFamily: "Poppins",
                      color: "#000000",
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </OptionItem>
          );
        }}
      />
      <InputError message={error} errorType={errorType} />
    </Container>
  );
};

export default AutoComplete;

import React, { useContext, useEffect, useRef, useMemo } from 'react';
import Context from '../context';

import styled from 'styled-components';
import {ReactComponent as TraingleIcon} from "../../../assets/svg/triangular.svg"

const ArrowUp = styled(TraingleIcon)`
  transform: rotate(180deg);
  transition: all 0.5s linear;
  margin-left: 10px ;
`;
const ArrowDown = styled(TraingleIcon)`
  transition: all 0.5s linear ;
  margin-left: 10px ;
`;
const SortContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column ;
    gap: 2px;
    align-items: center;
`;
const HeaderText = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    /* identical to box height, or 18px */
    letter-spacing: -0.02em;
    color: #000000;
    cursor: pointer;
`;

const HeaderCell = ({ column, style, ...reset }) => {
  const { tableInstance } = useContext(Context);
  const { totalColumnsWidth } = tableInstance;
  const fixed = column.fixed ? `ck-th fixed-${column.fixed}` : 'ck-th'
  const sty = useMemo(() => {
    const obj = { ...style };
    if (column.fixed === 'left') {
      obj.left = column.totalLeft;
    }
    if (column.fixed === 'right') {
      obj.right = totalColumnsWidth - column.totalLeft - column.totalWidth;
    }
    return obj
  }, [totalColumnsWidth, column, style]);

  return (
    <div className={fixed} style={sty} {...reset}>
      <div className="ck-cell">
        <HeaderText>{column.render('Header')}</HeaderText>
      </div>
        {column.canSort && <div className="ck-column-sorter">
            {column.isSortedDesc === true
                ? <SortContainer><ArrowUp /></SortContainer>
                : column.isSortedDesc === false ?<SortContainer><ArrowDown /></SortContainer> : <SortContainer></SortContainer>}
        </div>}
    </div>
  );
};


const headerProps = (props, { column }) => [
  props,
  {
    style: {
      position: column.fixed ? 'sticky' : 'relative', 
    },
  },
];

const Header = () => {
  const theadRef = useRef(null);
  const { dispatch, tableInstance } = useContext(Context);
  const { headerGroups, totalColumnsWidth } = tableInstance;

  useEffect(() => {
    if (theadRef) {
      dispatch({
        type: 'headerElem',
        headerElem: theadRef
      })
    }
  }, [dispatch])

  return (
    <>
      <div className="ck-header">
        <div ref={theadRef} className="ck-thead">
          {headerGroups.map((headerGroup) => {
            return (
              <div className="ck-tr" {...headerGroup.getHeaderGroupProps({ style: { width: totalColumnsWidth, minWidth: '100%' } })}>
                {headerGroup.headers.map((column) => {
                  return <HeaderCell column={column} {...column.getSortByToggleProps()} {...column.getHeaderProps(headerProps)} />
                })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Header;

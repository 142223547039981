import { takeLatest, call, put, select } from "redux-saga/effects";
import {
  destroyCluster,
  getClusterManagementData,
  getClusterManagementDataDone,
  getClusterManagementDataError,
  destroyClusterDone,
  connectCluster,
  connectClusterDone,
  createCluster,
  createClusterDone,
  createClusterError,
  getClusterRegions,
  getClusterRegionsDone,
  getClusterRegionsError,
  getClusterTypesDone,
  getClusterTypesError,
  getClusterTypes,
  deleteTrustedIPsRequest,
  deleteTrustedIPsDone,
  getClusterTrustedIps,
  getClusterTrustedIpsDone,
  addTrustedIPs,
  getConfig,
  getConfigDone,
  startCluster,
  stopCluster,
  startClusterDone,
} from "../../slices/clusterManagement";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  ClustersReportReqBody,
  CreateClusterReqBody,
} from "../../../types/clusterManagement";
import clusterManagement from "../../../apis/clusterManagement";
import { Response } from "../../../types/Response";
import { toastTypes, toastify } from "../../../hooks/useToast";
import { selectRoles } from "../../slices/general/selectors";

export function* handleGetClusterManagementData(
  action: PayloadAction<ClustersReportReqBody>
): any {
  try {
    const response: Response<any> = yield call(
      clusterManagement.getClusters,
      action.payload
    );
    if (response.success) {
      const { data } = response;
      yield put(getClusterManagementDataDone(data.data));
    } else {
      yield put(getClusterManagementDataError());
    }
  } catch (error: any) {
    // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
  }
}

export function* handleDestroyCluster(
  action: PayloadAction<{ clusterId: string; navigate?: any }>
): any {
  try {
    const response: Response<any> = yield call(
      clusterManagement.destroyCluster,
      action.payload.clusterId
    );
    if (response.success) {
      const { data } = response;
      yield put(destroyClusterDone(data.data));
      toastify("Cluster Deleted!", toastTypes.success);
      if (action.payload.navigate) action.payload.navigate(-1);
      yield put(getClusterManagementData({}));
    }
  } catch (error: any) {
    // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
  }
}

export function* handleConnectCluster(action: PayloadAction<string>): any {
  try {
    const response: Response<any> = yield call(
      clusterManagement.connectClusters,
      action.payload
    );
    if (response.success) {
      const { data } = response;
      yield put(connectClusterDone(data.data));
      toastify("Cluster Connected!", toastTypes.success);
      yield put(getClusterManagementData({}));
    }
  } catch (error: any) {
    // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
  }
}

export function* handleCreateCluster(
  action: PayloadAction<{ req: CreateClusterReqBody; navigate: any }>
): any {
  try {
    const roles = yield select(selectRoles);
    const isSystemAdmin = roles ? roles.indexOf("SYSTEM_ADMIN") >= 0 : false;
    console.log("roles", roles, isSystemAdmin);
    const response: Response<any> = yield call(
      clusterManagement.createClusters,
      action.payload.req
    );
    if (response.success) {
      const { data } = response;
      yield put(createClusterDone(data.data));
      toastify("Cluster Created!", toastTypes.success);
      if (isSystemAdmin) {
        action.payload.navigate("/Admin/Clusters");
      } else {
        action.payload.navigate("/Clusters");
      }
    } else {
      yield put(createClusterError());
    }
  } catch (error: any) {
    yield put(createClusterError());
  }
}

export function* handleGetClusterRegions(): any {
  try {
    const response: Response<any> = yield call(
      clusterManagement.getClusterRegions
    );
    if (response.success) {
      const { data } = response;
      yield put(getClusterRegionsDone(data.data));
    } else {
      yield put(getClusterRegionsError());
    }
  } catch (error: any) {
    yield put(getClusterRegionsError());
  }
}

export function* handleGetClusterTypes(): any {
  try {
    const response: Response<any> = yield call(
      clusterManagement.getClusterTypes
    );
    if (response.success) {
      const { data } = response;
      yield put(getClusterTypesDone(data.data));
    } else {
      yield put(getClusterTypesError());
    }
  } catch (error: any) {
    yield put(getClusterTypesError());
  }
}

export function* handleDeleteTrustedIPs(
  action: PayloadAction<{ clusterId: string; ids: Array<string> }>
): any {
  try {
    const response: Response<any> = yield call(
      clusterManagement.deleteTrustedIPs,
      action.payload.clusterId,
      action.payload.ids
    );
    if (response.success) {
      const { data } = response;
      yield put(deleteTrustedIPsDone(data.data));
      toastify("ip Deleted!", toastTypes.success);
      yield put(getClusterTrustedIps(action.payload.clusterId));
    }
  } catch (error: any) {
    // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
  }
}

export function* handleGetClusterTrustedIps(
  action: PayloadAction<string>
): any {
  try {
    const response: Response<any> = yield call(
      clusterManagement.getTrustedIPs,
      action.payload
    );
    if (response.success) {
      const { data } = response;
      yield put(getClusterTrustedIpsDone(data.data));
    }
  } catch (error: any) {
    // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
  }
}

export function* handleAddTrustedIPs(
  action: PayloadAction<{ clusterId: string; ips: Array<string> }>
): any {
  try {
    const response: Response<any> = yield call(
      clusterManagement.addTrustedIPs,
      action.payload.clusterId,
      action.payload.ips
    );
    if (response.success) {
      const { data } = response;
      yield put(deleteTrustedIPsDone(data.data));
      toastify("ip added!", toastTypes.success);
      yield put(getClusterTrustedIps(action.payload.clusterId));
    }
  } catch (error: any) {
    // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
  }
}

export function* handleGetConfig(
  action: PayloadAction<{ clusterId: string; suppressErrorToast?: boolean }>
): any {
  try {
    const response: Response<any> = yield call(
      clusterManagement.getconfig,
      action.payload
    );
    if (response.success) {
      const { data } = response;
      yield put(getConfigDone(data.data));
    }
  } catch (error: any) {
    // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
  }
}

export function* handleStartCluster(
  action: PayloadAction<{ clusterId: string; navigate?: any }>
): any {
  try {
    const response: Response<any> = yield call(
      clusterManagement.startCluster,
      action.payload.clusterId
    );
    if (response.success) {
      toastify("Cluster Started!", toastTypes.success);
      if (action.payload.navigate) action.payload.navigate(-1);
      yield put(startClusterDone(action.payload.clusterId));
    }
  } catch (error: any) {
    // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
  }
}

export function* handleStopCluster(
  action: PayloadAction<{ clusterId: string; navigate?: any }>
): any {
  try {
    const response: Response<any> = yield call(
      clusterManagement.stopCluster,
      action.payload.clusterId
    );
    if (response.success) {
      toastify("Cluster Stopped!", toastTypes.success);
      if (action.payload.navigate) action.payload.navigate(-1);
      yield put(startClusterDone(action.payload.clusterId));
    }
  } catch (error: any) {
    // yield put(loginFailure(error?.response?.data?.error || 'Failed To Login'));
  }
}

export function* clusterManagementSaga() {
  // generator function
  yield takeLatest(getClusterManagementData, handleGetClusterManagementData);
  yield takeLatest(destroyCluster, handleDestroyCluster);
  yield takeLatest(connectCluster, handleConnectCluster);
  yield takeLatest(createCluster, handleCreateCluster);
  yield takeLatest(getClusterRegions, handleGetClusterRegions);
  yield takeLatest(getClusterTypes, handleGetClusterTypes);
  yield takeLatest(deleteTrustedIPsRequest, handleDeleteTrustedIPs);
  yield takeLatest(getClusterTrustedIps, handleGetClusterTrustedIps);
  yield takeLatest(addTrustedIPs, handleAddTrustedIPs);
  yield takeLatest(getConfig, handleGetConfig);
  yield takeLatest(startCluster, handleStartCluster);
  yield takeLatest(stopCluster, handleStopCluster);

  // yield takeLatest(sagaActions.FETCH_CLUSTER_DATA_SAGA, handleLogin);
}

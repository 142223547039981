import { RoleTypes, RouteType } from "./config";
import { Outlet } from "react-router-dom";
import HomeIcon from "../assets/svg/sidebar/Home.svg";
import HomeSelectedIcon from "../assets/svg/sidebar/Home_selected.svg";
import HomeUnavailableIcon from "../assets/svg/sidebar/Home_unavailable.svg";
import ClustersIcon from "../assets/svg/sidebar/Clusters.svg";
import ClustersSelectedIcon from "../assets/svg/sidebar/Clusters_selected.svg";
import ClustersUnavailableIcon from "../assets/svg/sidebar/Clusters_unavailable.svg";
import CLIIcon from "../assets/svg/sidebar/Cli.svg";
import CLISelectedIcon from "../assets/svg/sidebar/Cli_selected.svg";
import CLIUnavailableIcon from "../assets/svg/sidebar/Cli_unavailable.svg";
import UsersIcon from "../assets/svg/sidebar/Users.svg";
import UsersSelectedIcon from "../assets/svg/sidebar/Users_selected.svg";
import UsersUnavailableIcon from "../assets/svg/sidebar/Users_unavailable.svg";
import SupportIcon from "../assets/svg/sidebar/Support.svg";
import SupportSelectedIcon from "../assets/svg/sidebar/Support_selected.svg";
import SupportUnavailableIcon from "../assets/svg/sidebar/Support_unavailable.svg";
import DocumentationIcon from "../assets/svg/sidebar/Documentation.svg";
import DocumentationSelectedIcon from "../assets/svg/sidebar/Documentation_selected.svg";
import DocumentationUnavailableIcon from "../assets/svg/sidebar/Documentation_unavailable.svg";
import AdminIcon from "../assets/svg/sidebar/Admin.svg";
import AdminSelectedIcon from "../assets/svg/sidebar/Admin_selected.svg";
import AdminUnavailableIcon from "../assets/svg/sidebar/Admin_unavailable.svg";
import Accounts from "../containers/Accounts";
import ResetPasswordUser from "../containers/ResetPassword";
import UserProfile from "../containers/UserProfile/index";
import UsersManagement from "../containers/UsersManagement";
import ClusterCli from "../containers/ClusterCLI";
import CreateNewAccount from "../Components/CreateNewAccount/CreateNewAccount";
import CreateNewUser from "../containers/CreateNewUser";
import Clusters from "../containers/Clusters/Admin/List/index";
import CreateNewClusterUser from "../containers/Clusters/CreateNewCluster/index";
import ClustersList from "../containers/Clusters/ClustersList/ClustersList";
import BackrestoreIcon from "../assets/svg/sidebar/BackupRestore.svg";
import BackrestoreIconSelected from "../assets/svg/sidebar/BackupRestore_selected.svg";
import BackupRestoreUnavailableIcon from "../assets/svg/sidebar/BackupRestore_unavailable.svg";
import RestoreBackup from "../containers/RestoreBackup";
import NewBackup from "../containers/NewBackup";
import Restore from "../containers/Restore";

const appRoutes: RouteType[] = [
  {
    path: "/Home",
    element: <div />,
    state: "home",
    topBarName: "Home",
    sidebarProps: {
      disabled: true,
      displayText: "ui.side.bar.home",
      icon: <img src={HomeIcon} alt={"Home"} width={24} />,
      selectedIcon: <img src={HomeSelectedIcon} alt={"Home"} width={24} />,
      unAvailableIcon: (
        <img src={HomeUnavailableIcon} alt={"Home"} width={24} />
      ),
    },
  },
  {
    path: "/reset-password-user",
    element: <ResetPasswordUser />,
    state: "resetPasswordUser",
    topBarName: "Update Password",
  },
  {
    path: "/user-profile",
    element: <UserProfile />,
    state: "userProfile",
    topBarName: "User profile",
  },
  {
    path: "/create-backup",
    element: <NewBackup type={"backup"} />,
    state: "create-backup",
    topBarName: "New Backup",
  },
  {
    path: "/create-backup-plan",
    element: <NewBackup type={"backup_plans"} />,
    state: "create-backup-plan",
    topBarName: "New Backup Plan",
  },
  {
    path: "/update-backup-plan",
    element: <NewBackup type={"backup_plans"} />,
    state: "update-backup-plan",
    topBarName: "Update Backup Plan",
  },
  {
    path: "/backup/restore",
    element: <Restore />,
    state: "backuprestore",
    topBarName: "Restore",
  },
  {
    path: "/CLI/:title",
    element: <ClusterCli />,
    state: "clusterCLi",
    topBarName: "Cluster",
  },
  {
    path: "/Admin/Accounts/new",
    element: <CreateNewAccount />,
    state: "createNewAccount",
    topBarName: "Create New Account",
  },
  {
    path: "/Clusters",
    element: <Clusters />,
    state: "clusters",
    topBarName: "Clusters",
    sidebarProps: {
      disabled: false,
      displayText: "ui.side.bar.clusters",
      icon: <img src={ClustersIcon} alt={"Clusters"} width={24} />,
      selectedIcon: (
        <img src={ClustersSelectedIcon} alt={"Clusters"} width={24} />
      ),
      unAvailableIcon: (
        <img src={ClustersUnavailableIcon} alt={"Clusters"} width={24} />
      ),
    },
  },
  {
    path: "/backup-restore",
    element: <RestoreBackup />,
    state: "Backup/Restore",
    topBarName: "Backup/Restore",
    sidebarProps: {
      disabled: false,
      displayText: "ui.side.bar.backupRestore",
      icon: <img src={BackrestoreIcon} alt={"Backup / Restore"} width={24} />,
      selectedIcon: (
        <img
          src={BackrestoreIconSelected}
          alt={"Backup / Restore"}
          width={24}
        />
      ),
      unAvailableIcon: (
        <img
          src={BackupRestoreUnavailableIcon}
          alt={"Backup / Restore"}
          width={24}
        />
      ),
    },
  },
  {
    path: "/Clusters/create",
    element: <CreateNewClusterUser />,
    state: "createNewClusterUser",
    topBarName: "Create New Cluster",
  },
  {
    path: "/Admin/Clusters/create",
    element: <CreateNewClusterUser />,
    state: "createNewClusterAdmin",
    topBarName: "Create New Cluster",
  },
  {
    path: "/Clusters/:name",
    element: <ClustersList />,
    state: "ClusterOverview",
    topBarName: "Cluster",
  },
  {
    path: "/Admin/Clusters/:name",
    element: <ClustersList />,
    state: "ClusterOverviewAdmin",
    topBarName: "Cluster",
  },
  // {
  //   path: "/CLI",
  //   element: <div></div>,
  //   state: "CLI",
  //   topBarName : 'CLI',
  //   sidebarProps: {
  //     disabled: true,
  //     displayText: "ui.side.bar.cli",
  //     icon: <img src={CLIIcon} alt={"CLI"} />,
  //     selectedIcon: <img src={CLISelectedIcon} alt={"CLI"} />,
  //     unAvailableIcon : <img src={CLIUnavailableIcon} alt={"CLI"} />,
  //   },
  // },
  {
    path: "/Users",
    element: <div></div>,
    state: "Users",
    topBarName: "Users",
    sidebarProps: {
      disabled: true,
      displayText: "ui.side.bar.users",
      icon: <img src={UsersIcon} alt={"Users"} width={24} />,
      selectedIcon: <img src={UsersSelectedIcon} alt={"Users"} width={24} />,
      unAvailableIcon: (
        <img src={UsersUnavailableIcon} alt={"Users"} width={24} />
      ),
    },
  },
  {
    path: "/Support",
    element: <div></div>,
    state: "Support",
    topBarName: "Support",
    sidebarProps: {
      disabled: true,
      displayText: "ui.side.bar.support",
      icon: <img src={SupportIcon} alt={"Support"} width={24} />,
      selectedIcon: (
        <img src={SupportSelectedIcon} alt={"Support"} width={24} />
      ),
      unAvailableIcon: (
        <img src={SupportUnavailableIcon} alt={"Support"} width={24} />
      ),
    },
  },
  {
    path: "/Documentation",
    element: <div></div>,
    state: "Documentation",
    topBarName: "Documentation",
    sidebarProps: {
      disabled: true,
      displayText: "ui.side.bar.documentation",
      icon: <img src={DocumentationIcon} alt={"Documentation"} width={24} />,
      selectedIcon: (
        <img src={DocumentationSelectedIcon} alt={"Documentation"} width={24} />
      ),
      unAvailableIcon: (
        <img
          src={DocumentationUnavailableIcon}
          alt={"Documentation"}
          width={24}
        />
      ),
    },
  },
  {
    path: "/Admin",
    element: (
      <>
        <Outlet />
      </>
    ),
    state: "Admin",
    topBarName: "Admin",
    roles: [RoleTypes.SYSTEM_ADMIN],
    sidebarProps: {
      disabled: false,
      displayText: "ui.side.bar.admin",
      icon: <img src={AdminIcon} alt={"Admin"} width={24} />,
      selectedIcon: <img src={AdminSelectedIcon} alt={"Admin"} width={24} />,
      unAvailableIcon: (
        <img src={AdminUnavailableIcon} alt={"Admin"} width={24} />
      ),
    },
    child: [
      {
        path: "/Admin/Accounts",
        element: <Accounts />,
        state: "Admin.Accounts",
        topBarName: "Accounts",
        sidebarProps: {
          disabled: false,
          displayText: "ui.side.bar.accounts",
        },
      },
      {
        path: "/Admin/UsersManagement",
        element: <UsersManagement />,
        state: "Admin.UsersManagement",
        topBarName: "Users Management",
        sidebarProps: {
          displayText: "ui.side.bar.users.management",
        },
      },
      {
        path: "/Admin/UsersManagement/new",
        element: <CreateNewUser />,
        state: "createNewUser",
        topBarName: "Create New User",
      },
      {
        path: "/Admin/Clusters",
        element: <Clusters />,
        state: "Admin.Clusters",
        topBarName: "Clusters",
        sidebarProps: {
          displayText: "ui.side.bar.clusters",
        },
      },

      {
        path: "/Admin/Billing",
        element: <div></div>,
        state: "Admin.Billing",
        topBarName: "Billing",
        sidebarProps: {
          displayText: "ui.side.bar.billing",
        },
      },
    ],
  },
];

export default appRoutes;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  BottomWrapperNew,
  Container,
  InputContainer,
  SaveUserButton,
  UserButtonsWrapper,
  UserContainer,
} from "./styles";
import restoreIcon from "../../assets/svg/info_new.svg";
import {
  AccountsTitle,
  Line,
  ButtonTitle,
  NavTitle,
  NavigationTitle,
} from "../../Components/AccountsHeader/styles";
import { useLocation, useNavigate } from "react-router-dom";
import InputField from "../../Components/InputField/InputField";
import {
  ButtonsContainer,
  CancelButton,
  LineBottom,
} from "../../Components/CreateNewAccount/styles";

import AlertDialog from "./Modal/index";
import BreadCrumbs from "../../Components/BreadCrubms";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import Select from "../../Components/Select/Select";
import AccountSelect from "../../Components/AccountsSelect";
import { getClusterManagementData } from "../../store/slices/clusterManagement";
import { selectClustersData } from "../../store/slices/clusterManagement/selectors";
import {
  selectAccountId,
  selectRoles,
} from "../../store/slices/general/selectors";
import {
  getBackupsData,
  restoreBackup,
} from "../../store/slices/backupRestore";
import { selectBackups } from "../../store/slices/backupRestore/selectors";

const Restore = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const clusterData = useSelector(selectClustersData);
  const [showModal, setShowModal] = useState(false);
  const [clusterItems, setClusterItems] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const backupData = useSelector(selectBackups);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
  } = useForm({
    defaultValues: {
      name: "",
      account: "",
      cluster: "",
      snapshot: "",
      target: "",
    },
  });

  const roles = useSelector(selectRoles);
  const accountId = useSelector(selectAccountId);
  const isSystemAdmin = useMemo(
    () => (roles ? roles.indexOf("SYSTEM_ADMIN") >= 0 : false),
    [roles]
  );

  const { account, cluster, snapshot, target } = getValues();
  const handleFieldChange = useCallback(
    (field: any, value: string) => {
      setValue(field, value);
      trigger(field);
    },
    [setValue, trigger]
  );

  const onSubmit = useCallback(() => {
    dispatch(
      restoreBackup({
        navigate: navigate,
        clusterId: cluster,
        body: {
          backupId: snapshot,
          restart: true,
          sourceCluster: cluster,
          targetCluster: target,
        },
      })
    );
  }, [cluster, dispatch, navigate, snapshot, target]);

  const OnPopup = useCallback(() => {
    setShowModal(true);
  }, []);

  useEffect(() => {
    handleFieldChange("account", accountId);
  }, [handleFieldChange, accountId]);

  useEffect(() => {
    dispatch(getClusterManagementData({ accountId: account }));
  }, [account, dispatch, isSystemAdmin]);

  // clusterId
  // clusterName
  useEffect(() => {
    const items = clusterData.data.map(
      (elem: { clusterId: any; clusterName: any }) => ({
        value: elem.clusterId,
        label: elem.clusterName,
      })
    );
    setClusterItems(items);
    const isClusterFound = items.find(
      (elem: any) => String(elem.value) === String(cluster)
    );
    const isClusterTargetFound = items.find(
      (elem: any) => String(elem.value) === String(target)
    );
    if (!isClusterFound) {
      handleFieldChange("cluster", "");
    }
    if (!isClusterTargetFound) {
      handleFieldChange("target", "");
    }
  }, [cluster, clusterData.data, handleFieldChange, target]);

  useEffect(() => {
    const { accountId, clusterId, tag, id } = location.state;
    handleFieldChange("name", tag);
    handleFieldChange("cluster", clusterId);
    handleFieldChange("target", clusterId);
    handleFieldChange("account", accountId);
    handleFieldChange("snapshot", id);
  }, [handleFieldChange, location.state]);

  // clusterIds
  useEffect(() => {
    if (cluster) {
      dispatch(getBackupsData({ clusterIds: [cluster] }));
    }
  }, [cluster, dispatch]);

  const [backupItems, setBackupItems] = useState([]);
  useEffect(() => {
    if (cluster) {
      const backupItems = backupData.map((elem: any) => ({
        value: elem.id,
        label: elem.tag ?? "",
      }));
      setBackupItems(backupItems);
      const isFound = backupItems.find(
        (elem: any) => String(elem.value) === String(snapshot)
      );
      if (!isFound) {
        handleFieldChange("snapshot", "");
      }
    } else {
      setBackupItems([]);
    }
  }, [backupData, cluster, handleFieldChange, snapshot]);

  const clusterItemText = useMemo(
    () =>
      clusterItems.find((elem: any) => String(elem.value) === String(target))
        ?.label,
    [clusterItems, target]
  );

  return (
    <Container>
      <BreadCrumbs />

      <UserContainer>
        {isSystemAdmin && (
          <InputContainer>
            <AccountSelect
              name={"account"}
              register={register}
              value={account}
              handleChange={(name: string, id: string) => {
                handleFieldChange("account", id);
                handleFieldChange("target", "");
                handleFieldChange("cluster", "");
              }}
              errors={errors}
              title={"Account"}
              placeholder={t("ui.account.select.title")}
              background={"#F6F5F5"}
            />
          </InputContainer>
        )}
        <InputContainer>
          <Select
            {...register("cluster", {
              required: t("field.required") || "",
            })}
            items={clusterItems}
            selectedItem={clusterItems.find(
              (elem: any) => String(elem?.value) === String(cluster)
            )}
            placeholder="Select Cluster"
            TitleText="Source Cluster"
            required={false}
            onSelect={(item: any) => {
              handleFieldChange(
                "cluster",
                item?.value ? String(item?.value) : ""
              );
              handleFieldChange("snapshot", "");
            }}
          />
        </InputContainer>
        <InputContainer>
          <Select
            {...register("snapshot", {
              required: t("field.required") || "",
            })}
            items={backupItems}
            placeholder="Select Snapshot"
            selectedItem={backupItems.find(
              (elem: any) => String(elem.value) === String(snapshot)
            )}
            TitleText="Snapshot"
            required={false}
            onSelect={(item: any) =>
              handleFieldChange("snapshot", item?.value ?? "")
            }
          />
        </InputContainer>
        <InputContainer>
          <Select
            {...register("target", {
              required: t("field.required") || "",
            })}
            placeholder="Select target cluster"
            items={clusterItems}
            selectedItem={clusterItems.find(
              (elem: any) => String(elem?.value) === String(target)
            )}
            TitleText="Target Cluster"
            required={false}
            onSelect={(item: any) =>
              handleFieldChange("target", item?.value ?? "")
            }
          />
        </InputContainer>

        {/* <InputContainer>
          <InputField
            {...register("name", {
              required: t("field.required") || "",
            })}
            placeholder={t("create.backup.name")}
            value={name}
            onChange={(text: string) => handleFieldChange("name", text)}
            onClickCloseHandle={() => handleFieldChange("name", "")}
            required={false}
            error={(errors.name?.message as string) || ""}
            title={t("create.backup.name") || ""}
          />
        </InputContainer> */}
      </UserContainer>
      {showModal ? (
        <AlertDialog
          title={"Are you sure?"}
          subTitle={`You are about to overwrite cluster`}
          src={restoreIcon}
          paragraph={clusterItemText ?? ""}
          subTitle2={""}
          closeDialog={() => {
            setShowModal(false);
          }}
          handleConfirm={onSubmit}
        />
      ) : (
        <></>
      )}
      <BottomWrapperNew>
        <LineBottom />
        <UserButtonsWrapper>
          <ButtonsContainer>
            <CancelButton
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel.button")}{" "}
            </CancelButton>
            <SaveUserButton onClick={handleSubmit(OnPopup)} disabled={!isValid}>
              <ButtonTitle>
                {t("user.profile.save.button")} {">"}
              </ButtonTitle>
            </SaveUserButton>
          </ButtonsContainer>
        </UserButtonsWrapper>
      </BottomWrapperNew>
    </Container>
  );
};

export default Restore;

import styled, {keyframes} from "styled-components";
import {ReactComponent as AddIcon} from "../../../assets/svg/AddIcon.svg"
export const Container = styled.div `
  width: 100%;
`;

export const Body = styled.div `
  width: calc(100% + 31px);
  padding-right: 31px;
  height: calc(100vh - 350px);
  overflow: auto;
  ::-webkit-scrollbar-track {
          background: #D8D8D8;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #707070;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const InputTitleContainer = styled.div `
  display: flex;
  align-items: center;
  padding-bottom: 22px;
`;

export const InputContainer = styled.div `
  display: flex;
  justify-content: left;
  width: 468px;
`;

export const TitleInput = styled.div <{paddingRight?: string}>`
  font-style: normal;
  width: 206px;
  font-family: ${({ theme: { language } }) =>
    language === "eng" ? "Poppins" : "unset"};
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.02em;
  /* or 18px */
  display: flex;
  color: #000000;
  padding-right: ${(props) => (props.paddingRight)}px;
`;

export const SelectionContainer = styled.div `
  background: #FFFFFF;
  border-radius: 10px;
  padding: 27px 0 0 28px;
  margin-right: 6px;
`;

export const ProviderContainer = styled.div `
  display: flex;
`;

export const ProviderSelectContainer = styled.div `
  display: flex;
  padding-top: 31px;
`;

export const ProviderTypeSelectContainer = styled.div `
  display: flex;
  padding: 42px 0 42px 0;
`;

export const ProviderNodeTypeSelectContainer = styled.div `
  display: flex;
  padding: 0 0 38px 0;
`;


export const ProviderTitle = styled.div `
  width: 181px;
  padding-left: 8px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: -0.02em;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;

  color: #000000;

`

export const ProviderIconContainer = styled.div`
  display: flex ;
  align-items: center ;
  justify-content: start ;
  gap: 35px;
`;

export const RegionTitle = styled.div `
  width: 181px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: -0.02em;
  /* identical to box height, or 167% */

  display: flex;
  align-items: center;

  color: #000000;

`

export const ProviderTitleDropDown = styled.div `
  width : 181px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  /* identical to box height, or 167% */
  display: flex;
  padding-top: 10px;
  color: #000000;

`

export const AmazonStyled = styled.img`
  cursor: not-allowed;
`;

export const VerticalLine = styled.div `
  width: 1px;
  height: 37px;
  background: #E0E0E0;
`;

export const MicrosoftStyled = styled.img`
  cursor: pointer;
`;

export const GoogleCloudStyled = styled.img`
  cursor: not-allowed;
`;

export const SelectContainer = styled.div `
   width: 468px;
`;

export const SelectTypeContainer = styled.div `
   width: 346px;
`;
export const SelectNodeTypeContainer = styled.div `
   width: 468px;
`;

export const SelectClusterTypeContainer = styled.div `
   width: 765px;
`;

export const SwitchContainer = styled.div `
   display: flex;
   padding-top: 35px;
`;

export const SwitchTitle = styled.div `

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 0px;
  /* identical to box height, or 0% */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  padding-right: 12px;
  color: #000000;
`;

export const SwitchImg = styled.img`
    padding-left: 16px;
`;

export const InfoTitle = styled.div `
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  /* identical to box height, or 200% */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  height: 30px;
  color: #707070;
  padding-top: 7px;
`;

export const SaveUserButton = styled.div<{validation: boolean}> `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 17px 26px;
  gap: 10px;
  cursor: pointer;
  height: 53px;

  background: ${(props) => (props.validation ? "#FB5E03": "#E0E0E0")};
  border-radius: 26.5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const BottomWrapperNew = styled.div`
  width: 100%;
  height: 81px;
  background: #FFFFFF;
  position: absolute;
  bottom: 0px;
  left: 0px;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  border: 1px solid #EEEEEE;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
`;

export const ButtonsContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 51px;
`;

export const TableNodeType = styled.div `
  width: 765px;
  height: 245px;
  margin: -60px 0 50px 190px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 10px;
  padding: 62px 0 0 12px;
`;

export const TableTitleContainer = styled.div `
 display: flex;
`;

export const TableTitle = styled.div `

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  /* or 15px */

  letter-spacing: -0.02em;
  padding-right: 44px;
  color: #000000;
`;

export const TableSeparateLine = styled.div `
  height: 1px;
  
  background: #E0E0E0;
  padding-right: 9px;
  margin: 17px 9px 12px 0;
`;

export const TableDetailsContainer = styled.div `
  display: flex;
  cursor: pointer;
  //justify-content: space-between;
  padding-left: 12px;
`;

export const TableDetails = styled.div<{paddingRight: string}> `
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  /* or 15px */

  letter-spacing: -0.02em;
  color: #000000;
  padding-right: ${(props) => (props.paddingRight)}px;
`;

export const InputNumberNodeContainer = styled.div `
  display: flex;
  justify-content: left;
  width: 468px;
`;

export const CheckBoxContainer = styled.div `
    padding: 13px 0 47px 0;
`;

export const TableContainer = styled.div`
  height: 400px;
  position: relative;
`;
export const TabsContentContainer = styled.div`
  background-color: #ffffff ;
`;

export const TrustIPSHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 20px;
`;

export const TrustedIPAddContainer = styled.div`
  display: flex ;
  align-items: center ;
  gap: 45px;
  cursor: pointer;
`;
export const AddIpIconText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 111%;
  /* or 22px */
  text-transform: capitalize;
  color: #000000;
`;

export const AddIconStyled = styled(AddIcon)`
  width: 28px;
  height: 28px;
`;
export const IPInputContainer = styled.div`
  width: 468px;
`;

export const AddButtonContainer = styled.div`
  display: flex ;
  align-items: center ;
  gap: 5px;
`;

export const VPCInputContainer = styled.div`
  width: 468px;
  padding: 49px 0 0 22px;
`;

export const VPCCheckBoxContainer = styled.div`
  padding: 33px 0 284px 0;
`;

import { useMemo, useEffect, useCallback, useRef } from "react";
import { ItemType } from "../../../../../Components/FilterHeader/types";
import ActionCell from "../CustomCells/Action";
import StatusCell from "../CustomCells/Status"
import Connect from "../CustomCells/Connect";
import { useDispatch, useSelector } from "react-redux";
import { selectClusterTypes, selectClustersData } from "../../../../../store/slices/clusterManagement/selectors";
import { getClusterManagementData } from "../../../../../store/slices/clusterManagement";
import useDateFormat from "../../../../../hooks/useDateFormatHook";
import { Cell, IdType, Row } from "react-table";
import { selectRoles } from "../../../../../store/slices/general/selectors";
import { StatusType } from "../CustomCells/Status/types";

import ClusterType from "../CustomCells/ClusterType";
import {useTranslation} from "react-i18next";
import Name from "../CustomCells/Name";


// const filterstatusType = (rows : any , id : string, filterValue: Array<number>) => {
//     return rows.filter((row : any) => {
//       const rowValue = row.values[id].status;
//       return rowValue !== undefined
//         ? filterValue.map((text : number) => String(text).toLowerCase()).includes(String(rowValue).toLocaleLowerCase())
//         : true
//     })
// }

const filterConnectType = (rows : any , id : string, filterValue: Array<string>) => {
    return rows.filter((row : any) => {
      const rowValue = row.values["status"];
      const enabled = rowValue !== undefined ? filterValue.includes("enabled") && String(rowValue) === "RUNNING" : true;
      const disabled = rowValue !== undefined ? filterValue.includes("disabled") && String(rowValue) !== "RUNNING" : true;
      return enabled || disabled;
    })
}

const useTable = () => {
    const {t} = useTranslation();
    const roles = useSelector(selectRoles);
    const isSystemAdmin = useMemo(() => roles ? roles.indexOf("SYSTEM_ADMIN") >= 0 : false, [roles]);
    const dispatch = useDispatch();
    const clusterData = useSelector(selectClustersData);
    const formatDate = useDateFormat();
    console.log("clusterData", clusterData)
    const data = useMemo(() => clusterData.data ??  [], [clusterData]);

    const refapi = useRef<any>(null)

    useEffect(() => {
        dispatch(getClusterManagementData({}))
        refapi.current = setInterval(() => {
            dispatch(getClusterManagementData({}))
        }, 5 * 1000);

    return () => {
        if(refapi.current){
            clearInterval(refapi.current)
        }
    }
    }, [dispatch])



    const statusTypes = useMemo(() => [{value: StatusType.Running, label :"Running"},{value: StatusType.Failed, label :"Failed"},{value: StatusType.Starting, label :"Starting"},{value: StatusType.Stopping, label :"Stopping"},{value: StatusType.Stopped, label :"Stopped"}, {value: StatusType.Deleting, label: "Deleting"}], []);
    const connectTypes = useMemo(() => [{value: "enabled", label :"Enabled"},{value: "disabled", label :"Disabled"}], []);
    const types = useSelector(selectClusterTypes)
    const clusterTypes = useMemo(() => types.map((elem: {type: string}) => ({value: elem.type, label: elem.type})), [types]);
    const columns = useMemo(
        () => {
            const columns = [
                {
                    Header: t("ui.cluster.columns.cluster.name"),
                    accessor: 'clusterName',
                    Cell: Name,
                    type: ItemType.text,
                    allowFilter: true,
                    ellipsis: true,
                    width: 180,
                },
                {
                    Header: t("ui.cluster.columns.cluster.type"),
                    accessor: 'compute',
                    type: ItemType.types,
                    types: clusterTypes,
                    filter: 'type',
                    width: 140,
                    Cell: ClusterType,
                    ellipsis: true,
                    allowFilter: true,
                },
                {
                    Header: t("ui.user.management.columns.status"),
                    accessor: 'status',
                    type: ItemType.types,
                    types: statusTypes,
                    filter: "type",
                    Cell: StatusCell,
                    allowFilter: true,
                    ellipsis: true,
                    width : 100,
                },
                {
                    Header: t("ui.cluster.columns.creation.date"),
                    accessor: 'createdAt',
                    type: ItemType.date,
                    filter: 'date',
                    ellipsis: true,
                    width: 170,
                    Cell: (cell : Cell) => {
                        return formatDate(cell.value);
                    },
                    allowFilter: true,
                },
                {
                    Header: t("ui.cluster.columns.connect"),
                    accessor: 'Connect',
                    type: ItemType.types,
                    types: connectTypes,
                    filter: filterConnectType,
                    width: 120,
                    ellipsis: true,
                    Cell: Connect,
                    allowFilter: false,
                },
                {
                    Header: t("ui.user.management.columns.action"),
                    accessor: 'Action',
                    type: ItemType.text,
                    disableSortBy: true,
                    allowFilter: false,
                    ellipsis: true,
                    width: 60,
                    Cell: ActionCell
                },
            ]
            if (isSystemAdmin) {
                // @ts-ignore
                columns.splice(1, 0, {
                    Header: t("ui.user.management.columns.account.name"),
                    accessor: 'accountName',
                    type: ItemType.text,
                    allowFilter: true,
                    ellipsis: true,
                    width: 180,
                });
            }
            return columns;
        },
        [clusterTypes, connectTypes, formatDate, isSystemAdmin, statusTypes, t]
    );


    const globalFilterFunction = useCallback(
        (rows: Row<any>[], ids: IdType<any>[], query: string) => {
            return rows.filter(row => {
                const rowValueAccName = row.values["accountName"];
                const rowValueClusterName = row.values["clusterName"];
                const rowValueCompute = row.values["compute"];
                const rowValuecreatedAt = row.values["createdAt"];
                const rowValuestatus = row.values["status"];

                const ClusterNameFilter =  rowValueClusterName !== undefined
                ? String(rowValueClusterName)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true

                const accNameFilter =  rowValueAccName !== undefined
                ? String(rowValueAccName)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true


                const createAtFilter =  rowValuecreatedAt !== undefined
                ? String(formatDate(rowValuecreatedAt))
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true

                const computeFilter =  rowValueCompute !== undefined
                ? String(rowValueCompute)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true

                const statusFilter =  rowValuestatus !== undefined
                ? String(rowValuestatus)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true

                  return ClusterNameFilter || (isSystemAdmin && accNameFilter) || createAtFilter || computeFilter || statusFilter;
              });

        },
        [formatDate, isSystemAdmin],
    );


    const initialState: any = useMemo(() => ({
        sortBy: [
            {
                id: 'createdAt',
                desc: true
            }
        ]
    }), []);

    return {initialState, columns, data, globalFilterFunction};
};

export default useTable;

//@ts-nocheck
import React, {
  useReducer,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useCallback,
  useState,
} from "react";
import styled from "styled-components";
import moment from "moment";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFilters,
  useColumnOrder,
  useResizeColumns,
  useFlexLayout,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import Context from "./context";

import Header from "./table/ckHeader";
import Body from "./table/ckBody";
import Footer from "./table/ckFooter";

const Styles = styled.div<{ tabContainer?: boolean; rowHover?: boolean }>`
  height: 100%;
  .ck-table {
    position: relative;
    display: flex;
    flex-flow: column;
    font-size: 16;
    overflow: hidden;
    border-radius: ${(props) =>
      props.tabContainer ? "0px 10px 10px 10px" : "10px 10px 10px 10px"};
    padding: 5px;
    background-color: white;
    .ck-header {
      border-bottom: 3px solid white;
      background-color: #f6f6f6;
      border-radius: 5px;
      padding: 0 13px 0 30px;
      .ck-thead {
        overflow-y: auto;
        overflow-x: hidden;

        .ck-tr {
          .ck-th {
            display: flex;
            align-items: center;

            .ck-cell {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              height: -webkit-fill-available;
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }

            .ck-column-sorter {
              position: relative;
            }

            .ck-column-filter {
              position: relative;
              border-radius: 2px;
              padding: 4px 2px;
              transition: 0.3s;
              cursor: pointer;

              .icon-filter {
                font-size: 12px;
                color: ccc;
              }

              &.filter-active .icon-filter {
                color: var(--primary-color);
              }

              &:hover {
                background-color: rgba(0, 0, 0, 0.04);
              }
            }
          }
        }

        &::-webkit-scrollbar {
          opacity: 0;
        }

        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar-track {
          background-color: inherit;
        }
      }
    }

    .ck-body {
      display: flex;
      overflow: hidden;
      flex: 1;

      .ck-tbody {
        overflow-x: auto;
        overflow-y: auto;

        ::-webkit-scrollbar-track {
          background: #d8d8d8;
        }

        ::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }
        ::-webkit-scrollbar-thumb {
          border-radius: 8px;
          background-color: #707070;
        }
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
        flex: 1;
      }

      .ck-tr {
        padding: 0 20px;

        &:hover {
          /* scale: 1.01; */

          ${(p) => {
            if (p.rowHover)
              return `transition: any 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
            box-shadow: 0px 4px 10px 0px #00000080;
            z-index: 10;`;
          }}
        }

        .ck-td {
          display: inline-flex;
          align-items: center;
          border-bottom: 1px solid #e0e0e0;
          background-color: #fff;
          padding: 0 10px;

          &.checked {
            background-color: #e6e6e6;
          }

          &.canSelect {
            cursor: pointer;
          }

          &.canDoubleClick {
            cursor: pointer;
          }

          .ck-cell {
            flex: 1;
            max-width: 100%;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 100%;
            /* identical to box height, or 15px */
            letter-spacing: -0.02em;
            color: #535151;
          }

          &.ellipsis .ck-cell {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            height: -webkit-fill-available;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
    }

    .ck-tfoot {
      overflow-y: scroll;
      overflow-x: hidden;
      background-color: #fff;

      .ck-tr {
        background-color: inherit;

        .ck-td {
          display: inline-flex;
          align-items: center;
          background-color: inherit;
          padding: 0 12px;

          .ck-cell {
            flex: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            height: -webkit-fill-available;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }

      &::-webkit-scrollbar {
        opacity: 0;
      }

      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-track {
        background-color: inherit;
      }
    }

    .ck-tr {
      height: 48px;
      min-height: 24px;
    }

    .fixed-left {
      position: sticky;
      left: 0;
      z-index: 1;
      box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.08);
    }

    .fixed-right {
      position: sticky;
      right: 0;
      z-index: 1;
      box-shadow: -4px 0px 4px 0px rgba(0, 0, 0, 0.08);

      & + div {
        box-shadow: none;
      }
    }

    &.large .ck-tr {
      height: 64px;
    }

    &.small .ck-tr {
      height: 48px;
    }

    &.mini .ck-tr {
      height: 36px;
    }
  }

  // 插入body内的额外Dom
  .ck-table-filter-dropdown {
    display: flex;
    flex-flow: column;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    background-color: #fff;

    .ck-table-filter-header {
      border-bottom: 1px solid #f5f5f5;
      padding: 6px 8px;

      input {
        font-size: 12px;
        height: 28px;
        padding: 0 8px;
      }
    }

    .ck-table-filter-body {
      width: 100%;
    }

    .ck-table-filter-empty {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      min-height: 60px;
      color: #666;
    }

    .ck-table-filter-list {
      max-height: 220px;
      padding: 4px 0;
      margin: 0;
      overflow: auto;

      .ck-table-filter-item {
        display: flex;
        align-items: center;
        height: 32px;
        min-width: 120px;
        padding: 0 8px;
        transition: 0.3s;

        &[disabled] {
          display: none;
        }

        &:hover {
          background-color: #f5f5f5;
        }

        .ck-table-filter-item-content {
          flex: auto;
        }
      }
    }

    .ck-table-filter-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 8px;
      border-top: 1px solid black;

      button {
        font-size: 12px;
        padding: 0 8px;
      }
    }
  }

  .ck-table-setting-dropdown {
    display: flex;
    flex-flow: column;
    box-shadow: 0 2px 6px black;
    background-color: #fff;
    transform: translateY(-6px);

    .ck-table-setting-body {
      width: 100%;

      .ck-table-setting-reset {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
        border-bottom: 1px solid black;
        padding: 0 8px;
      }

      .ck-table-setting-list {
        max-height: 320px;
        padding: 4px 0;
        margin: 0;
        overflow: auto;

        .ck-table-setting-item {
          display: flex;
          align-items: center;
          height: 32px;
          min-width: 160px;
          padding: 0 8px;
          transition: 0.3s;

          &[disabled] {
            display: none;
          }

          &:hover {
            background-color: green;
          }

          .ck-table-setting-item-content {
            flex: auto;
          }
        }
      }
    }
  }

  .icon-setting {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    height: 48px;
    width: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;
const initialState = {
  canSelect: false,
  rowHeight: 0,
  totalLen: 0,
  curScroll: {
    scrollLeft: 0,
  },
  headerElem: null,
  bodyElem: null,
  footerElem: null,
};

const reducer = (state, action) => {
  const {
    curScroll,
    headerElem,
    bodyElem,
    footerElem,
    canSelect,
    onDoubleClick,
  } = action;

  switch (action.type) {
    case "changeScroll":
      return {
        ...state,
        curScroll,
      };
    case "headerElem":
      return {
        ...state,
        headerElem,
      };
    case "bodyElem":
      return {
        ...state,
        bodyElem,
      };
    case "footerElem":
      return {
        ...state,
        footerElem,
      };
    case "selectable":
      return {
        ...state,
        canSelect,
      };
    case "doubleclick":
      return {
        ...state,
        onDoubleClick,
      };
    default:
      throw new Error();
  }
};

const Table = forwardRef((props: { [key: string]: unknown }, ref) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    data,
    columns,
    height = "100%",
    showFooter = false,
    size = "small",
    filters,
    globalFilterValue = "",
    globalFilterFunction,
    canSelect = false,
    onSelectedRowsChange,
    singleSelect = false,
    tableInitialState = {},
    tabContainer = false,
    onDoubleClick,
    rowHover,
  } = props;

  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);

  useEffect(() => {
    setTableColumns(columns);
  }, [columns]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    dispatch({ onDoubleClick: onDoubleClick, type: "doubleclick" });
  }, [onDoubleClick]);

  const dateBetweenFilterFn = useCallback((rows, id, filterValues) => {
    let sd = null;
    let ed = null;
    let withinLast = null;
    let moreThen = null;
    let hasBetween = filterValues.between ? true : false;
    let hasWithinLast = filterValues.withinLast ? true : false;
    let hasMoreThen = filterValues.moreThen ? true : false;

    if (hasBetween) {
      sd = moment(filterValues.between[0]).unix();
      ed = moment(filterValues.between[1]).unix();
    }
    if (hasWithinLast) {
      //for test of 5 days instead of input
      withinLast = moment(
        new Date(new Date().getTime() - filterValues.withinLast.msValue)
      ).unix();
    }
    if (hasMoreThen) {
      //for test of 5 days instead of input
      moreThen = moment(
        new Date(new Date().getTime() - filterValues.moreThen.msValue)
      ).unix();
    }

    if (!hasBetween && !hasWithinLast && !hasMoreThen) return rows;

    return rows.filter((r) => {
      var time = r.values[id];
      console.log(time, ed, sd);

      return (
        ((hasBetween && time >= sd && time <= ed) || !hasBetween) &&
        ((hasWithinLast && time >= withinLast) || !hasWithinLast) &&
        ((hasMoreThen && time <= moreThen) || !hasMoreThen)
      );
    });
  }, []);

  dateBetweenFilterFn.autoRemove = (val) => !val;

  const resetFilterTypes = React.useMemo(
    () => ({
      type: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? filterValue
                .map((text) => String(text).toLowerCase())
                .includes(String(rowValue).toLocaleLowerCase())
            : true;
        });
      },
      date: dateBetweenFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [dateBetweenFilterFn]
  );

  const handleAddRow = useCallback((row: Record<string, any>) => {
    setTableData((prev) => [row, ...prev]);
  }, []);

  const updateData = (rowIndex, columnId, value) => {
    setTableData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex]!,
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const deleteData = (rowIndex) => {
    setTableData((prev) => [
      ...prev.slice(0, rowIndex),
      ...prev.slice(rowIndex + 1),
    ]);
  };

  const tableInstance = useTable(
    {
      columns: tableColumns,
      data: tableData,
      defaultColumn: { width: 250, minWidth: 15 },
      filterTypes: resetFilterTypes,
      initialState: tableInitialState,
      globalFilter: globalFilterFunction,
      updateData,
      deleteData,
      autoResetHiddenColumns: false,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      stateReducer: (newState, action, prevState) => {
        console.log(action, newState);
        switch (action.type) {
          case "filters":
            return {
              ...prevState,
              filters: action.payload,
            };

          case "toggleRowSelected":
            if (singleSelect) {
              if (prevState.selectedRowIds[action.id])
                return { ...prevState, selectedRowIds: {} };
              return { ...prevState, selectedRowIds: { [action.id]: true } };
            }
            return newState;
          default:
            return newState;
        }
      },
    },
    useFlexLayout,
    useResizeColumns,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    useColumnOrder
  );

  const {
    getTableProps,
    selectedFlatRows,
    dispatch: dispatchTable,
    setGlobalFilter,
    state: { selectedRowIds },
  } = tableInstance;

  const updateLocalFilter = useAsyncDebounce((filters) => {
    console.log("disptach local filters", filters);
    dispatchTable({ type: "filters", payload: filters });
  }, 300);

  const updateGlobalFilter = useAsyncDebounce((value) => {
    console.log("disptach global filters", filters);
    setGlobalFilter(value || undefined);
  }, 300);

  useEffect(() => {
    dispatch({ type: "selectable", canSelect: canSelect });
  }, [canSelect]);

  useEffect(() => {
    updateLocalFilter(filters);
  }, [filters, updateLocalFilter]);

  useEffect(() => {
    console.log("global filter!");
    updateGlobalFilter(globalFilterValue);
  }, [globalFilterValue, updateGlobalFilter]);

  useImperativeHandle(ref, () => ({
    getCheckboxRecords: selectedFlatRows.map((v) => v.original),
    handleAddRow: handleAddRow,
    updateData: updateData,
    deleteData: deleteData,
    tableInstance,
  }));

  useEffect(() => {
    onSelectedRowsChange && onSelectedRowsChange(selectedRowIds);
  }, [onSelectedRowsChange, selectedRowIds]);

  return (
    <Context.Provider value={{ dispatch, state, tableInstance }}>
      <Styles tabContainer={tabContainer} rowHover={rowHover}>
        <div
          className={`ck-table ${size}`}
          {...getTableProps({ style: { height } })}
        >
          <Header />
          <Body />
          {showFooter && <Footer />}
        </div>
      </Styles>
    </Context.Provider>
  );
});

export default Table;

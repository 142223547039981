import React, {useEffect, useRef, useState} from 'react'
import {Container, InputContainer} from "./styles"
import { Cell } from 'react-table';

const isValidIP =(ip: string) => {
  const octet = '(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|0)';
  const regex = new RegExp(`^${octet}\\.${octet}\\.${octet}\\.${octet}$`);
  return regex.test(ip);
}

interface IPCellProps {
  cell : Cell
  updateData: any;
}
const IPCell: React.FC<IPCellProps> = (props) => {

  const {updateData , cell} = props;
  const [value, setValue] = React.useState(cell.value)
  // const [editMode, setEditMode] = useState<boolean>(false);

  // const ref = useRef<any>(null);
  // const onBlur = () => {
  //   updateData(cell.row.index, cell.column.id, value)
  //   setEditMode(false);
  // }

  // useEffect(() => {
  //   if (editMode && ref && ref.current && ref.current.focus) {
  //     ref.current.focus();
  //   }
  // }, [editMode, ref]);

  //to show input focus when add new IP
  // useEffect(() => {
  //   if (ref && ref.current && ref.current.focus) {
  //     ref.current.focus();
  //   }
  // }, [ref]);

  useEffect(() => {
    setValue(cell.value)
  }, [cell.value]);

  // const [disabled, setDisabled] = useState(false);

  // useEffect(() => {
  //   if (isValidIP(cell.value)) {
  //     setDisabled(true);
  //   }
  // }, [cell.value])


  return (
      <Container>
          {value}
      </Container>
  )
}
export default IPCell;

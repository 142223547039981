import React, {useState} from 'react';
import {
    Container,
    AccountsTitle,
    AccountLinesContainer,
    Line,
    FilterHeaderContainer,
    AccountsButtonContainer, ButtonTitle, PlusIcon, AddAccountButton,NavigationTitle
} from "./styles";
import Search from "../Search/Search";
import plusIcon from "../../assets/svg/plus_icon.svg";
import FilterHeader from '../FilterHeader';
import { ItemType, FilterHeaderItemProps } from '../FilterHeader/types';
import BreadCrumbs from '../BreadCrubms';
import {useTranslation} from "react-i18next";


interface AccountsHeaderProps {
    createNewAccount:() => void;
    // filterHeaderItems: FilterHeaderItemProps[];
    filterSearchCols: Record<string, any>;
    handleColumnCheckChange: (id: string) => void;
    handleResetAllFilters: () => void;
    handleColumnFilterValueChange: (id: string, value: string | Array<string> | Record<string,any>) => void;
    handleResetFilter: (id: string) => void;
    handleRemoveFilter: (id: string) => void;
    globalFilterValue: string;
    handleGlobalFilterChange: (value: string) => void;

}
const AccountsHeader: React.FC<AccountsHeaderProps> = (props) => {

    const {filterSearchCols, handleColumnCheckChange, handleResetAllFilters, handleColumnFilterValueChange, handleResetFilter, handleRemoveFilter, globalFilterValue, handleGlobalFilterChange} = props;
    const {t} = useTranslation();



    return (
        <Container>
            <BreadCrumbs />
            <AccountsButtonContainer>
                <Search placeholder={t("search_input_placeholder")}
                        value={globalFilterValue}
                        onChange={handleGlobalFilterChange}
                        filterSearchCols={filterSearchCols}
                        handleColumnCheckChange={handleColumnCheckChange}
                        handleResetAllFilters={handleResetAllFilters}
                         />

                    <FilterHeaderContainer><FilterHeader handleRemoveFilter={handleRemoveFilter} items={filterSearchCols} handleResetAllFilters={handleResetAllFilters} handleColumnFilterValueChange={handleColumnFilterValueChange} handleResetFilter={handleResetFilter}/></FilterHeaderContainer>
                <AddAccountButton onClick={() => {props.createNewAccount();}}>
                    <PlusIcon src={plusIcon} alt={"plusIcon"}/>
                    <ButtonTitle>{t("create.account.button")}</ButtonTitle>
                </AddAccountButton>
            </AccountsButtonContainer>
        </Container>
    );
};

export default AccountsHeader;

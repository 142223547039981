import React, {useState} from 'react';
import BreadCrumbs from "../BreadCrubms";
import {
    AddUserButton, ButtonRevoke, ButtonRevokeTitle, ButtonsContainer,
    ButtonTitle, Container,
    FilterHeaderContainer,
    ResendInvitationsButton,
    UserButtonContainer
} from "./styles";
import Search from "../Search/Search";
import FilterHeader from "../FilterHeader";
import {useTranslation} from "react-i18next";

interface UsersManagementHeaderProps {
    createNewUser:() => void;
    reSendInvitations: () => void;
    // filterHeaderItems: FilterHeaderItemProps[];
    filterSearchCols: Record<string, any>;
    handleColumnCheckChange: (id: string) => void;
    handleResetAllFilters: () => void;
    handleColumnFilterValueChange: (id: string, value: string | Array<string> | Record<string,any>) => void;
    handleResetFilter: (id: string) => void;
    handleRemoveFilter: (id: string) => void;
    userTab: boolean;
    handleRevoke: () => void;
    globalFilterValue: string;
    handleGlobalFilterChange: (value: string) => void;
    buttonDisabled: Record<string,boolean>;
}
const UsersManagementHeader: React.FC<UsersManagementHeaderProps> = (props) => {

    const {filterSearchCols, handleColumnCheckChange, handleResetAllFilters, handleColumnFilterValueChange, handleResetFilter, userTab, handleRemoveFilter, globalFilterValue, handleGlobalFilterChange, buttonDisabled} = props;
    const {t} = useTranslation();

    return (
        <Container>

            <BreadCrumbs />

            <UserButtonContainer>
                <Search placeholder={t("search_input_placeholder")}
                        value={globalFilterValue}
                        onChange={(text: string) => handleGlobalFilterChange(text)}
                        filterSearchCols={filterSearchCols}
                        handleColumnCheckChange={handleColumnCheckChange}
                        handleResetAllFilters={handleResetAllFilters}
                />

                <FilterHeaderContainer><FilterHeader handleRemoveFilter={handleRemoveFilter} items={filterSearchCols} handleResetAllFilters={handleResetAllFilters} handleColumnFilterValueChange={handleColumnFilterValueChange} handleResetFilter={handleResetFilter}/></FilterHeaderContainer>
                    {
                        userTab
                            ?
                                <AddUserButton onClick={() => {props.createNewUser();}}>
                                    <ButtonTitle>{t("users_management_tab_invite_button")} {'>'}</ButtonTitle>
                                </AddUserButton>
                            :
                            <>
                                <ButtonRevoke onClick={() => {props.handleRevoke();}} disabled={buttonDisabled.revoke}>
                                    <ButtonRevokeTitle>{t("users_management_tab_revoke_button")}</ButtonRevokeTitle>
                                </ButtonRevoke>
                                <ResendInvitationsButton onClick={() => {props.reSendInvitations();}} disabled={buttonDisabled.invite}>
                                    <ButtonTitle>{t("users_management_tab_resend_button")} {'>'}</ButtonTitle>
                                </ResendInvitationsButton>
                            </>
                    }
            </UserButtonContainer>
        </Container>
    );
};

export default UsersManagementHeader;

import React from 'react'
import {
    CloseIconStyled,
    IconContainer,
    Item,
    ItemContainer,
    ItemText,
    menuPopperProps,
    DeleteIconStyled,
    AddMyIPIconStyled
} from "./styles";
import Popover from '@mui/material/Popover';
import {useTranslation} from "react-i18next";
import {Cell} from "react-table";
import {ActionContainer} from "../../../CreateNewCluster/CustomCells/Action/styles";
import {useDispatch} from "react-redux";
import {deleteTrustedIPsRequest} from "../../../../../store/slices/clusterManagement";

interface IPCellProps {
    cell : Cell<Record<string,any>>
    deleteData: any;
}

const ActionCell : React.FC<IPCellProps> = (props) => {
    const {cell} = props;
    const dispatch = useDispatch();
    console.log("cell Trusted ", cell);
    const handleDelete = () => {
        // deleteData(cell.row.index);
        dispatch(deleteTrustedIPsRequest({clusterId: cell.row.original.clusterId, ids: [cell.row.original.id] }));
    }
    return (
        <React.Fragment>
            <ActionContainer>
                <DeleteIconStyled onClick={handleDelete}/>
            </ActionContainer>
        </React.Fragment>
    )
}
export default ActionCell;
/*
export default function ActionCell(props: any) {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);


    return (
        <React.Fragment>
            <IconContainer onClick={handleClick}>
                <CloseIconStyled />
            </IconContainer>
            <Popover
                id={"filter-panel"}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                PaperProps={menuPopperProps}
            >
                <ItemContainer>
                    <Item>
                        <ItemText>{t("clusters_list_add_action")}</ItemText>
                    </Item>
                    <Item onClick={() => {console.log("props ", props)}}>
                        <AddMyIPIconStyled />
                        <ItemText>{t("clusters_list_add_my_ip_action")}</ItemText>
                    </Item>
                    <Item>
                        <DeleteIconStyled />
                        <ItemText>{t("table_delete_action")}</ItemText>
                    </Item>
                </ItemContainer>
            </Popover>
        </React.Fragment>
    )
}
*/

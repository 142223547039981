import React, { FC, useEffect, useState } from "react";
import { InputContainer, InputForm, Title, Input } from "../EmailInput/styles";
import {Italics, ItalicsP, Password, PasswordContainer, PasswordImg, PlaceHolderDif} from "./styles";
import show_password from "../../assets/svg/show_password.svg";
import hide_password from "../../assets/svg/hide.svg";
import InputError from "../InputError";
import {useTranslation} from "react-i18next";

interface IProps {
    value: string;
    placeholder: string;
    status: "editable" | "readonly" | "disable";
    onChange: (text: string) => void;
    isRequired: boolean;
    title?: string;
    error?: string;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    width?: number;
    isDisabled?: boolean;
    disableCopyPaste?: boolean;
    signInScreen?: boolean;
    errorType?: "error" | "warning";
    autoComplete?: "off" | "on";
}

const PasswordInput: FC<IProps> = ({
                                    value,
                                    status,
                                    onChange,
                                    isRequired,
                                    title,
                                    placeholder,
                                    error = "",
                                    onBlur,
                                    onFocus,
                                    width,
                                    isDisabled,
                                    disableCopyPaste = true,
                                    signInScreen= false,
                                    errorType = "error",
                                    autoComplete = "off",
                                }) => {
    const [isActive, setIsActive] = useState(false);
    const [ph, setPh] = useState<string>();
    const [placeHolderShown, setPlaceHolderShown] = useState(true);
    // This variable determines whether password is shown or not
    const [isShown, setIsShown] = useState(false);
    const {t} = useTranslation();

    // This function is called when the checkbox is checked or unchecked
    const togglePassword = () => {
        setIsShown((isShown) => !isShown);
    };

    useEffect(() => {
        if (isActive) {
            setPh(undefined);
        } else {
            setPh(placeholder);
        }
    }, [isActive, placeholder]);

    const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
        if(e.target.value !== ""){
            setPlaceHolderShown(false);
        } else {
            setPlaceHolderShown(true);
        }
    };

    const onFocusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsActive(true);
        if (!onFocus) return;
        onFocus(e);
    };

    const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsActive(false);
        if (!onBlur) return;
        onBlur(e);
    };

    return (
        <InputContainer>
            <InputForm>
                <Title padding={title !== ""} signInScreen={signInScreen}>
                    <div style={{color:(signInScreen ? "#ffffff" : "#FF6600")}}>*</div>{title}
                </Title>
                <PasswordContainer>
                <Password
                    autoComplete={autoComplete}
                    disabled={isDisabled}
                    value={value}
                    placeholder={ph}
                    onChange={onChangeText}
                    onBlur={onBlurHandler}
                    onFocus={onFocusHandler}
                    onWheel={(event) => event.currentTarget.blur()}
                    width={width}
                    type={isShown ? "text" : "password"}
                    onPaste={(e) => {
                        if (disableCopyPaste) {
                            e.preventDefault();
                            return false;
                        }
                        return true;
                    }}
                    onCopy={(e) => {
                        if (disableCopyPaste) {
                            e.preventDefault();
                            return false;
                        }
                        return true;
                    }}
                    error={error !== ""}
                />
                    <Italics show={placeHolderShown}>
                        <ItalicsP>
                            {t("password_input_placeholder")}{" "}
                            <PlaceHolderDif>
                                {t("password_input_placeholder_characters")}
                            </PlaceHolderDif>
                        </ItalicsP>
                    </Italics>
                    <PasswordImg onClick={() => {togglePassword();}} src={isShown ? hide_password : show_password} alt={"show_password"}/>
                </PasswordContainer>
                <InputError message={error} errorType={errorType}/>
            </InputForm>
        </InputContainer>
    );
};

export default PasswordInput;

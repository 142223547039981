import styled from "styled-components";

export const Container = styled.div `
  width: 100%;
  height: 100%;
`;

export const NavTitle = styled.div `
  height: 24px;
  margin-bottom: 10px;
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Rubik" : "unset"};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  /* identical to box height, or 133% */
  color: #C2C2C2;
`;

export const ClustersTitle = styled.div `
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Poppins" : "unset"};
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;
  /* or 117% */
  letter-spacing: -0.02em;
  color: #000000;
  text-shadow: 0px 4px 20px rgba(45, 44, 44, 0.15);
  margin-bottom: 8px;
`;

export const NavigationTitle = styled.span `
  border-bottom: 4px solid #FF6600;
  border-radius: 2px;
`;

export const ClusterLinesContainer = styled.div `
  display: flex;
  width: 100%;
  height: 0;
  align-items: center;
  //margin-bottom: 107px;
  margin-bottom: 28px;
  padding-top: 2px;
`;

export const LineTitle = styled.div `
  /* Main Orange */
  width: 205px;
  height: 0;
  border: 3px solid #FF6600;
  border-radius: 5px;
`;

export const Line = styled.div `
  width: 100%;
  border: 1px solid rgba(183, 185, 187, 0.3);
  transform: matrix(1, 0, 0, -1, 0, 0);
`;

export const ClusterButtonContainer = styled.div `
   display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const AddAccountButton = styled.div `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 17px 26px;
  gap: 10px;
  cursor: pointer;
  width: 265px;
  height: 53px;

  background: #5C2EDF;
  border-radius: 26.5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const PlusIcon = styled.img `

`;

export const ButtonTitle = styled.div `
  height: 19px;

  /* Link & Button - Inconsolata Bold 17 - 10% Kerning */

  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 111%;
  /* identical to box height, or 19px */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #FBFBFB;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const FilterHeaderContainer = styled.div`
  width: 100%;
  padding: 0 22px ;
`;

import { AppBar, Toolbar, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../routes/appRoutes";
import { RouteType } from "../../routes/config";
// import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from "react-redux";
import Header from "../Header/Header";
// import { logoutAction } from "../../Store/actionCreators/main";

const searchForPathInRoutes = (routes: RouteType[], state: string): string => {
  for (let i =0;i < routes.length; i++) {
    const route = routes[i];
    if (route.state === state){
      return route.topBarName || '';
    }
    if (route.child && route.child.length > 0){
      const ans = searchForPathInRoutes(route.child, state);
      if (ans){
        return ans;
      }
    }
  }
  return '';
};

const Topbar = () => {
  const dispatch = useDispatch();
  const { path } = useSelector((state: any) => state.general);
  let topBarName = '';
  if (path) {
    topBarName = searchForPathInRoutes(appRoutes, path);
  }

  // const logout = () => {
  //   dispatch(logoutAction());
  // };

  return (
    <AppBar
      position="fixed"
      sx={{
        height: `${sizeConfigs.header.height} !important`,
        width: `100%`,
        ml: sizeConfigs.sidebar.width,
        boxShadow: "unset",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color
      }}
    > 
      <Header />
      {/* <Toolbar sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Typography variant="h6">
          {topBarName}
        </Typography>
      </Toolbar> */}
    </AppBar>
  );
};

export default Topbar;

import styled from "styled-components";
interface TableContainerType {
    height: number;
}

export const Container = styled.div `
  height: 100%;
`;

export const UsersManagementTableContainer = styled.div `
    height: 100%;
`;

export const UsersManagementHeaderContainer = styled.div `
   
`;

export const TableContainer = styled.div<TableContainerType> `
     height: calc(100% - ${props => props.height}px);
`;

export const TabsContainer = styled.div `
  display: flex;
  gap: 10px;
  margin-top: 35px;
  height: 52px;
`;

export const TabButton = styled.div<{selected: boolean}> `

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 0px;
  /* identical to box height, or 0% */

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  letter-spacing: -0.02em;

  color: ${(props) => (props.selected ? "#000000" : "#ffffff")};

  background: ${(props) => (props.selected ? "#FEFEFE" : "#E0E0E0")};
  border-radius: 20px 20px 0px 0px;
  width: 250px;
`;

import { createSlice } from "@reduxjs/toolkit";

export enum RestoreBackupTabEnum {
  BACKUP,
  PLANS,
}
export interface UsersState {
  loading: boolean;
  backups: any;
  backupPlans: any;
  tabSelected: RestoreBackupTabEnum;
}

// const [tabSelected, setTabSelected] = useState<RestoreBackupTabEnum>(
//   RestoreBackupTabEnum.BACKUP
// );

const initialState: UsersState = {
  loading: false,
  backups: { data: [], isLoading: false, isError: false },
  backupPlans: { data: [], isLoading: false, isError: false },
  tabSelected: RestoreBackupTabEnum.BACKUP,
};

const backupRestoreSlice = createSlice({
  name: "backupRestore",
  initialState,
  reducers: {
    getBackupsData: (state, action) => {
      state.backups.isLoading = true;
      state.backups.isError = false;
    },
    getBackupsDataDone: (state, action) => {
      state.backups.isLoading = false;
      state.backups.isError = false;
      state.backups.data = action.payload;
    },
    getBackupsDataError: (state, action) => {
      state.backups.isLoading = false;
      state.backups.isError = true;
      state.backups.data = [];
    },
    getBackupPlansData: (state, action) => {
      state.backupPlans.isLoading = true;
      state.backupPlans.isError = false;
    },
    getBackupPlansDataDone: (state, action) => {
      state.backupPlans.isLoading = false;
      state.backupPlans.isError = false;
      state.backupPlans.data = action.payload;
    },
    getBackupPlansDataError: (state, action) => {
      state.backupPlans.isLoading = false;
      state.backupPlans.isError = true;
      state.backupPlans.data = [];
    },
    deleteBackup: (state, action) => {},
    deleteBackupDone: (state, action) => {},
    restoreBackup: (state, action) => {},
    restoreBackupDone: (state, action) => {},
    updateBackupPlan: (state, action) => {},
    updateBackupPlanDone: (state, action) => {},
    saveNewBackup: (state, action) => {},
    saveNewBackupDone: (state, action) => {},
    saveNewBackupPlan: (state, action) => {},
    saveNewBackupPlanDone: (state, action) => {},
    setTabSelected: (state, action) => {
      state.tabSelected = action.payload;
    },
  },
});

export const {
  getBackupsData,
  getBackupsDataDone,
  getBackupsDataError,
  getBackupPlansData,
  getBackupPlansDataDone,
  getBackupPlansDataError,
  deleteBackup,
  deleteBackupDone,
  restoreBackup,
  restoreBackupDone,
  updateBackupPlan,
  updateBackupPlanDone,
  saveNewBackup,
  saveNewBackupDone,
  saveNewBackupPlan,
  saveNewBackupPlanDone,
  setTabSelected,
} = backupRestoreSlice.actions;

export default backupRestoreSlice;

import styled from "styled-components";
import {ReactComponent as CloseButton}  from "../../assets/svg/close.svg"

export const FilterContainer = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ClearAllContainer = styled.div`
  position: relative ;
  width: fit-content;
  padding-right: 10px;
`;

export const RemoveButtonStyled = styled(CloseButton)`
    width: 8px;
    height:8px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

`;

export const ClearAllText = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.02em;
    cursor: pointer ;
    color: #888888;

`;


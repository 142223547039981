import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { getAccountsDataRequest } from '../../store/slices/accounts';
import { selectAccountsData } from '../../store/slices/accounts/selectors';
import { BEResponse } from '../../types/BEResponse';
import AutoComplete from '../../Components/AutoComplete';

interface AccountSelectProps {
    register : any,
    name: string,
    handleChange: any,
    value: string,
    errors: any,
    title: any,
    placeholder: any,
    background? : string,
}

const AccountSelect: React.FC<AccountSelectProps> = (props) => {
    const {
        register,
        name,
        handleChange,
        value,
        errors,
        title,
        placeholder,
        background,
    } = props;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    // const {fieldsErrors, updateErrorField} = useFieldsErrorHook();
    const accountData: BEResponse = useSelector(selectAccountsData);

    const [localValue,setLocalValue] = useState<string>('');

    const [accountList,setAccountList] = useState<string[]>([]);

    useEffect(() => {
        if (accountData && accountData.data && accountData.data.length > 0) {
            setAccountList(accountData.data.map((it: any) => it.name ));
        }
    },[accountData]);


    const handleAccountChange = useCallback((newValue: string) => {
        if (accountData && accountData.data && accountData.data.length > 0) {
            const accountItem = accountData.data.find((it: any) => it.name === newValue);
            if (accountItem) {
                handleChange(name, accountItem.id);
                setLocalValue(newValue);
                return;
            }
        }
        if (!newValue) {
            handleChange(name, '');
            setLocalValue('');
        }
    },[handleChange, accountData, name]);

    useEffect(() => {
        const accountItem = accountData.data.find((it: any) => it.id === value);
            if (accountItem) {
                setLocalValue(accountItem.name);
                return;
            }
    },[accountData.data, value])

    useEffect(() => {
        dispatch(getAccountsDataRequest({}));
    }, [dispatch]);

    return (
        <AutoComplete
            {...register(name, {
            required: t("field.required") || ""})}
            placeholder={placeholder}
            options={accountList}
            value={localValue}
            updateValue={handleAccountChange}
            error={errors[name]?.message as string || ""}
            title={title}
            
            background={background}
        />

    );
};

export default AccountSelect;

import React, { useCallback } from "react";
import { Cell } from "react-table";
import { useNavigate } from "react-router";
import styled from "styled-components";
interface ConnectAdminListHeaderProps {
  cell: Cell<Record<string, any>>;
}

export const ContainerName = styled.div`
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.02em;
  text-align: left;
  cursor: pointer;

  &:hover {
    color: #5c2edf;
    font-weight: 700;
  }

`;
const Name: React.FC<ConnectAdminListHeaderProps> = (props) => {
  const navigate = useNavigate();
  //   const handleConnect = (event: React.MouseEvent<HTMLDivElement>) => {
  //     event.stopPropagation();
  //     if (isEnabled) {
  //       console.log("test");
  //       // dispatch(connectCluster(props.cell.row.original.clusterId));
  //       // /CLI/:id
  //       navigate(`/CLI/${props.cell.row.original.clusterName}`, {
  //         state: props.cell.row.original,
  //       });
  //     }
  //   };

  const onClick = useCallback(
    (row: any) => {
      console.log("onDoubleClick", props.cell);
      if (props.cell.value) {
        navigate(props.cell.value || "Cluster", {
          state: props.cell.row.original,
          relative: "route",
        });
      }
    },
    [navigate, props.cell]
  );

  return (
    <React.Fragment>
      <ContainerName className="namecell" onClick={onClick}>
        {props.cell.value}
      </ContainerName>
    </React.Fragment>
  );
};
export default Name;

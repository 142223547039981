import React, { useState } from "react";
import {
  HeaderContainer,
  Header_left,
  Header_right,
  HeaderBackground,
  Header_help_img,
  Header_logo_img,
  Header_info_img,
  Header_notification_img,
  Vertical_i,
  Account,
  ProfileContainer,
  menuPopperProps,
  AvatarTitle,
  MenuItemContainer,
  ListIcon,
  ListTitle,
  ListIconReset,
  ListIconSignOut,
} from "./styles";
import logoHeader from "../../assets/svg/logo_header.svg";
import infoIcon from "../../assets/svg/info_new.svg";
import infoActive from "../../assets/svg/info.svg";
import helpIcon from "../../assets/svg/help_header.svg";
import helpActive from "../../assets/svg/help_active.svg";
import notificationIcon from "../../assets/svg/notification_header.svg";
import notificationActive from "../../assets/svg/notification_active.svg";
import { Avatar, Menu, MenuItem } from "@mui/material";
import avatar from "../../assets/svg/avatar_header.svg";
import profile from "../../assets/svg/profile_icon.svg";
import reset from "../../assets/svg/reset_icon.svg";
import logOut from "../../assets/svg/log_out.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  selectEmail,
  selectFullName,
} from "../../store/slices/general/selectors";
import { logout } from "../../store/slices/general";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  account?: string;
}
const Header: React.FC<HeaderProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [openProfileList, setOpenProfileList] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const username = useSelector(selectFullName);
  const email = useSelector(selectEmail);
  const helpHandler = () => {
    console.log("helpHandler");
  };

  const infoHandler = () => {
    console.log("infoHandler");
  };

  const notificationHandler = () => {
    console.log("notificationHandler");
  };

  const toggleProfileMenu = (event: React.MouseEvent<HTMLImageElement>) => {
    setOpenProfileList(!openProfileList);
    if (openProfileList) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const logoutButton = () => {
    dispatch(logout());
  };

  const navigateTo = (
    event: React.MouseEvent<HTMLImageElement>,
    path: string
  ) => {
    toggleProfileMenu(event);
    navigate(path);
  };
  return (
    <HeaderBackground>
      <HeaderContainer>
        <Header_left>
          <Header_logo_img src={logoHeader} alt={"logoHeader"} />
        </Header_left>
        <Header_right>
          <Header_info_img
            onClick={() => {
              helpHandler();
            }}
            src={active ? infoActive : infoIcon}
            alt={"infoIcon"}
            active={active}
          />
          <Header_help_img
            onClick={() => {
              infoHandler();
            }}
            src={active ? helpActive : helpIcon}
            alt={"helpIcon"}
            active={active}
          />
          <Header_notification_img
            onClick={() => {
              notificationHandler();
            }}
            src={active ? notificationActive : notificationIcon}
            alt={"notificationIcon"}
            active={active}
          />
          <Vertical_i>|</Vertical_i>
          <Account>{email}</Account>
          <ProfileContainer onClick={toggleProfileMenu}>
            <Avatar
              sx={{ width: "32px", height: "32px", border: "1px solid white" }}
              src={avatar}
              alt={"avatar"}
            />
          </ProfileContainer>
        </Header_right>
      </HeaderContainer>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openProfileList}
        // onClick={toggleProfileMenu}
        onClose={toggleProfileMenu}
        PaperProps={menuPopperProps}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        style={{ marginTop: "15px" }}
      >
        <MenuItemContainer
          disableRipple
          sx={{
            height: "78px",
            borderBottom: "1px solid #D8D8D8;",
            cursor: "default",
          }}
        >
          <Avatar src={avatar} alt={"avatar"} style={{ marginLeft: "11px" }} />
          <AvatarTitle>
            {t("header_menu_hey")}, {username}
          </AvatarTitle>
        </MenuItemContainer>
        <MenuItemContainer
          onClick={(event: any) => navigateTo(event, "/user-profile")}
          disableRipple
          sx={{ height: "46px", borderBottom: "1px solid #D8D8D8;" }}
        >
          <ListIcon src={profile} alt={"profile"} />
          <ListTitle>{t("header_menu_profile")}</ListTitle>
        </MenuItemContainer>
        <MenuItemContainer
          onClick={(event: any) => navigateTo(event, "/reset-password-user")}
          disableRipple
          sx={{ height: "53px", borderBottom: "1px solid #D8D8D8;" }}
        >
          <ListIconReset src={reset} alt={"reset"} />
          <ListTitle>{t("update.password")}</ListTitle>
        </MenuItemContainer>
        <MenuItemContainer
          disableRipple
          onClick={logoutButton}
          sx={{ height: "54px", borderBottom: "1px solid #D8D8D8;" }}
        >
          <ListIconSignOut src={logOut} alt={"logOut"} />
          <ListTitle>{t("header_menu_sign_out")}</ListTitle>
        </MenuItemContainer>
      </Menu>
    </HeaderBackground>
  );
};

export default Header;

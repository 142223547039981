import * as React from "react";
import {
  StyledDialog,
  CloseIconStyled,
  ConfirmButton,
  DividerStyled,
  StyledDialogContent,
  DialogIconStyled,
  IconContainer,
  DialogContentTextStyled,
  DialogActionsStyled,
  DividerStyledFooter,
  CancelButton,
  TitleDialog,
  DialogContentTextInfo,
} from "./styles";
import closeIcon from "../../../assets/svg/close.svg";

import { useTranslation } from "react-i18next";

type AlertDialogProps = {
  title: string;
  subTitle: string;
  paragraph: string;
  subTitle2: string;
  closeDialog: () => void;
  handleConfirm: () => void;
  src: any;
};
const AlertDialog = ({
  title,
  subTitle,
  paragraph,
  closeDialog,
  handleConfirm,
  subTitle2,
  src,
}: AlertDialogProps) => {
  const [open, setOpen] = React.useState(true);
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
    closeDialog();
  };
  return (
    <div>
      {/*<Button variant="outlined" onClick={handleClickOpen}>*/}
      {/*    Open alert dialog*/}
      {/*</Button>*/}
      <StyledDialog open={open} onClose={handleClose}>
        <CloseIconStyled
          src={closeIcon}
          alt={"closeIcon"}
          onClick={handleClose}
        />
        <DividerStyled />
        <StyledDialogContent>
          <IconContainer>
            <DialogIconStyled src={src} width={50} height={50} />
          </IconContainer>
          <TitleDialog>{title}</TitleDialog>
          <DialogContentTextStyled>{subTitle}</DialogContentTextStyled>
          <DialogContentTextInfo>{paragraph}</DialogContentTextInfo>
          <DialogContentTextStyled>{subTitle2}</DialogContentTextStyled>
        </StyledDialogContent>
        <DialogActionsStyled>
          <DividerStyledFooter />
          <CancelButton onClick={handleClose}>
            {t("cancel.button")}
          </CancelButton>
          <ConfirmButton onClick={handleConfirm} autoFocus>
            {t("modal.confirm.button")}
          </ConfirmButton>
        </DialogActionsStyled>
      </StyledDialog>
    </div>
  );
};
export default AlertDialog;

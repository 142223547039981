import styled from "styled-components";

export const CheckBoxContainer = styled.nav`
  background: transparent;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
`;

const handleBackGroundColorChecked = (varient : "orange" | "white") => {
  if(varient === "orange")
    return "#FF6600";
  return "#ffffff"
}

const handleBorderColorChecked = (varient : "orange" | "white") => {
  if(varient === "orange")
    return "1px solid #FF6600";
  return "1px solid #B2B2B2"
}

export const SelectInput = styled.div<{ checked?: boolean, varient : "orange" | "white" }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border: ${(props) => (props.checked ? handleBorderColorChecked(props.varient) : "1px solid #B2B2B2")};
  border-radius: 4px;
  background-color: ${(props) => (props.checked ? handleBackGroundColorChecked(props.varient) : "#ffffff")};
  // margin-left: 5px;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.a<{ selected?: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0 8px;
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Poppins" : "unset"};
  font-stretch: normal;
  letter-spacing: -0.02em;
  color: #000000;
`;


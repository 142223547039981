import React, {useState} from 'react';
import {
    Container,
    FilterHeaderContainer,
    ClusterButtonContainer, ButtonTitle, PlusIcon, AddAccountButton
} from "./styles";
import Search from "../Search/Search";
import plusIcon from "../../assets/svg/plus_icon.svg";
import FilterHeader from '../FilterHeader';
import BreadCrumbs from '../BreadCrubms';
import {useTranslation} from "react-i18next";


interface ClutsersAdminListHeaderProps {
    createNewCluster:() => void;
    // filterHeaderItems: FilterHeaderItemProps[];
    filterSearchCols: Record<string, any>;
    handleColumnCheckChange: (id: string) => void;
    handleResetAllFilters: () => void;
    handleColumnFilterValueChange: (id: string, value: string | Array<Record<string,any>> | Record<string,any>) => void;
    handleResetFilter: (id: string) => void;
    handleRemoveFilter: (id: string) => void;
    handleGlobalFilterChange: (text: string) => void;
    globalFilterValue: string;
}
const ClutsersAdminListHeader: React.FC<ClutsersAdminListHeaderProps> = (props) => {
    const {filterSearchCols, handleColumnCheckChange,
        handleResetAllFilters, handleColumnFilterValueChange, handleResetFilter,
        createNewCluster, handleRemoveFilter, handleGlobalFilterChange, globalFilterValue} = props;
    const {t} = useTranslation();


    return (
        <Container>
            <BreadCrumbs />
            <ClusterButtonContainer>
                <Search placeholder={t("search_input_placeholder")}
                        value={globalFilterValue}
                        onChange={(text: string) => handleGlobalFilterChange(text)}
                        filterSearchCols={filterSearchCols}
                        handleColumnCheckChange={handleColumnCheckChange}
                        handleResetAllFilters={handleResetAllFilters}
                         />

                    <FilterHeaderContainer><FilterHeader handleRemoveFilter={handleRemoveFilter} items={filterSearchCols} handleResetAllFilters={handleResetAllFilters} handleColumnFilterValueChange={handleColumnFilterValueChange} handleResetFilter={handleResetFilter}/></FilterHeaderContainer>
                <AddAccountButton onClick={createNewCluster}>
                    <PlusIcon src={plusIcon} alt={"plusIcon"}/>
                    <ButtonTitle>{t("create.cluster.button")}</ButtonTitle>
                </AddAccountButton>
            </ClusterButtonContainer>
        </Container>
    );
};

export default ClutsersAdminListHeader;

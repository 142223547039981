import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    BottomWrapperNew,
    Container,
    Divider,
    InputContainer,
    MonthlyContainer,
    OnDayDiv,
    RadioGroup,
    RadioRow,
    RadioText,
    SaveUserButton,
    ScheduleContainer,
    TimeWrapper,
    UserButtonsWrapper,
    UserContainer,
    WeeklyRowDay,
} from "./styles";
import {ButtonTitle,} from "../../Components/AccountsHeader/styles";
import info_icon from "../../assets/svg/info_new.svg";
import {useLocation, useNavigate} from "react-router-dom";
import InputField from "../../Components/InputField/InputField";
import {ButtonsContainer, CancelButton, LineBottom,} from "../../Components/CreateNewAccount/styles";
import AlertDialog from "./Modal/index";
import BreadCrumbs from "../../Components/BreadCrubms";

import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";

import {useDispatch, useSelector} from "react-redux";
import Select from "../../Components/Select/Select";
import AccountSelect from "../../Components/AccountsSelect";
import {getClusterManagementData} from "../../store/slices/clusterManagement";
import {selectClustersData} from "../../store/slices/clusterManagement/selectors";
import {Radio} from "@mui/material";
import CheckBox from "../../Components/CheckBox/CheckBox";
import {selectAccountId, selectRoles,} from "../../store/slices/general/selectors";
import {recurrenceBackupPlan} from "../../types/backupRestore";
import {saveNewBackup, saveNewBackupPlan, updateBackupPlan,} from "../../store/slices/backupRestore";

interface Props {
  type: "backup" | "backup_plans";
}

function getRoundedMinutes() {
  const date = new Date();
  let minutes = date.getMinutes();
  // Round down to the nearest quarter-hour
  minutes = Math.floor(minutes / 15) * 15;
  // Convert to a two-digit string
  return minutes.toString().padStart(2, "0");
}

const NewBackup: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { type } = props;
  const dispatch = useDispatch();
  const clusterData = useSelector(selectClustersData);
  const [showModal, setShowModal] = useState(false);
  const [clusterItems, setClusterItems] = useState<
    Array<{ label: string; value: any }>
  >([]);
  const roles = useSelector(selectRoles);
  const accountId = useSelector(selectAccountId);
  const location = useLocation();

  const isSystemAdmin = useMemo(
    () => (roles ? roles.indexOf("SYSTEM_ADMIN") >= 0 : false),
    [roles]
  );

  console.log("location.state ", location.state);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      hour: new Date().getHours().toString().padStart(2, "0"),
      min: getRoundedMinutes(),
      name: "",
      account: "",
      cluster: "",
      recurrence: recurrenceBackupPlan.DAILY,
      dayOfWeek: 7,
      dayOfMonth: 1,
    },
  });

  const {
    name,
    account,
    cluster,
    hour,
    min,
    recurrence,
    dayOfWeek,
    dayOfMonth,
  } = getValues();
  const handleFieldChange = useCallback(
    (field: any, value: any) => {
      setValue(field, value);
      trigger(field);
    },
    [setValue, trigger]
  );

  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    //if edit
    if (location.state) {
      const {
        tag,
        clusterId,
        dayOfWeek,
        dayOfMonth,
        recurrencePattern,
        startTime,
        accountId,
      } = location.state;
      setIsUpdate(true);
      handleFieldChange("name", tag);
      handleFieldChange("cluster", clusterId);
      if (recurrencePattern === recurrenceBackupPlan.WEEKLY)
        handleFieldChange("dayOfWeek", dayOfWeek);
      if (recurrencePattern === recurrenceBackupPlan.MONTHLY)
        handleFieldChange("dayOfMonth", dayOfMonth);
      handleFieldChange("recurrence", recurrencePattern);
      handleFieldChange("account", accountId);
      const [hoursStr, minutesStr] = startTime.split(":");
      const hours = Number(hoursStr);
      const minutes = Number(minutesStr);
      handleFieldChange("hour", hours);
      handleFieldChange("min", minutes);
    }
  }, [handleFieldChange, location.state]);

  const onSubmit = useCallback(() => {
    // dispatch(saveNewBackup({}))
    console.log("errors", errors);
    console.log("cluster, tag", cluster, name);

    if (type === "backup") {
      dispatch(
        saveNewBackup({
          clusterId: cluster,
          body: { tag: name, restart: true },
          navigate: navigate,
        })
      );
    } else {
      const request = {
        navigate: navigate,
        clusterId: cluster,
        body: {
          clusterId: cluster,
          dayOfMonth:
            recurrence === recurrenceBackupPlan.MONTHLY ? dayOfMonth : null,
          dayOfWeek:
            recurrence === recurrenceBackupPlan.WEEKLY ? dayOfWeek : null,
          timeZone: "Asia/Jerusalem",
          enabled: true,
          recurrencePattern: recurrence,
          retentionInDays: 30,
          startTime: `${hour.toString().padStart(2, "0")}:${min
            .toString()
            .padStart(2, "0")}:00`,
          tag: name,
        },
      };
      if (isUpdate) {
        dispatch(updateBackupPlan(request));
      } else {
        dispatch(saveNewBackupPlan(request));
      }
    }
    setShowModal(false);
  }, [
    cluster,
    dayOfMonth,
    dayOfWeek,
    dispatch,
    errors,
    hour,
    isUpdate,
    min,
    name,
    navigate,
    recurrence,
    type,
  ]);

  useEffect(() => {
    handleFieldChange("account", accountId);
  }, [handleFieldChange, accountId]);

  useEffect(() => {
    dispatch(
      getClusterManagementData({
        accountId: account,
        clusterStatus: ["RUNNING"],
      })
    );
  }, [account, dispatch, isSystemAdmin]);

  // clusterId
  // clusterName
  useEffect(() => {
    const items = clusterData.data.map(
      (elem: { clusterId: any; clusterName: any }) => ({
        value: elem.clusterId,
        label: elem.clusterName,
      })
    );
    setClusterItems(items);
  }, [clusterData.data]);

  const hoursItems = useMemo(
    () =>
      [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ].map((elem) => ({ value: Number(elem), label: elem })),
    []
  );
  const minsItems = useMemo(
    () =>
      ["00", "15", "30", "45"].map((elem) => ({
        value: Number(elem),
        label: elem,
      })),
    []
  );

  console.log("min", min);

  const daysItems = useMemo(
    () => [
      { value: 7, label: "Sunday" },
      { value: 1, label: "Monday" },
      { value: 2, label: "Tuesday" },
      { value: 3, label: "Wednesday" },
      { value: 4, label: "Thursday" },
      { value: 5, label: "Friday" },
      { value: 6, label: "Saturday" },
    ],
    []
  );

  const daysMonthItems = useMemo(
    () =>
      Array.from({ length: 31 }, (_, i) => ({
        value: i + 1,
        label: `${i + 1}`,
      })),
    []
  );

  const clusterItemText = useMemo(
    () =>
      clusterItems.find((elem: any) => String(elem.value) === String(cluster))
        ?.label,
    [clusterItems, cluster]
  );

  const OnPopup = useCallback(() => {
    setShowModal(true);
  }, []);

  return (
    <Container>
      <BreadCrumbs />

      <UserContainer>
        <InputContainer>
          <InputField
            {...register("name", {
              required: t("field.required") || "",
            })}
            placeholder={
              type === "backup"
                ? t("create.backup.name")
                : t("create.backupplan.name")
            }
            value={name}
            onChange={(text: string) => handleFieldChange("name", text)}
            onClickCloseHandle={() => handleFieldChange("name", "")}
            required={false}
            error={(errors.name?.message as string) || ""}
            title={
              (type === "backup"
                ? t("create.backup.name")
                : t("create.backupplan.name")) || ""
            }
          />
        </InputContainer>

        {isSystemAdmin && (
          <InputContainer>
            <AccountSelect
              name={"account"}
              register={register}
              value={account}
              handleChange={(name: string, id: string) =>
                handleFieldChange("account", id)
              }
              errors={errors}
              title={"Account"}
              placeholder={t("ui.account.select.title")}
              background={"#F6F5F5"}
            />
          </InputContainer>
        )}
        <InputContainer>
          <Select
            {...register("cluster", {
              required: t("field.required") || "",
            })}
            items={clusterItems}
            selectedItem={clusterItems.find(
              (elem: { value: any; label: any }) =>
                String(elem.value) === cluster
            )}
            placeholder="Select Cluster"
            TitleText="Cluster"
            allowEmptySelect={false}
            required={(errors.cluster?.message as string) ? true : false}
            isError={(errors.cluster?.message as string) ? true : false}
            onSelect={(item: any) =>
              handleFieldChange("cluster", item?.value ?? "")
            }
          />
        </InputContainer>
        {type === "backup_plans" && (
          <ScheduleContainer>
            <TimeWrapper>
              <Select
                items={hoursItems}
                selectedItem={hoursItems.find(
                  (el) => Number(el.value) === Number(hour)
                )}
                placeholder=""
                TitleText=""
                required={false}
                allowEmptySelect={false}
                onSelect={(item: any) =>
                  handleFieldChange("hour", String(item?.value))
                }
              />
              :
              <Select
                items={minsItems}
                placeholder=""
                TitleText=""
                selectedItem={minsItems.find(
                  (el) => Number(el.value) === Number(min)
                )}
                allowEmptySelect={false}
                required={false}
                onSelect={(item: any) =>
                  handleFieldChange("min", String(item?.value))
                }
              />
            </TimeWrapper>
            <Divider />
            <RadioGroup>
              <RadioRow>
                <Radio
                  checked={recurrence === recurrenceBackupPlan.DAILY}
                  onChange={(e) =>
                    handleFieldChange("recurrence", e.target.value)
                  }
                  value={recurrenceBackupPlan.DAILY}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                  color="default"
                />
                <RadioText>Daily</RadioText>
              </RadioRow>
              <RadioRow>
                <Radio
                  checked={recurrence === recurrenceBackupPlan.WEEKLY}
                  onChange={(e) =>
                    handleFieldChange("recurrence", e.target.value)
                  }
                  value={recurrenceBackupPlan.WEEKLY}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "B" }}
                  color="default"
                />
                <RadioText>Weekly</RadioText>
              </RadioRow>
              <RadioRow>
                <Radio
                  checked={
                    recurrence === recurrenceBackupPlan.MONTHLY ||
                    recurrence === recurrenceBackupPlan.ENDOFMONTH
                  }
                  onChange={(e) =>
                    handleFieldChange("recurrence", e.target.value)
                  }
                  value={recurrenceBackupPlan.MONTHLY}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "B" }}
                  color="default"
                />
                <RadioText>Monthly</RadioText>
              </RadioRow>
            </RadioGroup>
            {recurrence !== recurrenceBackupPlan.DAILY && <Divider />}
            {recurrence === recurrenceBackupPlan.WEEKLY && (
              <WeeklyRowDay>
                On{" "}
                <Select
                  items={daysItems}
                  placeholder=""
                  TitleText=""
                  required={false}
                  allowEmptySelect={false}
                  selectedItem={daysItems.find(
                    (elem) => String(elem.value) === String(dayOfWeek)
                  )}
                  onSelect={(item: any) =>
                    handleFieldChange("dayOfWeek", item?.value)
                  }
                />
              </WeeklyRowDay>
            )}
            {(recurrence === recurrenceBackupPlan.MONTHLY ||
              recurrence === recurrenceBackupPlan.ENDOFMONTH) && (
              <MonthlyContainer>
                {recurrence === recurrenceBackupPlan.MONTHLY && (
                  <WeeklyRowDay>
                    <OnDayDiv>On day </OnDayDiv>
                    <Select
                      items={daysMonthItems}
                      placeholder=""
                      TitleText=""
                      required={false}
                      allowEmptySelect={false}
                      selectedItem={daysMonthItems.find(
                        (elem) => String(elem.value) === String(dayOfMonth)
                      )}
                      onSelect={(item: any) =>
                        handleFieldChange("dayOfMonth", item?.value)
                      }
                    />
                  </WeeklyRowDay>
                )}
                <CheckBox
                  isChecked={recurrence === recurrenceBackupPlan.ENDOFMONTH}
                  title="End of month"
                  checkHandler={() =>
                    handleFieldChange(
                      "recurrence",
                      recurrence === recurrenceBackupPlan.ENDOFMONTH
                        ? recurrenceBackupPlan.MONTHLY
                        : recurrenceBackupPlan.ENDOFMONTH
                    )
                  }
                  varient="white"
                />
              </MonthlyContainer>
            )}
          </ScheduleContainer>
        )}
      </UserContainer>
      {showModal && (
        <AlertDialog
          title={t("modal.title")}
          subTitle={"you are about to backup cluster"}
          src={info_icon}
          paragraph={clusterItemText ?? ""}
          subTitle2="This will prevent any connectivity with the database during the backup."
          closeDialog={() => {
            setShowModal(false);
          }}
          handleConfirm={onSubmit}
        />
      )}
      <BottomWrapperNew>
        <LineBottom />
        <UserButtonsWrapper>
          <ButtonsContainer>
            <CancelButton
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel.button")}{" "}
            </CancelButton>
            <SaveUserButton onClick={handleSubmit(OnPopup)} disabled={!isValid}>
              <ButtonTitle>
                {type === "backup"
                  ? t("user.backup.save.button")
                  : t("user.profile.save.button")}
                {" >"}
              </ButtonTitle>
            </SaveUserButton>
          </ButtonsContainer>
        </UserButtonsWrapper>
      </BottomWrapperNew>
    </Container>
  );
};

export default NewBackup;

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 313px;
  max-width: 313px;
  height: 51px;
`;

export const ContainerInput = styled.div<{
    size?: "lg" | "sm";
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  gap: 12px;
  height: 100%;
  //background: linear-gradient(174.06deg, #EEEEEE 20.86%, #FFFFFF 87.44%, #FFFFFF 131.09%);
  background: #ffffff;

  padding-left: 19px;
  &:hover,
  &:focus-within {
    border: 1px solid #EEEEEE;
  }
`;

export const IconStyled = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const LineIconStyled = styled.img`
  height: 36px;
  width: 14px;
`;

export const FilterIconStyled = styled.img`
  cursor: pointer;
  padding: 18px 19px 19px 0px;
`;

export const InputFieldStyled = styled.input `
  outline: none;
  border: none;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 100%;
  /* identical to box height, or 15px */

  letter-spacing: -0.02em;
  font-stretch: normal;
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Poppins" : "unset"};
  background: transparent;
  color: #2C2C2C;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  }
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #888888;
  }
`;

export const menuPopperProps = ({
  elevation: 0,
  sx: {
    borderRadius: '20px',
    width: '222px',
    background: '##FFFFFF',
    overflow: 'visible',
    filter: 'drop-shadow(0px 4px 20px rgba(0,0,0,0.15))',
    mt: 1.5,
    '& .MuiList-root': {
      padding: 0,
    },
  },
});


export const SearchFilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px 18px 20px;
`;

export const TitleFilter = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 100%;
/* or 20px */

letter-spacing: -0.02em;

color: #000000;
`;

export const ClearFiltersContainer = styled.div `
 padding-top: 15px;
`;

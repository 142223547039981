
export enum ItemType {
    text  = "text",
    types = "types",
    date = "date",
}

export interface FilterHeaderItemProps {
    Header: string;
    accessor: string;
    type: ItemType;
    types: Array<Record<string,any>>;
    handleColumnFilterValueChange: (id: string, value: string | Array<string | Record<string,any>>) => void;
    handleResetFilter: (id: string) => void;
    handleRemoveFilter: (id: string) => void;
    value: string | Array<Record<string,any>> | Record<string,any>;

}
import {RootState} from "../..";

export const selectClustersData = (state: RootState) =>
  state.clustermanagement.clusters;
export const selectClusterRegions = (state: RootState) =>
  Array.isArray(state.clustermanagement.clusterRegions.data)
    ? state.clustermanagement.clusterRegions.data
    : [];
export const selectClusterTypes = (state: RootState) =>
  Array.isArray(state.clustermanagement.clusterTypes.data)
    ? state.clustermanagement.clusterTypes.data
    : [];
export const selectClusterTrustedIps = (state: RootState) =>
  Array.isArray(state.clustermanagement.clusterTrustedIps.data)
    ? state.clustermanagement.clusterTrustedIps.data
    : [];

export const selectExternalIP = (state: RootState) => {
      if (!state.clustermanagement.configuration) {
            return null;
      }

      for (const node of state.clustermanagement.configuration[0]?.configuration.clusterNodes || []) {
            const module = node.modules.find((elem: any) => elem.type === "RDB");
            if (module) {
                  return module;
            }
      }

      return null;
};

import  React, {useMemo, useState} from 'react';
import {
    StyledDialog, CloseIconStyled, ConfirmButton, DividerStyled, StyledDialogContent,
    DialogIconStyled, IconContainer, DialogContentTextStyled, DialogActionsStyled,
    DividerStyledFooter, CancelButton, TitleDialog, DialogInfoStyled, ClusterNameText, InputContainer, ButtonsContainer
} from "./styles";
import closeIcon from "../../../../assets/svg/close.svg";
import dialogIcon from "../../../../assets/svg/dialog_icon.svg";
import {useTranslation} from "react-i18next";
import InputField from '../../../../Components/InputField/InputField';

type AlertDialogClusterDeleteProps = {
    handleClose: (e: any) => void;
    handleConfirm:  (e: any) => void;
    isOpen: boolean;
    validateName: string;
}
const AlertDialogClusterDelete  = ({  handleClose, handleConfirm, isOpen, validateName }: AlertDialogClusterDeleteProps) =>  {
    const {t} = useTranslation();
    const [value, setValue] = useState("")
    const disabled = useMemo(() => validateName !== value, [validateName, value]);

    const handleConfirmClick = (e: any) => {
        if(!disabled){
            handleConfirm(e);
        }
    }
    return (
        <div onClick={(e :  React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}>
            <StyledDialog
                open={isOpen}
                onClose={handleClose}
                onClick={e => e.stopPropagation()}
                onBackdropClick={e => e.stopPropagation()}
            >
                <CloseIconStyled src={closeIcon} alt={"closeIcon"} onClick={handleClose}/>
                <DividerStyled />
                <StyledDialogContent>
                    <IconContainer><DialogIconStyled src={dialogIcon} alt={"dialogIcon"}/></IconContainer>
                    {/* <TitleDialog>{"test"}</TitleDialog> */}
                    <DialogContentTextStyled>
                        {t("cluster_delete_modal_title",  { clusterName: validateName, })}
                    </DialogContentTextStyled>
                    <DialogContentTextStyled>
                        {t("cluster_delete_modal_subtitle")}
                    </DialogContentTextStyled>
                    <InputContainer>
                        <ClusterNameText>{t("cluster_delete_modal_name")}:</ClusterNameText>
                        <InputField value={value} onChange={(text: string) => setValue(text)} placeholder={t("cluster_delete_modal_name")} onClickCloseHandle={() => setValue("")}/>
                    </InputContainer>
                </StyledDialogContent>
                <DialogActionsStyled>
                    <DividerStyledFooter />
                     <ButtonsContainer>
                         <CancelButton onClick={handleClose}>{t("cancel.button")}</CancelButton>
                         <ConfirmButton disabled={disabled} onClick={handleConfirmClick} autoFocus>
                             {t("modal.confirm.button")}
                         </ConfirmButton>
                     </ButtonsContainer>
                </DialogActionsStyled>
            </StyledDialog>
        </div>
    );
}
export default AlertDialogClusterDelete;

import React from 'react';
import {
 FilterContainer,
 ClearAllContainer,
 ClearAllText,
 RemoveButtonStyled
} from "./styles";

import Item from "./Item"
import {useTranslation} from "react-i18next";
interface FilterHeaderProps {
    // items: {title: string, type: ItemType}[]
    items: Record<string, any>;
    handleResetAllFilters: () => void;
    handleResetFilter: (id: string) => void;
    handleRemoveFilter: (id: string) => void;
    handleColumnFilterValueChange: (id: string, value: string | Array<string> | Record<string,any>) => void;
}
const FilterHeader: React.FC<FilterHeaderProps> = (props) => {
    const {t} = useTranslation();
    const {items, handleResetAllFilters, handleColumnFilterValueChange, handleResetFilter, handleRemoveFilter} = props;
    const enabledItemsLength = Object.entries(items).filter(([_,values], i) => values.enabled).length;
    return (
        <FilterContainer>{Object.entries(items).map(([_,values], i) => values.enabled ? <Item key={i} handleRemoveFilter={handleRemoveFilter} handleResetFilter={handleResetFilter} handleColumnFilterValueChange={handleColumnFilterValueChange} {...values }/> : null)}
            {enabledItemsLength > 0 && (<ClearAllContainer onClick={handleResetAllFilters}>
                <ClearAllText>{t("clear all")}</ClearAllText>
                <RemoveButtonStyled />
            </ClearAllContainer>)}
        </FilterContainer>
    );
};

export default FilterHeader;

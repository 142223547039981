import React, { useState } from "react";
import styled from "styled-components";


const Container = styled.div`
    caption-side: bottom;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(238, 238, 238);
    border-radius: 0 0 10px 10px;
    padding: 10px;
    width: 100%;
    margin-top: -30px;
`;
const StyledTable = styled.table`
  caption-side: top;
  border: none;
  width: 100%;
  border-collapse: collapse;
  /* border-collapse: separate; */
  /* border-spacing: 5px 10px; */

              
  td,
  th {
    border: none;
  }

  td {
    padding: 5px 10px;

  }

  tbody {
    padding: 0 20px;
  }
  tbody > tr {
    border-bottom: 1px solid rgb(224, 224, 224);
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    height:45px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: rgb(0, 0, 0);

    :last-child{
        border: none;
    }
    :hover {
      background-color: #efefef;
      cursor: pointer;
    }
  }
  thead > tr {
    background-color: #ffffff;
    text-align: left;
    border-bottom: 1px solid rgb(224, 224, 224);
    height: 40px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: -0.02em;
    padding-right: 44px;
    color: rgb(0, 0, 0);
  }
  caption {
    font-size: 0.9em;
    padding: 5px;
    font-weight: bold;
  }
`;


interface InputInterface {
    columns: Array<{id: string, label:string}>;
    data: Array<Record<string,string>>;
    onRowSelect: (row: Record<string,string>) => void;
}

const SimpleTable: React.FC<InputInterface> = (props) => {
    const {
        columns, data,
        onRowSelect
    } = props;
;

    return (
        <Container>
        <StyledTable>
            <thead>
            <td style={{height:"35px"}}></td>
            </thead>
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={index}>{col.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item , index : number) => (
            <tr key={index} onClick={() => onRowSelect(item)}>
              {columns.map((col, index) => (
                <td key={index}>{item[col.id]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </StyledTable>
      </Container>
    );
};

export default SimpleTable;

import React, { useState } from "react";
import BreadCrumbs from "../BreadCrubms";
import {
  AddRestoreBackup,
  ButtonTitle,
  Container,
  FilterHeaderContainer,
  BackupPlansButton,
  RestoreBackupContainer,
  PlusIcon,
} from "./styles";
import Search from "../Search/Search";
import FilterHeader from "../FilterHeader";
import { useTranslation } from "react-i18next";
import plusIcon from "../../assets/svg/plus_icon.svg";

interface BackupRestoreHeaderProps {
  openCreateNewBackup: (type: "backup" | "backup_plans") => void;
  // filterHeaderItems: FilterHeaderItemProps[];
  filterSearchCols: Record<string, any>;
  handleColumnCheckChange: (id: string) => void;
  handleResetAllFilters: () => void;
  handleColumnFilterValueChange: (
    id: string,
    value: string | Array<string> | Record<string, any>
  ) => void;
  handleResetFilter: (id: string) => void;
  handleRemoveFilter: (id: string) => void;
  backupTab: boolean;
  globalFilterValue: string;
  handleGlobalFilterChange: (value: string) => void;
}
const BackupRestoreHeader: React.FC<BackupRestoreHeaderProps> = (props) => {
  const {
    filterSearchCols,
    handleColumnCheckChange,
    handleResetAllFilters,
    handleColumnFilterValueChange,
    handleResetFilter,
    backupTab,
    handleRemoveFilter,
    globalFilterValue,
    handleGlobalFilterChange,
  } = props;
  const { t } = useTranslation();

  return (
    <Container>
      <BreadCrumbs />

      <RestoreBackupContainer>
        <Search
          placeholder={t("search_input_placeholder")}
          value={globalFilterValue}
          onChange={(text: string) => handleGlobalFilterChange(text)}
          filterSearchCols={filterSearchCols}
          handleColumnCheckChange={handleColumnCheckChange}
          handleResetAllFilters={handleResetAllFilters}
        />

        <FilterHeaderContainer>
          <FilterHeader
            handleRemoveFilter={handleRemoveFilter}
            items={filterSearchCols}
            handleResetAllFilters={handleResetAllFilters}
            handleColumnFilterValueChange={handleColumnFilterValueChange}
            handleResetFilter={handleResetFilter}
          />
        </FilterHeaderContainer>
        {backupTab ? (
          <AddRestoreBackup
            onClick={() => {
              props.openCreateNewBackup("backup");
            }}
          >
            <PlusIcon src={plusIcon} alt={"plusIcon"} />
            <ButtonTitle>{t("restore_backups_tab_create_backup")}</ButtonTitle>
          </AddRestoreBackup>
        ) : (
          <>
            <BackupPlansButton
              onClick={() => {
                props.openCreateNewBackup("backup_plans");
              }}
              disabled={false}
            >
              <PlusIcon src={plusIcon} alt={"plusIcon"} />
              <ButtonTitle>
                {t("restore_backups_tab_create_backup_plan")}
              </ButtonTitle>
            </BackupPlansButton>
          </>
        )}
      </RestoreBackupContainer>
    </Container>
  );
};

export default BackupRestoreHeader;

import moment from "moment";
import {useCallback} from "react"

const useDateFormat = () => {
    const format = 'MMM DD YYYY, HH:mm';
    return useCallback((timestamp: number): any => {
        if (typeof timestamp === 'number'){
            return moment.unix(timestamp).format(format);
        }
        return  timestamp;
    },[]);
};

export default useDateFormat;
import React, { useState } from "react";
import {
    ContainerInput,
    InputFieldStyled,
    Container,
    CloseIconStyled,Title
} from "./styles";
import closeIcon from "../../assets/svg/close.svg";

interface InputInterface {
    placeholder: string;
    value: string;
    onChange: (text: string) => void;
    onEnter?: () => void;
    size?: "lg" | "sm";
    onClickHandle?: () => void;
    onClickCloseHandle?: () => void;
    title?: string;
}

const InputField: React.FC<InputInterface> = (props) => {
    const {
        placeholder,
        onChange,
        value,
        onEnter,
        size = "lg",
        onClickHandle,
        onClickCloseHandle,
        title
    } = props;

    const [focused, setFocused] = useState(false);
    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);
    const onClickHandler = () => {
        setFocused(true);
        if (onClickHandle) {
            onClickHandle();
        }
    };


    return (
        <Container size={size}>
           {title &&  <Title>
                <div style={{color:"#FF6600"}}>*</div>{title}
            </Title>}
            <ContainerInput size={size}>
                <InputFieldStyled
                    value={value}
                    placeholder={focused ? "" : placeholder}
                    type={"text"}
                    onChange={(e) => onChange(e.target.value)}
                    onKeyPress={(event) => {
                        if (event.key === "Enter") {
                            event.preventDefault();
                            if (onEnter) {
                                onEnter();
                            }
                        }
                    }}
                    onClick={() => onClickHandler()}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
            </ContainerInput>
        </Container>
    );
};

export default InputField;

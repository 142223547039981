import React, {useState, useEffect, useMemo, useCallback, forwardRef} from 'react';

import{ DateFilterContainer, Title, RowContainer, Text, DatePickerContainer, InputContainer, InputCustomDatePicker, DatePickerCustomInputContainer, DatePickerCustomInputIcon } from "./styles"
import {ClearAllContainer,ClearAllText,RemoveButtonStyled} from "../styles"
import CheckBox from '../../CheckBox/CheckBox';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberInput from '../../NumberInput/InputField';
import SelectDropDown from './Select';
import CalendardateIcon from '../../../assets/svg/calendar_date.svg';
import {useTranslation} from "react-i18next";
interface DateFilterProps {
    accessor: string;
    Header: string;
    types: Array<Record<string,any>>;
    value:any;
    handleColumnFilterValueChange: (id: string, value:any) => void;
    handleResetFilter: (id : string) => void;
}

export enum Time {
    mins  = "1",
    hours = "2",
    days = "3",
    none = ""
}
const DateFilter: React.FC<DateFilterProps> = (props) => {
    const {t} = useTranslation();
    const { accessor, Header, handleColumnFilterValueChange, value, handleResetFilter } = props;
    const [withinLastEnabled, setWithinLastEnabled] = useState<boolean>(value.withinLast ? true : false);
    const [moreThenEnabled, setMoreThenEnabled] = useState<boolean>(value.moreThen ? true : false);
    const [betweenEnabled, setBetweenEnabled] = useState<boolean>(value.between ? true : false);
    const initialStartDate = useMemo(() => value.between ? value.between[0] : new Date(),[value.between]);
    const initialEndDate = useMemo(() => value.between ? value.between[1] : new Date(),[value.between]);
    const [startDate, setStartDate] = useState<Date>(initialStartDate);
    const [endDate, setEndDate] = useState<Date>(initialEndDate);
    const [withinLastValue,setWithinLastValue ] = useState<string>(value.withinLast ? value.withinLast.value : "");
    const [moreThenValue,setMoreThenValue ] = useState<string>(value.moreThen ? value.moreThen.value : "");
    const timeTypes = [{value: Time.mins, label: "Minutes"}, {value: Time.hours, label: "Hours"}, {value: Time.days, label: "Days"}]

    const [withinTimeType, setWithinTimeType] = useState<Time>(value.withinLast ? value.withinLast.type : Time.none);
    const [moreThenType, setMoreThenType] = useState<Time>(value.moreThen ? value.moreThen.type : Time.none);

    const handleWithinTimeChange = (value: string) => {
        setWithinTimeType(value as Time);
    }
    const handleMoreThenTimeChange = (value: string) => {
        setMoreThenType(value as Time);
    }

    const getTimeinMilliSeconds = useCallback((type: Time, value: string) => {
        switch(type){
            case Time.mins:
                return (+value)*60000;
            case Time.hours:
                return (+value)*3600000;
            case Time.days:
                return (+value)*86400000;
            default:
                return 0;
        }
    },[])

    const handleClear = () => {
        handleResetFilter(accessor);
        setWithinLastEnabled(false);
        setMoreThenEnabled(false);
        setBetweenEnabled(false);
        setWithinLastValue("");
        setMoreThenValue("");
        setWithinTimeType(Time.none);
        setMoreThenType(Time.none);
    }

    useEffect(() => {
        const dateFilter = {
            ...(betweenEnabled && {between : [startDate,endDate]}),
            ...(withinLastEnabled && {withinLast : {value: withinLastValue ,msValue: getTimeinMilliSeconds(withinTimeType, withinLastValue), type: withinTimeType }}),
            ...(moreThenEnabled && {moreThen : {value: moreThenValue ,msValue:getTimeinMilliSeconds(moreThenType, moreThenValue), type: moreThenType }}),
        };
        //add debounce here
        handleColumnFilterValueChange(accessor, dateFilter)

    },[accessor, betweenEnabled, endDate, getTimeinMilliSeconds, handleColumnFilterValueChange, moreThenEnabled, moreThenType, moreThenValue, startDate, withinLastEnabled, withinLastValue, withinTimeType])

    const CustomInputDate: any = forwardRef(({ value, onClick, onChange } : any, ref: any) => (
        <DatePickerCustomInputContainer>
            <InputCustomDatePicker
              value={value}
              className="example-custom-input"
              onClick={onClick}
              onChange={onChange}
              ref={ref}
            />
            <DatePickerCustomInputIcon onClick={onClick} src={CalendardateIcon}/>
        </DatePickerCustomInputContainer>
      ));

    return (
        <DateFilterContainer>
            <Title>{Header}</Title>
            <RowContainer>
                <CheckBox isChecked={withinLastEnabled} title={t("within_the_last") || ""} checkHandler={() => setWithinLastEnabled(prev => !prev)} varient='white'/>
                <InputContainer>
                    <NumberInput onChange={(text: string) => setWithinLastValue(text)} value={withinLastValue} placeholder='' size="sm"/>
                </InputContainer>
                <SelectDropDown items={timeTypes} selected={withinTimeType} handleChange={handleWithinTimeChange}/>
            </RowContainer>
            <RowContainer>
                <CheckBox isChecked={moreThenEnabled} title={t("more_then") || ""} checkHandler={() => setMoreThenEnabled(prev => !prev)} varient='white'/>
                <InputContainer>
                    <NumberInput onChange={(text: string) => setMoreThenValue(text)} value={moreThenValue} placeholder='' size="sm"/>
                </InputContainer>
                <SelectDropDown items={timeTypes} selected={moreThenType} handleChange={handleMoreThenTimeChange}/>
                <Text>{t("ago")}</Text>
            </RowContainer>
            <RowContainer>
                <CheckBox isChecked={betweenEnabled} title={t("between") || ""} checkHandler={() => setBetweenEnabled(prev => !prev)} varient='white'/>
                <DatePickerContainer>
                    <DatePicker
                        selected={startDate}
                        onChange={(date : Date) => setStartDate(date)}
                        customInput={<CustomInputDate />}
                    />
                </DatePickerContainer>
                <Text>{t("and")}</Text>
                <DatePickerContainer>
                    <DatePicker
                        selected={endDate}
                        onChange={(date : Date) => setEndDate(date)}
                        customInput={<CustomInputDate />}
                    />
                </DatePickerContainer>
            </RowContainer>




            <ClearAllContainer onClick={handleClear}><ClearAllText>{t("clear all")}</ClearAllText><RemoveButtonStyled /></ClearAllContainer>
        </DateFilterContainer>
    );
};

export default DateFilter;

import styled from "styled-components";
import { DropDownMode, POSITION } from "./types";

const HandleBorderColor = (mode: DropDownMode, varient?: string) => {
    switch (mode) {
        case DropDownMode.disabled:
            return `solid 1px #e4e7eb`;
        case DropDownMode.readonly:
            return `solid 1px #e4e7eb`;
        case DropDownMode.selectable:
            return varient === "smallblue"
                ? "solid 1px #d1d1d1"
                : varient === "yellow-border"
                    ? "solid 1px #f2cc2f"
                    : "solid 1px #6c7481";
    }
};

const HandleTitleColor = (mode: DropDownMode) => {
    switch (mode) {
        case DropDownMode.disabled:
            return `#b9bec6`;
        case DropDownMode.readonly:
            return `#6c7481`;
        case DropDownMode.selectable:
            return "#404d61";
    }
};

const HandleDropDownColor = (
    mode: DropDownMode,
    isPlaceholder?: boolean,
    selected?: boolean
) => {
    if (selected) {
        return "#FF6600";
    }
    if (isPlaceholder) {
        return `#6C7481;`;
    }
    switch (mode) {
        case DropDownMode.disabled:
            return `#b9bec6;`;
        case DropDownMode.readonly:
            return `#6c7481;`;
        case DropDownMode.selectable:
            return "#050709;";
    }
};

export const Container = styled.div<{ Title?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerMulti = styled.div<{ Title?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${(p) => (p.Title ? 4 : 0)}px;
  width: 100%;
`;

export const WithDeleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 50%;
`;

export const TitleSelector = styled.div `
  font-style: normal;
  font-family: ${({ theme: { language } }) =>
    language === "eng" ? "Poppins" : "unset"};
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  /* or 18px */
  display: flex;
  color: #000000;
  padding-bottom: 16px;
`;

export const Title = styled.div<{
    mode: DropDownMode;
    isError?: boolean;
    dir?: string;
}>`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  /* or 15px */
  letter-spacing: -0.02em;
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Poppins" : "unset"};
  overflow: hidden;
  text-align: ${(props) => {
    if (props.theme.dir === "rtl" && props.dir !== "ltr") {
        return "right";
    } else {
        return "left";
    }
}};
  -webkit-line-clamp: 1;
  display: -webkit-box;

  -webkit-box-orient: vertical;
  color: ${(props) =>
    props.isError ? "red": HandleTitleColor(props.mode)};
`;

export const TitleReq = styled.div<{ isError?: boolean; dir?: string }>`
  margin: 0 0 0 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  /* or 15px */
  letter-spacing: -0.02em;
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Poppins" : "unset"};
  text-align: ${(props) => {
    if (props.theme.dir === "rtl" && props.dir !== "ltr") {
        return "right";
    } else {
        return "left";
    }
}};
  color: ${(props) => (props.isError ? "red": "#6c7481")};
`;

export const DropDownContainer = styled.div<{
    isOpen: boolean;
    mode: DropDownMode;
    Placement?: POSITION | undefined;
    isError?: boolean;
    varient?: string;
    width?: number;
}>`
  width: ${(p) => (p.width ? `${p.width}px` : "100%")};
  position: relative;
  box-sizing: border-box;
  cursor: ${(p) =>
    p.mode === DropDownMode.selectable ? "pointer" : "default"};
  background: #F7F5F5;
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 30px 30px ${(props) => (props.isOpen ? "0" : "30")}px ${(props) => (props.isOpen ? "0" : "30")}px; 
`;

export const DropDownHeader = styled.div<{
    mode: DropDownMode;
    varient?: string;
    dir?: string;
}>`
  display: flex;
  flex-direction: row;
  margin: 4px;
  justify-content: space-between;
  align-items: center;
  text-align: ${(props) => {
    if (props.theme.dir === "rtl" && props.dir !== "ltr") {
        return "right";
    } else {
        return "left";
    }
}};
  padding: ${(props) => {
    if (props.theme.dir === "rtl" && props.dir !== "ltr") {
        if (props.varient === "customPadding") {
            return "8px 12px 8px 8px";
        } else {
            return "12px 16px 12px 16px";
        }
    } else {
        if (props.varient === "customPadding") {
            return "8px 8px 8px 12px";
        } else {
            return "12px 16px 12px 16px";
        }
    }
}};

  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  /* or 15px */
  letter-spacing: -0.02em;
  color: #535151;
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Poppins" : "unset"};
  overflow: hidden;
  cursor: ${(p) =>
    p.mode === DropDownMode.selectable ? "pointer" : "default"};
`;

export const DropDownListContainer = styled.div<{
    mode: DropDownMode;
    Placement?: POSITION | undefined;
    width?: number;
    visibileItemsHeight?: number;
    varient?: string;
    isOpen: boolean;
}>`
  width: ${(p) => p.width}px;
  z-index: 2;
  background: white;
  /* ${(props) =>
    props.Placement === POSITION.TOP &&
    `
    transform: translateY(${
        props.visibileItemsHeight ? 200 - props.visibileItemsHeight * 40 : 0
    }px);
  `} */
  height: fit-content;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: ${(p) =>
    p.Placement === POSITION.BOTTOM ? "column" : "column-reverse"};
  border: 1px solid #eeeeee;
  border-radius: ${(props) => (props.isOpen ? "0" : "30")}px ${(props) => (props.isOpen ? "0" : "30")}px 30px 30px ;
  ${(p) =>
    p.Placement === POSITION.BOTTOM
        ? `border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-style: none;`
        : `border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-style: none;`}
`;

export const DropDownList = styled.div<{
    maxHeight?: string;
    varient?: string;
    dir?: string;
}>`
  padding: 0;
  margin: 0;
  direction: ${(props) => (props.dir ? props.dir : props.theme.dir)};
  background: ${(p) => (p.varient === "smallblue" ? "#ecf1f7" : "#ffffff")};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  /* or 15px */
  letter-spacing: -0.02em;
  color: #535151;
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Poppins" : "unset"};
  border-top: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  max-height: ${(props) => props.maxHeight || "200px"};
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 6px;
    background: ${(p) => (p.varient === "smallblue" ? "#ecf1f7" : "#ffffff")};
    margin-right: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #afafaf;
    border-right: 2px white solid;
    background-clip: padding-box;
  }
`;

export const ListItem = styled.div<{
    isSelected: boolean;
    varient?: string;
    scrolled?: boolean;
    dir?: string;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 12px 16px 12px 16px;
  text-align: ${(props) => {
    if (props.theme.dir === "rtl" && props.dir !== "ltr") {
        return "right";
    } else {
        return "left";
    }
}};
  list-style: none;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  /* or 15px */
  letter-spacing: -0.02em;
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Poppins" : "unset"};
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  cursor: pointer;
  color: ${(props) =>
    props.isSelected ? "#FF6600" : "#535151"};
  background: #F7F5F5;

  &:hover {
    background-color: #E6E6E6;
    color: #101010;
  }
`;

export const DropDownText = styled.div<{
    mode: DropDownMode;
    isPlaceholder?: boolean;
    selected?: boolean;
    width?: number;
    dir?: string;
}>`
  outline: none;
  border: none;
  /* overflow: hidden; */
  width: calc(100% - 30px);
  color: ${(props) =>
    HandleDropDownColor(props.mode, props.isPlaceholder, props.selected)};
  user-select: none;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  /* or 15px */
  letter-spacing: -0.02em;
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Poppins" : "unset"};
  /* direction: ltr; */
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: ${(props) => {
    if (props.theme.dir === "rtl" && props.dir !== "ltr") {
        return "right";
    } else {
        return "left";
    }
}};
`;

export const StyledCheckmarkIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export const Header = styled.div<{ hideBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0;
  position: sticky;
  background: #ffffff;
  border-bottom: ${(props) =>
    props.hideBorder ? "none" : "1px solid #c5c9cf"};
  top: 0;
  left: 0;
  right: 0;
`;


export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ExpandImg = styled.img `

`;

import styled, { keyframes } from "styled-components";

import { ReactComponent as ErrorIcon } from "../../assets/svg/input_error.svg";
import { ReactComponent as WarningIcon } from "../../assets/svg/input_warning.svg";

const animation = keyframes`
  from {
    transform: scale(0.90);
  }

  to {
    transform: scale(1);
  }
`;

export const Container = styled.div<{ errorType?: "error" | "warning" }>`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  transform: translate(0, 100%);
  width: 100%;
  bottom: -5px;
  left: 0;
  background: ${(p) =>
    p.errorType === "error" ? "rgb(234, 0, 0)" : "#C9917C"};
  border: ${(p) =>
    p.errorType === "error" ? "2px solid rgb(234, 0, 0)" : "2px solid #C9917C"};
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  animation: ${animation} 0.2s linear;
  padding: 7px 10px;
  z-index: 2;
  color: ${(p) => (p.errorType === "error" ? "#FFFFFF" : "2px solid #535151")};
  /* or 14px */
  display: flex;
  align-items: center;
`;

export const ArrowContainer = styled.div<{ errorType?: "error" | "warning" }>`
  display: block;
  position: absolute;
  transform: translateY(-50%) rotate(45deg);
  top: -1px;
  left: 14px;
  width: 10px;
  height: 10px;
  z-index: 0;
  border-radius: 2px;
  background-color: ${(p) =>
    p.errorType === "error" ? "rgb(234, 0, 0)" : "#C9917C"};
  border-top: ${(p) =>
    p.errorType === "error" ? "2px solid rgb(234, 0, 0)" : "1px solid #C9917C"};
  border-left: ${(p) =>
    p.errorType === "error" ? "2px solid rgb(234, 0, 0)" : "1px solid #C9917C"};
`;
export const IconContainer = styled.div`
  width: 20px;
  height: 20px;
`;
export const ErrorIconStyled = styled(ErrorIcon)``;
export const WarningIconStyled = styled(WarningIcon)``;

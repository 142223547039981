import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#EEEEEE",
    color: "#000000",
    disabledAction : "#BABABA",
    hoverBg: "#1e293a",
    activeBg: "#FF6600",

    selectedItem : {
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "15px",
      textAlign: "left",
      color: "#191C21"
    },
    disabledItem : {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: "300",
      lineHeight: "15px",
      textAlign: "left",
      color: "#BABABA",

    },
    unSelectedItemItem: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "15px",
      textAlign: "left",
      color: "#191C21"
    }
  },
  footer: {
    bg: '#191C21',

  },
  topbar: {
    bg: "#fff",
    color: "#000"
  },
  mainBg: colors.grey["100"]
};

export default colorConfigs;
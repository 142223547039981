export interface ClustersReportReqBody {
    accountId?: string;
    clusterIds?: Array<string>
}
export interface CreateQueryRequest {
    query: string
    clusterId: string
}

export interface QueryResponseItem {
    response: string;
}

export enum Region {
    us_east2 = "us_east2",
    us_east1 = "us_east1"
}
export enum Environment {
    dev = "dev",
    production = "production",
    staging = "staging"
}

export enum Cloud {
    AZURE = "AZURE",
    GOOGLE = "GCP",
    AWS = "AWS",
}

export enum ClusterType {
    small = "small"
}
export interface CreateClusterReqBody {
    allowList: Array<string>,
    cidr: string,
    cloud: Cloud,
    clusterName: string,
    clusterType: ClusterType,
    envPath: string,
    environment: Environment,
    nodeCount: number,
    nodeType: string,
    region: Region
}

export interface GetConfigInterface {
    clusterId: string;
    suppressErrorToast?: boolean

}
import styled from 'styled-components'


export const Container = styled.div`
    width:100%;
    display: flex ;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

   
  
`;

export const Title = styled.div<{isEnabled : boolean}>`
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: ${p => p.isEnabled ? "#FBFBFB" : "#D9D9D9"}
`;
export const ButtonContainer = styled.div<{isEnabled : boolean}>`
  width:91px;
  height: 25px;
  background: ${p => p.isEnabled ? "#5C2EDF" : "#EEEEEE"};
  cursor: ${p => p.isEnabled ? "pointer" : "not-allowed"};
  border-radius: 12.5px;
  display: flex ;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
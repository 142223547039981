import React from "react";
import CheckIcon from '@mui/icons-material/Check';
import {SelectInput, Line, Title, CheckBoxContainer} from "./styles";


interface CheckBoxProps {
    title?: string;
    isChecked: boolean;
    checkHandler: () => void;
    varient?: "orange" | "white"
}
const CheckBox: React.FC<CheckBoxProps> = (props) => {
    const {title, isChecked, checkHandler, varient = "orange" } = props;

    return (
        <CheckBoxContainer>
         <Line onClick={checkHandler}>
          <SelectInput checked={isChecked} varient={varient}>
            {isChecked && (
                <CheckIcon style={{ fontSize: "12px", color: varient === "orange" ?"#ffffff" : "#000000" }} />
            )}
        </SelectInput>
        <li>
            <Title>
                {title}
            </Title>
        </li>
       </Line>
        </CheckBoxContainer>
    );
};

export default CheckBox;

import React from 'react'
import {CloseIconStyled,IconContainer,Item,ItemContainer,ItemText,ResetPasswordIconStyled,menuPopperProps,} from "./styles";
import Popover from '@mui/material/Popover';
import {resetPasswordEmailRequest} from "../../../../store/slices/general";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";


export default function ActionCell(props: any) {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const resetPassword = (email: string) => {
      dispatch(resetPasswordEmailRequest(email));
    };

    return (
        <React.Fragment>
            <IconContainer onClick={handleClick}>
                <CloseIconStyled />
            </IconContainer>
            <Popover
                id={"filter-panel"}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                PaperProps={menuPopperProps}
            >
                <ItemContainer>
                    <Item onClick={() => {resetPassword(props.cell.row.original.email)}}>
                        <ResetPasswordIconStyled />
                        <ItemText>{t("user.profile.reset.password")}</ItemText>
                    </Item>
                    {/*<Item>*/}
                    {/*    <DeleteIconStyled />*/}
                    {/*    <ItemText>Delete</ItemText>*/}
                    {/*</Item>*/}
                </ItemContainer>
            </Popover>
        </React.Fragment>
    )
}

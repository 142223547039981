import styled from "styled-components";

export const Gradient = styled.div `
  height: calc(100vh - 51px);
  display: flex;
  background-image: radial-gradient(farthest-side at 60% 70%, #FE9A2B, #FB7B07, #D44000, #BC3806);
`;

export const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 150px;
  width: 100%;
`;

export const LeftSide = styled.div `
  width: 580px;
`;

export const RightSide = styled.div `
  //width: 614px;
  //height: calc(100% - 50px);
  max-height: 700px;
  border: solid 1px #CC9898;
  border-radius: 40px;
  box-shadow: inset 0 0 10px #CC9898;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const WelcomeTitle = styled.div `
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 119%;
  /* or 65px */

  letter-spacing: -0.02em;

  /* Light Grey */

  color: #EEEEEE;

  text-shadow: 0px 4px 20px rgba(45, 44, 44, 0.15);
`;

export const WelcomeSubTitle = styled.div `
  margin-top: 11px;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 150%;
  color: #EEEEEE;

  text-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
`;

export const WelcomeInfo = styled.div `
  margin-top: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;

  color: #F9F3F3;

  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

export const TitleSignIn = styled.div `
  font-style: normal;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 36px;
  line-height: 56px;
  text-align: center;
  letter-spacing: -0.02em;
  padding: 67px 163px 0 155px; 
  color: #FFFFFF;
`;

export const SubTitleSignIn = styled.div `
  padding: 5px 0 29px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;

  text-align: center;
  letter-spacing: -0.02em;

  color: #FFFFFF;
`;

export const SubmitBtn = styled.button `
  width: 351px;
  margin-top: 38px;
  background: #5C2EDF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
  height: 58px;
  font-family: 'Inconsolata';
  font-weight: 700;
  font-size: 17px;
  line-height: 111%;
  /* identical to box height, or 19px */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Light Grey */

  color: #EEEEEE;
  border: 0px;
  cursor: pointer;
`;

export const Info = styled.div `
  width: 300px;
  height: 37px;
  padding: 38px 0 34px 0;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  text-align: center;
  letter-spacing: -0.02em;

  color: #FFFFFF;

`;

export const ReturnContainer = styled.div `
   display: flex;
   padding: 34px 0 68px 0;
  gap: 2px;
`;

export const Return = styled.div `
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  /* or 112% */

  letter-spacing: -0.02em;
  color: #FFFFFF;
`;

export const ReturnSignIn = styled.div `
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  /* or 112% */

  letter-spacing: -0.02em;
  cursor: pointer;
  color: #5C2EDF;
  text-decoration: underline;
`;

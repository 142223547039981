import styled from "styled-components";

export const Container = styled.div<{
  size?: "lg" | "sm";
}>`
  display: flex;
  flex-direction: column;gap: 4px;
  width: 100%;
  height: ${p => p.size === "lg" ? 58 : 30}px;
`;

export const Title = styled.div `
  font-style: normal;
  font-family: ${({ theme: { language } }) =>
    language === "eng" ? "Poppins" : "unset"};
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  /* or 18px */
  display: flex;
  color: #000000;
  padding-bottom: 10px;
`;

export const ContainerInput = styled.div<{
    size?: "lg" | "sm";
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #EEEEEE;
  border-radius: 30px;
  gap: 12px;
  height: 100%;
  border: 1px solid #EEEEEE;
  padding-left: 19px;
  &:hover,
  &:focus-within {
    border: 1px solid #EEEEEE;
  }
`;

export const IconStyled = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;


export const CloseIconStyled = styled.img`
  cursor: pointer;
  padding: 18px 19px 19px 0px;
`;

export const InputFieldStyled = styled.input `
  outline: none;
  border: none;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  /* identical to box height, or 15px */

  letter-spacing: -0.02em;
  font-stretch: normal;
  font-family: ${({ theme: { language } }) =>
    language === "eng" ? "Poppins" : "unset"};
  background: transparent;
  color: #2C2C2C;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  }
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #888888;
  }
`;

import { RootState } from "../..";

export const selectToken = (state: RootState) => state.general.token;
export const selectUsername = (state: RootState) => state.general.user;
export const selectRoles = (state: RootState) => state.general.roles;
export const selectPath = (state: RootState) => state.general.path;
export const selectExpandSidebar = (state: RootState) =>
  state.general.expandSideBar;
export const selectFullName = (state: RootState) =>
  state.general.decodedToken?.platformUser?.name;
export const selectEmail = (state: RootState) =>
  state.general.decodedToken?.platformUser?.email;
export const selectUserId = (state: RootState) =>
  state.general.decodedToken?.platformUser?.id;
export const selectAccountId = (state: RootState) =>
  state.general.decodedToken?.platformUser?.platformAccount?.id;
export const selectResetPasswordEmailResp = (state: RootState) =>
  state.general.resetPasswordEmailResp;
export const selectResetPasswordTokenResp = (state: RootState) =>
  state.general.resetPasswordTokenResp;
export const selectResetPasswordUserResp = (state: RootState) =>
  state.general.resetPasswordUserResp;
export const selectInviteUserResponse = (state: RootState) =>
  state.general.inviteUserResp;
export const selectRolesMetaData = (state: RootState) =>
  state.general.rolesMetaData;
export const selectCreateNewCluster = (state: RootState) =>
  state.general.createNewClusterResp;
export const selectQueryClusterResponse = (state: RootState) =>
  state.general.queryClusterResponse;
export const selectIsLoading = (state: RootState) => state.general.loading;

export const selectIsQueryLoading = (state: RootState) =>
  state.general.queryLoad;

import styled from "styled-components";
import { ReactComponent as SettingIcon } from "../../../../../../assets/svg/SettingIconTable.svg";
import { ReactComponent as StartIcon } from "../../../../../../assets/svg/Start.svg";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/svg/delete.svg";

export const CloseIconStyled = styled(SettingIcon)`
  cursor: pointer;
  width: 18px;
  height: 18px;
`;

export const StartIconStyled = styled(StartIcon)<{ disabled?: boolean }>`
  width: 14px;
  height: 14px;
  & path {
    fill: ${(p) => (p.disabled ? "#E0E0E0" : "#535151")};
  }
`;
export const DeleteIconStyled = styled(DeleteIcon)`
  width: 14px;
  height: 14px;
`;

export const IconContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
`;
export const ItemContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: 5px 0;
`;

export const Item = styled.div<{ disabled?: boolean }>`
  width: 140px;
  height: 25px;
  display: flex;
  align-items: center;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  padding: 0 5px;
  gap: 5px;
  z-index: 2;
  border-radius: 4px;
  background-color: #ffffff;
  &:hover {
    background: ${(p) => (p.disabled ? "#ffffff" : "#EEEEEE")};
  }
`;
export const menuPopperProps = {
  elevation: 0,
  sx: {
    borderRadius: "6px",
    minWidth: "145px",
    background: "##ffffff",
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 0,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "50%",
      right: -5,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
    "& .MuiList-root": {
      padding: 0,
    },
  },
};

export const ItemText = styled.div<{ disabled?: boolean }>`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: ${(p) => (p.disabled ? "#E0E0E0" : "#535151")};
`;

// useInputHistory.js
import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { createQueryRequest } from "../../../store/slices/general";
import { useLocation } from "react-router";

const useInputHistory = (
  query: string,
  handleFieldChange: (text: string, value: string) => void,
  isLoading: boolean
) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [history, setHistory] = useState<Array<string>>([]);
  const [historyIndex, setHistoryIndex] = useState(-1);

  const handleChange = useCallback(
    (text: string) => {
      handleFieldChange("query", text);
      // Reset history index on user input to allow fresh typing
    },
    [handleFieldChange]
  );

  const onSubmit = useCallback(() => {
    if (isLoading) {
      return;
    }
    dispatch(
      createQueryRequest({ clusterId: location.state.clusterId, query: query })
    );

    if (query && (!history.length || query !== history[history.length - 1])) {
      setHistory((history) => [...history, query]);
    }
    setHistoryIndex(-1);
  }, [dispatch, history, isLoading, location.state.clusterId, query]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      // Navigate up in history
      if (event.key === "ArrowUp") {
        event.preventDefault(); // Prevent cursor movement
        setHistoryIndex((prevIndex) => {
          // Initialize prevIndex to history.length on first use or if it's at the initial state
          const initialIndex = prevIndex === -1 ? history.length : prevIndex;
          // Ensure we don't go beyond the first history item
          const newIndex = Math.max(initialIndex - 1, 0);
          handleFieldChange("query", history[newIndex]);
          return newIndex;
        });
      }
      // Navigate down in history
      else if (event.key === "ArrowDown") {
        event.preventDefault(); // Prevent cursor movement
        setHistoryIndex((prevIndex) => {
          const initialIndex =
            prevIndex === -1 ? history.length - 1 : prevIndex;
          // Ensure the new index doesn't exceed the history bounds
          const newIndex = Math.min(initialIndex + 1, history.length);
          if (newIndex === history.length) {
            // If we've moved past the last history item, clear the input
            handleFieldChange("query", "");
            return -1; // Reset the index
          } else {
            handleFieldChange("query", history[newIndex]);
            return newIndex;
          }
        });
      } else if (event.key === "Enter") {
        event.preventDefault();

        onSubmit();
      }
    },
    [handleFieldChange, history, onSubmit]
  );

  return { handleKeyDown, handleChange, onSubmit };
};

export default useInputHistory;

import { useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestToken, setToken } from "../../store/slices/general";
import { API_URL } from "../../network/client";
import getPkce from 'oauth-pkce';
import { selectRoles, selectToken } from "../../store/slices/general/selectors";
import { RoleTypes } from "../../routes/config";

const generatePKCEPair = async () => {
  return new Promise((resolve, reject) => {
    getPkce(128, (error, { verifier, challenge }) => {
      console.log(verifier, challenge);
      window.localStorage.setItem('pkce', JSON.stringify({verifier, challenge}));
      resolve(true);
    });
  })
}

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const roles = useSelector(selectRoles);

    const navigateToPage = useCallback(() => {
      if (roles && roles.indexOf(RoleTypes.SYSTEM_ADMIN) >= 0){
        navigate('/Admin/Accounts');
      }
      else {
        navigate('/Clusters');
      }
    }, [roles, navigate]);

    useEffect(() => {
      if (token){
        navigateToPage();
      }
    }, [token, navigateToPage]);

    useEffect(() => {
      if (token){
        navigateToPage();
        return;
      }

      const authorize = () => {
        const pkce = JSON.parse(window.localStorage.getItem('pkce')|| '{}');
        if (queryParams.has('jwt')){
          const token = queryParams.get('jwt');
          dispatch(setToken(token));
        }
        else if (queryParams.has('code')) {
          const code = queryParams.get('code');
          dispatch(requestToken({
            code: code,
            verifier: pkce.verifier,
          }));
        }
        else {
          const link = document.createElement('a');
          const url = `${API_URL}/oauth2/authorize?response_type=code&client_id=Regatta-client&scope=openid&redirect_uri=${window.location.origin}/login&code_challenge=${pkce.challenge}&code_challenge_method=S256`
          link.href = url;
          document.body.appendChild(link);
          link.click();
        }
      } 

      const queryParams =  new URLSearchParams(window.location.search.replace('?',''));
      // If Github API returns the code parameter
      
      if(window.localStorage.getItem('pkce') == null){
        generatePKCEPair().then(() => {
          authorize();
        })
      }else {
        authorize();
      }
    }, []);
    return (
        <div>
        </div>
    );
};

export default Login;

import styled from 'styled-components'


export const Container = styled.div`
    width:100%;
    height: 100%;
    display: flex ;
    flex-direction: row;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: -0.02em;
    text-decoration-line: underline;
`;

export const InputContainer = styled.input<{editMode: boolean}>`
    border: ${props => props.editMode ? '' : '0'};
`;


import React, { useMemo } from "react";
import { Cell } from "react-table";
import { Container } from "./styles";
import ToolTipComp from "../../../../Components/Select/ToolTip";

interface Props {
  cell: Cell<Record<string, any>>;
}

// Mapping for days of the week

const daysOfWeek = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};

type DayOfWeek = 1 | 2 | 3 | 4 | 5 | 6 | 7;
function getDayOfWeekText(dayNumber: DayOfWeek) {
  return daysOfWeek[dayNumber];
}

function convertTo12HourFormat(timeString: string) {
  // Split the time string into parts
  const [hours, minutes] = timeString.split(":");

  // Parse hours to integer to perform calculations
  const hoursInt = parseInt(hours, 10);

  // Determine AM or PM
  const amPm = hoursInt >= 12 ? "pm" : "am";

  // Convert hours to 12-hour format
  const twelveHourFormat = hoursInt % 12 || 12;

  // Format the hours with leading zero if needed
  const formattedHours = `0${twelveHourFormat}`.slice(-2);

  // Return formatted time string
  return `${formattedHours}:${minutes}${amPm}`;
}

export const buildTextSchedule = (row: Record<string, any>) => {
  const startTime = convertTo12HourFormat(row.original["startTime"]);
  const timeZone = row.original["timeZone"];
  const dayOfWeek = row.original["dayOfWeek"];
  const dayOfMonth = row.original["dayOfMonth"];

  if (row.original.recurrencePattern === "backup.recurrence.pattern.daily") {
    return "Daily at " + startTime + " " + timeZone;
  } else if (
    row.original.recurrencePattern === "backup.recurrence.pattern.weekly"
  ) {
    return (
      "Weekly on " +
      getDayOfWeekText(dayOfWeek) +
      " at " +
      startTime +
      " " +
      timeZone
    );
  } else if (
    row.original.recurrencePattern === "backup.recurrence.pattern.monthly"
  ) {
    return (
      "Monthly on the " + dayOfMonth + "th at " + startTime + " " + timeZone
    );
  } else {
    return "Monthly on the last day at " + startTime + " " + timeZone;
  }
};

const ScheduleCell: React.FC<Props> = (props) => {
  const { cell } = props;

  const text = useMemo(() => {
    return buildTextSchedule(cell.row);
  }, [cell.row]);

  return (
    <React.Fragment>
      <Container>
        <ToolTipComp title={text} arrow>
          <div
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "100%",
              lineHeight: "1.2",
            }}
          >
            {text}
          </div>
        </ToolTipComp>
      </Container>
    </React.Fragment>
  );
};
export default ScheduleCell;

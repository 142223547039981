import { all } from "redux-saga/effects";
import { generalSaga } from "./general";
import { clusterManagementSaga } from "./clustersManagement";
import { accountsSaga } from "./accounts";
import { usersSaga } from "./users";
import { backRestoreSaga } from "./backupRestore";

export function* rootSaga() {
  yield all([
    generalSaga(),
    clusterManagementSaga(),
    accountsSaga(),
    usersSaga(),
    backRestoreSaga(),
  ]);
}

import React, { useMemo, useState } from "react";
import {
  CloseIconStyled,
  DeleteIconStyled,
  IconContainer,
  Item,
  ItemContainer,
  ItemText,
  StartIconStyled,
  menuPopperProps,
} from "./styles";
import Popover from "@mui/material/Popover";
import { Cell } from "react-table";
import { StatusType } from "../Status/types";
import {
  destroyCluster,
  startCluster,
  stopCluster,
} from "../../../../../../store/slices/clusterManagement";
import { useDispatch } from "react-redux";
import AlertDialogClusterDelete from "../../../../Components/DeleteClusterModal";
import { useTranslation } from "react-i18next";
interface ActionAdminListHeaderProps {
  cell: Cell<Record<string, any>>;
}

const Action: React.FC<ActionAdminListHeaderProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const disabledStart = useMemo(
    () => props.cell.row.original.status !== StatusType.Stopped,
    [props.cell.row.original.status]
  );
  const disabledStop = useMemo(
    () => props.cell.row.original.status !== StatusType.Running,
    [props.cell.row.original.status]
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickStart = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (!disabledStart) {
      console.log("start action");
    }
  };

  const open = Boolean(anchorEl);

  const handleDestroyCluster = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(destroyCluster({ clusterId: props.cell.row.original.clusterId }));
    setIsDeleteModalOpen(false);
  };

  const handleOpenDeleteModal = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setIsDeleteModalOpen(true);
    //close popup
    setAnchorEl(null);
  };

  const handleCloseDeleteModal = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setIsDeleteModalOpen(false);
  };

  const handleStart = () => {
    dispatch(startCluster({ clusterId: props.cell.row.original.clusterId }));
  };

  const handleStop = () => {
    dispatch(stopCluster({ clusterId: props.cell.row.original.clusterId }));
  };

  return (
    <React.Fragment>
      <IconContainer onClick={handleClick}>
        <CloseIconStyled />
        <AlertDialogClusterDelete
          validateName={props.cell.row.original.clusterName}
          isOpen={isDeleteModalOpen}
          handleClose={handleCloseDeleteModal}
          handleConfirm={handleDestroyCluster}
        />
      </IconContainer>
      <Popover
        id={"filter-panel"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        PaperProps={menuPopperProps}
      >
        <ItemContainer>
          <Item onClick={handleStart} disabled={disabledStart}>
            <StartIconStyled disabled={disabledStart} />
            <ItemText disabled={disabledStart}>
              {t("clusters_list_start_action")}
            </ItemText>
          </Item>
          <Item onClick={handleStop} disabled={disabledStop}>
            <StartIconStyled disabled={disabledStop} />
            <ItemText disabled={disabledStop}>
              {t("clusters_list_stop_action")}
            </ItemText>
          </Item>
          <Item onClick={handleOpenDeleteModal}>
            <DeleteIconStyled />
            <ItemText>{t("table_delete_action")}</ItemText>
          </Item>
        </ItemContainer>
      </Popover>
    </React.Fragment>
  );
};
export default Action;

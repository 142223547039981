import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Response } from "../types/Response";

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  suppressErrorToast?: boolean;
}

export const API_URL =
  process.env.REACT_APP_API_ENDPOINT || "https://app.regatta.dev";

const commonHeaders = {
  "Content-Type": "application/JSON",
  withCredentials: false,
  Accept: "application/json",
};

export const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: commonHeaders,
});

const client = {
  get: <T>(
    path: string,
    headers?: any,
    idToken?: string,
    suppressErrorToast?: boolean
  ): Promise<AxiosResponse<T>> => {
    const configs: CustomAxiosRequestConfig = {
      // baseURL: API_URL,
      headers: idToken
        ? { ...(headers || {}), Authorization: idToken }
        : { ...(headers || {}) },
      suppressErrorToast: suppressErrorToast,
    };
    return axiosInstance.get<T>(path, configs);
  },
  post: <T>(
    path: string,
    body: any,
    headers?: any,
    idToken?: string
  ): Promise<AxiosResponse<T>> => {
    const configs: AxiosRequestConfig = {
      baseURL: API_URL,
      headers: idToken
        ? { ...(headers || {}), Authorization: idToken }
        : { ...(headers || {}) },
    };
    return axiosInstance.post<T>(path, JSON.stringify(body), configs);
  },
  postToken: <T>(
    path: string,
    body: any,
    headers?: any
  ): Promise<AxiosResponse<T>> => {
    const configs: AxiosRequestConfig = {
      baseURL: API_URL,
      headers: {
        ...headers,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    return axiosInstance.post<T>(path, body, configs);
  },
  patch: <T>(
    path: string,
    body: any,
    headers?: any,
    idToken?: string
  ): Promise<AxiosResponse<T>> => {
    const configs: AxiosRequestConfig = {
      baseURL: API_URL,
      headers: idToken
        ? { ...(headers || {}), Authorization: idToken }
        : { ...(headers || {}) },
    };
    return axiosInstance.patch<T>(path, JSON.stringify(body), configs);
  },
  delete: <T>(
    path: string,
    body: any,
    headers?: any,
    idToken?: string
  ): Promise<AxiosResponse<T>> => {
    const configs: AxiosRequestConfig = {
      baseURL: API_URL,
      headers: idToken
        ? { ...(headers || {}), Authorization: idToken }
        : { ...(headers || {}) },
    };
    return axiosInstance.delete<T>(path, {
      ...configs,
      data: JSON.stringify(body),
    });
  },
  put: <T>(
    path: string,
    body: any,
    headers?: any,
    idToken?: string
  ): Promise<AxiosResponse<T>> => {
    const configs: AxiosRequestConfig = {
      baseURL: API_URL,
      headers: idToken
        ? { ...(headers || {}), Authorization: `Bearer ${idToken}` }
        : { ...(headers || {}) },
    };
    return axiosInstance.put<T>(path, JSON.stringify(body), configs);
  },
};
export const run = async <T>(
  request: Promise<AxiosResponse<T>>
): Promise<Response<T>> => {
  try {
    const response = await request;
    if (response.status >= 200 && response.status < 400) {
      return {
        data: response.data,
        success: true,
        code: response.status,
      };
    }
    return {
      message: response.statusText,
      success: false,
      code: response.status,
    };
  } catch (error: any) {
    return {
      // @ts-ignore
      message: error.message,
      success: false,
      code: error?.response?.status,
      error: error?.response?.data?.error || { ...error?.response?.data },
    };
  }
};

export const GET = <T>(
  path: string,
  headers = {},
  idToken?: string,
  suppressErrorToast?: boolean
) => run<T>(client.get<T>(path, headers, idToken, suppressErrorToast));

export const POST = <T>(
  path: string,
  body: unknown,
  headers = {},
  idToken?: string
) => run<T>(client.post<T>(path, body, headers, idToken));

export const POST_TOKEN = <T>(path: string, body: any, headers: any = {}) =>
  run<T>(client.postToken<T>(path, body, headers));

export const PATCH = <T>(
  path: string,
  body: unknown,
  headers = {},
  idToken?: string
) => run<T>(client.patch<T>(path, body, headers, idToken));

export const DELETE = <T>(
  path: string,
  body?: unknown,
  headers = {},
  idToken?: string
) => run<T>(client.delete<T>(path, body, headers, idToken));

export const PUT = <T>(
  path: string,
  body: unknown,
  headers = {},
  idToken?: string
) => run<T>(client.put<T>(path, body, headers, idToken));


export default client;

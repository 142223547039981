import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { ItemType } from "../../../Components/FilterHeader/types";
import { selectAccountsData } from "../../../store/slices/accounts/selectors";
import { BEResponse } from "../../../types/BEResponse";
// import ActionCell from "../CustomCells/ActionCell";
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';
import { CellWrapper } from '../styles';
import useDateFormat from "../../../hooks/useDateFormatHook";
import {Row, IdType} from "react-table"


const useTable = () => {

    const data: BEResponse = useSelector(selectAccountsData);
    const { t } = useTranslation();
    const formatDate = useDateFormat();
    const AccountTypes : Array<{label: string, value: string}> = useMemo(() => [{value: "account.type.premium", label: "Premuim"}],[]);
    const columns = useMemo(
        () => [
            {
                Header: 'Account Name',
                accessor: 'name',
                type: ItemType.text,
                allowFilter: true,
                ellipsis: true,
                
            },
            {
                Header: 'Account Type',
                accessor: 'type',
                type: ItemType.types,
                types: AccountTypes,
                filter: 'type',
                Cell: (cell : Cell) => {
                    return <CellWrapper>{t(cell.value)}</CellWrapper>;
                },
                allowFilter: true,
            },
            {
                Header: 'Creation Date',
                accessor: 'creationTime',
                type: ItemType.date,
                filter: 'date',
                Cell: (cell : Cell) => {
                    return <CellWrapper>{formatDate(cell.value)}</CellWrapper>;
                },
                allowFilter: true,
                width: 200,
            },
            // {
            //     Header: 'Action',
            //     accessor: 'Action',
            //     type: ItemType.text,
            //     disableSortBy: true,
            //     allowFilter: false,
            //     width: 60,
            //     Cell: ActionCell
            // },
        ],
        [AccountTypes, formatDate, t]
    );

    const globalFilterFunction = useCallback(
        (rows: Row<any>[], ids: IdType<any>[], query: string, ...props : any) => {
            console.log("global props any", props);
            return rows.filter(row => {
                const rowValueName = row.values["name"];
                const rowValueType = row.values["type"];
                const rowValueDate = formatDate(row.values["creationTime"]);
                const nameFilter =  rowValueName !== undefined
                ? String(rowValueName)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true

                const rowValueTypeLabel = AccountTypes.find(elem => elem.value === rowValueType)?.label;
                const typeFilter =  rowValueTypeLabel !== undefined
                ? String(rowValueTypeLabel)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true

                const DateFilter =  rowValueDate !== undefined
                ? String(rowValueDate)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true
                  return nameFilter || typeFilter || DateFilter;
              });

        },
        [AccountTypes, formatDate],
    );

    const initialState: any = useMemo(() => ({
        sortBy: [
            {
                id: 'creationTime',
                desc: true
            }
        ]
    }), []);
    
    
    return {
        initialState,
        columns, 
        data: data.data,
        globalFilterFunction
    };
};

export default useTable;
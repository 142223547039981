import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
    useMemo,
} from "react";
import {
    Container,
    TitleSelector,
    TitleReq,
    Header,
    DropDownContainer,
    DropDownHeader,
    DropDownText,
    DropDownListContainer,
    DropDownList,
    ListItem,
    StyledCheckmarkIcon,
    IconsContainer, ExpandImg,
} from "./styles";
import { DropDownMode, DropDownSelectInterface, Item, POSITION } from "./types";
import checkmark from "../../assets/svg/checkmark.svg";
import CloseIcon from "../../assets/svg/notification_active.svg";
import ToolTip from "./ToolTip";
import useOnClickOutside from "./useOnClickOutside";
import {PositioningPortal} from "@codastic/react-positioning-portal/lib/legacy/index.js";
import expand from "../../assets/svg/expand.svg";
import {useTranslation} from "react-i18next";

const Select: React.FC<DropDownSelectInterface> = (props) => {
    const {
        placeholder,
        onSelect,
        required,
        TitleText,
        items,
        selectedItem,
        isError,
        dropDownListHeight,
        varient = "",
        allowEmptySelect = true,
        showXtoUnselect = false,
        icon,
        dir,
        showAsterisk = true,
    } = props;
    const mode = props.mode ?? DropDownMode.selectable;
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selected, setselected] = useState<Item | undefined>(selectedItem);
    const [searchInput, setsearchInput] = useState("");
    const [itemsResult, setitemsResult] = useState<Item[]>([]);
    const refDropDown = useRef<HTMLDivElement>(null);
    const container = useRef<HTMLDivElement>(null);
    const [strategyPlacment, setStrategyPlacment] = useState<POSITION>(
        POSITION.BOTTOM
    );
    const [itemScrolled, setItemScrolled] = useState<number>();
    const [textWidths, setTextWidths] = useState<number[]>([]);
    const searchable =
        props.searchable ?? (props.items.length > 5 ? true : false);

    const placeHolderSelector = useMemo<Item>(
        () => ({ label: placeholder, value: -1 }),
        [placeholder]
    );

    const refList = useRef<HTMLDivElement[]>([]);

    //document.onkeydown = checkKey;
    const onArrowClick = useCallback(
        (arrow: string) => {
            console.log("arrow ", arrow);
            console.log("itemScrolled ", itemScrolled);
            console.log("itemsResult.length ", itemsResult.length);
            if (itemScrolled !== undefined) {
                if (arrow === "Up" && itemScrolled > 0) {
                    setItemScrolled(itemScrolled - 1);
                    //to scroll to item
                    if (
                        refList.current[itemScrolled - 1] !== null &&
                        refList.current[itemScrolled - 1] !== undefined
                    ) {
                        refList.current[itemScrolled - 1].scrollIntoView({
                            behavior: "smooth",
                        });
                    }
                } else if (arrow === "Down" && itemScrolled < itemsResult.length - 1) {
                    //to move to next item
                    setItemScrolled(itemScrolled + 1);
                    //to scroll to item
                    if (
                        refList.current[itemScrolled + 1] !== null &&
                        refList.current[itemScrolled + 1] !== undefined
                    ) {
                        refList.current[itemScrolled + 1].scrollIntoView({
                            behavior: "smooth",
                        });
                    }
                }
            }
        },
        [itemScrolled, itemsResult.length]
    );

    const checkKey = useCallback(
        (e: any) => {
            e = e || window.event;

            if (e.keyCode == "38") {
                // up arrow
                if (onArrowClick) {
                    onArrowClick("Up");
                }
            } else if (e.keyCode == "40") {
                // down arrow
                if (onArrowClick) {
                    onArrowClick("Down");
                }
            } else if (e.keyCode == "37") {
                // left arrow
            } else if (e.keyCode == "39") {
                // right arrow
            } else if (e.keyCode == "13") {
                // enter arrow
                if (itemScrolled !== undefined) {
                    setItemScrolled(undefined);
                    ItemClicked(itemsResult[itemScrolled]);
                }
            }
        },
        [onArrowClick]
    );

    useEffect(() => {
        if (isOpen) {
            document.addEventListener("keydown", checkKey);
        }
        return () => {
            document.removeEventListener("keydown", checkKey);
        };
    }, [checkKey, isOpen]);

    const onInputClickHandle = () => {
        //when at search input then show item hover to start use arrow
        if (allowEmptySelect) {
            setItemScrolled(1);
        } else {
            setItemScrolled(0);
        }
    };
    //DEBOUNCE NOT WORKING IN ANGULARJS!!!!!!!
    // const debouncedSearch = useDebounce((searchValue : string) => setitemsResult(items.filter(elem => elem.label.includes(searchValue))), 600);

    useEffect(() => {
        let itemsUpdated = [];
        if (allowEmptySelect) {
            itemsUpdated = [
                placeHolderSelector,
                ...items.filter((elem) =>
                    elem.label
                        .toLocaleLowerCase()
                        .includes(searchInput.toLocaleLowerCase())
                ),
            ];
        } else {
            itemsUpdated = items.filter((elem) =>
                elem.label.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
            );
        }

        setitemsResult(itemsUpdated);
    }, [allowEmptySelect, items, placeHolderSelector, searchInput]);

    useEffect(() => {
        setselected(selectedItem);
    }, [selectedItem]);

    const toggling = () => {
        //clean search to enable open again
        setsearchInput("");
        if (itemsResult.length > 0) {
            if (mode === DropDownMode.selectable) setIsOpen((prev) => !prev);
        } else {
            setIsOpen(false);
        }
    };

    const ItemClicked = (item: Item) => {
        setIsOpen(false);
        if (allowEmptySelect) {
            if (selected?.value === item.value) {
                onSelect(undefined);
                setselected(undefined);
            } else {
                if (item.value !== -1) {
                    onSelect(item);
                    setselected(item);
                } else {
                    onSelect(undefined);
                    setselected(undefined);
                }
            }
        } else {
            if (item.value !== null) {
                onSelect(item);
                setselected(item);
            } else {
                onSelect(undefined);
                setselected(undefined);
            }
        }

        setsearchInput("");
    };

    const unselectItem = (e: any) => {
        e.stopPropagation();
        onSelect(undefined);
        setselected(undefined);
    };

    const onEnterSearch = () => {
        setselected(itemsResult[0]);
        setIsOpen(false);
        setsearchInput("");
    };

    const positionStrategy = useCallback(
        (preferredPosition: POSITION) => (parentRect: any, portalRect: any) => {
            const scrollX = window.scrollX || window.pageXOffset;
            const scrollY = window.scrollY || window.pageYOffset;
            const body = window.document.documentElement || window.document.body;

            const horizontalCenter = (parentRect.width - portalRect.width) / 2;
            const additionalPadding = 0;

            const positions = {
                [POSITION.BOTTOM]: {
                    position: POSITION.BOTTOM,
                    top: parentRect.top + scrollY + additionalPadding,
                    left: parentRect.left + scrollX + horizontalCenter,
                    enoughSpace:
                        parentRect.top + portalRect.height + additionalPadding <
                        body.clientHeight,
                },
                [POSITION.TOP]: {
                    position: POSITION.TOP,
                    top:
                        parentRect.top -
                        portalRect.height +
                        parentRect.height +
                        scrollY -
                        additionalPadding,
                    left: parentRect.left + scrollX + horizontalCenter,
                    enoughSpace:
                        parentRect.top - portalRect.height - additionalPadding > 0,
                },
            };

            // Horizontal fallback preferred
            let sortedPositions = [
                positions[preferredPosition],
                positions[POSITION.BOTTOM],
                positions[POSITION.TOP],
            ];

            const pickedPosition =
                sortedPositions.find(({ enoughSpace }) => enoughSpace) ||
                positions[preferredPosition];

            const finalTop = Math.max(
                Math.min(
                    pickedPosition.top,
                    body.clientHeight + scrollY - portalRect.height
                ),
                scrollY
            );
            const shiftY = Math.max(
                Math.min(
                    finalTop - pickedPosition.top,
                    portalRect.height / 2 - additionalPadding
                ),
                portalRect.height / -2 + additionalPadding
            );

            const finalLeft = Math.max(
                Math.min(
                    pickedPosition.left,
                    body.clientWidth + scrollX - portalRect.width
                ),
                scrollX
            );
            const shiftX = Math.max(
                Math.min(
                    finalLeft - pickedPosition.left,
                    portalRect.width / 2 - additionalPadding
                ),
                portalRect.width / -2 + additionalPadding
            );

            const demensions = {
                top: Math.max(
                    Math.min(
                        pickedPosition.top,
                        body.clientHeight + scrollY - portalRect.height
                    ),
                    scrollY
                ),
                left: Math.max(
                    Math.min(
                        pickedPosition.left,
                        body.clientWidth + scrollX - portalRect.width
                    ),
                    scrollX
                ),
                strategy: {
                    position: pickedPosition.position,
                    shift:
                        pickedPosition.position === "top" ||
                        pickedPosition.position === "bottom"
                            ? shiftX
                            : shiftY,
                },
            };
            setStrategyPlacment(demensions.strategy.position);
            return demensions;
        },
        []
    );

    useEffect(() => {
        const Texts = itemsResult.map((elem) => elem.label);
        const widths = Texts.map((elem) => {
            const item = document.createElement("div");
            item.style.cssText = `  outline: none;
    border: none;
    overflow: hidden;
    user-select: none;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    white-space: nowrap;
    text-overflow: ellipsis;`;
            item.innerText = elem;
            document.body.appendChild(item);
            const itemWidth = item.getBoundingClientRect().width;
            document.body.removeChild(item);
            return itemWidth;
        });
        setTextWidths(widths);
    }, [itemsResult]);

    const ref = useRef<HTMLDivElement | null>(null);

    const onClickOutside = useCallback(() => {
        setIsOpen(false);
    }, []);

    //close dropdown container inside a modal component on click outside
    useOnClickOutside(ref, onClickOutside);

    const transformPositionDependsOnItemsShows = (len: number) => {
        switch (len) {
            case 0:
                return 160;
            case 1:
                return 120;
            case 2:
                return 80;
            case 3:
                return 40;
            default:
                return 0;
        }
    };

    return (
        <Container ref={container} Title={TitleText}>
            {TitleText &&  <TitleSelector>
                <div style={{color:"#FF6600"}}>{showAsterisk ? "*" : ""}</div>
                {TitleText}
            </TitleSelector>}
            {/*<Title mode={mode} isError={isError} dir={dir}>*/}
            {/*    {TitleText}*/}
            {/*</Title>*/}
            <PositioningPortal
                positionStrategy={positionStrategy(POSITION.BOTTOM)}
                isOpen={isOpen}
                // rootNode={getPortalContainer()}
                portalElement={
                    <div
                        style={{
                            position: "absolute",
                            zIndex: 999999999,
                        }}
                    />
                }
                onShouldClose={() => {
                    setIsOpen(false);
                    //clean search to enable open again
                    setsearchInput("");
                }}
                portalContent={({relatedWidth}) => (
                    <div
                        style={{
                            display: "flex",
                            flexDirection:
                                strategyPlacment === POSITION.BOTTOM
                                    ? "column"
                                    : "column-reverse",
                            transform:
                                strategyPlacment === POSITION.TOP
                                    ? `translateY(${transformPositionDependsOnItemsShows(
                                        itemsResult.length - 1
                                    )}px)`
                                    : `translateY(0)`,
                        }}
                        ref={ref}
                    >
                        <DropDownContainer
                            Placement={strategyPlacment}
                            varient={varient}
                            isOpen={isOpen}
                            mode={mode}
                            isError={isError}
                            width={relatedWidth}
                        >
                            <DropDownHeader
                                onClick={toggling}
                                mode={mode}
                                varient={varient}
                                dir={dir}
                            >
                                <DropDownText
                                    // width={Width}
                                    mode={mode}
                                    isPlaceholder={!selected?.label}
                                    dir={dir}
                                >
                                    {selected ? selected.label : placeholder}
                                </DropDownText>
                                <React.Fragment>
                                    {showXtoUnselect &&
                                    allowEmptySelect &&
                                    mode === DropDownMode.selectable &&
                                    selected && (
                                        <StyledCheckmarkIcon
                                            title={t("select_close") || ""}
                                            src={CloseIcon}
                                            onClick={(e) => unselectItem(e)}
                                        />
                                    )}
                                    <IconsContainer>
                                        {icon}
                                        <ExpandImg src={expand} alt={"expand"}/>
                                    </IconsContainer>
                                </React.Fragment>
                            </DropDownHeader>
                        </DropDownContainer>
                        <DropDownListContainer
                            varient={varient}
                            // Placement={strategy ? strategy.position : undefined}
                            width={relatedWidth}
                            mode={mode}
                            ref={refDropDown}
                            visibileItemsHeight={Math.min(itemsResult.length, 5)}
                            isOpen={isOpen}
                        >
                            {searchable && (
                                <Header>
                                </Header>
                            )}
                            <DropDownList
                                maxHeight={dropDownListHeight}
                                varient={varient}
                                dir={dir}
                            >
                                {itemsResult.map((option: Item, index: number) => (
                                    <ListItem
                                        ref={(el) => el && (refList.current[index] = el)}
                                        varient={varient}
                                        onClick={() => ItemClicked(option)}
                                        isSelected={
                                            selected ? option.value === selected.value : false
                                        }
                                        key={index}
                                        scrolled={index === itemScrolled}
                                        dir={dir}
                                    >
                                        {textWidths[
                                            itemsResult.findIndex(
                                                (elem) => elem.value === option.value
                                            )
                                            ] >
                                        relatedWidth - 70 ? (
                                            <ToolTip title={option.label} placement="top-start">
                                                <DropDownText
                                                    mode={mode}
                                                    selected={selected && option.value === selected.value}
                                                    width={relatedWidth}
                                                    dir={dir}
                                                >
                                                    {option.childComponent
                                                        ? option.childComponent
                                                        : option.label}
                                                </DropDownText>
                                            </ToolTip>
                                        ) : (
                                            <DropDownText
                                                mode={mode}
                                                selected={selected && option.value === selected.value}
                                                width={relatedWidth}
                                                dir={dir}
                                            >
                                                {option.childComponent
                                                    ? option.childComponent
                                                    : option.label}
                                            </DropDownText>
                                        )}

                                        {selected && option.value === selected.value && (
                                            <StyledCheckmarkIcon
                                                src={checkmark}
                                                alt="checkmark-icon"
                                            />
                                        )}
                                    </ListItem>
                                ))}
                            </DropDownList>
                        </DropDownListContainer>
                    </div>
                )}
            >
                <div>
                    <DropDownContainer
                        Placement={strategyPlacment}
                        varient={varient}
                        // position={strategy ? strategy.position : undefined}
                        isOpen={isOpen}
                        mode={mode}
                        isError={isError}
                    >
                        <DropDownHeader
                            onClick={toggling}
                            mode={mode}
                            varient={varient}
                            dir={dir}
                        >
                            <DropDownText
                                // width={Width}
                                mode={mode}
                                isPlaceholder={!selected?.label}
                                dir={dir}
                            >
                                {selected ? selected.label : placeholder}
                            </DropDownText>
                            <React.Fragment>
                                {showXtoUnselect &&
                                allowEmptySelect &&
                                mode === DropDownMode.selectable &&
                                selected && (
                                    <StyledCheckmarkIcon
                                        title={t("select_close") || ""}
                                        src={CloseIcon}
                                        onClick={(e) => unselectItem(e)}
                                    />
                                )}
                                <IconsContainer>
                                    {icon}
                                    <ExpandImg src={expand} alt={"expand"}/>
                                </IconsContainer>
                            </React.Fragment>
                        </DropDownHeader>
                    </DropDownContainer>
                </div>
            </PositioningPortal>
            {required && mode === DropDownMode.selectable && (
                <TitleReq isError={isError} dir={dir}>
                    * {t("select_required")}
                </TitleReq>
            )}
        </Container>
    );
};

export default Select;

import { RootState } from "../..";

export const selectBackups = (state: RootState) =>
  state.backupRestore.backups.data;

export const selectBackupPlans = (state: RootState) =>
  state.backupRestore.backupPlans.data;

export const selectTabSelected = (state: RootState) =>
  state.backupRestore.tabSelected;

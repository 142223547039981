import { useCallback, useEffect, useMemo, useState } from "react";
import { ItemType } from "../Components/FilterHeader/types";

interface Column {
    Header: string;
    accessor: string;
    type: ItemType;
    allowFilter?: boolean
    types?: Array<Record<string,any>>;
}
interface FilterSearchInterface {
    Header: string;
    accessor: string;
    type: ItemType;
    allowFilter?: boolean
    types: Array<Record<string,any>>;
    value: string | (Array<string>) | (Record<string,any>);
    enabled: boolean;
}

const useFilterSearch = (columns : Array<Column>) => {

    const [filterSearchCols, setFilterSearchCols] = useState<Record<string,FilterSearchInterface>>({});
    const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

    const GetInitialValue = useCallback((col: Column) => {
        console.log("col", col);
        switch(col.type){
            case ItemType.text:
                return "";
            case ItemType.types:
                return col.types? col.types.map(elem => String(elem.value)) : [];
            case ItemType.date:
                return {};
        }
    },[]);

    //remove all filters
    const handleResetAllFilters = useCallback(() => {
        setFilterSearchCols(columns.reduce((accumulator, col) => {
            if(col.allowFilter){
                return {...accumulator, [col.accessor]: {...col,type: col.type, enabled: false, value: GetInitialValue(col)}};
            }
           return accumulator;
        }, {}))
    },[GetInitialValue, columns]);

    useEffect(() => {
        handleResetAllFilters();
    },[handleResetAllFilters])

    //reset values but keep filter enabled
    const handleResetFilter = useCallback((id : string) => {
        setFilterSearchCols(prev => ({...prev , [id]: {...prev[id], enabled: true, value: GetInitialValue(prev[id])}}))
    },[GetInitialValue]);

    //reset value and removes the filter
    const handleRemoveFilter = useCallback((id : string) => {
        setFilterSearchCols(prev => ({...prev , [id]: {...prev[id], enabled: false, value: GetInitialValue(prev[id])}}))
    },[GetInitialValue]);


    //on filter enable/disable
    const handleColumnCheckChange = useCallback((id : string) => {
        setFilterSearchCols(prev => ({...prev , [id]: {...prev[id], enabled: !prev[id].enabled}}))
    },[]);

    //changing and value inside the filter
    const handleColumnFilterValueChange = useCallback((id : string, value : string | Array<string> | Record<string,any>) => {
        setFilterSearchCols(prev => ({...prev , [id]: {...prev[id], value: value}}))
    },[]);


    //table filter for it's state
    const tableFilters = useMemo(() => {
        const filters : Array<Record<string, any>> = [];
        Object.entries(filterSearchCols).forEach(([id,values]) => {
            if(values.enabled){
                filters.push({id: id, value: values.value})
            }
        });
        console.log("filters table" ,filters);
        return filters;

    },[filterSearchCols]);

    const handleGlobalFilterChange = (value: string) => {
        setGlobalFilterValue(value);
    }
    return {filterSearchCols,
            handleColumnCheckChange,
            handleResetAllFilters,
            handleResetFilter,
            handleColumnFilterValueChange,
            tableFilters,
            handleRemoveFilter,
            handleGlobalFilterChange,
            globalFilterValue}

};

export default useFilterSearch;

import { Avatar, Drawer, List, Stack, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import expandIcon from "../../assets/svg/sidebar_expand.svg";
import collapseIcon from "../../assets/svg/sidebar_collapse.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectExpandSidebar } from "../../store/slices/general/selectors";
import { toggleSideBar } from "../../store/slices/general";

const Sidebar = () => {
  const expand = useSelector(selectExpandSidebar);
  const dispatch = useDispatch();
  const toggleSideBarLocal = () => {
    dispatch(toggleSideBar());
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: expand ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.collapsedWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: expand ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.collapsedWidth,
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color,
          top: sizeConfigs.header.height,
          height: `calc(100% - ${sizeConfigs.footer.height} - ${sizeConfigs.header.height})`
        }
      }}
    >
      <Avatar
        onClick={toggleSideBarLocal}
        sx={{position: 'absolute', right: 0, top: '23px',cursor: 'pointer',zIndex: 1}}
        src={expand ? collapseIcon : expandIcon} alt={"toggle"} variant="square"/>
      <List sx={{paddingTop : '50px',paddingLeft: expand ? '30px' : 0}}>
        {appRoutes.map((route, index) => (
          route.sidebarProps ? (
            route.child ? (
              <SidebarItemCollapse item={route} key={index} />
            ) : (
              <SidebarItem item={route} key={index} />
            )
          ) : null
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;

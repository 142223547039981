import styled from "styled-components";

interface IInput {
    backgroundColor: string;
    borderColor?: string;
    colorText: string;
    isAble: boolean;
    width?: number;
    isNotEmail?: boolean
}

export const InputContainer = styled.div `
  width: 100%;
`;

export const InputForm = styled.form `
  width: 100%;
  position: relative;
`;

export const ContainerInput = styled.div<{
    size?: "lg" | "sm"; isNotEmail: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({isNotEmail}) => isNotEmail ? "4" : "1"}px solid ${({isNotEmail}) => isNotEmail ? "#EA4100" : "#EEEEEE"};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  height: 100%;
  //background: linear-gradient(174.06deg, #EEEEEE 20.86%, #FFFFFF 87.44%, #FFFFFF 131.09%);
  background: #ffffff;

  padding-left: 19px;
  &:hover,
  &:focus-within {
    /* border: 1px solid #EEEEEE; */
  }
`;

export const Input = styled.input<IInput>`
  display: flex;
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Poppins" : "unset"};
  background: transparent;
  outline: none;
  border: none;
  height: 56px;
  width: 100%;


  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  }
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #888888;
  }
`;

export const Title = styled.div<{padding?: boolean, signInScreen?: boolean}> `
  font-style: normal;
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Poppins" : "unset"};
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  /* or 18px */
  display: flex;
  color: ${(props) => (props.signInScreen ? "#FFFFFF" : "#000000")};
  padding-bottom: ${(props) => (props.padding ? "10" : "0")}px;
`;

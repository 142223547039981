import { useCallback, useState } from "react";
import { BEErrorResponse } from "../types/BEResponse";

const useFieldsErrorHook = () => {
    const [fieldsErrors,setFieldsErrors] = useState<any>({});
    const updateErrorField = useCallback((errors: BEErrorResponse[]) => {
        const newTempData = {...fieldsErrors};
        let changed = false;
        if (errors && errors.length > 0) {
            errors.forEach((error: BEErrorResponse) => {
                if (error.field) {
                    newTempData[error.field] = error.reason || error.message;
                    changed = true;
                }
            })
        }
        if (changed) {
            setFieldsErrors(newTempData);
        }
    },[fieldsErrors]);
    return {
        fieldsErrors,
        updateErrorField
    }
};

export default useFieldsErrorHook;
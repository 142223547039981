import styled from "styled-components";

export const Container = styled.div `

`;

export const LineTitle = styled.div `
  /* Main Orange */
  width: 535px;
  height: 0;
  border: 3px solid #FF6600;
  border-radius: 5px;
`;

export const CreatContainer = styled.div `
  margin-top: 26px;
  height: calc(100vh - 294px);

  background: #FFFFFF;
  border-radius: 10px;
  padding: 49px 0 0 56px;
`;

export const InputContainer = styled.div `
  display: flex;
  justify-content: left;
  padding: 11px 0 0 0;
  width: 468px;
`;

export const SelectContainer = styled.div `
   margin-top: 37px;
   width: 468px;
`;

export const Title = styled.div `

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  /* or 18px */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  color: #FF6600;
`;

export const LineBottom = styled.div `
border: 1px solid rgba(183, 185, 187, 0.3);
transform: matrix(1, 0, 0, -1, 0, 0);
`;


export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  padding: 34px 0 0 0;
`;

export const ButtonsContainer = styled.div `
  display: flex;
  align-items: center;
`;

export const CancelButton = styled.div `
  /* Link & Button - Inconsolata Bold 17 - 10% Kerning */
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 111%;
  /* identical to box height, or 19px */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #BABABA;

  margin-right: 13px;
  cursor: pointer;
`;

export const BottomWrapperNew = styled.div`
    width: 100%;
      padding: 10px;
      left: 0;
      bottom: 0px;
      position: absolute;
      background: #ffffff ;
`;

import React, { FC, useEffect, useState } from "react";
import {InputContainer, InputForm, Title, Input, ContainerInput} from "./styles";
import {CloseIconStyled} from "../InputField/styles";
import closeIcon from "../../assets/svg/close.svg";
import InputError from "../InputError";

interface IProps {
    value: string;
    placeholder: string;
    status: "editable" | "readonly" | "disable";
    onChange: (text: string) => void;
    isRequired: boolean;
    title?: string;
    error?: string;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    width?: number;
    isDisabled?: boolean;
    errorType?: "error" | "warning";
    disableCopyPaste?: boolean;
    signInScreen?: boolean;
    showAsterisk?: boolean;
    readonly?: boolean;
}

const colorBackground = {
    editable: "white",
    readonly: "#fafafa",
    disable: "white",
};

const colorBorder = {
    editable: "#6c7481",
    readonly: "#e4e7eb",
    disable: "#e4e7eb",
};

const colorText = {
    editable: "#050709",
    readonly: "#6c7481",
    disable: "#b9bec6",
};

const EmailInput: FC<IProps> = ({
                                        value,
                                        status,
                                        onChange,
                                        isRequired,
                                        title,
                                        placeholder,
                                        error = "",
                                        errorType = "error",
                                        onBlur,
                                        onFocus,
                                        width,
                                        isDisabled,
                                        disableCopyPaste = false,
                                        signInScreen= false,
                                        showAsterisk = true,
                                        readonly = false,
                                    }) => {
    const [isActive, setIsActive] = useState(false);
    const [ph, setPh] = useState<string>();
    const [isTouch, setIsTouch] = useState(false);
    const [isLeave, setIsLeave] = useState(false);
    const [isNotEmail, setIsNotEmail] = useState(false);
    const [email, setEmail] = useState("");
    const reg = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    const emailPattern: RegExp = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;


    useEffect(() => {
        if (isActive) {
            setPh(undefined);
        } else {
            setPh(placeholder);
        }
    }, [isActive, placeholder]);

    const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
        setEmail(e.target.value);
        setIsNotEmail(false);
    };

    const onFocusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsTouch(true);
        setIsLeave(false);
        setIsActive(true);
        if (!onFocus) return;
        onFocus(e);
    };

    const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsLeave(true);
        setIsActive(false);
        if (emailPattern.test(email)) {
            setIsNotEmail(false);
        } else {
            setIsNotEmail(true);
        }
        if (!onBlur) return;
        onBlur(e);
    };

    const onClickClose = () => {
        onChange("");
    };

    return (
        <InputContainer>
            <InputForm>
                <Title padding={title !== ""} signInScreen={signInScreen}>
                    <div style={{color:(signInScreen ? "#ffffff" : "#FF6600")}}>{showAsterisk ? "*" : ""}</div>{title}
                </Title>
           <ContainerInput isNotEmail={isNotEmail}>
            <Input
                type="email"
                readOnly={readonly}
                disabled={isDisabled}
                colorText={colorText[status]}
                backgroundColor={colorBackground[status]}
                isNotEmail={isNotEmail && isLeave}
                isAble={status === "editable"}
                value={value}
                placeholder={ph}
                onChange={onChangeText}
                onBlur={onBlurHandler}
                onFocus={onFocusHandler}
                onWheel={(event) => event.currentTarget.blur()}
                width={width}
                onPaste={(e) => {
                    if (disableCopyPaste) {
                        e.preventDefault();
                        return false;
                    }
                    return true;
                }}
                onCopy={(e) => {
                    if (disableCopyPaste) {
                        e.preventDefault();
                        return false;
                    }
                    return true;
                }}
            />
                {
                    readonly || isDisabled ?
                        <></>
                        :
                       <CloseIconStyled src={closeIcon} alt={"closeIcon"} onClick={() => onClickClose()}/>
                }
            </ContainerInput>
            <InputError message={error} errorType={errorType}/>
          </InputForm>
        </InputContainer>
    );
};

export default EmailInput;

import React from 'react';
import {
    ItemContainer,
    RemoveButtonStyled,
    Text,
    menuPopperProps,
    ArrowDownStyled
} from "./styles";
import Popover from '@mui/material/Popover';

import { ItemType, FilterHeaderItemProps } from "../types";
import TextFilter from '../TextFilter';
import CheckBoxFilter from '../CheckBoxFilter';
import DateFilter from "../DateFilter"
const FilterHeaderItem: React.FC<FilterHeaderItemProps> = (props) => {
    const { accessor, Header, type, handleRemoveFilter } = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleRemove = (e:  React.MouseEvent<HTMLOrSVGElement>) => {
        e.stopPropagation();
        handleRemoveFilter(accessor)
    }
    return (
        <React.Fragment>
            <ItemContainer onClick={handleClick}>
                <ArrowDownStyled />
                <Text >{Header}</Text>
                <RemoveButtonStyled onClick={handleRemove} />
            </ItemContainer>
             <Popover
                id={"filter-panel"}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={menuPopperProps}
            >
              {type === ItemType.text  &&   <TextFilter {...props}/>}
              {type === ItemType.types  &&   <CheckBoxFilter {...props} />}
              {type === ItemType.date  &&   <DateFilter {...props} />}

            </Popover>
        </React.Fragment>
    );
};

export default FilterHeaderItem;

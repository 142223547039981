import React, { useEffect, useMemo, useRef, useState } from 'react';
// @ts-ignore
import ignoreCase from 'ignore-case';
import { Styles } from './styles'
import closeIcon from "../../assets/svg/close.svg";
import InputError from "../InputError";
import {
    ContainerInput,
    Container,
    CloseIconStyled, Title,
} from "./styles";
export enum KeyEnum {
    TAB = 'Tab',
    ENTER = 'Enter',
    ARROW_UP = 'ArrowUp',
    ARROW_DOWN = 'ArrowDown',

}

export interface Props {
    value?: string;
    dataSource: any[];
    className?: string;
    style?: React.CSSProperties;
    placeholder?: string;
    disabled?: boolean;
    navigate?: boolean;
    caseSensitive?: boolean;
    onBlur?: () => void;
    onFocus?: () => void;
    onChange?: (value: string) => void;
    onPressEnter?: () => void;
    onKeyDown?: (e: any) => void;
    onSelect?: (item: DataSourceItem) => void;
    size?: "lg" | "sm";
    onClickCloseHandle?: () => void;
    title?: string;
    showAsterisk?: boolean;
    required?: boolean;
    signInScreen?: boolean;
    error?: string;
    errorType?: "error" | "warning";
    readonly?: boolean;
}

export interface DataSourceItem {
    text: string;
    value: string | number;
    [key: string]: any;
}



const Autocomplete: React.ForwardRefRenderFunction<HTMLInputElement, Props> = (props, ref) => {
    const {
        value,
        dataSource,
        className,
        navigate = true,
        caseSensitive = true,
        onBlur,
        onFocus,
        onChange,
        onPressEnter,
        onSelect,
        disabled,
        onKeyDown,
        placeholder,
        size = "lg",
        onClickCloseHandle,
        title,
        error,
        showAsterisk = true,
        signInScreen = false,
        errorType = "error",
        ...others
    } = props;
    const [innerVal, setInnerVal] = useState('');
    const [matchedDataSource, setMatchedDataSource] = useState<DataSourceItem[]>();
    const [activeIndex, setActiveIndex] = useState(0);
    const [substr, setsubstr] = useState("");
    const ctrlValue = value ?? innerVal;

    /**
     * inputRef
     */
    const inputRef = useRef<HTMLInputElement>();
    React.useImperativeHandle(ref, () => inputRef.current!);

    const updateValue = (value: string) => {
        onChange && onChange(value);
        setInnerVal(value);
    };

    const updateMatchedDataSource = (value?: string) => {
        setActiveIndex(0);



        let text = (value || "")

        let substrings: any[] = [];
        for (let start = 0; start < text.length; start++) {
            // Extract the substring from 'start' position to the end of the string
            let substring = text.substring(start);
            // Add the substring to the array
            substrings.push(substring);
        }

        // console.log("substrings", substrings)

        let matcharray: any[] = [];
        for (let i = 0; i < text.length; i++) {
            const substring = substrings[i];
            matcharray = dataSource.filter(({ text }) =>
                caseSensitive
                    ? text.startsWith(substring) && text !== substring
                    : ignoreCase.startsWith(text, substring) && !ignoreCase.equals(text, substring)

            )
            if (matcharray.length) {
                setsubstr(substring)
                break;
            }

        }
        setMatchedDataSource(matcharray)

    };

    /**
     * InputChange Handler
     * @param e
     */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        updateValue(value);
        updateMatchedDataSource(value);
    };

    /**
     * KeyDown Handler
     * deal with `Tab` | `Enter` | `ArrowUp` | `ArrowDown`
     * @param e
     */
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (Object.values(KeyEnum).includes(e.key as KeyEnum)) {
            e.preventDefault();
        }


        switch (e.key) {
            case "ArrowRight":
                const matchedDataSourceItem = matchedDataSource?.[activeIndex];
                if (!matchedDataSourceItem) return;

                /**
                 * onChange >>> onSelect >>> Search matched item
                 */
                const { text } = matchedDataSourceItem;
                let longestMatch = 0;
                for (let i = 0; i <= ctrlValue.length; i++) {
                    const substr = ctrlValue.substring(i);


                    if (caseSensitive ? text.startsWith(substr) : text.toLowerCase().startsWith(substr.toLowerCase())) {
                        longestMatch = substr.length;
                        break; // Found the longest match at the end of ctrlValue
                    }
                }

                const newValue = ctrlValue.substring(0, ctrlValue.length - longestMatch) + text;

                // Update the value with this new concatenated string
                updateValue(newValue);
                onSelect && onSelect(matchedDataSourceItem);
                updateMatchedDataSource(text);
                break;
            case KeyEnum.ENTER:
                /**
                 * onPressEnter >>> Reset
                 */
                onPressEnter && onPressEnter();
                updateMatchedDataSource();
                break;
            case KeyEnum.ARROW_UP:
                if (!navigate) break;

                let index = 0;
                if (matchedDataSource?.length) {
                    index = (activeIndex - 1 + matchedDataSource.length) % matchedDataSource.length;
                    setActiveIndex(index);
                } else {
                    onKeyDown && onKeyDown(e)
                }


                break;
            case KeyEnum.ARROW_DOWN:
                if (!navigate) break;

                let index2 = 0;
                if (matchedDataSource?.length) {
                    index2 = (activeIndex + 1) % matchedDataSource.length;
                    setActiveIndex(index2);
                } else {
                    onKeyDown && onKeyDown(e)
                }


                break;
            default:
                break;
        }
    };



    const completeContent = useMemo(() => {
        const selectedItemText = matchedDataSource?.[activeIndex]?.text;
        if (!selectedItemText || !ctrlValue) {
            return undefined;
        }

        let matchIndex = -1;
        // Start with the longest possible substring of ctrlValue and look for a match at the end.
        for (let i = 0; i < ctrlValue.length; i++) {
            const substr = ctrlValue.substring(i);
            // Check if selectedItemText starts with this substring.
            // Adjust case sensitivity as needed.
            if (caseSensitive ? selectedItemText.startsWith(substr) : selectedItemText.toLowerCase().startsWith(substr.toLowerCase())) {
                matchIndex = i;
                break; // Found the longest matching substring at the end of ctrlValue.
            }
        }

        if (matchIndex !== -1) {
            // Found a match, append unmatched part of selectedItemText to the original ctrlValue.
            const unmatchedPart = selectedItemText.substring(ctrlValue.substring(matchIndex).length);
            return ctrlValue + unmatchedPart;
        }

        // If no matching part is found, perhaps return the whole selectedItemText or undefined based on your needs.
        return undefined;

    }, [activeIndex, caseSensitive, ctrlValue, matchedDataSource])



    const onClickClose = () => {
        if (onClickCloseHandle) {
            onClickCloseHandle();
        }
    };


    useEffect(() => {
        if (!disabled) {
            inputRef.current?.focus();
        }
    }, [disabled])
    // console.log("substr", substr)
    return (
        <Container>
            {title && <Title signInScreen={signInScreen}>
                <div style={{ color: (signInScreen ? "#ffffff" : "#FF6600") }}>{showAsterisk ? "*" : ""}</div>{title}
            </Title>}
            <ContainerInput message={error} size={size}>
                <Styles hideComplete={ctrlValue === ""}>
                    <div className={"ria-wrap"}>
                        <input
                            ref={inputRef as any}
                            className={"ria-input"}
                            value={ctrlValue}
                            placeholder={placeholder}
                            type="text"
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onChange={handleChange}
                            disabled={disabled}
                            onKeyDown={handleKeyDown}
                            {...others}
                        />
                        <div className={"ria-complete"}>{completeContent}</div>
                    </div>
                </Styles>
                <CloseIconStyled src={closeIcon} alt={"closeIcon"} onClick={() => onClickClose()} />
            </ContainerInput>
            <InputError message={error} errorType={errorType} />
        </Container>
    );
};

const RefAutoComplete = React.forwardRef<HTMLInputElement, any>(Autocomplete);
export default RefAutoComplete;
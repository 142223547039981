import React from 'react'
import { DeleteIconStyled, ActionContainer} from "./styles";
import { Cell } from 'react-table';

interface IPCellProps {
    cell : Cell
    deleteData: any;
  }

const ActionCell : React.FC<IPCellProps> = (props) => {
    const {deleteData, cell} = props;

    const handleDelete = () => {
        deleteData(cell.row.index);
    }
    return (
        <React.Fragment>
            <ActionContainer>
                <DeleteIconStyled onClick={handleDelete}/>
            </ActionContainer>
        </React.Fragment>
)
}
export default ActionCell;
import styled from "styled-components";
import sizeConfigs from "../../configs/sizeConfigs";

export const TableContainer = styled.div `
padding: 20px 0 0 20px;
  margin: 230px 0 0 0;
`;

export const OutletContainer = styled.div<{path: string}> `
  height: calc(100vh - ${sizeConfigs.footer.height} - ${sizeConfigs.header.height});
  padding: ${props => props.path === 'clustersCLi' ? '' : '29px 33px 39px 38px'};
  position: relative;
`;

import styled from "styled-components";
export const Container = styled.div `
 //padding: 29px 27px 0 37px;
`;

export const LineTitle = styled.div `
  /* Main Orange */
  width: 70px;
  height: 0;
  border: 3px solid #FF6600;
  border-radius: 5px;
`;

export const AccountLinesContainer = styled.div `
   display: flex;
  width: 100%;
  height: 0;
  align-items: center;
  //margin-bottom: 107px;
  margin-bottom: 28px;
  padding-top: 2px;
`;

export const UserContainer = styled.div `
  height: calc(100vh - 245px);
  position: relative ;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 18px 16px 0 58px;
  margin-right: 6px;
  padding-bottom: 100px;
  overflow: auto ;
`;

export const InputContainer = styled.div `
  display: flex;
  justify-content: left;
  padding-top: 28px;
  width: 468px;
`;

export const EmailContainer = styled.div `
  display: flex;
  justify-content: left;
  padding: 39px 0 33px 0;
  width: 468px;
`;

export const UserButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  padding: 34px 0 0 0;
`;

export const SaveUserButton = styled.div<{disabled?: boolean}> `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 17px 26px;
  gap: 10px;
  cursor: pointer;
  height: 53px;

  background: ${props => props.disabled ? '#E0E0E0' : '#FB5E03'};
  border-radius: 26.5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const CloseUserProfileContainer = styled.div `
  justify-content: flex-end;
  display: flex;
  top: 13px;
  right: 13px;
`;

export const ResetPasswordContainer = styled.div `
  justify-content: flex-end;
  display: flex;
  padding-bottom: 27px;
`;

export const ResetPasswordButton = styled.div `

  /* Link & Button - Inconsolata Bold 17 - 10% Kerning */

  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 111%;
  /* identical to box height, or 19px */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #FBFBFB;

  background: #5C2EDF;
  border-radius: 26.5px;
  cursor: pointer;
  padding: 17px 26px;
  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const BottomWrapperNew = styled.div`
      width: 100%;
      padding: 10px;
      left: 0;
      bottom: 0px;
      position: absolute;
      background: #ffffff ;
     
`;

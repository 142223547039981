import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  UsersManagementHeaderContainer,
  UsersManagementTableContainer,
  TableContainer,
  TabsContainer,
  TabButton,
} from "./styles";
import BackupRestoreHeader from "../../Components/BackupRestoreHeader/BackupRestoreHeader";
import useFilterSearch from "../../hooks/useFilterSearch";
import TableTest from "../../Components/Table2";
import useTable from "./hooks/useTable";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectResetPasswordEmailResp } from "../../store/slices/general/selectors";
import useToast from "../../hooks/useToast";
import { resetPasswordSentSuccessfully } from "../../store/slices/general";
import {
  RestoreBackupTabEnum,
  getBackupPlansData,
  getBackupsData,
  setTabSelected,
} from "../../store/slices/backupRestore";
import { selectTabSelected } from "../../store/slices/backupRestore/selectors";

interface RefType {
  getCheckboxRecords: any;
}

const RestoreBackup = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef<HTMLInputElement>(null);
  const tableRef = useRef<RefType>(null);
  const tabSelected = useSelector(selectTabSelected);
  const [headerHeight, setHeaderHeight] = useState<any>(250);
  const {
    backupColumns,
    backupData,
    backupPlansColumns,
    backupPlanData,
    globalFilterFunctionBackups,
    globalFilterFunctionBackupPlans,
    backupPlanInitialState,
  } = useTable();
  const {
    filterSearchCols,
    handleColumnCheckChange,
    handleResetAllFilters,
    handleResetFilter,
    handleColumnFilterValueChange,
    tableFilters,
    handleRemoveFilter,
    globalFilterValue,
    handleGlobalFilterChange,
  } = useFilterSearch(
    tabSelected === RestoreBackupTabEnum.BACKUP
      ? backupColumns
      : backupPlansColumns
  );

  const refapi = useRef<any>(null);

  useEffect(() => {
    dispatch(getBackupsData({}));
    dispatch(getBackupPlansData({}));
    refapi.current = setInterval(() => {
      if (tabSelected === RestoreBackupTabEnum.BACKUP) {
        dispatch(getBackupsData({}));
      } else if (tabSelected === RestoreBackupTabEnum.PLANS) {
        dispatch(getBackupPlansData({}));
      }
    }, 5 * 1000);

    return () => {
      if (refapi.current) {
        clearInterval(refapi.current);
      }
    };
  }, [dispatch, tabSelected]);

  const openCreateNewBackup = useCallback(
    (type: "backup" | "backup_plans") => {
      console.log("type", type);
      navigate(type === "backup" ? "/create-backup" : "/create-backup-plan");
    },
    [navigate]
  );

  //   useEffect(() => {
  //     if (
  //       resetPasswordEmailResp &&
  //       resetPasswordEmailResp &&
  //       resetPasswordEmailResp.httpStatusCode === 200
  //     ) {
  //       toast.success(
  //         resetPasswordEmailResp.reason || resetPasswordEmailResp.message || ""
  //       );
  //       dispatch(resetPasswordSentSuccessfully({}));
  //     }
  //   }, [dispatch, resetPasswordEmailResp, toast]);

  return (
    <Container>
      <UsersManagementTableContainer>
        <UsersManagementHeaderContainer ref={ref}>
          <BackupRestoreHeader
            openCreateNewBackup={openCreateNewBackup}
            handleRemoveFilter={handleRemoveFilter}
            filterSearchCols={filterSearchCols}
            handleColumnCheckChange={handleColumnCheckChange}
            handleResetAllFilters={handleResetAllFilters}
            handleColumnFilterValueChange={handleColumnFilterValueChange}
            handleResetFilter={handleResetFilter}
            backupTab={tabSelected === RestoreBackupTabEnum.BACKUP}
            globalFilterValue={globalFilterValue}
            handleGlobalFilterChange={handleGlobalFilterChange}
          />
          <TabsContainer>
            <TabButton
              selected={tabSelected === RestoreBackupTabEnum.BACKUP}
              onClick={() => {
                dispatch(setTabSelected(RestoreBackupTabEnum.BACKUP));
              }}
            >
              {t("restore_backups_tab_backup")}
            </TabButton>
            <TabButton
              selected={tabSelected === RestoreBackupTabEnum.PLANS}
              onClick={() => {
                dispatch(setTabSelected(RestoreBackupTabEnum.PLANS));
              }}
            >
              {t("restore_backups_tab_plans")}
            </TabButton>
          </TabsContainer>
        </UsersManagementHeaderContainer>

        <TableContainer height={headerHeight}>
          {tabSelected === RestoreBackupTabEnum.BACKUP && (
            <TableTest
              tabContainer={true}
              globalFilterValue={globalFilterValue}
              globalFilterFunction={globalFilterFunctionBackups}
              canSelect={false}
              data={backupData}
              columns={backupColumns}
              size="small"
              filters={tableFilters}
            />
          )}
          {tabSelected === RestoreBackupTabEnum.PLANS && (
            <TableTest
              tabContainer={true}
              ref={tableRef}
              tableInitialState={backupPlanInitialState}
              globalFilterValue={globalFilterValue}
              globalFilterFunction={globalFilterFunctionBackupPlans}
              data={backupPlanData}
              columns={backupPlansColumns}
              size="small"
              filters={tableFilters}
            />
          )}
        </TableContainer>
      </UsersManagementTableContainer>
    </Container>
  );
};

export default RestoreBackup;

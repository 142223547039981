import React, {useEffect,} from "react";
import {Container} from "./styles";

interface ClusterQueryOutputProps {
    value: JSX.Element[];
}

const ClusterQueryOutput: React.FC<ClusterQueryOutputProps> = ({ value }) => {
    useEffect(() => {
    }, [value]);

    return (
        <Container>
            {value}
        </Container>
    );
};

export default ClusterQueryOutput;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import auth from "../../../apis/auth";
import { axiosInstance } from "../../../network/client";
import { Cloud } from "../../../types/clusterManagement";

export interface UsersState {
  clusters: any;
  destroyCluster: any;
  connectCluster: any;
  createCluster: any;
  clusterRegions: any;
  clusterTypes: any;
  deleteTrustedIPs: any;
  addTrustedIPs: any;
  clusterTrustedIps: any;
  configuration: any;
}

const initialState: UsersState = {
  clusters: { data: [], isLoading: false, isError: false },
  destroyCluster: null,
  connectCluster: null,
  createCluster: { data: null, isLoading: false, isError: false },
  clusterRegions: { data: null, isLoading: false, isError: false },
  clusterTypes: { data: null, isLoading: false, isError: false },
  deleteTrustedIPs: null,
  addTrustedIPs: null,
  clusterTrustedIps: { data: null, isLoading: false, isError: false },
  configuration: {},
};

const usersSlice = createSlice({
  name: "clustermanagement",
  initialState,
  reducers: {
    getClusterManagementData: (state, action) => {
      state.clusters.isLoading = true;
      state.clusters.isError = false;
      // state.clusters.data = null;
    },
    getClusterManagementDataDone: (state, action) => {
      state.clusters.isLoading = false;
      state.clusters.isError = false;
      state.clusters.data = action.payload;
    },
    getClusterManagementDataError: (state) => {
      state.clusters.isLoading = false;
      state.clusters.isError = true;
      state.clusters.data = null;
    },
    destroyCluster(state, action) {
      state.destroyCluster = null;
    },
    destroyClusterDone(state, action) {
      state.destroyCluster = action.payload;
    },
    deleteTrustedIPsRequest(state, action) {
      state.deleteTrustedIPs = null;
    },
    deleteTrustedIPsDone(state, action) {
      state.deleteTrustedIPs = action.payload;
    },
    connectCluster(state, action) {
      state.connectCluster = null;
    },
    connectClusterDone(state, action) {
      state.connectCluster = action.payload;
    },
    createCluster(state, action) {
      state.createCluster.isLoading = true;
      state.createCluster.isError = false;
      state.createCluster.data = null;
    },
    createClusterDone(state, action) {
      state.createCluster.isLoading = false;
      state.createCluster.isError = false;
      state.createCluster.data = action.payload;
    },
    createClusterError(state) {
      state.createCluster.isLoading = false;
      state.createCluster.isError = true;
      state.createCluster.data = null;
    },
    getClusterRegions(state) {
      state.clusterRegions.isLoading = true;
      state.clusterRegions.isError = false;
      state.clusterRegions.data = null;
    },
    getClusterRegionsDone(state, action) {
      state.clusterRegions.isLoading = false;
      state.clusterRegions.isError = false;
      state.clusterRegions.data = action.payload;
    },
    getClusterRegionsError(state) {
      state.clusterRegions.isLoading = false;
      state.clusterRegions.isError = true;
      state.clusterRegions.data = null;
    },
    getClusterTypes(state) {
      state.clusterTypes.isLoading = true;
      state.clusterTypes.isError = false;
      state.clusterTypes.data = null;
    },
    getClusterTypesDone(state, action) {
      state.clusterTypes.isLoading = false;
      state.clusterTypes.isError = false;
      state.clusterTypes.data = action.payload;
    },
    getClusterTypesError(state) {
      state.clusterTypes.isLoading = false;
      state.clusterTypes.isError = true;
      state.clusterTypes.data = null;
    },
    getClusterTrustedIps(state, action) {
      state.clusterTrustedIps.isLoading = true;
      state.clusterTrustedIps.data = null;
    },
    getClusterTrustedIpsDone(state, action) {
      state.clusterTrustedIps.isLoading = false;
      state.clusterTrustedIps.data = action.payload;
    },
    addTrustedIPs(state, action) {
      state.addTrustedIPs = null;
    },
    addTrustedIPsDone(state, action) {
      state.addTrustedIPs = action.payload;
    },
    getConfig(state, action) {
      state.configuration = {};
    },
    getConfigDone(state, action) {
      state.configuration = action.payload;
    },
    startCluster(state, action) {},
    startClusterDone(state, action) {},
    stopCluster(state, action) {},
    stopClusterDone(state, action) {},
  },
});

export const {
  getClusterManagementData,
  getClusterManagementDataDone,
  getClusterManagementDataError,
  destroyCluster,
  destroyClusterDone,
  connectCluster,
  connectClusterDone,
  createClusterError,
  createClusterDone,
  createCluster,
  getClusterRegions,
  getClusterRegionsDone,
  getClusterRegionsError,
  getClusterTypes,
  getClusterTypesDone,
  getClusterTypesError,
  deleteTrustedIPsRequest,
  deleteTrustedIPsDone,
  getClusterTrustedIps,
  getClusterTrustedIpsDone,
  addTrustedIPs,
  addTrustedIPsDone,
  getConfig,
  getConfigDone,
  startCluster,
  startClusterDone,
  stopCluster,
  stopClusterDone,
} = usersSlice.actions;

export default usersSlice;

import React, {useMemo} from 'react'
import {Container, ButtonContainer, Title} from "./styles"
import { Cell } from 'react-table';

import { selectClusterTypes } from '../../../../../../store/slices/clusterManagement/selectors';
import { useSelector } from 'react-redux';
import ToolTipComp from '../../../../../../Components/Select/ToolTip';
import { useTranslation } from 'react-i18next';
interface ConnectAdminListHeaderProps {
  cell: Cell<Record<string, any>>
}


const ClusterType: React.FC<ConnectAdminListHeaderProps> = (props) => {

  const {cell} = props;
  const {t}= useTranslation();
  const types = useSelector(selectClusterTypes)
  const tooltipText = useMemo(() => types? types.find((elem: {type: string, description: string}) => elem.type === cell.value)?.description : "",[cell.value, types]);

  
  return (
    <React.Fragment>
      <Container>
        <ToolTipComp title={t(tooltipText)} arrow>
         <div>{cell.value}</div>
         </ToolTipComp>
      </Container>
    </React.Fragment>
  )
}
export default ClusterType;

import { ReactNode } from "react";

export type RouteType = {
  element: ReactNode,
  state: string,
  index?: boolean,
  path?: string,
  topBarName?: string,
  child?: RouteType[],
  roles?: RoleTypes[],
  sidebarProps?: {
    disabled?: boolean,
    displayText: string,
    icon?: ReactNode;
    selectedIcon?: ReactNode;
    unAvailableIcon?: ReactNode;
    roles?: RoleTypes[],
  };
};

export enum RoleTypes {
  SYSTEM_ADMIN = "SYSTEM_ADMIN",
  ADMIN = "ADMIN",
}
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Container,
  Body,
  OverviewContainer,
  OverviewTitle,
  ConnectButton,
  SecurityTitle,
  TableTrustIPContainer,
  AzureIcon,
  AmazonIcon,
  OverviewDataContainer,
  OverviewTitleContainer,
  Title,
  OverviewBodyContainer,
  OverviewBodyItem,
  GoogleIcon,
  VPCInputContainer,
  TitleInput,
} from "./styles";
import BreadCrumbs from "../../../Components/BreadCrubms";
import { useTranslation } from "react-i18next";
import { TabButton, TabsContainer } from "../../UsersManagement/styles";
import TableTest from "../../../Components/Table2";
import useTable from "./hooks/useTable";
import {
  connectCluster,
  createCluster,
  getClusterTrustedIps,
  addTrustedIPs,
  getClusterManagementData,
  getConfig,
} from "../../../store/slices/clusterManagement";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import {
  AddButtonContainer,
  AddIconStyled,
  AddIpIconText,
  IPInputContainer,
  TabsContentContainer,
  TrustedIPAddContainer,
  TrustIPSHeader,
} from "../CreateNewCluster/styles";
import azureIcon from "../../../assets/svg/azure_enabled.svg";
import amazonIcon from "../../../assets/svg/amazon_logo.svg";
import googleIcon from "../../../assets/svg/google_enabled.svg";

import ProviderAction from "./CustomCells/Action/providerAction";
import InputField from "../../../Components/InputField/InputField";
import {
  selectClusterTrustedIps,
  selectClusterTypes,
  selectExternalIP,
} from "../../../store/slices/clusterManagement/selectors";
import ToolTip from "../../../Components/Select/ToolTip/index";
import useData from "../CreateNewCluster/hooks/useData";
import { useForm } from "react-hook-form";
import CheckBox from "../../../Components/CheckBox/CheckBox";
import { Cloud } from "../../../types/clusterManagement";

enum CreateNewClusterTabEnum {
  EXTERNALIPS,
  TRUSTEDIPS,
  VPC,
}

interface ClustersListProps {}

const isValidIP = (ip: string) => {
  const octet = "(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|0)";
  const regex = new RegExp(`^${octet}\\.${octet}\\.${octet}\\.${octet}$`);
  return regex.test(ip);
};

const ClustersList: React.FC<ClustersListProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabSelected, setTabSelected] = useState<CreateNewClusterTabEnum>(
    CreateNewClusterTabEnum.EXTERNALIPS
  );
  const tableRef = useRef(null);
  const { trustedIPSColumns, ipInput, handleIpChange, ipError, setIpError } =
    useTable(tableRef);
  const [data, setData] = useState<any>(location.state);
  const trustedIPSData = useSelector(selectClusterTrustedIps);
  const clusterTypes = useSelector(selectClusterTypes);

  // const {  getValues } =  useForm({
  //     mode: "onChange",
  //     defaultValues : {
  //         cloud: "AZURE",
  //         name: '',
  //         region: '',
  //         type: '',
  //         account: '',
  //         nodeNumber: '',
  //         nodeType: '',
  //     }
  // });
  // const {cloud } = getValues();
  // const { TypeOptions } = useData(cloud);

  console.log("clusterTypes ", clusterTypes);
  console.log("location.state ", location.state);

  const connect = () => {
    if (data.status === "RUNNING") {
      navigate(`/CLI/${data.clusterName}`, {
        state: data,
      });
    }
  };

  useEffect(() => {
    if (data.clusterId) {
      dispatch(getClusterTrustedIps(data.clusterId));
    }
  }, [data, dispatch]);

  const handleAddIP = useCallback(() => {
    if (isValidIP(ipInput) && data.clusterId) {
      // tableRef.current.handleAddRow({ip: ipInput});
      dispatch(addTrustedIPs({ clusterId: data.clusterId, ips: [ipInput] }));
      handleIpChange("");
    } else {
      setIpError("Ip is not valid!");
    }
  }, [data.clusterId, dispatch, ipInput, setIpError]);

  const getDescription = (clusterType: string) => {
    const description = clusterTypes.find(
      (elem: { type: string; description: string }) => elem.type === clusterType
    )?.description;

    // TypeOptions.forEach((elem: any) => {
    //     if(elem.ClusterType === clusterType){
    //         description = elem.description;
    //     }
    // });
    return description;
  };

  const rdbConfig = useSelector(selectExternalIP);
  useEffect(() => {
    //get external ips ports
    dispatch(
      getConfig({ clusterId: data.clusterId, suppressErrorToast: true })
    );
  }, [data.clusterId, dispatch]);

  return (
    <Container>
      <BreadCrumbs title={data.clusterName} />
      <Body>
        <OverviewContainer>
          <Title>{t("clusters_list_overview")}</Title>
          <ConnectButton
            statusRunning={data.status === "RUNNING"}
            onClick={() => {
              connect();
            }}
          >
            {t("clusters_list_connect")} {">"}
          </ConnectButton>
        </OverviewContainer>

        <OverviewDataContainer>
          <OverviewTitleContainer>
            <OverviewTitle>{t("cluster_provider")}</OverviewTitle>
            <OverviewTitle>{t("cluster_region")}</OverviewTitle>
            <OverviewTitle>{t("cluster_type")}</OverviewTitle>
            <OverviewTitle>{t("cluster_version")}</OverviewTitle>
            <OverviewTitle action={true}>{t("cluster_action")}</OverviewTitle>
          </OverviewTitleContainer>
          <OverviewBodyContainer>
            <OverviewBodyItem>
              {location.state["cloud"] === Cloud.AZURE ? (
                <AzureIcon src={azureIcon} alt={"azureIcon"} />
              ) : location.state["cloud"] === Cloud.AWS ? (
                <AmazonIcon src={amazonIcon} alt={"amazonIcon"} />
              ) : location.state["cloud"] === Cloud.GOOGLE ? (
                <GoogleIcon src={googleIcon} alt={"googleIcon"} />
              ) : (
                <></>
              )}
            </OverviewBodyItem>
            <OverviewBodyItem>{location.state["region"]}</OverviewBodyItem>
            <OverviewBodyItem>
              <ToolTip
                title={t(getDescription(location.state["compute"]))}
                placement="bottom"
                arrow
              >
                <div>{location.state["compute"]}</div>
              </ToolTip>
            </OverviewBodyItem>
            <OverviewBodyItem>-</OverviewBodyItem>
            <OverviewBodyItem action={true}>
              <ProviderAction
                clusterId={location.state["clusterId"] || ""}
                clusterName={location.state["clusterName"] || ""}
                status={location.state["status"] || ""}
              />
            </OverviewBodyItem>
          </OverviewBodyContainer>
        </OverviewDataContainer>
        <SecurityTitle>{t("cluster_security")}</SecurityTitle>
        <React.Fragment>
          <TabsContainer>
            <TabButton
              selected={tabSelected === CreateNewClusterTabEnum.EXTERNALIPS}
              onClick={() => {
                setTabSelected(CreateNewClusterTabEnum.EXTERNALIPS);
              }}
            >
              {t("cluster_externalips_tab")}
            </TabButton>
            <TabButton
              selected={tabSelected === CreateNewClusterTabEnum.TRUSTEDIPS}
              onClick={() => {
                setTabSelected(CreateNewClusterTabEnum.TRUSTEDIPS);
              }}
            >
              {t("cluster_trusted_tab")}
            </TabButton>
            <TabButton
              selected={tabSelected === CreateNewClusterTabEnum.VPC}
              onClick={() => {
                setTabSelected(CreateNewClusterTabEnum.VPC);
              }}
            >
              {location.state["cloud"] === "AZURE"
                ? t("cluster_azure_tab")
                : t("cluster_vpc_tab")}
            </TabButton>
          </TabsContainer>
          <TabsContentContainer>
            {tabSelected === CreateNewClusterTabEnum.TRUSTEDIPS && (
              <TableTrustIPContainer>
                <TrustIPSHeader>
                  <TrustedIPAddContainer>
                    <AddButtonContainer onClick={handleAddIP}>
                      <AddIconStyled />
                      <AddIpIconText>{t("cluster_add_ip")}</AddIpIconText>
                    </AddButtonContainer>
                    <IPInputContainer>
                      <InputField
                        placeholder={"111.11.11.111"}
                        value={ipInput}
                        onChange={(text: string) => handleIpChange(text)}
                        onClickCloseHandle={() => handleIpChange("")}
                        required={true}
                        onEnter={handleAddIP}
                        error={ipError}
                        title={""}
                      />
                    </IPInputContainer>
                  </TrustedIPAddContainer>
                </TrustIPSHeader>
                <TableTest
                  ref={tableRef}
                  filters={[]}
                  canSelect={false}
                  data={trustedIPSData}
                  columns={trustedIPSColumns}
                  size="small"
                />
              </TableTrustIPContainer>
            )}
            {tabSelected === CreateNewClusterTabEnum.EXTERNALIPS && (
              <TrustIPSHeader>
                <TrustedIPAddContainer>
                  <VPCInputContainer>
                    <TitleInput>IP</TitleInput>
                    <InputField
                      placeholder={""}
                      value={rdbConfig ? `${rdbConfig.publicIp}` : ""}
                      onChange={() => {}}
                      onClickCloseHandle={() => {}}
                      onEnter={() => {}}
                      readonly={true}
                    />
                  </VPCInputContainer>
                  <VPCInputContainer>
                    <TitleInput>Port</TitleInput>
                    <InputField
                      placeholder={""}
                      value={rdbConfig ? `${rdbConfig.port}` : ""}
                      onChange={() => {}}
                      onClickCloseHandle={() => {}}
                      onEnter={() => {}}
                      readonly={true}
                    />
                  </VPCInputContainer>
                </TrustedIPAddContainer>
              </TrustIPSHeader>
            )}
            {tabSelected === CreateNewClusterTabEnum.VPC && (
              <TrustIPSHeader>
                <TrustedIPAddContainer>
                  <VPCInputContainer>
                    <InputField
                      placeholder={""}
                      value={location.state["cidr"] || ""}
                      onChange={() => {}}
                      onClickCloseHandle={() => {}}
                      required={true}
                      onEnter={() => {}}
                      error={ipError}
                      readonly={true}
                      title={""}
                    />
                  </VPCInputContainer>
                </TrustedIPAddContainer>
              </TrustIPSHeader>
            )}
          </TabsContentContainer>
        </React.Fragment>
      </Body>
    </Container>
  );
};

export default ClustersList;

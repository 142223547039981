import { useEffect, useMemo, useCallback } from "react";
import { ItemType } from "../../../Components/FilterHeader/types";
import ActionCell from "../CustomCells/Action";
import StatusCell from "../CustomCells/Status";
import { Cell, IdType, Row } from 'react-table';
import useDateFormat from "../../../hooks/useDateFormatHook";
import { useDispatch, useSelector } from "react-redux";
import { getInvitationsData, getUsersData } from "../../../store/slices/users";
import { selectUsers, selectInvitations } from "../../../store/slices/users/selectors";
import { StatusType } from "../CustomCells/Status/types";
import { useTranslation } from "react-i18next";
import moment from "moment";

const useTable = () => {

    const dispatch = useDispatch();
    const formatDate = useDateFormat();
    const users = useSelector(selectUsers);
    const {t} = useTranslation();
    const invitations = useSelector(selectInvitations);
    const userData = useMemo(() => users ?? [], [users]);
    const invitationData = useMemo(() => invitations ?? [], [invitations]);

    console.log("invitations", invitationData)
    const roleTypes : Array<{label: string, value: string}> = useMemo(() => [{value: "ACCOUNT_ADMIN", label: "account admin"},{value: "SYSTEM_ADMIN", label: "system admin"}],[]);
    const roleTypesMap : Record<string, string> = useMemo(() => ({ACCOUNT_ADMIN: t("ui.user.management.role.account.admin"), SYSTEM_ADMIN: t("ui.user.management.role.system.admin")}),[]);

    const userColumns = useMemo(
        () => [

            {
                Header: t("ui.user.management.columns.user.name"),
                accessor: 'name',
                type: ItemType.text,
                ellipsis: true,
                allowFilter: true,
                // width: 180,
            },
            {
                Header: t("ui.user.management.columns.account.name"),
                accessor: 'accountName',
                type: ItemType.text,
                ellipsis: true,
                allowFilter: true,
                // width: 180,

            },
            {
                Header: t("ui.user.management.columns.email"),
                accessor: 'email',
                type: ItemType.text,
                ellipsis: true,
                allowFilter: true,
                // width: 150,
            },
            {
                Header: t("ui.user.management.columns.role"),
                accessor: 'roleName',
                types: roleTypes,
                type: ItemType.types,
                Cell: (cell : Cell) => roleTypesMap[cell.value]
                ,
                ellipsis: true,
                filter: 'type',
                allowFilter: true,
            },
            {
                Header: t("ui.user.management.columns.action"),
                accessor: 'Action',
                type: ItemType.text,
                disableSortBy: true,
                allowFilter: false,
                width: 60,

                Cell: ActionCell
            },


        ],
        [roleTypes, roleTypesMap, t]
    );

    const StatusTypes : Array<{label: string, value: string}> = useMemo(() => [{value: StatusType.Completed, label: t("ui.user.management.status.completed")}, {value: StatusType.Sent, label: t("ui.user.management.status.pending") }, {value: StatusType.Revoked, label: t("ui.user.management.status.revoked")},  {value: StatusType.Canceled, label: t("ui.user.management.status.canceled")}],[t]);
    const StatusTypesMap : Record<string,string> = useMemo(() => ({[StatusType.Completed]: t("ui.user.management.status.completed"), [StatusType.Sent]: t("ui.user.management.status.pending"), "invitation.status.revoked": t("ui.user.management.status.revoked"), [StatusType.Canceled]: t("ui.user.management.status.canceled")}),[t]);

    const invitationsColumns = useMemo(
        () => [

            {
                Header: t("ui.user.management.columns.user.name"),
                accessor: 'name',
                type: ItemType.text,
                ellipsis: true,
                allowFilter: true,
                width: 150,
            },
            {
                Header: t("ui.user.management.columns.account.name"),
                accessor: 'accountName',
                type: ItemType.text,
                ellipsis: true,
                allowFilter: true,
                width: 150,

            },
            {
                Header: t("ui.user.management.columns.email"),
                accessor: 'email',
                type: ItemType.text,
                ellipsis: true,
                width: 170,
                allowFilter: true,
            },
            {
                Header: t("ui.user.management.columns.status"),
                accessor: 'status',
                type: ItemType.types,
                types: StatusTypes,
                filter: 'type',
                Cell: StatusCell,
                width: 150,
                allowFilter: true,
            },
            {
                Header: t("ui.user.management.columns.sent.date"),
                accessor: 'sentDate',
                type: ItemType.date,
                filter: 'date',
                ellipsis: true,
                allowFilter: true,
                width: 150,
                Cell: (cell : Cell) => {
                    return formatDate(cell.value);
                },
            },
            {
                Header: t("ui.user.management.columns.expiration.date"),
                accessor: 'expiryDate',
                type: ItemType.date,
                ellipsis: true,
                filter: 'date',
                width: 150,
                Cell: (cell : Cell) => {
                    if (moment(new Date(new Date().getTime())).unix() < cell.value){
                        return formatDate(cell.value);
                    } else {
                        return t("ui.user.management.invitation.expired");
                    }
                },
                allowFilter: true,
            },

        ],
        [StatusTypes, formatDate, t]
    );


    const globalFilterFunctionUsers = useCallback(
        (rows: Row<any>[], ids: IdType<any>[], query: string, ...props : any) => {
            console.log("global props any", props);
            return rows.filter(row => {
                const rowValueAccName = row.values["accountName"];
                const rowValueName = row.values["name"];
                const rowValueEmail = row.values["email"];
                const rowValueRole = row.values["roleName"];

                const AccNameFilter =  rowValueAccName !== undefined
                ? String(rowValueAccName)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true

                const nameFilter =  rowValueName !== undefined
                ? String(rowValueName)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true

                const EmailFilter =  rowValueEmail !== undefined
                ? String(rowValueEmail)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true

                const rowValueRoleLabel = roleTypesMap[rowValueRole];
                const RoleFilter =  rowValueRoleLabel !== undefined
                ? String(rowValueRoleLabel)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true
                  return RoleFilter || AccNameFilter || nameFilter || EmailFilter;
              });

        },
        [roleTypesMap],
    );

    const globalFilterFunctionInvitations = useCallback(
        (rows: Row<any>[], ids: IdType<any>[], query: string) => {
            return rows.filter(row => {
                const rowValueAccName = row.values["accountName"];
                const rowValueName = row.values["name"];
                const rowValueEmail = row.values["email"];
                const rowValueStatus = row.values["status"];
                const rowValuedate1 = formatDate(row.values["sentDate"]);
                const rowValuedate2 = formatDate(row.values["expiryDate"]);

                const AccNameFilter =  rowValueAccName !== undefined
                ? String(rowValueAccName)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true

                const nameFilter =  rowValueName !== undefined
                ? String(rowValueName)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true

                const EmailFilter =  rowValueEmail !== undefined
                ? String(rowValueEmail)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true

                const rowValueStatusLabel = StatusTypesMap[rowValueStatus];
                const StatusFilter =  rowValueStatusLabel !== undefined
                ? String(rowValueStatusLabel)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true

                const DateFilter1 =  rowValuedate1 !== undefined
                ? String(rowValuedate1)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true

                const DateFilter2 =  rowValuedate2 !== undefined
                ? String(rowValuedate2)
                    .toLowerCase()
                    .includes(String(query).toLowerCase())
                : true

                  return  AccNameFilter || nameFilter || EmailFilter || StatusFilter || DateFilter1 || DateFilter2;
              });

        },
        [StatusTypesMap, formatDate],
    );

    const invitationsInitialState: any = useMemo(() => ({
        sortBy: [
            {
                id: 'expiryDate',
                desc: true
            }
        ]
    }), []);

    return {invitationsInitialState, userColumns, userData, invitationsColumns, invitationData, globalFilterFunctionUsers, globalFilterFunctionInvitations};
};

export default useTable;

import styled from "styled-components";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export const ToolTipComp = styled(({ className, ...props }) => (
    <Tooltip
        // PopperProps={{
        //     disablePortal: true,
        // }}
        {...props}
        classes={{ popper: className }}
    />
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
))(({ theme }) => ({
    [`& .${tooltipClasses.popper}`]: {
        background: "red"
    },
    [`& .${tooltipClasses.tooltip}`]: {
        padding: "11px",
        margin: "5px",
        top: "20px",
        backgroundColor: "#EEEEEE",
        color: "#000000",
        maxWidth: 'none',
        fontSize: "13px",
        borderRadius: "5px",
        border: "1px solid #e0e0e0",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "15px",
        fontfamily: "Rubik",
        fontweight: "300",
        letterSpacing: "-0.02em",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#EEEEEE",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
    },
}));
export default ToolTipComp;

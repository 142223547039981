import React, {useEffect, useState} from 'react';
import {
    ButtonsContainer, CancelButton, LineBottom
} from "../../Components/CreateNewAccount/styles";
import {
    Container,
    InterPassword,
    PasswordContainer,
    NewPasswordContainer,
    ConfirmPasswordContainer,
    ResetPasswordUserContainer, ResetPasswordButtonsWrapper, ResetPasswordHeader, SubmitBtn
} from "./styles";
import PasswordInput from "../../Components/PasswordInput/PasswordInput";
//import close from "../../assets/svg/close.svg";
import {useNavigate} from "react-router-dom";
import BreadCrumbs from '../../Components/BreadCrubms';
import AlertDialog from "../../Components/Modal";
import {useDispatch, useSelector} from "react-redux";
import {selectResetPasswordUserResp, selectUserId} from "../../store/slices/general/selectors";
import {resetPasswordUserRequest} from "../../store/slices/general";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {BEErrorResponse} from "../../types/BEResponse";
import useToast from "../../hooks/useToast";
import { BottomWrapperNew } from '../UserProfile/styles';

const ResetPasswordUser = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const {t} = useTranslation();
    const userId = useSelector(selectUserId);
    const dispatch = useDispatch();
    const [errorType, _] = useState<"error" | "warning">("error");
    const resetPasswordUserResp = useSelector(selectResetPasswordUserResp);
    const toast = useToast();

;

    const { setError, register, handleSubmit, formState : {errors, isValid} ,setValue,  getValues, clearErrors  } = useForm();
    const { newPassword, confirmPassword, oldPassword } = getValues();

    const handleChangePassword = (field: string, newPassword: string) => {
        // Set the new password value using setValue
        setValue(field, newPassword);
        clearErrors();
    }

    console.log("errors ", errors, oldPassword, confirmPassword)

    useEffect(() => {
        if (resetPasswordUserResp &&
            resetPasswordUserResp.httpStatusCode === 200) {
            toast.success(resetPasswordUserResp.reason || resetPasswordUserResp.message || '');
            navigate(-1);
        } else if (resetPasswordUserResp &&
            resetPasswordUserResp.httpStatusCode !== 200){
                 const errors = resetPasswordUserResp.errors || [];
                 errors.forEach((err: BEErrorResponse) => {
                    if(err.field){
                        setError(err.field, {
                            type: "server",
                            message: t(err.reason) || err.message,
                          });
                    }
                 });
         }
    }, [dispatch, navigate, resetPasswordUserResp, setError, t, toast]);

    const onSubmit = () => {
        console.log("reset password");
        dispatch(resetPasswordUserRequest({id: userId, oldPassword: oldPassword, newPassword: newPassword}));
    };

    const handleDialogConfirm = () => {
        console.log("Reset Password");
    };

    const validateSamePassword = (value: string) => {
        if( value === confirmPassword &&  confirmPassword === oldPassword ){
            return t("new.password.same.as.old");
        }
        return true;
    }

    return (
        <Container>
            <BreadCrumbs />
            <ResetPasswordUserContainer>
                {/*<CloseResetPasswordContainer>*/}
                {/*    <CloseIconResetPassword src={close} alt={"close"} onClick={() => {navigate(-1);}}/>*/}
                {/*</CloseResetPasswordContainer>*/}
                <InterPassword>{t("reset.password.title")}</InterPassword>
                <PasswordContainer>
                    <PasswordInput
                     {...register('oldPassword', {
                        required: t("field.required") || "",
                        minLength: {
                            value: 10,
                            message: t("password.length") || "",
                        },
                        pattern: {
                            value: /^(?=.*[a-zA-Z])(?=.*\d).{10,}$/,
                            message: t("password.must.contain") || "" }})}
                        value={oldPassword}
                        placeholder={""}
                        status={"editable"}
                        onChange={(pass) => handleChangePassword('oldPassword', pass)}
                        isRequired={true}
                        error={errors.oldPassword?.message as string || ""}
                        title={t("old_password_input.title") || ""}
                                   />
                </PasswordContainer>

                <NewPasswordContainer>
                    <PasswordInput
                        {...register('newPassword', {
                            required: t("field.required") || "",
                            minLength: {
                                value: 10,
                                message: t("password.length") || "",
                            },
                            validate: validateSamePassword,
                            pattern: {
                                value: /^(?=.*[a-zA-Z])(?=.*\d).{10,}$/,
                                message: t("password.must.contain") || "" }})}
                        value={newPassword}
                        placeholder={""}
                        status={"editable"}
                        onChange={(pass) => handleChangePassword('newPassword', pass)}
                        isRequired={true}
                        title={t("new_password_input.title") || ""}
                        error={errors.newPassword?.message as string || ""}
                        errorType={errorType}/>
                </NewPasswordContainer>

                <ConfirmPasswordContainer>
                    <PasswordInput
                        {...register('confirmPassword', {
                            required: t("field.required") || "",
                            validate: (value) => value === newPassword || t("password.not.match") || "",
                        })}
                        value={confirmPassword}
                        placeholder={""}
                        status={"editable"}
                        onChange={(pass) => handleChangePassword('confirmPassword', pass)}
                        isRequired={true}
                        title={t("confirm_password_input.title") || ""}
                        error={errors.confirmPassword?.message as string || ""}
                        errorType={errorType}/>
                </ConfirmPasswordContainer>
                        <BottomWrapperNew>
                            <LineBottom/>
                            <ResetPasswordButtonsWrapper>
                                <div></div>
                                <ButtonsContainer>
                                    <CancelButton onClick={() => { navigate(-1);}}>{t("cancel.button")} </CancelButton>
                                    <SubmitBtn type="submit" onClick={handleSubmit(onSubmit)}>{t("reset.password.button")} {'>'}</SubmitBtn>
                                </ButtonsContainer>
                            </ResetPasswordButtonsWrapper>
                        </BottomWrapperNew>
            </ResetPasswordUserContainer>
            {
                showModal ?
                    <AlertDialog title={t("modal.title")}
                                 subTitle={t("modal.subtitle")}
                                 paragraph={t("modal.paragraph")}
                                 closeDialog={() => {setShowModal(false);}}
                                 handleConfirm={() => {handleDialogConfirm();}}/>
                    : <></>
            }
        </Container>
    );
};

export default ResetPasswordUser;

import styled from "styled-components";

export const DateFilterContainer = styled.div`
  width: 550px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 20px 17px 20px 17px;
`;

export const Title = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 100%;
    /* or 20px */

    letter-spacing: -0.02em;

    color: #000000;
`;

export const RowContainer = styled.div`
  display: flex ;
  gap: 5px;
  align-items: center ;
`;
export const Text = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  /* or 16px */

  letter-spacing: -0.02em;

  color: #000000;
`;
export const DatePickerContainer = styled.div`
  /* width: 155px; */
`;

export const InputContainer = styled.div`
  width: 70px;
`;

export const InputCustomDatePicker = styled.input`
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 30px;
  width: 155px;
  height: 29px;
  padding: 6px 15px;
`;

export const DatePickerCustomInputContainer = styled.div`
  position: relative;
`;

export const DatePickerCustomInputIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: pointer;
`;
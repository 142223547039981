import { GET, PATCH, POST } from '../network/client';
import { UserSingUpReqInterface } from '../store/sagas/users';
import {Response} from "../types/Response";
import { UsersFilterReqBody, InvitationsFilterReqBody, InviteUserReqBody,  } from '../types/user';

const paths = {
    INVITATION_DATA_REQ: '/v1/users/invitations/{token}',
    SIGNUP_REQ: '/v1/users/signup/invitations/{token}',
    GET_USERS: '/v1/users/filter?size=1000',
    GET_INVITATIONS: '/v1/users/invitations/filter?size=1000',
    REVOKE_INVITATION: '/v1/users/invitations/{invitationToken}/revoke',
    INVITE_USER: '/v1/users/invitations'
};

const getInvitationData = (token: string): Promise<Response<any>> => GET<any>(paths.INVITATION_DATA_REQ.replace('{token}', token));
const signUpUser = (data: UserSingUpReqInterface): Promise<Response<any>> => POST<any>(paths.SIGNUP_REQ.replace('{token}', data.token),{name : data.name,password: data.password});
const getUsers = (body: UsersFilterReqBody): Promise<Response<any>> => POST<any>(paths.GET_USERS, body);
const getInvitations = (body: InvitationsFilterReqBody): Promise<Response<any>> => POST<any>(paths.GET_INVITATIONS, body);
const revokeInvitation = (invitationToken : string): Promise<Response<any>> => PATCH<any>(paths.REVOKE_INVITATION.replace("{invitationToken}", invitationToken), {});
const inviteUser = (body: InviteUserReqBody): Promise<Response<any>> => POST<any>(paths.INVITE_USER, body);

const users = { getInvitationData, signUpUser, getUsers, getInvitations, revokeInvitation, inviteUser};

export default users;

import React, {useCallback, useEffect, useState} from 'react';
import {
    Container,
    Gradient,
    LeftSide,
    RightSide,
    WelcomeInfo,
    WelcomeSubTitle,
    WelcomeTitle,
    TitleSignIn,
    SubTitleSignIn,
    SubmitBtn, Info, ReturnContainer, Return, ReturnSignIn
} from "./styles";
import Footer from "../Footer/Footer";
import EmailInput from "../EmailInput/EmailInput";
import {EmailContainer} from "../Welcome/styles";
import { useNavigate } from 'react-router';
import { selectResetPasswordEmailResp } from '../../store/slices/general/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordEmailRequest } from '../../store/slices/general';
import useToast from '../../hooks/useToast';
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";

const ResetPasswordSignIn = () => {
    const toast = useToast();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
   // const [email, setEmail] = useState("");
    const { register, handleSubmit, formState : {errors} , setValue,  getValues, clearErrors, setError } = useForm();
    const { email } = getValues();
    const resetPasswordEmailResp = useSelector(selectResetPasswordEmailResp);

    const handleFieldChange = useCallback((field: string,  value: string) => {
        setValue(field,value);
        clearErrors(field)
    },[clearErrors, setValue]);

    // const inputHandler = (enterText: string) => {
    //     setEmail(enterText);
    // };

    const onSubmit = () => {
        dispatch(resetPasswordEmailRequest(email));
    };

    const handleReturnToSignIn = useCallback(() => {
        navigate('/login');
    },[navigate]);

    useEffect(() => {
        if (resetPasswordEmailResp && resetPasswordEmailResp &&
            resetPasswordEmailResp.httpStatusCode === 200) {
            toast.success(resetPasswordEmailResp.reason || resetPasswordEmailResp.message || '');
            setTimeout(() => {
                handleReturnToSignIn();
            }, 2000);
        }
    },[handleReturnToSignIn, resetPasswordEmailResp, toast])

    return (
        <Gradient>
            <Container>
                <LeftSide>
                    <WelcomeTitle>{t("login.title")} </WelcomeTitle>
                    <WelcomeSubTitle>{t("login.subtitle")} </WelcomeSubTitle>
                    <WelcomeInfo>{t("login.info")}</WelcomeInfo>
                </LeftSide>
                <RightSide>
                    <TitleSignIn>{t("user.profile.reset.password")}</TitleSignIn>
                    <SubTitleSignIn>{t("reset_password_sign_in_email")}</SubTitleSignIn>
                    <EmailContainer>
                        <EmailInput
                            {...register('email', {

                                required: t("field.required") || "",
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: t("email.format") || ""
                                }})}
                            value={email}
                            placeholder={t("user.profile.email.placeholder")}
                            status={"editable"}
                            onChange={(text: string) => handleFieldChange("email", text)}
                            isRequired={true}
                            title={t("user.profile.user.email") || ""}
                            error={errors.email?.message as string || ""}
                            signInScreen={true}/>
                    </EmailContainer>
                    <SubmitBtn type="submit" onClick={handleSubmit(onSubmit)}> {t("user.profile.reset.password")} {">"}</SubmitBtn>
                    <Info>{t("reset.password.sign.in.info1")}{"\n"}
                        {t("reset.password.sign.in.info2")}</Info>
                    <ReturnContainer>
                        <Return>{t("reset.password.sign.in.return")}</Return>
                        <ReturnSignIn onClick={() => {handleReturnToSignIn()}}>{t("reset.password.sign.in.button")}</ReturnSignIn>
                    </ReturnContainer>
                </RightSide>
            </Container>
            <Footer/>
        </Gradient>
    );
};

export default ResetPasswordSignIn;

import React, { useEffect, useState } from "react";
import {
  ContainerInput,
  InputFieldStyled,
  Container,
  CloseIconStyled,
  Title,
} from "./styles";
import closeIcon from "../../assets/svg/close.svg";
import InputError from "../InputError";
// import { debounce } from "@mui/material";

interface InputInterface {
  placeholder: string;
  value: string;
  onChange: (text: string) => void;
  onEnter?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  size?: "lg" | "sm";
  onClickHandle?: () => void;
  onClickCloseHandle?: () => void;
  title?: string;
  showAsterisk?: boolean;
  required?: boolean;
  signInScreen?: boolean;
  error?: string;
  errorType?: "error" | "warning";
  readonly?: boolean;
}

const InputField: React.FC<InputInterface> = (props) => {
  const {
    placeholder,
    onChange,
    value,
    onEnter,
    size = "lg",
    onClickHandle,
    onClickCloseHandle,
    title,
    error,
    showAsterisk = true,
    required,
    signInScreen = false,
    errorType = "error",
    readonly = false,
    onKeyDown,
  } = props;

  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const onClickHandler = () => {
    setFocused(true);
    if (onClickHandle) {
      onClickHandle();
    }
  };

  const onClickClose = () => {
    if (onClickCloseHandle) {
      onClickCloseHandle();
    }
  };

  const [inputValue, setInputValue] = useState("");
  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    // debounce(() => onChange(e.target.value), 1);
    onChange(e.target.value);
    setInputValue(e.target.value);
  };

  useEffect(() => {
    // console.log("input updated")
    setInputValue(value);
  }, [value]);
  return (
    <Container>
      {title && (
        <Title signInScreen={signInScreen}>
          <div style={{ color: signInScreen ? "#ffffff" : "#FF6600" }}>
            {showAsterisk ? "*" : ""}
          </div>
          {title}
        </Title>
      )}
      <ContainerInput message={error} size={size}>
        <InputFieldStyled
          value={inputValue}
          placeholder={focused ? "" : placeholder}
          type={"text"}
          onChange={onChangeText}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              if (onEnter) {
                onEnter();
              }
            }
          }}
          onKeyDown={(e) => onKeyDown && onKeyDown(e)}
          onClick={() => onClickHandler()}
          onFocus={onFocus}
          onBlur={onBlur}
          required={required}
          readOnly={readonly}
        />
        {readonly ? (
          <></>
        ) : (
          <CloseIconStyled
            src={closeIcon}
            alt={"closeIcon"}
            onClick={() => onClickClose()}
          />
        )}
      </ContainerInput>
      <InputError message={error} errorType={errorType} />
    </Container>
  );
};

export default InputField;

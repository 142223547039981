import { useMemo } from "react";
import styled from "styled-components";

const Styles = styled.div`
  width: calc(100vw - 420px);
  max-height: 400px;
  overflow: auto;
  border: 2px solid white;
  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid white;
  }
  th,
  td {
    border: 1px solid white;
    text-align: left;
    padding: 8px;
    overflow: hidden;
    height: 24px;
  }

  thead tr {
    border-bottom: 2px solid white; /* Ensure visibility */
  }
  th {
    position: relative;
    background-color: black;
    white-space: nowrap;
    /* Make headers sticky */
    border: 1px solid white;
    position: -webkit-sticky; /* For Safari */
    box-shadow: 0 2px 2px -1px rgba(255, 255, 255, 0.4);
    position: sticky;
    top: 0; /* Stick to the top of the table */
    z-index: 4; /* Ensures the header is above other content */
  }
  tbody tr td {
    height: 24px;
    min-width: 100px; /* Adjust minimum width as needed */
  }
`;

const Basic = ({ table }) => {
  const columns = useMemo(
    () => table.data?.columns ?? [],
    [table.data?.columns]
  );
  const rows = useMemo(() => table.data?.rows ?? [], [table.data?.rows]);

  return (
    <Styles>
      <table>
        <thead>
          <tr style={{ border: "1px solid white" }}>
            {columns.map((header, i) => (
              <th key={i} style={{ border: "1px solid white" }}>
                {header}
                <div
                  style={{
                    width: "100%",
                    height: "2px",
                    background: "white",
                    position: "absolute",
                    bottom: "0",
                    left: 0,
                  }}
                ></div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <tr key={i}>
              {row.map((cell, i) => (
                <td key={i}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "start",
                    }}
                  >
                    {cell}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Styles>
  );
};

export default Basic;

import React, { useEffect, useState } from "react";
import {
  ButtonsContainer,
  ButtonsWrapper,
  CancelButton,
  Container,
  CreatContainer,
  InputContainer,
  LineBottom,
  SelectContainer,
  BottomWrapperNew,
} from "./styles";
import {
  AccountLinesContainer,
  AccountsTitle,
  AddAccountButton,
  ButtonTitle,
  Line,
  NavigationTitle,
  NavTitle,
  PlusIcon,
} from "../AccountsHeader/styles";
import { DropDownMode } from "../Select/types";
import Select from "../Select/Select";
import InputField from "../InputField/InputField";
import plusIcon from "../../assets/svg/plus_icon.svg";
import BreadCrumbs from "../BreadCrubms";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAccountNewData,
  createNewAccountReq,
} from "../../store/slices/accounts";
import { AccountsTypes } from "../../types/Accounts";
import { selectCreateData } from "../../store/slices/accounts/selectors";
import { BEErrorResponse } from "../../types/BEResponse";
import useFieldsErrorHook from "../../hooks/useFieldsErrorHook";
import useToast from "../../hooks/useToast";
import { useTranslation } from "react-i18next";

interface CreateNewAccountProps {}
const CreateNewAccount: React.FC<CreateNewAccountProps> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast();
  const createData = useSelector(selectCreateData);
  const { fieldsErrors, updateErrorField } = useFieldsErrorHook();
  const actionList = [
    {
      label: t("create.account.selector_item"),
      value: 0,
    },
  ];
  const [triggerClicked, setTriggerClicked] = useState(actionList[0].label);
  const [triggerValueClicked, setTriggerValueClicked] = useState(
    actionList[0].value
  );
  const handleDropDown = (item: string, index: number) => {
    setTriggerClicked(item);
    setTriggerValueClicked(index);
  };

  const [account, setAccount] = useState("");

  const onEnterAccount = () => {
    setAccount("");
  };

  const onInputChange = (text: string) => {
    setAccount(text);
  };

  const onInputClickHandle = () => {
    console.log("onInputClickHandle");
  };

  const saveAccount = () => {
    dispatch(
      createNewAccountReq({
        name: account,
        type: AccountsTypes.premium,
      })
    );
  };

  const onClickClose = () => {
    // setInputErrorMessage("");
    setAccount("");
  };

  useEffect(() => {
    if (createData && createData.httpStatusCode === 200) {
      toast.success(createData.reason || createData.message || "");
      dispatch(clearAccountNewData());
      navigate(-1);
    } else if (createData && createData.httpStatusCode !== 200) {
      console.log("createData.errors", createData.errors);
      updateErrorField(createData.errors || []);
    }
    //TODO set errors
  }, [createData, dispatch, navigate, toast, updateErrorField]);

  return (
    <Container>
      <BreadCrumbs />
      <CreatContainer>
        <InputContainer>
          <InputField
            placeholder={t("create.account.placeholder")}
            error={(fieldsErrors && t(fieldsErrors.name)) || ""}
            value={account}
            onChange={onInputChange}
            onEnter={onEnterAccount}
            onClickHandle={onInputClickHandle}
            onClickCloseHandle={onClickClose}
            title={t("create.account.title") || ""}
          />
        </InputContainer>
        <SelectContainer>
          <Select
            placeholder={t("create.account.selector_item")}
            required={false}
            selectedItem={
              triggerClicked !== ""
                ? {
                    label: triggerClicked,
                    value: triggerValueClicked,
                  }
                : undefined
            }
            onSelect={function (
              item:
                | {
                    value: any;
                    label: string;
                  }
                | undefined
            ): void {
              if (item !== undefined) {
                handleDropDown(item.label, item.value);
              } else {
                setTriggerClicked("");
                setTriggerValueClicked(0);
              }
            }}
            TitleText={t("create.account.selector_title")}
            items={actionList}
            mode={DropDownMode.disabled}
            searchable={false}
            allowEmptySelect={false}
            dropDownListHeight={actionList.length.toString()}
          />
        </SelectContainer>
        <BottomWrapperNew>
          <LineBottom />
          <ButtonsWrapper>
            <ButtonsContainer>
              <CancelButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                {t("cancel.button")}{" "}
              </CancelButton>
              <AddAccountButton onClick={saveAccount}>
                <PlusIcon src={plusIcon} alt={"plusIcon"} />
                <ButtonTitle>{t("create.account.button")}</ButtonTitle>
              </AddAccountButton>
            </ButtonsContainer>
          </ButtonsWrapper>
        </BottomWrapperNew>
      </CreatContainer>
    </Container>
  );
};

export default CreateNewAccount;

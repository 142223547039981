import styled from "styled-components";
interface FooterProps {
  height: string;
  bg: string;
}

export const  FooterContainer = styled.div<FooterProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height:  ${(props: FooterProps) => props.height};
  background: ${(props: FooterProps) => props.bg};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const Footer_logo_img = styled.img `
  text-align: center;
  position: fixed;
  left: 52px;
  width: 87px;
`;

export const CopyTermsContainer = styled.div `
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Rubik" : "unset"};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #EEEEEE;
`;

export const Terms = styled.span `
  text-decoration: underline;
  cursor: pointer;
`;

import React, { useEffect, useState } from "react";
import {
  Container,
  ErrorIconStyled,
  WarningIconStyled,
  ArrowContainer,
  IconContainer,
} from "./styles";

interface InputInterface {
  message?: string;
  errorType?: "error" | "warning";
}

const InputError: React.FC<InputInterface> = (props) => {
  const { message, errorType = "error" } = props;
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (message) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [message]);

  return visible ? (
    <Container errorType={errorType}>
      <ArrowContainer errorType={errorType} />
      {errorType === "error" ? (
        <IconContainer>
          <ErrorIconStyled />
        </IconContainer>
      ) : (
        <IconContainer>
          <WarningIconStyled />
        </IconContainer>
      )}
      {message}
    </Container>
  ) : null;
};

export default InputError;

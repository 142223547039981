import styled from "styled-components";

export const ClusterQueryContainer = styled.div`
  height: 100%;
  background-color: #000000;
  padding: 29px 33px 10px 38px;
  position: relative ;

`;
export const Container = styled.div`
  height: calc(100vh - 440px);

  overflow: auto;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: white;
  }

  ::-webkit-scrollbar-track {
    background-color: black;
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
    border-radius: 4px;
  }
`;

export const SubmitBtn = styled.button<{isLoading: boolean}>`
  background: ${p => p.isLoading ? "gray" : "#fb5e03"};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
  height: 53px;
  padding: 17px 26px;
  gap: 10px;
  font-family: "Inconsolata";
  font-weight: 700;
  font-size: 17px;
  line-height: 111%;
  /* identical to box height, or 19px */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Light Grey */

  color: #eeeeee;
  border: 0px;
  cursor: pointer;
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  margin-top: 24px;
  justify-content: flex-end;
  gap: 10px;
`;

export const ResponseQueryContainer = styled.div`
  height: 100%;
  left: calc(50% - 218px / 2 - 366px);
  top: calc(50% - 22px / 2 - 212px);

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 22px */

  text-align: left;
  letter-spacing: -0.02em;

  color: #ffffff;
  padding-bottom: 62px;
  white-space: pre-line;
`;
export const Title = styled.div<{ signInScreen?: boolean }>`
  font-style: normal;
  font-family: ${({ theme: { language } }) =>
    language === "eng" ? "Poppins" : "unset"};
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.02em;
  /* or 18px */
  display: flex;
  color: #ffffff;
  padding-bottom: 10px;
`;

import {  useState, useCallback, useMemo } from "react";
import { ItemType } from "../../../../Components/FilterHeader/types";
import ActionCell from "../CustomCells/Action/index";
import IPCell from "../CustomCells/IPCell";
import { Row } from "react-table";
import {useTranslation} from "react-i18next";

const isValidIP =(ip: string) => {
    const octet = '(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|0)';
    const regex = new RegExp(`^${octet}\\.${octet}\\.${octet}\\.${octet}$`);
    return regex.test(ip);
  }

const isValidVPCIPAddress = (ipAddress: string) => {
    const ipAddressPattern = /^(\d{1,3}\.){3}\d{1,3}\/\d{1,2}$/;
    return ipAddressPattern.test(ipAddress);
}

interface IP {
    ip : string;
}
const useTable = (tableRef: any) => {
    const {t} = useTranslation();
    const [trustedIPSData, setTrustedIPSData] = useState<Array<IP>>([]);
    const [ipInput,setIpInput] = useState("");
    const [ipVPCInput,setIpVPCInput] = useState("");
    const [ipError, setIpError] = useState("");


    const trustedIPSColumns = useMemo(
        () => [
            {
                Header: 'IP',
                accessor: 'ip',
                type: ItemType.text,
                Cell: IPCell,
                allowFilter: false,
            },
            {
                Header: 'Action',
                accessor: 'Action',
                type: ItemType.text,
                disableSortBy: true,
                allowFilter: false,
                width: 20,
                Cell: ActionCell,
            },
        ],
        []
    );


    const handleAddIP = useCallback(() => {
        if(isValidIP(ipInput)){
            tableRef.current.handleAddRow({ip: ipInput});
            handleIpChange("")
        }else{
            setIpError(t("ui.create.new.cluster.ip.error") || "");
        }

    },[ipInput, tableRef]);

    const handleVPCIP = useCallback(() => {
        if(isValidVPCIPAddress(ipVPCInput)){
            setIpError("");
        }else{
            setIpError(t("ui.create.new.cluster.ip.vpc.error") || "");
        }

    },[ipVPCInput]);

    const handleGetIPS = useCallback(() => {
        let ips = []
        if(tableRef.current){
            ips = tableRef.current?.tableInstance?.rows.map((row : Row<IP>) => row.original.ip);
            return ips
        }
        return trustedIPSData.map((row : IP) => row.ip);;
    },[tableRef, trustedIPSData]);


    const handleIpChange = (text: string) => {
        setIpInput(text);
        setIpError("");
    }

    const handleIpVPCChange = (text: string) => {
        setIpVPCInput(text);
        handleVPCIP();
    }

    const handleGetIPVPC = useCallback(() => {
        return ipVPCInput;
    },[ipVPCInput])

    return {trustedIPSColumns, trustedIPSData, handleAddIP, handleVPCIP, handleGetIPS, handleGetIPVPC, ipInput, ipVPCInput, handleIpChange,handleIpVPCChange, ipError, setTrustedIPSData};
};

export default useTable;

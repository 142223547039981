import styled from "styled-components"
import {ReactComponent as SettingIcon} from "../../../../../assets/svg/SettingIconTable.svg"
import {ReactComponent as ResetPasswordIcon} from "../../../../../assets/svg/reset_password_action.svg"
import {ReactComponent as DeleteIcon} from "../../../../../assets/svg/delete.svg"

export const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
`;
export const CloseIconStyled = styled(SettingIcon)`
  cursor: pointer;
  width: 18px;
  height: 18px;
`;

export const ResetPasswordIconStyled = styled(ResetPasswordIcon)`
  width: 14px;
  height: 14px;
`;
export const DeleteIconStyled = styled(DeleteIcon)`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

export const ActionContainer = styled.div`
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
`;

export const IconContainer = styled.div`
    width: 18px;
    height: 18px;
`;
export const ItemContainer = styled.div`
    width:100%;
    height: 100%;
    display: flex ;
    flex-direction: column ;
    justify-content: center ;
    align-items: center ;
    z-index: 2;
    padding: 5px 0;
`;

export const Item = styled.div`
    width: 140px;
    height: 25px;
    display: flex ;
    align-items: center ;
    cursor: pointer;
    padding: 0 5px ;
    gap: 5px;
    z-index: 2 ;
    border-radius: 4px;
    background-color:#ffffff;
    &:hover {
        background: #EEEEEE;
    }
`;
export const menuPopperProps = ({
    elevation: 0,
    sx: {
        borderRadius: '6px',
        minWidth: '145px',
        background: '##ffffff',
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 0,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: "50%",
            right: -5,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
        '& .MuiList-root': {
            padding: 0,
        },
    },
});


export const ItemText = styled.div`
  
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    letter-spacing: -0.02em;

    color: #535151;
  `;

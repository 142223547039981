import { ListItemButton, ListItemIcon } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import usePermissions from "../../hooks/usePermissions";
import { RouteType } from "../../routes/config";
import {
  selectExpandSidebar,
  selectPath,
} from "../../store/slices/general/selectors";
import { useTranslation } from "react-i18next";

type Props = {
  item: RouteType;
};

const SidebarItem = ({ item }: Props) => {
  const { t } = useTranslation();
  const path = useSelector(selectPath);
  const expand = useSelector(selectExpandSidebar);
  const enabled = usePermissions(item.roles || []);

  // const getColor = () => {
  //   if (path === item.state) {
  //     return colorConfigs.sidebar.activeBg;
  //   }
  //   if (item.sidebarProps?.disabled) {
  //     return colorConfigs.sidebar.disabledAction;
  //   }
  //   return colorConfigs.sidebar.color;
  // };

  const getFontStyles = () => {
    if (path === item.state) {
      return colorConfigs.sidebar.selectedItem;
    }
    if (item.sidebarProps?.disabled) {
      return colorConfigs.sidebar.disabledItem;
    }
    return colorConfigs.sidebar.unSelectedItemItem;
  };

  const getIcon = () => {
    if (path === item.state) {
      return item.sidebarProps?.selectedIcon;
    }
    if (item.sidebarProps?.disabled) {
      return item.sidebarProps?.unAvailableIcon;
    }
    return item.sidebarProps?.icon;
  };

  const getFontSize = () => {
    if (!expand) {
      return "15px";
    }
    return path === item.state ? "16px" : "16px";
  };

  return enabled && item.sidebarProps && item.path ? (
    <ListItemButton
      disableRipple
      disabled={item.sidebarProps?.disabled}
      component={Link}
      to={item.path}
      sx={{
        "&: hover": {
          backgroundColor: "unset",
        },
        "&.Mui-focusVisible": {
          backgroundColor: "unset",
        },
        marginBottom: "10px",

        // color: getColor(),
        ...getFontStyles(),
        fontSize: getFontSize(),
        lineHeight: 0,
        fontWeight:
          t(item.sidebarProps.displayText) === t("ui.side.bar.home")
            ? "bold"
            : path === item.state
            ? 600
            : 500,
        fontStyle: "normal",
        fontFamily: "Poppins",
        flexDirection: expand ? "row" : "column",
      }}
    >
      <ListItemIcon
        sx={{
          width: expand ? "24px" : "auto",
          minWidth: "auto",
          marginRight: "12px",
        }}
      >
        {item.sidebarProps.icon && getIcon()}
      </ListItemIcon>
      <div
        style={{
          marginTop: expand ? "7px" : "15px",
          paddingBottom: "3px",
        }}
      >
        {t(item.sidebarProps.displayText)}
      </div>
    </ListItemButton>
  ) : null;
};

export default SidebarItem;

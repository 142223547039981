import React, {useState, useEffect} from 'react';

import{ CheckBoxFilterContainer, Title } from "./styles"
import {ClearAllContainer,ClearAllText,RemoveButtonStyled} from "../styles"
import CheckBox from '../../CheckBox/CheckBox';
interface CheckBoxFilterProps {
    accessor: string;
    Header: string;
    types: Array<Record<string,any>>;
    value: any;
    handleColumnFilterValueChange: (id: string, value: any) => void;
    handleResetFilter: (id: string) => void;
}
const CheckBoxFilter: React.FC<CheckBoxFilterProps> = (props) => {

    const { accessor, Header, types, handleColumnFilterValueChange, value, handleResetFilter } = props;
    const [checked, setCheck ]= useState<Record<string,boolean>>((value as Array<string>).reduce((ac,colTypeName) => ({...ac,[colTypeName]: true}),{}));


    const handleClear = () => {
        handleResetFilter(accessor);
        setCheck(types.map(elem => elem.value).reduce((ac,colTypeName) => ({...ac,[colTypeName]: true}),{}))
    }
    useEffect(() => {
        //debounce

        handleColumnFilterValueChange(accessor, Object.keys(checked).filter(typeName => checked[typeName]))
    },[accessor, checked, handleColumnFilterValueChange])
    return (
        <CheckBoxFilterContainer>
            <Title>{Header}</Title>
            {types.map((typeName : Record<string,any>) =>
                <CheckBox isChecked={checked[typeName.value]}
                          checkHandler={() => setCheck(prev => ({...prev, [typeName.value] : !prev[typeName.value]}))}
                          title={typeName.label} varient="white" />)}
                <ClearAllContainer onClick={handleClear}><ClearAllText>Clear all</ClearAllText><RemoveButtonStyled /></ClearAllContainer>
        </CheckBoxFilterContainer>
    );
};

export default CheckBoxFilter;

import CkCell from './ckCell';
import {useCallback, useContext, useMemo} from "react"
import Context from '../context';

const Row = ({ row, style }) => {
  const {checked, onChange} = row.getToggleRowSelectedProps();
  const {state} = useContext(Context);
  const {onDoubleClick, canSelect} = state;
  const canDoubleClick = useMemo(() => onDoubleClick ? true : false,[onDoubleClick]);
  const handleDobuleClick = useCallback(() => {
    onDoubleClick && onDoubleClick(row.original);
  },[onDoubleClick, row]);

  return (    
    <div className={`ck-tr`} style={style} onClick={state.canSelect ? onChange : null } onDoubleClick={handleDobuleClick}>
      {row.cells.map((cell) => {
        return <CkCell cell={cell} {...cell.getCellProps()} checked={checked} canSelect={canSelect} canDoubleClick={canDoubleClick}/>
      })}
    </div>
  );
};

export default Row;

import {DELETE, PATCH, POST} from '../network/client';
import {Response} from "../types/Response";
import {
    BackupRestoreReqBody,
    BackupsFilterReqBody,
    SaveBackupPlanReqBody,
    SaveBackupReqBody,
    UpdateBackupPlanReqBody
} from '../types/backupRestore';

const paths = {
    GET_BACKUPS: '/v2/skynet/cluster-ops/backups?size=1000',
    GET_BACKUP_PLANS: '/v2/skynet/clusters/backup-plans/filter?size=1000',
    DELETE_BACKUP : '/v2/skynet/cluster-ops/{clusterId}/backups/{backupId}/delete',
    RESTORE_BACKUP: '/v2/skynet/cluster-ops/{clusterId}/restore',
    UPDATE_BACKUP_PLAN: '/v2/skynet/clusters/{clusterId}/backup-plans',
    BACKUP: '/v2/skynet/cluster-ops/{clusterId}/backup',
    BACKUP_PLAN: '/v2/skynet/clusters/{clusterId}/backup-plans'

};

const getBackups = (body: BackupsFilterReqBody): Promise<Response<any>> => POST<any>(paths.GET_BACKUPS, body);
const getBackupPlans = (body: BackupsFilterReqBody): Promise<Response<any>> => POST<any>(paths.GET_BACKUP_PLANS, body);
const deleteBackup = (clusterId: string, backupId: string): Promise<Response<any>> => DELETE<any>(paths.DELETE_BACKUP.replace('{clusterId}', clusterId).replace('{backupId}',backupId));
const restoreBackup = (clusterId: string, body : BackupRestoreReqBody ): Promise<Response<any>> => POST<any>(paths.RESTORE_BACKUP.replace('{clusterId}', clusterId), body);
const updateBackupPlan = (clusterId: string, body :  UpdateBackupPlanReqBody): Promise<Response<any>> => PATCH<any>(paths.UPDATE_BACKUP_PLAN.replace('{clusterId}', clusterId), body);
const saveBackup = (clusterId: string, body: SaveBackupReqBody): Promise<Response<any>> => POST<any>(paths.BACKUP.replace('{clusterId}', clusterId), body);
const saveBackupPlan = (clusterId: string, body: SaveBackupPlanReqBody): Promise<Response<any>> => POST<any>(paths.BACKUP_PLAN.replace('{clusterId}', clusterId), body);


const backupRestore = { getBackups, getBackupPlans, deleteBackup,restoreBackup, updateBackupPlan, saveBackup, saveBackupPlan};

export default backupRestore;

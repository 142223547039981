import styled from "styled-components";

export const Password = styled.input<{error?: boolean}> `
  display: flex;
  padding: 0px 20px;
  //background: linear-gradient(174.06deg, #EEEEEE 20.86%, #FFFFFF 87.44%, #FFFFFF 131.09%);
  background: #ffffff;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
  border: ${p => p.error ? "2px solid #EA0000" : "1px solid #eeeeee"};
  height: 56px;
  width: 100%;
  -webkit-text-security: initial;
`;

export const PasswordContainer = styled.div `
    display: flex;
    position: relative;
    align-items: center;
    justify-content: right;
`;

export const PasswordImg = styled.img `
  position: absolute;
  z-index: 999;
  margin-right: 19px;
  align-self: center;
  margin-top: 3px;
  cursor: pointer;
`;

export const Italics = styled.div<{show: boolean}> `
  box-sizing: border-box;
  display: ${(props) => (props.show ? "initial" : "none")};
`;

export const ItalicsP = styled.p `
  display: inline !important;
  position: absolute;
  top: 8px;
  left: 17px;
  color:  #888888;
  pointer-events: none;
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Poppins" : "unset"};
  font-weight: 300;
  font-style: normal;
  font-size: 15px;
  line-height: 100%;
`;

export const PlaceHolderDif = styled.i `
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  font-family: ${({ theme: { language } }) =>
          language === "eng" ? "Poppins" : "unset"};
  color: rgba(136, 136, 136, 0.5);
`;


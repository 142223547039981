import React, {useEffect, useState, useCallback} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import appRoutes from '../../routes/appRoutes';
import { RouteType } from '../../routes/config';
import { selectPath } from '../../store/slices/general/selectors';
import { BreadCrumbItem, BreadcrumbsContainer, HeaderContainer, Line, LineTitle, Title, TitleContainer } from './styles';

interface BreadCrumbsProps{
    darkMode?: boolean;
    title?: string;
}

const BreadCrumbs: React.FC<any> = ({darkMode, title} : BreadCrumbsProps) => {
    const navigate = useNavigate();
    const state = useSelector(selectPath);
    // const 
    const [path, setPath] = useState<string>("");

    const getRelevantRoute = useCallback((routes: RouteType[], state: string, searchBy: string): RouteType | undefined => {
        for(let i = 0;i < routes.length ; i++) {
            const route: any = routes[i];
            if (route[searchBy] === state){
                return route;
            }
            if (route.child && route.child.length > 0) {
                const foundRoute = getRelevantRoute(route.child, state, searchBy);
                if (foundRoute){
                    return foundRoute;
                }
            }
        }
        return;
    },[]);

    const goTo = (route: any) => {
        if (route.path && route.enabled){
            navigate(route.path);
        }
    };

    useEffect(() => {
        const route: RouteType | undefined = getRelevantRoute(appRoutes, state, 'state');
        if (route){
            setPath(route.path || "");
        }
    },[state, getRelevantRoute]);

    const pathRouteHierarchy: any = [];
    const pathSections = path.split('/');
    let pathTemp = "";
    pathSections.forEach((section: string) => {
        if (!section){
            return;
        }
        const pathRoute: any = {
            name: section,
            enabled: false,
        }
        pathTemp  = `${pathTemp}/${section}`;
        const route = getRelevantRoute(appRoutes,pathTemp, 'path');
        if (route){
            if (!route.child || route.child.length === 0) {
                pathRoute.enabled = true;
            }
            pathRoute.name = route.topBarName;
            pathRoute.path = route.path;
        }
        pathRouteHierarchy.push(pathRoute);
    });
    let currentTitle = '';
    if (pathRouteHierarchy && pathRouteHierarchy.length) {
        currentTitle = title || pathRouteHierarchy[pathRouteHierarchy.length - 1].name;
    }
    return (
        <HeaderContainer>
            <BreadcrumbsContainer>
                {
                    pathRouteHierarchy.map((it: any, index: number) => (
                        (pathRouteHierarchy.length - 1) !== index ? 
                            <BreadCrumbItem key={index} onClick={() => goTo(it)} enabled={it.enabled}>{it.name} {'>'}</BreadCrumbItem>
                        :
                            <></>
                    ))
                }
            </BreadcrumbsContainer>
            <TitleContainer>
                <Title darkMode={ darkMode }>{currentTitle}</Title>
                <LineTitle/>
            </TitleContainer>
            <Line />
        </HeaderContainer>
    );
};

export default BreadCrumbs;

import React from "react";
import { Container } from "./styles";
import { Cell } from "react-table";
import ToolTipComp from "../../../../Components/Select/ToolTip";
interface Props {
  cell: Cell<Record<string, any>>;
}

const TagCell: React.FC<Props> = (props) => {
  const { cell } = props;

  return (
    <React.Fragment>
      <Container>
        <ToolTipComp title={cell.value} arrow>
          <div
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "100%",
              lineHeight: "1.2",
            }}
          >
            {cell.value}
          </div>
        </ToolTipComp>
      </Container>
    </React.Fragment>
  );
};
export default TagCell;

import styled from "styled-components";
export const Container = styled.div `
  width: 100%;
  height: 100%;
`;

export const UserButtonContainer = styled.div `
   display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const AddUserButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px 36px 16px 35px;
  gap: 10px;
  cursor: pointer;
  

  background: #5C2EDF;
  border-radius: 26.5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ResendInvitationsButton = styled.div<{disabled: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px 36px 16px 35px;
  gap: 10px;
  cursor: ${p => p.disabled? "not-allowed" : "pointer"};
  

  background: ${p => p.disabled ? "#EEEEEE" : "#5C2EDF" };
  border-radius: 26.5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;


export const ButtonsContainer = styled.div `
 display: flex;
`;

export const ButtonRevoke = styled.div<{disabled: boolean}>`
  width: 112px;
  
  background: ${p => p.disabled? "#EEEEEE" : "red"};
  border-radius: 26.5px;
  cursor: ${p => p.disabled? "not-allowed" : "pointer"};
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 13px;
`;

export const ButtonRevokeTitle = styled.div `

  padding: 17px 26px;
  /* Link & Button - Inconsolata Bold 17 - 10% Kerning */

  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 111%;
  /* identical to box height, or 19px */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Black&amp;White/White */

  color: #FFFFFF;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ButtonTitle = styled.div `
  
  /* Link & Button - Inconsolata Bold 17 - 10% Kerning */

  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 111%;
  /* identical to box height, or 19px */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #FBFBFB;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

export const FilterHeaderContainer = styled.div`
  width: 100%;
  padding: 0 22px ;
`;

import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  UserContainer,
  AccountLinesContainer,
  EmailContainer,
  InputContainer,
  UserButtonsWrapper,
  SaveUserButton,
  CloseUserProfileContainer,
  ResetPasswordButton,
  ResetPasswordContainer,
  BottomWrapperNew,
} from "./styles";
import {
  AccountsTitle,
  Line,
  ButtonTitle,
  NavTitle,
  NavigationTitle,
} from "../../Components/AccountsHeader/styles";
import { CloseIconResetPassword } from "../ResetPassword/styles";
import close from "../../assets/svg/close.svg";
import { useNavigate } from "react-router-dom";
import EmailInput from "../../Components/EmailInput/EmailInput";
import InputField from "../../Components/InputField/InputField";
import {
  ButtonsContainer,
  CancelButton,
  LineBottom,
} from "../../Components/CreateNewAccount/styles";
import AlertDialog from "../../Components/Modal/index";
import BreadCrumbs from "../../Components/BreadCrubms";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  selectEmail,
  selectUserId,
  selectFullName,
} from "../../store/slices/general/selectors";
import { useSelector, useDispatch } from "react-redux";
import { updateProfile } from "../../store/slices/general";

const UserProfile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const email = useSelector(selectEmail);
  const userId = useSelector(selectUserId);
  const currentUserName = useSelector(selectFullName);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
  } = useForm();
  const { user } = getValues();
  const handleFieldChange = useCallback(
    (field: string, value: string) => {
      setValue(field, value);
      clearErrors(field);
    },
    [clearErrors, setValue]
  );

  useEffect(() => {
    if (handleFieldChange && currentUserName) {
      handleFieldChange("user", currentUserName);
    }
  }, [currentUserName, handleFieldChange]);

  const onSubmit = useCallback(() => {
    dispatch(updateProfile({ id: userId, name: user }));
  }, [dispatch, user, userId]);

  const onClickResetPassword = () => {
    setShowModal(true);
  };

  const handleDialogConfirm = () => {
    navigate("/reset-password-user");
  };

  return (
    <Container>
      <BreadCrumbs />

      <UserContainer>
        <InputContainer>
          <InputField
            {...register("user", {
              required: t("field.required") || "",
            })}
            placeholder={t("user.profile.user.name")}
            value={user}
            onChange={(text: string) => handleFieldChange("user", text)}
            onClickCloseHandle={() => handleFieldChange("user", "")}
            required={true}
            readonly={true}
            error={(errors.user?.message as string) || ""}
            title={t("user.profile.user.name") || ""}
          />
        </InputContainer>
        <EmailContainer>
          <EmailInput
            value={email}
            placeholder={t("user.profile.email.placeholder")}
            status={"readonly"}
            isDisabled={true}
            onChange={(text: string) => {}}
            isRequired={true}
            title={t("user.profile.user.email") || ""}
          />
        </EmailContainer>
        <BottomWrapperNew>
          <LineBottom />
          <UserButtonsWrapper>
            <ButtonsContainer>
              <CancelButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                {t("cancel.button")}{" "}
              </CancelButton>
              <SaveUserButton disabled={true}>
                <ButtonTitle>
                  {t("user.profile.save.button")} {">"}
                </ButtonTitle>
              </SaveUserButton>
            </ButtonsContainer>
          </UserButtonsWrapper>
        </BottomWrapperNew>
      </UserContainer>
      {showModal ? (
        <AlertDialog
          title={t("modal.title")}
          subTitle={t("modal.subtitle")}
          paragraph={t("modal.paragraph")}
          closeDialog={() => {
            setShowModal(false);
          }}
          handleConfirm={() => {
            handleDialogConfirm();
          }}
        />
      ) : (
        <></>
      )}
    </Container>
  );
};

export default UserProfile;

import { ReactNode } from "react";
import { Route } from "react-router-dom";
import PageWrapper from "../Components/layout/PageWrapper";
import appRoutes from "./appRoutes";
import { RouteType } from "./config";

const generateRoute = (routes: RouteType[]): ReactNode => {
  const x =  routes.map((route, index) => {
    if (route.index) {
      return (<Route
        index
        path={route.path}
        element={<PageWrapper state={route.state}>
          {route.element}
        </PageWrapper>}
        key={index}
      />);
    }
    else {
      return (      <Route
        path={route.path}
        element={
          <PageWrapper state={route.child ? undefined : route.state}>
            {route.element}
          </PageWrapper>
        }
      >
        {route.child && (
          generateRoute(route.child)
        )}
      </Route>)
    };
  });
  return x;
};

export const routes: ReactNode = generateRoute(appRoutes);

import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import "react-toastify/dist/ReactToastify.min.css";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import store from './store';
import { ToastContainer } from 'react-toastify';
import theme from './theme';
import i18n from './translations/i18n';

const element = document.getElementById('root');
const root = createRoot(element!);

root.render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ToastContainer 
              bodyClassName={() => "text-sm font-white font-med block p-3"}
              position="top-center"
              autoClose={3000}
              hideProgressBar={false}
              closeButton={false} 
          />
          <App />
        </ThemeProvider>
      </I18nextProvider>
    </Provider>);
// ReactDOM.render(<App />, document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import styled from "styled-components";

export const Container = styled.div `

`;

export const ResetPasswordHeader = styled.div `
`;

export const ResetPasswordUserContainer = styled.div `
  position: relative;
  margin-top: 26px;
  height: calc(100vh - 270px);
  background: #FFFFFF;
  border-radius: 10px;
  padding: 47px 0 0 56px;
  margin-right: 6px;
  overflow: auto;
`;

export const LineTitle = styled.div `
  /* Main Orange */
  width: 365px;
  height: 0;
  border: 3px solid #FF6600;
  border-radius: 5px;
`;

export const InterPassword = styled.div `
  height: 22px;
  left: calc(50% - 218px/2 - 366px);
  top: calc(50% - 22px/2 - 212px);

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;
  /* identical to box height, or 22px */

  text-align: left;
  letter-spacing: -0.02em;

  color: #000000;
  padding-bottom: 62px;
`;

export const PasswordContainer = styled.div `
  display: flex;
  justify-content: left;
  padding: 11px 0 33px 0;
  width: 468px;
`;

export const NewPasswordContainer = styled.div `
  display: flex;
  justify-content: left;
  padding: 11px 0 31px 0;
  width: 468px;
`;

export const ConfirmPasswordContainer = styled.div `
  display: flex;
  justify-content: left;
  padding: 11px 0 31px 0;
  width: 468px;
`;

export const ResetPasswordButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  margin-top: 24px;
  justify-content: flex-end;
`;

export const SubmitBtn = styled.button `
  background: #FB5E03;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
  height: 53px;
  padding: 17px 26px;
  gap: 10px;
  font-family: 'Inconsolata';
  font-weight: 700;
  font-size: 17px;
  line-height: 111%;
  /* identical to box height, or 19px */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  /* Light Grey */

  color: #EEEEEE;
  border: 0px;
  cursor: pointer;
`;

export const ResetPasswordButton = styled.div `
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 17px 26px;
  gap: 10px;
  cursor: pointer;
  height: 53px;

  background: #FB5E03;
  border-radius: 26.5px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const CloseResetPasswordContainer = styled.div `
  position: absolute;
  top: 13px;
  right: 13px;
`;

export const CloseIconResetPassword = styled.img `
  width: 16px;
  height: 17px;
  cursor: pointer;
`;

import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPath } from "../../store/slices/general";
type Props = {
  state?: string,
  children: ReactNode;
};

const PageWrapper = (props: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.state) {
      dispatch(setPath(props.state));
    }
  }, [dispatch, props]);

  return (
    <>{props.children}</>
  );
};

export default PageWrapper;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { axiosInstance } from "../network/client";
import { logout } from "../store/slices/general";
import { BEErrorResponse } from "../types/BEResponse";
import useToast from "./useToast";

const useAuth = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(() => {
    // Add a response interceptor
    setLoaded(true);
    axiosInstance.interceptors.response.use(
      function (response) {
        // Do something with response data
        return response;
      },
      function (error: any) {
        const suppressErrorToast = error.config.suppressErrorToast;
        console.log("suppressErrorToast", suppressErrorToast);
        if (
          !suppressErrorToast &&
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.length > 0
        ) {
          error.response.data.errors.forEach((error: BEErrorResponse) => {
            if (!error.field) {
              toast.error(error.reason);
            }
          });
        }
        // Do something with response error
        if (error.response.status === 401) {
          dispatch(logout());
        }
        return Promise.reject(error);
      }
    );
  }, [dispatch, toast]);
  return loaded;
};

export default useAuth;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Gradient,
  Info,
  LeftSide,
  Return,
  ReturnContainer,
  ReturnSignIn,
  RightSide,
  SubmitBtn,
  SubTitleSignIn,
  TitleSignIn,
  WelcomeInfo,
  WelcomeSubTitle,
  WelcomeTitle,
} from "../ResetPasswordSignIn/styles";
import Footer from "../Footer/Footer";
import { useForm } from "react-hook-form";
import { resetPasswordTokenRequest } from "../../store/slices/general";
import { useDispatch } from "react-redux";
import PasswordInput from "../PasswordInput/PasswordInput";
import { PasswordContainer } from "../Welcome/styles";
import { useTranslation } from "react-i18next";
import { translations } from "../../translations/translations";
import { useSelector } from "react-redux";
import { selectResetPasswordTokenResp } from "../../store/slices/general/selectors";
import useToast from "../../hooks/useToast";
import { BEErrorResponse } from "../../types/BEResponse";

const SetNewPasswordSignIn = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const [token, setToken] = useState<string>("");
  const dispatch = useDispatch();
  const [errorType, _] = useState<"error" | "warning">("error");
  const resetPasswordTokenResp = useSelector(selectResetPasswordTokenResp);

  useEffect(() => {
    const queryParams = new URLSearchParams(
      window.location.search.replace("?", "")
    );
    if (queryParams.has("token")) {
      setToken(queryParams.get("token") || "");
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const {
    setError,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
  } = useForm();
  const { password, confirmPassword } = getValues();

  console.log("errors ", errors, password, confirmPassword);

  const handleChangePassword = (field: string, newPassword: string) => {
    // Set the new password value using setValue
    setValue(field, newPassword);
    clearErrors(field);
  };

  const onSubmit = () => {
    dispatch(
      resetPasswordTokenRequest({
        resetPasswordToken: token,
        password: password,
      })
    );
  };

  useEffect(() => {
    if (
      resetPasswordTokenResp &&
      resetPasswordTokenResp.httpStatusCode === 200
    ) {
      toast.success(
        resetPasswordTokenResp.reason || resetPasswordTokenResp.message || ""
      );
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else if (
      resetPasswordTokenResp &&
      resetPasswordTokenResp.httpStatusCode !== 200
    ) {
      const errors = resetPasswordTokenResp.errors || [];
      errors.forEach((err: BEErrorResponse) => {
        if (err.field) {
          setError(err.field, {
            type: "server",
            message: err.reason || err.message,
          });
        }
      });
    }
  }, [dispatch, navigate, resetPasswordTokenResp, setError, toast]);

  const handleReturnToSignIn = () => {
    navigate("/signup");
  };

  return (
    <Gradient>
      <Container>
        <LeftSide>
          <WelcomeTitle>{t("login.title")}</WelcomeTitle>
          <WelcomeSubTitle>{t("login.subtitle")}</WelcomeSubTitle>
          <WelcomeInfo>{t("login.info")}</WelcomeInfo>
        </LeftSide>
        <RightSide>
          <TitleSignIn>{t("reset.password.sign.in.title")}</TitleSignIn>
          <SubTitleSignIn>{t("reset.password.title")}</SubTitleSignIn>
          <PasswordContainer>
            <PasswordInput
              value={password}
              {...register("password", {
                required: t(translations["field.required"]) || "",
                minLength: {
                  value: 10,
                  message: t(translations["password.length"]) || "",
                },
                pattern: {
                  value: /^(?=.*[a-zA-Z])(?=.*\d).{10,}$/,
                  message: t(translations["password.must.contain"]) || "",
                },
              })}
              placeholder={""}
              status={"editable"}
              onChange={(pass) => handleChangePassword("password", pass)}
              isRequired={true}
              title={t("new_password_input.title") || ""}
              signInScreen={true}
              error={(errors.password?.message as string) || ""}
              errorType={errorType}
            />
          </PasswordContainer>
          <PasswordContainer>
            <PasswordInput
              {...register("confirmPassword", {
                required: t(translations["field.required"]) || "",
                validate: (value) =>
                  value === password ||
                  t(translations["password.not.match"]) ||
                  "",
              })}
              value={confirmPassword}
              placeholder={""}
              status={"editable"}
              onChange={(pass) => handleChangePassword("confirmPassword", pass)}
              isRequired={true}
              title={t("confirm_password_input.title") || ""}
              error={(errors.confirmPassword?.message as string) || ""}
              errorType={errorType}
              signInScreen={true}
            />
          </PasswordContainer>
          <SubmitBtn type="submit" onClick={handleSubmit(onSubmit)}>
            {t(translations["reset.password.button"])} {">"}
          </SubmitBtn>
          <Info>
            {t(translations["reset.password.sign.in.info1"])}
            {"\n"}
            {t(translations["reset.password.sign.in.info2"])}
          </Info>
          <ReturnContainer>
            <Return>{t(translations["reset.password.sign.in.return"])}</Return>
            <ReturnSignIn
              onClick={() => {
                handleReturnToSignIn();
              }}
            >
              {t(translations["reset.password.sign.in.button"])}
            </ReturnSignIn>
          </ReturnContainer>
        </RightSide>
      </Container>
      <Footer />
    </Gradient>
  );
};

export default SetNewPasswordSignIn;
